import React, {useEffect, useState} from 'react';
import {googleMapsApiKey} from "../../env";
import {GoogleMap, LoadScript, Marker, InfoWindow} from '@react-google-maps/api';
import mapSpotGroup from '../../assets/icons/map-spot-group.svg'
import mapStand from '../../assets/icons/map-stand.svg'
import SquareBookable from "../SquareBookable/SquareBookable";
import useBookable from "../../hooks/useBookable";
import Loading from "../Loading/Loading";
import Button from "../Button/Button";
import "./style.scss";

const MapBookables = (props) => {
  const {bookables, isLoading, filter, setFilter, setFilterByPath} = props
  const [bookable, setBookable] = useState({});
  const [map, setMap] = useState(null)
  const [showSearchButton, setShowSearchButton] = useState(false)
  const {isStand} = useBookable()
  const defaultCenter = {
    lat: 52.497118623051904, lng: 5.5198451726829845
  }
  const [mapCoordinates, setMapCoordinates] = useState(defaultCenter)


  useEffect(() => {
    isLoading && setBookable({})
    isLoading && setShowSearchButton(false)
  }, [isLoading])

  /**
   *
   */
  const onZoomChanged = () => {
    if (!map) return
    updateFilter()
  }

  /**
   * Calculate km
   *
   * @param zoomLevel
   * @param lat
   * @returns {number}
   */
  const calculateKm = (zoomLevel, lat) => {
    return Math.round((38000 / Math.pow(2, zoomLevel - 3) * Math.cos(lat * Math.PI / 180)) * 0.3);
  }

  /**
   *
   */
  const onCenterChanged = () => {
    if (!map) return
    if (map.center.lat() !== mapCoordinates.lat || map.center.lng() !== mapCoordinates.lng) {
      //change has occurred
      updateFilter()

      // setMapCoordinates({
      //   lat: map.center.lat(),
      //   lng: map.center.lng(),
      // })

    }
  }

  /**
   * Update filter
   */
  const updateFilter = () => {
    filter.map.lat = map.center.lat()
    filter.map.lng = map.center.lng()
    filter.map.radius = calculateKm(map.zoom, map.center.lat())
    setFilter(filter)
    setShowSearchButton(true)
  }

  /**
   *
   */
  const onResize = () => {
    if (!map) return
    updateFilter()
  }


  /**
   * Handle search
   */
  const handleSearch = () => {
    setBookable({})
    setShowSearchButton(false)
    setFilterByPath('page', 'map', true)
  }

  return (
    <div className="component-map-bookables">
      <div className={(showSearchButton) ? 'search-button-wrapper show' : 'search-button-wrapper hide'}>
        <Button
          label="Zoek in dit gebied"
          color="green"
          onClick={handleSearch}
        />
      </div>
      <div className={(bookables && bookables.length >= 50) ? "warning show " : "warning hide"}>
        <p>Er zijn meer dan 50 zoekresultaten, Verfijn je zoekopdracht: zoom in, pas filter aan</p>
      </div>
      {isLoading &&
        <div className="is-loading">
          <Loading/>
        </div>
      }

      <LoadScript
        googleMapsApiKey={googleMapsApiKey}>
        <GoogleMap
          onLoad={map => {
            setMap(map)
          }}
          mapContainerClassName={'map-container-inner'}
          zoom={8}
          center={mapCoordinates}
          clickableIcons={false}
          streetViewControl={false}
          onZoomChanged={onZoomChanged}
          onClick={() => setBookable({})}
          onCenterChanged={onCenterChanged}
          onResize={onResize}
          options={{gestureHandling: "greedy"}}
          // disableDefaultUI={true}
          // streetView={false}
          // options={{featureType: 'landscape.natural', stylers: [{visibility: 'off'}]}}
          // onBoundsChanged={onBoundsChanged}
          // onMouseUp={onMouseUp}
          // options={{ gestureHandling: 'none', disableDefaultUI: true}}
        >
          {bookables.map((bookable, i) => {
            return (
              <Marker
                key={i}
                position={{
                  lat: parseFloat(bookable.lat),
                  lng: parseFloat(bookable.lng),
                }}
                onClick={() => {
                  setBookable(bookable)
                }}
                icon={(isStand(bookable)) ? mapStand : mapSpotGroup}
              />
            )
          })}
          {bookable.lat &&
            <InfoWindow
              position={{
                lat: parseFloat(bookable.lat),
                lng: parseFloat(bookable.lng),
              }}
              clickable={true}
              onCloseClick={() => setBookable({})}
            >
              <SquareBookable
                bookable={bookable}
                responsive={true}
              />
            </InfoWindow>
          }
        </GoogleMap>
      </LoadScript>
      <style>
        {"\
        .gm-style .gm-style-iw{\
          max-width:360px!important;\
          border-radius: 27px;\
          overflow:visible!important;\
        }\
        @media only screen and (max-width: 575px) {\
        .gm-style .gm-style-iw{max-width:260px!important;}\
        }\
        .gm-style .gm-style-iw > button{\
          width: 40px!important;\
          height: 40px!important;\
          top: 0px!important;\
          right: 5px!important;\
          background: white!important;\
        }\
        .gm-style .gm-style-iw > button span{\
          width: 24px!important;\
          height: 24px!important;\
        }\
        .gm-style .gm-style-iw-d::-webkit-scrollbar-track, .gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece{background:transparent!important;}\
        .gm-style .gm-style-iw-d::-webkit-scrollbar{width: 12px;!important;}\
      "}
      </style>
    </div>
  )
}

const isEqual = (prevProps, nextProps) => {
  // console.log('isEqual', prevProps, nextProps)
  return false
}

export default React.memo(MapBookables, isEqual)
