import React from "react";
// import {useNavigate} from "react-router-dom";
import './style.scss';

//todo: Button met iets van 'check opnieuw' (of automatisch na x sec. checken of iemand bevestigd heeft
//todo: Button met Stuur nogmaals? nadenken over flow; ik kan nu ff niet nadenken

const WaitingForVerification = () => {
  // const navigate = useNavigate();

  return (
    <div className="page-waiting-for-verification">
      <h1>Welkom bij Standplaats.nl</h1>
      <p>Je hebt van ons een bevestigingsmail ontvangen.</p>
      <p>Voordat je verder gaat is het <strong>belangrijk dat je deze bevestigd in de mail</strong>.</p>
      <br/>
      <p>Heb je deze mail niet ontvangen?</p>
      <p>Wacht enkele minuten, of check je spambox.</p>
      <p>(Nog steeds niks, probeer even opnieuw in te loggen, op deze manier wordt de mail opnieuw verstuurd)</p>

      {/*<p>Niks ontvangen? Wacht enkele minuten, of check de spam folder! Als je daarna nog steeds niks ontvangen hebt kun je proberen om opnieuw in te loggen, waarna je de mogelijkheid hebt om een nieuwe mail te versturen.</p>*/}
      <br/>
      <br/>
      <br/>
      <br/>
      {/*<Button*/}
      {/*  label="Opnieuw controlleren"*/}
      {/*  color="grey-ghost"*/}
      {/*  onClick={() => navigate('/inloggen')}*/}
      {/*/>*/}
    </div>
  )
}

export default WaitingForVerification
