import React from "react";
import logoIcon from "../../assets/icons/logo-icon.svg";
import logo from "../../assets/icons/logo.svg";
import {useNavigate} from "react-router-dom";
import './style.scss'

const LogoContainer = () => {

  const navigate = useNavigate()

  return (
    <a href="/" onClick={(e) => {

      //todo this is a hack, but it works. Problem is that i need a
      // way to disable the map view on the homepage when user clicks logo.
      // since that state is deep in the flow, i do this.
      e.preventDefault()
      if (window.location.pathname === '/') {
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.delete('page'); // Adjust the parameter name as needed
        const hasParams = Array.from(queryParams).length
        window.location = window.location.pathname+(hasParams)?'?'+queryParams:''
      } else {
        navigate('/')
      }
    }}>
      <div className="component-logo-container">
        <div className="logo-wide">
          <img src={logo} className="logo" width="200" alt="Standplaats.nl logo"/>
        </div>
        <div className="logo-small">
          <img src={logoIcon} className="logo-icon" width="42" alt="Standplaats.nl logo"/>
        </div>
      </div>
    </a>
  )
}
export default LogoContainer
