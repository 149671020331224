import useApi from "../../api/useApi";
import useFunction from "../../hooks/useFunction";

const useSpotGroupSpotsService = () => {

  const {api} = useApi()
  const {c} = useFunction()

  const userSpotMapShow = (callbackSuccess, callbackValidationErrors, spotGroupId, data) => {
    return api(
      'get',
      'user/spot_group/' + spotGroupId + '/spot_map/show',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error => c('Error in Service', error),
      data,
    )
  }

  const userSpotMapUpdate = (callbackSuccess, callbackValidationErrors, spotGroupId, data) => {
    return api(
      'post',
      'user/spot_group/' + spotGroupId + '/spot_map/update',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error => c('Error in Service', error),
      data,
    )
  }

  return {
    objectType: 'spot_group_spots',
    userSpotMapShow,
    userSpotMapUpdate,
  }
}

export default useSpotGroupSpotsService
