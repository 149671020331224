import React from "react";
import BlockList from "../BlockList/BlockList";
import './SelectBlock-style.scss'

const SelectBlock = (props) => {
  const {
    label,
    value,
    data,
    className,
    hasError,
    error,
    isLoading,
    formData,
    setFormData,
  } = props

  /**
   *
   */
  const onChange = () => {
    setFormData(prevFilter => {
      return {
        ...prevFilter,
        collection: formData.collection, //todo change to mdoel
        isChanged:true,
      }
    })
  }

  return (
    <div className={className + 'select-block '}>
      <label>{label}
        {hasError && <span className="error"> - {error}</span>}
        <div className="container">
          {!isLoading && value &&
            <BlockList
              data={data}
              state={value}
              setStateCallback={onChange}
              selectParents={true}
            />
          }
        </div>
      </label>
    </div>
  )
}

export default SelectBlock
