import React, {useEffect} from "react";
import PropTypes from "prop-types";
import useMediaService from "../../api/services/useMediaService";
import useFunction from "../../hooks/useFunction";
import {default as MediaElement} from "../Media/Media";
import Info from "../Info/Info";
import swal from "sweetalert";
import './Media-style.scss'
// import {default} from "gsap";

//todo could be refactored, but it works.
//todo has a bug: does check after 'cancel' as 'still-to-be-deleted' was done after a deleted image - not yet saved -

const Media = (props) => {
  const {
    label,
    name,
    value,
    className,
    model,
    hasError,
    onChange,
    error,
    formData,
    setFormData,
    isLoading,
    width,
    height,
    exampleImage,
    size,
    allowSvg,
    save,
  } = props

  const classNameBase = className + 'media '
  const {c} = useFunction()
  const [imageQuality, setImageQuality] = React.useState(0)
  const [imageQualityString, setImageQualityString] = React.useState('') //todo implement
  const [dynamicHeight, setDynamicHeight] = React.useState(height)
  const {userShowInfo} = useMediaService()

  /**
   *
   */
  useEffect(() => {
    if (value) {
      if (!('type' in value && value.type === 'svg')) {
        if ('file' in value && value.file) {

          /* Check allowed filetypes */
          if (allowSvg === false && value.file.type === 'image/svg+xml') {
            swal(
              {
                title: 'Geen SVG toegestaan',
                text: 'SVG bestanden zijn niet toegestaan',
                icon: 'error',
                closeOnClickOutside: false,
                buttons: {
                  confirm: {
                    text: "Oke",
                    value: true,
                    visible: true,
                    className: 'btn-color-green btn-size-m',
                    closeModal: true
                  }
                }
              })
            formData.model[name] = null
            save()
            return
          }

          if (!
            (
              value.file.type === 'image/jpeg' ||
              value.file.type === 'image/jpg' ||
              value.file.type === 'image/png' ||
              value.file.type === 'image/gif' ||
              value.file.type === 'image/svg+xml' ||
              value.file.type === 'image/bmp'
            )
          ) {

            let text = 'Ondersteunde formats: jpeg, jpg, png, gif, bmp'
            text += (allowSvg) ? ', svg' : ''

            swal(
              {
                title: 'Geen correct afbeelding bestand',
                text: text,
                icon: 'error',
                closeOnClickOutside: false,
                buttons: {
                  confirm: {
                    text: "Oke",
                    value: true,
                    visible: true,
                    className: 'btn-color-green btn-size-m',
                    closeModal: true
                  }
                }
              })
            formData.model[name] = null
            save()
            return
          }

          if (value.file.type === 'image/svg+xml') {
            setImageQuality(10)
          } else {
            getQualityRating(value.file)
            const size = ((value.file.size / 1024) / 1024).toFixed(4)
            if (size > 6) {
              swal(
                {
                  title: 'Ableeding te groot',
                  text: 'Afbeelding mag niet meer zijn dan 5 MB',
                  icon: 'error',
                  closeOnClickOutside: false,
                  buttons: {
                    confirm: {
                      text: "Oke",
                      value: true,
                      visible: true,
                      className: 'btn-color-green btn-size-m',
                      closeModal: true
                    }
                  }
                })
              formData.model[name] = null
            }
          }
        } else if (value && 'id' in value && value.id) {
          getQualityRatingFromInfo(value.id)
        } else {
          if (!height) {
            setDynamicHeight(0)
          }
          setImageQuality(0)
        }
      } else {
        setImageQuality(10)
      }
    } else {
      setImageQuality(0)
    }
  }, [value])

  /**
   *
   * @param e
   */
  const handleDelete = (e) => {
    e.preventDefault()
    model[name] = null
    setFormData(prevFormData => {
      return {
        ...prevFormData,
        collection: formData.collection,
        isChanged: true,
        isStored: false,
      }
    })
  }

  /**
   * Get the quality rating from the image
   *
   * @param imgSize
   * @returns {number}
   */
  const getRating = (imgSize) => {
    if (imgSize > (width * 2)) {
      return 10
    } else if (imgSize > width) {
      return 8
    } else if (imgSize > (width / 2)) {
      return 6
    } else if (imgSize > (width / 4)) {
      return 4
    } else if (imgSize > (width / 8)) {
      return 2
    } else {
      return 1
    }
  }

  /**
   * Get the quality rating from the image info
   *
   * @param id
   */
  const getQualityRatingFromInfo = (id) => {
    userShowInfo((data) => {
        setQualityRating({width: data.width, height: data.height})
      },
      (errors) => {
        c('errors', errors)
      },
      id
    )
  }

  /**
   * Set the quality rating
   *
   * @param props
   */
  const setQualityRating = (props) => {
    const widthRating = getRating(props.width)
    const heightRating = getRating(props.height)
    if (!height) {
      const scale = width / props.width
      setDynamicHeight(props.height * scale)
    }
    setImageQuality((widthRating < heightRating) ? widthRating : heightRating)
  }

  /**
   * Get the quality rating from the image
   *
   * @param image
   */
  const getQualityRating = (image) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(image);
    fileReader.onload = function () { // File is loaded
      const image = new Image();
      image.onload = function () { // The image is loaded; sizes are available
        setQualityRating({width: image.width, height: image.height})
      };
      image.src = fileReader.result; // Is the data URL because called with readAsDataURL
    };
  }

  /**
   *
   * @return {false}
   */
  const hasNewImage = () => {
    return (value && 'file' in value && value.file && 'name' in value.file && value.file.name)
  }

  /**
   *
   * @return {boolean}
   */
  const hasProcessedImage = () => {
    return (value && typeof value === 'object' && 'id' in value)
  }

  /**
   *
   * @return {JSX.Element}
   */
  const imageForm = () => {
    let media = model[name]
    return (
      <div className="image-form">
        <MediaElement media={media} size={size}/>
        <div className="delete-button btn-size-xs btn-color-red" onClick={handleDelete}>Verwijder</div>
      </div>
    )
  }

  /**
   *
   * @return {JSX.Element}
   */
  const uploadForm = () => {
    return (
      <>
        {hasNewImage()
          ?
          <div className="image-form">
            <img src={URL.createObjectURL(value.file)} alt=""/>
            <div className="browse-button btn-size-xs btn-color-grey">bladeren</div>
          </div>
          :
          <div className="upload-form">
            <div>&#43;</div>
            <p>Foto toevoegen</p>
          </div>
        }
        <input
          disabled={isLoading}
          accept="image/*"
          type="file"
          name={name}
          // value={value && typeof value === 'object' && 'value' in value ? value.value : ''}
          onChange={onChange}
          alt="Selecteer afbeelding"
        />
      </>
    )
  }

  /**
   *
   * @returns {JSX.Element}
   */
  const tooltipContent = () => {
    return (
      <div>
        {exampleImage &&
          <>
            <p style={{color: "white"}}>Voorbeeld afbeelding:</p>
            <img src={exampleImage} alt="Voorbeeld afbeelding"/>
          </>
        }
        <p style={{color: "lightgray", margin: 0}}>Voldoende:
          Breedte: {width}px{(height) ? ', Hoogte: ' + height + 'px.' : ''}</p>
        <p style={{color: "lightgray", margin: 0}}>Perfect:
          Breedte: {width * 2}px{(height) ? ', Hoogte: ' + height * 2 + 'px.' : ''}</p>
      </div>
    )
  }

  /**
   *
   * @param className
   * @returns {string}
   */
  const getClassName = (className) => {
    if (!imageQuality) {
      return className + ' disabled'
    }
    if (imageQuality < 4) {
      return className + ' low'
    }
    if (imageQuality < 7) {
      return className + ' medium'
    }
    return className + ' high'
  }


  return (
    <div className={hasNewImage() ? classNameBase + ' no-pointer-events' : classNameBase}>
      <div className="container-outer">{label} {width && <Info content={tooltipContent()}/>}
        <div className=" row-quality">
          <div className="">
            Kwaliteit:
          </div>
          <div className={getClassName('image-quality')}>
            <span>{(imageQuality) ? imageQuality : null}</span>
            <div
              className="bar"
              style={{width: imageQuality * 10 + '%'}}
            ></div>
          </div>
          {imageQualityString &&
            <div className={getClassName('image-quality-string')}>
              {imageQualityString}
            </div>
          }
        </div>
        {hasError &&
          <span className="error"> - {error}</span>
        }
        <label className="container-inner" style={{
          maxWidth: width,
          maxHeight: (dynamicHeight) ? height : 'unset',
        }}>
          <div
            className={isLoading ? "media-form-container disabled-loading" : "media-form-container"}
            style={{
              paddingBottom: (dynamicHeight && width) ? (dynamicHeight / width) * 100 + '%' : 'unset',
              height: (!height) ? 350 : 'unset',
            }}
          ></div>
          {hasProcessedImage() ? imageForm() : uploadForm()}
        </label>
      </div>
    </div>
  )
}

Media.defaultProps = {
  label: '',
  name: '',
  value: {},
  className: '',
  hasError: false,
  onChange: () => {
  },
  error: '',
  show: true,
  allowSvg: true,
}

Media.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  className: PropTypes.string,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number,
  size: PropTypes.string.isRequired,
  show: PropTypes.bool,
  allowSvg: PropTypes.bool,
}

const areEqual = (prevProps, nextProps) => {

  //todo this comparison is not always working correctly
  if (prevProps.value !== nextProps.value) {
    return false
  }

  return true
}

export default React.memo(Media, areEqual)
