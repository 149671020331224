import React from "react";
import facebookIcon from "../../assets/icons/facebook.svg";
import googleIcon from "../../assets/icons/google.svg";
import linkedIn from "../../assets/icons/linkedIn.svg";
import Button from "../Button/Button";
import "./style.scss"

const SocialLoginButtons = () => {
  return (
    <div className="component-social-login-buttons">
      <Button
        label="Facebook Login"
        icon={facebookIcon}
        color="grey-ghost"
        size="s"
        link="https://backend.standplaats.nl/api/login_provider/facebook"
      />
      <Button
        label="Google Login"
        icon={googleIcon}
        color="grey-ghost"
        size="s"
        link="https://backend.standplaats.nl/api/login_provider/google"
      />
      <Button
        label="LinkedIn Login"
        icon={linkedIn}
        color="grey-ghost"
        size="s"
        link="https://backend.standplaats.nl/api/login_provider/linkedin-openid"
      />
    </div>
  )
}

export default SocialLoginButtons
