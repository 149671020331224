import React from "react";
import Button from "../../components/Button/Button";
import icon from "../../assets/icons/stand-white.svg"
import './style.scss'
import useApi from "../../api/useApi";
import Loading from "../../components/Loading/Loading";


const TestPage = () => {




  return (
    <div className="testpage">




      {/*<Button*/}
      {/*  label={"Buttonasd a"}*/}
      {/*  onClick={() => console.log('click')}*/}
      {/*  icon={icon}*/}
      {/*  iconReversed={false}*/}
      {/*  fullWidth={true}*/}
      {/*  justifyContent={"left"}*/}
      {/*/>*/}

      {/*<br/>*/}
      {/*<br/>*/}
      {/*<Button*/}
      {/*  label={"2"}*/}
      {/*  size={"s"}*/}
      {/*  color={"blue"}*/}
      {/*/>*/}

      {/*<br/>*/}
      {/*<br/>*/}
      {/*<Button*/}
      {/*  disabled={true}*/}
      {/*  label={"disabled Button"}*/}
      {/*/>*/}

      {/*<br/>*/}
      {/*<br/>*/}
      {/*<Button*/}
      {/*  label={"Button class red"}*/}
      {/*  className={"btn-red"}*/}
      {/*/>*/}

      {/*<br/>*/}
      {/*<br/>*/}
      {/*<Button*/}
      {/*  label={"link"}*/}
      {/*  link={"/testoage?test=1"}*/}
      {/*/>*/}

      {/*<br/>*/}
      {/*<br/>*/}
      {/*<Button*/}
      {/*  label={"link Outside"}*/}
      {/*  linkOutside={"https://www.google.com"}*/}
      {/*  target={"_blank"}*/}
      {/*/>*/}

      {/*<br/>*/}
      {/*<br/>*/}
      {/*<Button*/}
      {/*  label={"opacity"}*/}
      {/*  style={{opacity: 0.5}}*/}
      {/*/>*/}

      {/*<br/>*/}
      {/*<br/>*/}

      {/*<button disabled>*/}
      {/*  This a button*/}
      {/*</button>*/}

      {/*<br/>*/}
      {/*<br/>*/}
      {/*<button >*/}
      {/*  This a button*/}
      {/*</button>*/}

      {/*<br/>*/}
      {/*<br/>*/}

      {/*<div className={"btn"}>*/}
      {/*  This also is a button*/}
      {/*</div>*/}

      {/*<br/>*/}
      {/*<br/>*/}

      {/*<a href="#" className={"btn"}>*/}
      {/*  this also is a button*/}
      {/*</a>*/}

      {/*<br/>*/}
      {/*<br/>*/}

      {/*<div className="btn">*/}
      {/*  <div className="label">Also a button</div>*/}
      {/*</div>*/}

      {/*<br/>*/}
      {/*<br/>*/}

      {/*<div className="btn">*/}
      {/*  <i className="icon">I</i>*/}
      {/*  <div className="label">Also a button</div>*/}
      {/*</div>*/}

      {/*<br/>*/}
      {/*<br/>*/}

      {/*<div className="btn">*/}
      {/*  <i className="icon">I</i>*/}
      {/*  <div className="label">Also a button</div>*/}
      {/*</div>*/}

      {/*<br/>*/}
      {/*<br/>*/}

      {/*<div className="btn">*/}
      {/*  <div className="icon"><img src={icon} alt={"asd"}/></div>*/}
      {/*  <div className="label">Also a button</div>*/}
      {/*</div>*/}

      {/*<br/>*/}
      {/*<br/>*/}

      {/*<div className="btn">*/}
      {/*  <a href="#">Buttons</a>*/}
      {/*</div>*/}

    </div>
  )
}

export default TestPage
