import {Tooltip} from 'react-tooltip'
import {Link, Routes} from "react-router-dom";
import Button from "../../components/Button/Button";
import logoIcon from "../../assets/icons/logo-icon.svg";
import React from "react";
import logo from "../../assets/icons/logo.svg";
import './style.scss'

const Wizard = (props) => {
  const {
    form, //useForm object
    wizard, //useWizard object
    buttonTopRight,
  } = props

  const breadcrumbs = wizard.getBreadcrumbs(form.formData.model)

  return (
    <div className="layouts-wizard">
      <div className="header-row">
        <div className="section-container">
          <Link
            to={'/'}
          >
            <img
              src={logo}
              width="200"
              alt="Standplaats.nl"
              className="logo"
            />
            <img
              src={logoIcon}
              width="42"
              alt="Standplaats.nl"
              className="logo-icon"
            />
          </Link>
          <div className="navigation">
            {buttonTopRight && buttonTopRight}
            <Button
              label="Help"
              linkOutside="/service/help/"
              target="_blank"
              color="green-ghost"
              size="s"
            />
          </div>
        </div>
      </div>
      <div className="main-content section-container">
        <Tooltip id="tooltip-wizard-breadcrumbs"/>
        <div className="breadcrumbs-container">
          {wizard && wizard.finished &&
            <div className="breadcrumbs">
              {breadcrumbs.map((stepItem, i) => {
                return (
                  <div
                    className={(stepItem.slug === wizard.step) ? 'breadcrumb active ' : 'breadcrumb'}
                    data-tooltip-id={"tooltip-wizard-breadcrumbs"}
                    // data-tooltip-content={('label' in stepItem) ? stepItem.label : 'Stap ' + i}
                    data-tooltip-content={('label' in stepItem) ? stepItem.label : stepItem.slug + ' ' + (i + 1)}
                    key={i}
                    onClick={() => wizard.breadcrumbClick(stepItem)}
                  ></div>
                )
              })}
            </div>
          }
          {(!wizard || (wizard && !wizard.finished)) &&
            <div className="progress-bar">
              <div className="progress" style={{width: wizard.getProgress(breadcrumbs, wizard.step) + '%'}}></div>
            </div>
          }
        </div>
        <div className="children">
          <Routes>
            {props.children}
          </Routes>
        </div>
        <div className="bottom-nav row">
          <div className="col left">
            {wizard.getButtonValue(wizard.buttonPrev, 'visible', true) &&
              <Button
                label={wizard.getButtonValue(wizard.buttonPrev, 'label', 'Vorige')}
                className={wizard.getButtonValue(wizard.buttonPrev, 'className', "btn-color-grey")}
                onClick={wizard.getButtonValue(wizard.buttonPrev, 'onClick', () => wizard.prevStep(undefined, form.formData.model))}
                disabled={(
                  form.formData.isLoading || //todo
                  // wizard.form.formData.isLoading || //todo
                  wizard.getButtonValue(wizard.buttonPrev, 'disabled', false)
                )}
              />
            }
          </div>
          <div className="col right">
            {wizard.getButtonValue(wizard.buttonNext, 'visible', true, true) &&
              <Button
                label={wizard.getButtonValue(wizard.buttonNext, 'label', 'Volgende', true)}
                className={wizard.getButtonValue(wizard.buttonNext, 'className', "btn-color-green", true)}
                onClick={wizard.getButtonValue(wizard.buttonNext, 'onClick', () => wizard.nextStep(undefined, form.formData.model), true)}
                disabled={(
                  form.formData.isLoading ||
                  wizard.getButtonValue(wizard.buttonNext, 'disabled', false, true)
                )}
              />
            }
            {wizard && wizard.finished && wizard.getFinishStep().slug !== wizard.step &&
              <Button
                label="Finish"
                onClick={() => wizard.handleClickFinish()}
                disabled={(
                  form.formData.isLoading ||
                  form.formData.isChanged
                )}
              />
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Wizard
