import useApi from "../useApi";
import useFunction from "../../hooks/useFunction";

const useOptionStandService = (type) => {

  const {api} = useApi()
  const {c} = useFunction()

  const index = (callbackSuccess, callbackValidationErrors) => {
    return api(
      'get',
      'options/' + type,
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in Service', error),
    )
  }

  const create = (callbackSuccess, callbackValidationErrors, data) => {
    return api(
      'post',
      'options/' + type + '/create',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in Service', error),
      data
    )
  }

  return {
    index,
    create,
  }
}

export default useOptionStandService
