import React from "react";
import {Input} from "./Form";
import PropTypes from "prop-types";

const Address = (props) => {

  const {
    value,
    label,
    text,
    save,
    errorChild,
    error,
    autoFocus,
  } = props

  /**
   *
   * @param e
   */
  const onChange = (e) => {
    e.preventDefault()
    value[e.target.name] = e.target.value
    save()
  }

  /**
   *
   * @param name
   * @returns {*}
   */
  const hasError = (name) => {
    return (errorChild && errorChild.includes(name))
  }

  return (
    <div className={'field form-component-address '}>
      {label && <label>{label}</label>}
      {text && <p>{text}</p>}
      <br/>
      <Input
        label="Straat"
        name="street"
        value={value.street}
        onChange={onChange}
        className={(hasError('street'))?' has-error ':null}
        hasError={hasError('street')}
        error={(hasError('street'))?error[0]:null}
        autoFocus={autoFocus}
      />
      <Input
        label="Huisnummer"
        name="house_number"
        type="number"
        value={value.house_number}
        onChange={onChange}
        className={(hasError('house_number'))?' has-error ':null}
        hasError={(hasError('house_number'))}
        error={(hasError('house_number'))?error[0]:null}
      />
      <Input
        label="Huisnummer toevoeging"
        name="house_number_addition"
        value={value.house_number_addition}
        onChange={onChange}
        className={(hasError('house_number_addition'))?' has-error ':null}
        hasError={(hasError('house_number_addition'))}
        error={(hasError('house_number_addition'))?error[0]:null}
      />
      <Input
        label="Postcode"
        name="postal_code"
        value={value.postal_code}
        onChange={onChange}
        className={(hasError('postal_code'))?' has-error ':null}
        hasError={(hasError('postal_code'))}
        error={(hasError('postal_code'))?error[0]:null}
      />
      <Input
        label="Plaats"
        name="city"
        value={value.city}
        onChange={onChange}
        className={(hasError('city'))?' has-error ':null}
        hasError={(hasError('city'))}
        error={(hasError('city'))?error[0]:null}
      />
      <Input
        label="Extra uitleg/opmerking"
        name="extra_field"
        value={value.extra_field}
        placeholder="Bijvoorbeeld: 'Plein Grote Markt, achter de kerk'"
        onChange={onChange}
        className={(hasError('extra_field'))?' has-error ':null}
        hasError={(hasError('extra_field'))}
        error={(hasError('extra_field'))?error[0]:null}
      />
    </div>
  )
}

Address.defaultProps = {
  autoFocus:'',
}
//todo add others
Address.propTypes = {
  autoFocus:PropTypes.string,
}


export default Address
