import React from "react";
import {default as LoginComp} from "../../components/Login/Login";
import './style.scss';

const Login = () => {

  return (
    <div className="page-login">
      <h1>Welkom terug!</h1>
      <LoginComp
        showRegisterButton={true}
      />
    </div>
  )
}



export default Login
