import React, {useEffect, useState} from "react";
import initPrice from "../../data/initPrice";
import {Price} from "./Form";
import swal from "sweetalert";
import Popup from '../../components/Popup/Popup';
import Button from "../Button/Button";
import Loading from "../Loading/Loading";
import usePrice from "../../hooks/usePrice";
import useFunction from "../../hooks/useFunction";
import addIcon from "../../assets/icons/add-white.svg"
import PropTypes from "prop-types";
import './Options-style.scss'

const Options = (props) => {
  const {
    label,
    value,
    className,
    hasError,
    error,
    text,
    isLoading,
    formData,
    setFormData,
    apiService,
    save,
    name,
    type,
    modelLabel,
    modelLabels,
    showPrice,
  } = props
  const [indexOptions, setIndexOptions] = useState({})
  const [isLoadingIndexOptions, setIsLoadingIndexOptions] = useState(true)
  const [isLoadingCreateOption, setIsLoadingCreateOption] = useState(false)
  const [filterValue, setFilterValue] = useState('')
  const [createLabelValue, setCreateLabelValue] = useState('')
  const [showPopupCreate, setShowPopupCreate] = useState(false)
  const [showPopupAdd, setShowPopupAdd] = useState(false)
  const [showPopupEdit, setShowPopupEdit] = useState([])
  const {objIsEmpty} = useFunction()
  const {formatPrice} = usePrice()

  /**
   * Load indexOptions array from server when showPopupAdd is opened
   */
  useEffect(() => {
    if (showPopupAdd && objIsEmpty(indexOptions)) {
      apiService.index(
        (indexOptions) => {
          setIndexOptions(indexOptions)
          setIsLoadingIndexOptions(false)
        }
      )
    }
  }, [showPopupAdd])

  /**
   *
   * @param e
   */
  const onChangeFilter = (e) => {
    setFilterValue(e.target.value)
  }

  /**
   *
   * @param string
   * @returns {string}
   */
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  /**
   *
   * @param option
   * @param i
   */
  const handleAdd = (option, i) => {
    option.type = type
    const newOption = {
      option_id: option.id,
      option: option,
      label: '-',
      max_per_booking: 0,
      max_per_spot: 1,
    }

    if (showPrice) {
      newOption.price = {...initPrice, tempId: Date.now()} // Temporary unique ID
      newOption.price.default = 0
    }

    value.push(newOption)
    save()

    swal({
      title: 'Success',
      icon: 'success',
      timer: 650,
      buttons: false,
    }).then(r => {

      setShowPopupEdit({...showPopupEdit, [value.length - 1]: true}) //has bug (isChanged is overriden)
      // setShowPopupEdit([...showPopupEdit, i]) //has bug (isChanged is overriden)
      setShowPopupCreate(false)

      /* patch. not sure why this is needed */
      formData.isChanged = true
      save()
    })
  }

  /**
   *
   * @param option
   * @param i
   */
  const handleRemove = (option, i) => {
    value[i].is_trash = true
    save()
  }

  /**
   *
   * @param e
   */
  const handeLabelChange = (e) => {
    setCreateLabelValue(e.target.value)
  }

  /**
   *
   * @param e
   * @param i
   */
  const onChangeInput = (e, i) => {
    value[i][e.target.name] = e.target.value
    save()
  }

  /**
   *
   */
  const handleCreate = () => {
    setIsLoadingCreateOption(true)
    apiService.create(
      (data) => {
        setIsLoadingCreateOption(false)
        const newIndexOptions = [...indexOptions, data.option]
        setIndexOptions(newIndexOptions)
        swal({
          title: 'Success',
          icon: 'success',
          timer: 650,
          buttons: false,
        }).then(r => setShowPopupCreate(false))
      },
      () => {
      },
      {label: createLabelValue}
    )
  }

  /**
   * Subcomponent for max to-book options input field
   *
   * @param props
   * @returns {JSX.Element}
   * @constructor
   */
  const InputMax = (props) => {
    const {label, modelOption, i, modelKey} = props
    return (
      <>
        <label>{label}<br/>
          {modelOption[modelKey] ?
            <div className="row">
              <div className="col">
                <input
                  value={modelOption[modelKey]}
                  onChange={(e) => onChangeInput(e, i)}
                  name={modelKey}
                  type="number"
                  min={1}
                  step={1}
                />
              </div>
              <div className="col">
                <Button
                  label="Geen max instellen"
                  size="s"
                  color="grey-ghost"
                  onClick={() => {
                    modelOption[modelKey] = 0;
                    save()
                  }}
                />
              </div>
            </div>
            :
            <div className="row">
              <div className="col">Geen maximaal</div>
              <div className="col">
                <Button
                  label="instellen"
                  color="grey-ghost"
                  size="s"
                  onClick={() => {
                    modelOption[modelKey] = 1;
                    save()
                  }}
                />
              </div>
            </div>
          }
        </label><br/><br/>
      </>
    )
  }

  return (
    <>
      <div className={className + 'options '}>
        <div>

          <label>{label}</label>
          {text && text !== '' &&
            <p className="text-small">{text}</p>
          }

          {hasError && <span className="error"> - {error}</span>}

          <div className="options-container">
            {isLoading ?
              <Loading/>
              :
              <>
                <br/>
                {/*<h3>{capitalizeFirstLetter(modelLabels)}:</h3>*/}
                <div className="options-list">
                  {value && value.map((modelOption, i) => {
                    if ('is_trash' in modelOption) {
                      return null
                    }
                    return (
                      <div key={i} className="option">
                        <div className="label">{modelOption.option.label}</div>
                        <div
                          className="custom-label">{(modelOption.label.length) ? ' - ' + modelOption.label.substring(0, 20) + '...' : ''}</div>
                        {('price' in modelOption) && modelOption.price &&
                          <div className="price">{formatPrice(modelOption.price.default)}</div>
                        }
                        <div className="buttons">
                          <Popup
                            showPopup={showPopupEdit}
                            setShowPopup={setShowPopupEdit}
                            iteration={i}
                            triggerPopup={() => showPopupEdit[i]}

                            // a patch for strange bug that sets formData.isChanged to false when showPopupEdit = changed
                            callbackOpenPopup={() => {formData.isChanged = true; save()}}
                            trigger={
                              <Button
                                label="Bewerken"
                                size="s"
                                color="grey"
                              />
                            }
                            title={capitalizeFirstLetter(modelLabel) + ' eigenschappen'}
                          >
                            <h3>Algemeen</h3>
                            <p>Korte extra omschrijving</p>
                            <textarea
                              value={modelOption.label}
                              onChange={(e) => onChangeInput(e, i)}
                              name="label"
                              rows="3"
                            />
                            <hr/>
                            <h3>Maximaal per boekingen</h3>
                            <InputMax
                              label="Maximaal aantal te boeken per boeking"
                              modelOption={modelOption}
                              i={i}
                              modelKey="max_per_booking"
                            />
                            <InputMax
                              label="Maximaal aantal te boeken per geboekte Standplaats/Spot/Plek"
                              modelOption={modelOption}
                              i={i}
                              modelKey="max_per_spot"
                            />
                            {('price' in modelOption) && modelOption.price &&
                              <>
                                <hr/>
                                <h3>Prijs</h3>
                                <Price
                                  name="price"
                                  value={modelOption.price}
                                  hasError={hasError}
                                  error={error}
                                  isLoading={isLoading}
                                  formData={formData}
                                  setFormData={setFormData}
                                  save={save}
                                  className={className}
                                  fixedType="positive"
                                />
                              </>
                            }
                          </Popup>
                          <Button
                            label="Verwijder"
                            size="s"
                            color="red"
                            onClick={() => handleRemove(modelOption, i)}
                          />
                        </div>
                      </div>
                    )
                  })}
                  <div className="add-option">
                    <Popup
                      trigger={<Button icon={addIcon} label={capitalizeFirstLetter(modelLabel) + " Toevoegen"}/>}
                      title={capitalizeFirstLetter(modelLabel) + ' Toevoegen'}
                      showPopup={showPopupAdd}
                      setShowPopup={setShowPopupAdd}
                      triggerPopup={() => showPopupAdd}
                      closeButtonLabel="Sluiten"
                      disableButtons={isLoadingCreateOption}
                    >
                      <div className="popup-form-component-options-index-container">
                        {isLoadingIndexOptions ?
                          <Loading/>
                          :
                          <>
                            <h3>Alle beschikbare {modelLabels}:</h3>
                            <div className="filter-row row">
                              <div className="col-input col">
                                <input
                                  disabled={isLoading}
                                  name="filter"
                                  value={filterValue}
                                  placeholder="Zoek"
                                  onChange={onChangeFilter}
                                />
                              </div>
                              <div className="col-button col">
                                <Button
                                  label="Reset"
                                  size="xs"
                                  color="red"
                                  disabled={(!filterValue)}
                                  onClick={() => setFilterValue('')}
                                />
                              </div>
                            </div>
                            <div className="options-index">
                              {indexOptions.map((option, i) => {
                                if (
                                  option && 'label' in option &&
                                  option.label
                                    .toLowerCase()
                                    .replace(/\s/g, '')
                                    .includes(filterValue
                                      .toLowerCase()
                                      .replace(/\s/g, ''))) {
                                  return (
                                    <div className="option" key={i}>
                                      <div className="label">{option.label}</div>
                                      <div className="buttons">
                                        <Button
                                          label="Toevoegen"
                                          size="s"
                                          color="grey"
                                          onClick={() => handleAdd(option, i)}
                                        />
                                      </div>
                                    </div>
                                  )
                                }
                              })}
                              <div className="add-option">
                                <p>Staat jouw {modelLabel} er niet tussen, voeg hem dan gemakkelijk toe via onderstaande
                                  button:</p>
                                <Popup
                                  trigger={<Button label={capitalizeFirstLetter(modelLabel) + " aanmaken"}/>}
                                  title={capitalizeFirstLetter(modelLabel) + ' aanmaken'}
                                  showPopup={showPopupCreate}
                                  setShowPopup={setShowPopupCreate}
                                  triggerPopup={() => showPopupCreate}
                                  closeButtonLabel="Annuleren"
                                  closeButtonClassName="btn-red"
                                  postButtons={<Button
                                    label="Aanmaken"
                                    onClick={handleCreate}
                                    disabled={isLoadingCreateOption || createLabelValue === '' || !createLabelValue}
                                  />}
                                  disableButtons={isLoadingCreateOption}
                                >
                                  <p>Omdat <i>{modelLabel}</i> voor andere gebruikers ook zichtbaar zijn vragen we je
                                    dubbel te checken of de gewenste <i>{modelLabel}</i> tussen de lijst staat.
                                    Wanneer dit niet het geval is kun je er gerust een toevoegen.</p>
                                  <p>Kies een generieke naam zodat deze <i>{modelLabel}</i> door andere Locaties en
                                    Stands ook gebruikt kan worden.</p>
                                  <p>Wanneer wij merken dat sommige <i>{modelLabels}</i> onder andere namen hetzelfde
                                    vertegenwoordigen dan kunnen we ze mogelijk samenvoegen en hernoemen tot
                                    één <i>{modelLabel}</i>.</p>
                                  {isLoadingCreateOption ?
                                    <Loading/>
                                    :
                                    <>
                                      <input
                                        value={createLabelValue}
                                        onChange={handeLabelChange}
                                        placeholder="Korte omschrijving"
                                      />
                                    </>
                                  }
                                </Popup>
                              </div>
                            </div>
                          </>
                        }
                      </div>
                    </Popup>
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </>
  )
}
Options.defaultProps = {
  modelLabel: 'optie',
  modelLabels: 'opties',
  form: null,
  showPrice: false,
}

Options.propTypes = {
  modelLabel: PropTypes.string,
  modelLabels: PropTypes.string,
  showPrice: PropTypes.bool,
  form: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
}

export default Options
