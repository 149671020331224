import React, {useEffect, useState} from "react";
import Button from "../../components/Button/Button";
import Tile from "../../components/Tile/Tile";
import Loading from "../../components/Loading/Loading";
import useStandService from "../../api/services/useStandService";
import standIcon from '../../assets/icons/stand-white.svg'

const WizardAddStandStart = (props) => {

  const {userShowCollection} = useStandService()
  const [stands, setStands] = useState(null)
  const {wizard} = props

  useEffect(() => {
    userShowCollection(
      (stands) => {
        setStands(stands)
      }
    )
  }, [])

  const handleAdd = () => {
    wizard.setFinished(false)
    wizard.nextStep('')
  }

  return (
    <div className="wizard-add-stand-index">
      <div className="width-medium">
        {stands ?
          stands.length ?
            stands.map((stand, i) => {
              return (
                <Tile
                  key={i}
                  bookable={stand}
                  showEditButton={true}
                  editLinkWizard={true}
                  editButtonHook={() => wizard.setFinished(true)}
                />
              )
            })
            :
            <div>Je hebt op dit moment nog geen <strong>Stand of Trucks</strong> op het platform aangemeld.</div>
          :
          <Loading/>
        }
        <br/>
        <br/>
        <p>Om een nieuwe Stand of Truck aan te maken:</p>
        <div className="center">
          <Button
            label="Toevoegen"
            onClick={handleAdd}
            icon={standIcon}
          />
        </div>
      </div>
    </div>
  )
}

export default WizardAddStandStart
