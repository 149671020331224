import React, {useState} from "react";
import PropTypes from "prop-types";
import './style.scss'

const InfoBar = (props) => {

  const {title, text, id} = props
  const storageKey = 'banner-' + id
  const isClosed = localStorage.getItem(storageKey)
  const [show, setShow] = useState((isClosed !== 'true'))

  const handleClose = () => {
    // console.log('close')
    localStorage.setItem(storageKey, 'true')
    setShow(false)
  }

  return (
    <>
      {show &&
        <div className="component-info-bar">
          {id && <div className="close" onClick={handleClose}>&#10005;</div>}
          <div className="section-container">
            {title && <h3>{title}</h3>}
            {text && <p>{text}</p>}
          </div>
        </div>
      }
    </>
  )
}

InfoBar.defaultProps = {
  title: null,
  text: null,
  id: null,
}

InfoBar.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  id: PropTypes.string,
}

export default InfoBar
