import React from "react";
import './style.scss'

const Footer = () => {
  return(
    <div className="component-footer"/>
  )
}

export default Footer
