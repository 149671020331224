import React from "react";
import {
  Form,
  Fields,
} from "../../components/Form/Form";
import useStandService from "../../api/services/useStandService";
import standIcon from '../../assets/icons/stand-white.svg'
import Button from "../../components/Button/Button";
import TileList from "../../components/TileList/TileList";

const UserStands = () => {

  const {userShowCollection} = useStandService()

  return (
    <div>
      <h1>Stands</h1>
      <p>Stands zijn: mobiele ondernemingen, foodtrucks, (markt) kraampje, ijs-bak-fietsen, acts, etc.</p>
      <Button
        label="Stand/Truck/Act aanmaken"
        linkState={{buttonPrev:{new:{onClick:{navigate:'/user/stands'}}}}}
        link="/wizard/stand/new"
        icon={standIcon}
      />
      <br/>
      <br/>
      <br/>
      <TileList
        apiServiceShow={userShowCollection}
        modelLabelKey="label"
        modelImageKey="overview_media"
        showBookableStatus={true}
        buttonProfileBaseUrl={'/stand/'}
        buttonOneDynamicUrl={'/user/stand/%id/manager'}
        buttonOneLabel={'Management'}
        buttonOneColor={'blue'}
        buttonTwoDynamicUrl={'/user/stand/%id/edit'}
        buttonTwoLabel={'Instellingen'}
      />
    </div>
  )
}


export default UserStands
