import React, {useEffect, useState} from 'react';
import {Tooltip} from "react-tooltip";
import useBookable from "../../hooks/useBookable";
import usePrice from "../../hooks/usePrice";
import PropTypes from "prop-types";
import infoIcon from "../../assets/icons/info.svg"
import './style.scss'

const Price = (props) => {

  const {
    bookable,
    showTooltip,
    showTextBelowPrice,
    // textAlign,
    showInfo
  } = props

  const {
    hasDifferentDayPrices,
    formatPrice,
    formatPercent,
    getLowestDayPrice,
    getHighestDayPrice,
    hasDifferentSpotsPrices,
    hasMultipleSpotTypes,
    addBookingFee,
    getSpotHighestPrice,
    getSpotLowestPrice,
  } = usePrice()

  const {isStand, isSpotGroup, calcAverageSpotSurface} = useBookable()
  const delayHide = 0
  const [forceRerender, setForceRerender] = useState(true);

  /*
   * force re-render of this component when bookable changes
   * this can be necessary when spots are added to bookable;
   */
  useEffect(() => {
    setForceRerender(!forceRerender)
  }, [bookable]);

  /**
   *
   * @returns {JSX.Element}
   * @constructor
   */
  const BelowPrice = () => {
    let hasDifferentSpotsPricesBool, hasMultipleSpotTypesBool
    // if (isSpotGroup(bookable) && bookable.spots && bookable.spots.length > 0) {
    if (isSpotGroup(bookable) && bookable.spots) {
      hasDifferentSpotsPricesBool = hasDifferentSpotsPrices(bookable)
      hasMultipleSpotTypesBool = hasMultipleSpotTypes(bookable)
    }

    //todo test if a spotGroup has multi_spot enabled, but only 1 spot added. Is it shown correctly everywhere?
    return (
      <>
        {isSpotGroup(bookable) && !!bookable.multi_spot &&
          <div className="below-price">
            {hasDifferentSpotsPricesBool && !hasMultipleSpotTypesBool &&
              <span>
                Prijs is per Standplaats verschillend, bekijk de prijs per Standplaats op de map.
              </span>
            }
            {hasMultipleSpotTypesBool && !hasDifferentSpotsPricesBool &&
              <span>
                Prijstype kan afwijken per Standplaats, bekijk de prijstyoe per Standplaats op de map.
              </span>
            }
            {(hasDifferentSpotsPricesBool && hasMultipleSpotTypesBool) &&
              <span>
                Prijs en prijstype kan afwijken per Standplaats, bekijk de prijs per Standplaats op de map.
              </span>
            }
          </div>
        }
      </>
    )
  }

  const averageSpotSurface = calcAverageSpotSurface(bookable)
  const lowestPricedSpot = (isSpotGroup(bookable) && bookable.spots) ? getSpotLowestPrice(bookable.spots) : null
  const highestPricedSpot = (isSpotGroup(bookable) && bookable.spots) ? getSpotHighestPrice(bookable.spots) : null

  // const averageSpotSurface = bookable.spots && bookable.spots.length > 0 ? calcAverageSpotSurface(bookable) : null;
  // const lowestPricedSpot = (isSpotGroup(bookable) && bookable.spots && bookable.spots.length > 0) ? getSpotLowestPrice(bookable.spots) : null;
  // const highestPricedSpot = (isSpotGroup(bookable) && bookable.spots && bookable.spots.length > 0) ? getSpotHighestPrice(bookable.spots) : null;

  return (
    <div className="component-price">
      <div className="price-container">
        {showInfo &&
          <div className="info">
            <a href="/prijzen-betaalmethodes/" target="_blank">
              <img src={infoIcon} alt="Alles over prijzen en betaalmethodes"/>
            </a>
          </div>
        }
        {(bookable.price && bookable.price.type === 'positive') &&
          <>
            {showTooltip &&
              <Tooltip id="tooltip-positive" place="bottom">
                {isStand(bookable) ?
                  <>Locaties/organisaties kunnen deze Stand of Truck inhuren voor:</>
                  :
                  <>Stands en Trucks kunnen een Standplaats bij deze Locatie huren voor:</>
                }
              </Tooltip>
            }
            <div data-tooltip-id="tooltip-positive" data-tooltip-delay-hide={delayHide}>
              <div className="above-price">
                Dagprijs:
              </div>
              <p className="amount">
                {isSpotGroup(bookable)
                  ?
                  <>
                    {
                      bookable.spots &&
                      ((hasDifferentSpotsPrices(bookable)) || hasDifferentDayPrices(bookable)) &&
                      (lowestPricedSpot && highestPricedSpot)
                        ?
                        <>
                          <div className="row">




                            <span className="price">{formatPrice(addBookingFee(getLowestDayPrice(lowestPricedSpot)))}</span>





                            <span>{lowestPricedSpot.width} x {lowestPricedSpot.length} meter</span>
                            <span className="pre-price">tot</span>
                          </div>
                          <div className="row">
                          <span
                            className="price">{formatPrice(addBookingFee(getHighestDayPrice(highestPricedSpot)))}</span>
                            <span className="post-price">p/dag</span>
                            <span>{highestPricedSpot.width} x {highestPricedSpot.length} meter</span>
                          </div>
                        </>
                        :
                        <>
                          <div className="row">
                            <span className="price">{formatPrice(addBookingFee(bookable.price.default))}</span>
                            <span className="post-price">p/dag</span>
                            {averageSpotSurface &&
                              <span>per {averageSpotSurface.width} x {averageSpotSurface.length} meter</span>
                            }
                          </div>
                        </>
                    }
                  </>
                  :
                  <>
                    {hasDifferentDayPrices(bookable) ?
                      <>
                        <div className="row">
                          <span className="price">{formatPrice(addBookingFee(getLowestDayPrice(bookable)))}</span>
                          <span className="pre-price">tot</span>
                          <span className="price">{formatPrice(addBookingFee(getHighestDayPrice(bookable)))}</span>
                          <span className="post-price">p/dag</span>
                        </div>
                      </>
                      :
                      <>
                        <div className="row">
                          <span className="price">{formatPrice(addBookingFee(bookable.price.default))}</span>
                          <span className="post-price">p/dag</span>
                        </div>
                      </>
                    }
                  </>
                }
              </p>
            </div>
            {showTextBelowPrice &&
              <BelowPrice/>
            }
          </>
        }

        {(bookable.price && bookable.price.type === 'negative') &&
          <>
            {showTooltip &&
              <Tooltip
                id="tooltip-negative" place="bottom"
                data-tooltip-delay-hide={delayHide}
              >
                {isStand(bookable) ?
                  <>Locaties/organisaties verdienen geld door deze Stand/Truck een standplaats te bieden.</>
                  :
                  <>Stands en Trucks verdienen geld door hier hun goederen en diensten aan te bieden.</>
                }
              </Tooltip>
            }
            <div data-tooltip-id="tooltip-negative" data-tooltip-delay-hide={delayHide}>
              <div className="above-price">
                Verdien per dag:
              </div>
              <p className="amount">
                {isSpotGroup(bookable)
                  ?
                  <>
                    {
                      bookable.spots &&
                      ((hasDifferentSpotsPrices(bookable)) || hasDifferentDayPrices(bookable)) &&
                      (lowestPricedSpot && highestPricedSpot)
                        ?
                        <>
                          <div className="row">
                          <span
                            className="price">{formatPrice(addBookingFee(getLowestDayPrice(lowestPricedSpot)))}</span>
                            <span>{lowestPricedSpot.width} x {lowestPricedSpot.length} meter</span>
                            <span className="pre-price">tot</span>
                          </div>

                          <div className="row">
                          <span
                            className="price">{formatPrice(addBookingFee(getHighestDayPrice(highestPricedSpot)))}</span>
                            <span className="post-price">p/dag</span>
                            <span>{highestPricedSpot.width} x {highestPricedSpot.length} meter</span>
                          </div>
                        </>
                        :
                        <>
                          <div className="row">
                            <span className="price">{formatPrice(addBookingFee(bookable.price.default))}</span>
                            <span className="post-price">p/dag</span>
                            {averageSpotSurface &&
                              <span>per {averageSpotSurface.width} x {averageSpotSurface.length} meter</span>
                            }
                          </div>
                        </>
                    }

                  </>
                  :
                  <>
                    {hasDifferentDayPrices(bookable) ?
                      <>
                        <div className="row">
                          <span className="price">{formatPrice(addBookingFee(getLowestDayPrice(bookable)))}</span>
                          <span className="pre-price">tot</span>
                          <span className="price">{formatPrice(addBookingFee(getHighestDayPrice(bookable)))}</span>
                          <span className="post-price">p/dag</span>
                        </div>
                      </>
                      :
                      <>
                        <div className="row">
                          <span className="price">{formatPrice(addBookingFee(bookable.price.default))}</span>
                          <span className="post-price">p/dag</span>
                        </div>
                      </>
                    }
                  </>
                }
              </p>
            </div>
            {showTextBelowPrice &&
              <BelowPrice/>
            }
          </>
        }

        {(bookable.price && bookable.price.type === 'negotiate') &&
          <>
            {showTooltip &&
              <Tooltip id="tooltip-negotiate" place="bottom">
                Je kunt hierover onderhandelen en tijdens het boeken een bod doen.
              </Tooltip>
            }
            <div data-tooltip-id="tooltip-negotiate" data-tooltip-delay-hide={delayHide}>
              <div className="above-price">
                Prijs in overleg (n.o.t.k.):
              </div>
            </div>
            {showTextBelowPrice &&
              <BelowPrice/>
            }
          </>
        }

        {(bookable.price && bookable.price.type === 'relative') &&
          <>
            {showTooltip &&
              <Tooltip id="tooltip-relative" place="bottom">
                Dit percentage van de omzet wordt afgedragen aan de Locatie eigenaar.
              </Tooltip>
            }
            <div data-tooltip-id="tooltip-relative" data-tooltip-delay-hide={delayHide}>
              <div className="above-price">
                Prijs op basis van omzet:
              </div>
              <p className="amount">
                {isSpotGroup(bookable)
                  ?
                  <>
                    {
                      bookable.spots &&
                      ((hasDifferentSpotsPrices(bookable)) || hasDifferentDayPrices(bookable)) &&
                      (lowestPricedSpot && highestPricedSpot)
                        ?
                        <>

                          <div className="row">
                          <span
                            className="price">{formatPercent(addBookingFee(getLowestDayPrice(lowestPricedSpot)))}</span>
                            <span>{lowestPricedSpot.width} x {lowestPricedSpot.length} meter</span>
                            <span className="pre-price">tot</span>
                          </div>

                          <div className="row">
                          <span
                            className="price">{formatPercent(addBookingFee(getHighestDayPrice(highestPricedSpot)))}</span>
                            <span className="post-price">p/dag</span>
                            <span>{highestPricedSpot.width} x {highestPricedSpot.length} meter</span>
                          </div>
                        </>
                        :
                        <>

                          <div className="row">
                            <span className="price">{formatPercent(addBookingFee(bookable.price.default))}</span>
                            <span className="post-price">p/dag</span>
                            {averageSpotSurface &&
                              <span>per {averageSpotSurface.width} x {averageSpotSurface.length} meter</span>
                            }
                          </div>
                        </>
                    }
                  </>
                  :
                  <>
                    {hasDifferentDayPrices(bookable) ?
                      <>
                        <div className="row">
                          <span className="price">{formatPercent(addBookingFee(getLowestDayPrice(bookable)))}</span>
                          <span className="pre-price">tot</span>
                          <span className="price">{formatPercent(addBookingFee(getHighestDayPrice(bookable)))}</span>
                          <span className="post-price">p/dag</span>
                        </div>
                      </>
                      :
                      <>
                        <div className="row">
                          <span className="price">{formatPercent(addBookingFee(bookable.price.default))}</span>
                          <span className="post-price">p/dag</span>
                        </div>
                      </>
                    }
                  </>
                }
              </p>
            </div>
            {showTextBelowPrice &&
              <BelowPrice/>
            }
          </>
        }
      </div>
    </div>
  )
}

Price.defaultProps = {
  showTooltip: true,
  showTextBelowPrice: true,
  showInfo: false,
  // textAlign:'center',
}

Price.propTypes = {
  bookable: PropTypes.object.isRequired,
  showTooltip: PropTypes.bool,
  showTextBelowPrice: PropTypes.bool,
  showInfo: PropTypes.bool,
  // textAlign: PropTypes.string,
}

export default Price
