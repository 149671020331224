import React, {createContext, memo, useContext, useEffect, useRef, useState} from 'react';
import PropTypes from "prop-types";
import useFunction from "../hooks/useFunction";
import useMessageService from "../api/services/useMessageService";
import AuthContext from "./AuthContext";
import {localhost} from "../env";

const AppContext = createContext(undefined);

// Hook to provide access to context object
const useAppContext = () => {
  return useContext(AppContext);
};

export const AppContextProviderComp = (props) => {
  const refreshMs = 3000;
  const {getToken} = useFunction()
  const {countNotifications} = useMessageService()
  const [notifications, setNotifications] = useState(0);
  const [windowIsActive, setWindowIsActive] = useState(true);
  const {user} = useContext(AuthContext)

 //todo make sure the notification state change does not re-renders the whole app.

  useEffect(() => {
    window.onblur = function () {
      setWindowIsActive(false)
    };
    window.onfocus = function () {
      setWindowIsActive(true)
    };
    return () => {
      window.onblur = null;
      window.onfocus = null;
    }
  }, [])

  /**
   * Set interval for notifications
   */
  const timeoutRef = useRef(null);
  const [hack, setHack] = useState(false)

  /**
   * Set timeOut for notifications.
   */
  const getNotifications = () => {
    if (windowIsActive && getToken() && user.active_company_id && !localhost) {
      countNotifications(
        (count) => {

          console.log('N', count)

          setNotifications(count)
          setHack(!hack)
        },
        () => {
          setHack(!hack)
        },
        user.active_company_id
      )
    }
  }

  useEffect(() => {
    timeoutRef.current = window.setTimeout(() => {
      getNotifications()
    }, refreshMs)
    return () => window.clearTimeout(timeoutRef.current);
  }, [hack, windowIsActive]);

  // Assign React state and constants to context object
  const AppContextObject = {
    notifications,
    setNotifications,
  }

  return (
    <AppContext.Provider value={AppContextObject}>
      {props.children}
    </AppContext.Provider>
  );
};

AppContextProviderComp.propTypes = {
  children: PropTypes.element,
};

const AppContextProvider = memo(
  AppContextProviderComp,
  (prevProps, nextProps) => {
    return true;
  })

export {
  AppContextProvider,
  useAppContext
}
