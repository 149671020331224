import React from "react";
import PropTypes from "prop-types";
import './Three-style.scss'

const Three = (props) => {

  const {
    label,
    name,
    text,
    value,
    className,
    hasError,
    error,
    isLoading,
    afterLabel,
    formData,
    save,
    labelYes,
    labelNo,
    labelOther,
  } = props

  /**
   *
   * @param e
   * @param value
   */
  const onChange = (e, value) => {
    formData.model[e.target.name] = value
    save()
  }

  /**
   *
   * @param inputValue
   * @returns {boolean}
   */
  const getValue = (inputValue) => {
    return (inputValue === value)
  }

  return (
    <>
      <div className={className + 'three '}>
        <div>{label}<br/><br/>
          {text && text !== '' &&
            <p className="text-small">{text}</p>
          }
          {hasError && <span className="error"> - {error}</span>}
          <div className="form-row">
            <label>{labelYes}
              <input
                disabled={isLoading}
                name={name}
                value="1"
                checked={getValue(1)}
                onChange={(e) => onChange(e, 1)}
                type='radio'
              />
            </label>
            <label>{labelNo}
              <input
                disabled={isLoading}
                name={name}
                value="0"
                checked={getValue(0)}
                onChange={(e) => onChange(e, 0)}
                type='radio'
              />
            </label>
            <label>{labelOther}
              <input
                disabled={isLoading}
                name={name}
                value="2"
                checked={getValue(2)}
                onChange={(e) => onChange(e, 2)}
                type='radio'
              />
            </label>
            {afterLabel &&
              <span className="after-label">{afterLabel}</span>
            }
          </div>
        </div>
      </div>
    </>
  )
}

Three.defaultProps = {
  label: '',
  name: '',
  text: '',
  value: '',
  className: '',
  hasError: false,
  onChange: () => {
  },
  error: '',
  reversed: false,
  labelYes: 'Ja',
  labelNo: 'Nee',
  labelOther: 'Anders',
  form: null,
}

Three.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  className: PropTypes.string,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  reversed: PropTypes.bool,
  labelYes: PropTypes.string,
  labelNo: PropTypes.string,
  form: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
}

export default Three
