import React, {useEffect, useState} from "react";
import {DateObject} from "react-multi-date-picker";
import usePrice from "../../hooks/usePrice";
import useBookable from "../../hooks/useBookable";
import SpotPopup from "../SpotPopup/SpotPopup";
import Button from "../Button/Button";
import './BookingPrice-style.scss'

const BookingPrice = (props) => {

  const {
    className,
    name,
    formData,
    save,
  } = props

  const {
    countBookingPriceTypeNegotiate,
    formatPrice,
    formatPercent,
    getBookingDates,
    getBookable,
  } = usePrice()
  const booking = formData.model
  const bookable = getBookable(booking)
  const [showPopupSpot, setShowPopupSpot] = useState([])
  const {isSpotGroup, isStand} = useBookable()
  const countTypeNegotiate = countBookingPriceTypeNegotiate(booking, bookable)
  const days = getBookingDates(booking).length

  useEffect(() => {
    if (formData.isChanged === false) {
      initiate()
    }
  }, [formData.isChanged])

  /**
   *
   */
  const initiate = () => {
    // formData.model = updateBookingPricesProperty(formData.model)
    // const date = new DateObject()
    if (!(name in formData.model) || formData.model.negotiate_offers === null) {
      formData.model.negotiate_offers = []
      for (let i = 0; i < countTypeNegotiate; i++) {
        formData.model.negotiate_offers.push({
          spot_id: false,
          // date: date.format('YYYY-MM-DD'),
          value: 0, //total amount for all booking dates for this spot/stand
          type: 'negotiate',
          // id: uuid(),
        })
      }
    }

    save(false)
  }

  const onChangeNegotiateTypeSpot = (e, index, spot) => {
    formData.model.negotiate_offers[index].type = e.target.value
    formData.model.negotiate_offers[index].spot_id = spot.id
    save()
  }

  const onChangeInputSpot = (e, index, spot) => {
    formData.model.negotiate_offers[index].value = e.target.value
    formData.model.negotiate_offers[index].spot_id = spot.id
    save()
  }

  const onChangeNegotiateTypeStand = (e, index, stand) => {
    formData.model.negotiate_offers[index].type = e.target.value
    formData.model.negotiate_offers[index].stand_id = stand.id
    save()
  }

  const onChangeInputStand = (e, index, stand) => {
    formData.model.negotiate_offers[index].value = e.target.value
    formData.model.negotiate_offers[index].stand_id = stand.id
    save()
  }


  const getNegotiateType = (index) => {
    if (formData.model.negotiate_offers) {
      return formData.model.negotiate_offers[index].type
    } else {
      return 'negotiate'
    }
  }

  /**
   * Get all spots with price type 'negotiate'
   *
   * @returns {*}
   */
  const getNegotiateSpots = () => {
    return formData.model.spots.filter(spot => spot.price.type === 'negotiate')
  }

  /**
   * Get the stand with type 'negotiate'
   *
   * @returns {*}
   */
  const getNegotiateStand = () => {
    if (formData.model.stand.price.type === 'negotiate') {
      return [formData.model.stand]
    } else {
      return []
    }
  }

  /**
   * Get offers array from booking.prices for specific spot
   *
   * @param spot
   * @returns {*[]|*}
   */
  const getOffersBySpot = (spot) => {
    const spotPrices = formData.model.prices.spots.find((item) => item.id === spot.id)
    if (spotPrices.offers && Array.isArray(spotPrices.offers)) {
      return spotPrices.offers
    } else {
      return []
    }
  }

  /**
   * Get offers array from booking.prices for specific spot
   *
   * @returns {*[]|*}
   */
  const getOffersByStand = () => {
    if (
      formData.model.prices.stand &&
      'offers' in formData.model.prices.stand &&
      Array.isArray(formData.model.prices.stand.offers) &&
      formData.model.prices.stand.offers.length
    ) {
      return formData.model.prices.stand.offers
    } else {
      return []
    }
  }

  return (
    <div className={className + 'booking-price '}>
      {countTypeNegotiate > 0 ?
        <>
          {isSpotGroup(bookable) &&
            <>
              <h3>Deze boekingsaanvraag
                heeft {countTypeNegotiate} {(countTypeNegotiate === 1) ? 'standplaats' : 'standplaatsen'} met een
                N.O.T.K
                prijs:</h3>
              <p>Doe direct een bod
                voor {(countTypeNegotiate === 1) ? 'de onderstaande standplaats' : 'alle onderstaande standplaatsen'},
                of doe dit later. Deze boekingsaanvraag kan pas afgerond worden als alle Standplaatsen met N.O.T.K
                prijzen minimaal 1 bod gedaan is.</p>
              <p>Deze boeking is voor {(days) === 1 ? days + ' dag' : days + ' dagen'}.</p>

              <div className="spot-group-spots">
                {getNegotiateSpots().map((spot, index) => {
                  let hasOffers = false
                  return (
                    <div key={index} className="spot-price">
                      <div className="negotiate">


                        <div className="row">
                          <div className="col col-label">
                            {spot.spot_cluster
                              ? <div className="label">Onderhandelen over prijs standplaats: {spot.spot_cluster.label}: {spot.label}</div>
                              : <div className="label">Onderhandelen over prijs standplaats: {spot.label}</div>
                            }
                          </div>
                          <div className="col col-button">
                            <div
                              className="popup-button"
                              onClick={() => setShowPopupSpot({...showPopupSpot, [index]: true})}
                            >
                              <Button
                                label="Standplaats Info"
                                size="s"
                                color="grey"
                              />
                            </div>
                            <SpotPopup
                              spot={spot}
                              iteration={index}
                              setShowPopupSpot={setShowPopupSpot}
                              showPopupSpot={showPopupSpot}
                            />
                          </div>
                        </div>


                        {getOffersBySpot(spot).map((negotiateOffer, i) => {
                          hasOffers = true
                          return (
                            <div
                              className={(i === 0) ? "negotiate-offers final" : "negotiate-offers"}
                              key={i}
                            >
                              {(i === 0) ? "Laatste bod:" : ""} Datum bod: {new DateObject(negotiateOffer.date).toString()}
                              ,&nbsp;
                              Type:&nbsp;
                              {booking.direction === 'receiving' && negotiateOffer.type === 'positive' && 'Verdien'}
                              {booking.direction === 'requesting' && negotiateOffer.type === 'positive' && 'Betaal'}
                              {booking.direction === 'receiving' && negotiateOffer.type === 'negative' && 'Betaal'}
                              {booking.direction === 'requesting' && negotiateOffer.type === 'negative' && 'Verdien'}
                              {negotiateOffer.type === 'relative' && 'Omzet'}
                              {negotiateOffer.type === 'negotiate' && 'Onderhandelen'}
                              ,&nbsp;
                              <strong>
                                {negotiateOffer.type === 'relative'
                                  ? <>{formatPercent(negotiateOffer.value)}</>
                                  : <>{formatPrice(negotiateOffer.value)}</>
                                }
                              </strong>
                            </div>
                          )
                        })}


                        <h4>Nieuw bod doen:</h4>
                        <p>Type prijs:</p>
                        <select
                          name="type"
                          id=""
                          onChange={e => onChangeNegotiateTypeSpot(e, index, spot)}
                          value={getNegotiateType(index)}
                        >
                          {booking.direction === 'receiving' ?
                            <>
                              <option value="positive">Verdien:</option>
                              <option value="negative">Betaal:</option>
                            </>
                            :
                            <>
                              <option value="positive">Betaal:</option>
                              <option value="negative">Verdien:</option>
                            </>
                          }
                          <option value="relative">Omzet: Prijs hangt af van de omzet (percentage)</option>
                          {hasOffers
                            ? <option value="negotiate">Laatste bod handhaven</option>
                            : <option value="negotiate">Later kiezen/nog niet overeengekomen (n.o.t.k.)</option>
                          }
                        </select>
                        {getNegotiateType(index) !== 'negotiate' &&
                          <div className="row">
                            <input
                              placeholder=""
                              type="number"
                              min="0"
                              step="1"
                              value={formData.model.negotiate_offers[index].value}
                              onChange={e => onChangeInputSpot(e, index, spot)}
                            /> <p> {(getNegotiateType(index) === 'relative') ? 'procent' : 'ex. BTW, in euro'},
                            per dag.
                            {formData.model.date_ranges.length > 1 &&
                              <>&nbsp;Dus
                                voor {(days) === 1 ? days + ' dag' : days + ' dagen'}: {formatPrice(formData.model.negotiate_offers[index].value * formData.model.date_ranges.length)}</>
                            }
                          </p>
                          </div>
                        }
                      </div>
                    </div>
                  )
                })}
              </div>
            </>
          }


          {isStand(bookable) &&
            <>
              <h3>De Stand/Truck/Act in deze boekingsaanvraag heeft een N.O.T.K prijs:</h3>
              <p>Doe direct een bod of doe dit later. Deze boekingsaanvraag kan pas geaccepteerd worden er minimaal 1
                bod
                gedaan is.</p>
              <p>Deze boeking is voor {(days) === 1 ? days + ' dag' : days + ' dagen'}.</p>

              <div className="stands">
                {getNegotiateStand().map((stand, index) => {
                  let hasOffers = false
                  return (
                    <div key={index} className="stand-price">
                      <div className="negotiate">

                        <div className="row">
                          <div className="col col-label">
                            <div className="label">Onderhandelen over prijs Stand: {stand.label}</div>
                          </div>
                        </div>


                        {getOffersByStand().map((negotiateOffer, i) => {
                          hasOffers = true
                          return (
                            <div
                              className={(i === 0) ? "negotiate-offers final" : "negotiate-offers"}
                              key={i}
                            >
                              {(i === 0) ? "Laatste bod:" : ""} Datum bod: {new DateObject(negotiateOffer.date).toString()}
                              ,&nbsp;
                              Type:&nbsp;
                              {booking.direction === 'receiving' && negotiateOffer.type === 'positive' && 'Verdien'}
                              {booking.direction === 'requesting' && negotiateOffer.type === 'positive' && 'Betaal'}
                              {booking.direction === 'receiving' && negotiateOffer.type === 'negative' && 'Betaal'}
                              {booking.direction === 'requesting' && negotiateOffer.type === 'negative' && 'Verdien'}
                              {negotiateOffer.type === 'relative' && 'Omzet'}
                              {negotiateOffer.type === 'negotiate' && 'Onderhandelen'}
                              ,&nbsp;
                              <strong>
                                {negotiateOffer.type === 'relative'
                                  ? <>{formatPercent(negotiateOffer.value)}</>
                                  : <>{formatPrice(negotiateOffer.value)}</>
                                }
                              </strong>
                            </div>
                          )
                        })}


                        <h4>Nieuw bod doen:</h4>
                        <p>Type prijs:</p>
                        <select
                          name="type"
                          id=""
                          onChange={e => onChangeNegotiateTypeStand(e, index, stand)}
                          value={getNegotiateType(index)}
                        >
                          {booking.direction === 'receiving' ?
                            <>
                              <option value="positive">Verdien:</option>
                              <option value="negative">Betaal:</option>
                            </>
                            :
                            <>
                              <option value="positive">Betaal:</option>
                              <option value="negative">Verdien:</option>
                            </>
                          }
                          <option value="relative">Omzet: Prijs hangt af van de omzet (percentage)</option>
                          {hasOffers
                            ? <option value="negotiate">Laatste bod handhaven</option>
                            : <option value="negotiate">Later kiezen/nog niet overeengekomen (n.o.t.k.)</option>
                          }
                        </select>
                        {getNegotiateType(index) !== 'negotiate' &&
                          <div className="row">
                            <input
                              placeholder=""
                              type="number"
                              min="0"
                              step="1"
                              value={formData.model.negotiate_offers[index].value}
                              onChange={e => onChangeInputStand(e, index, stand)}
                            /> <p> {(getNegotiateType(index) === 'relative') ? 'procent' : 'ex. BTW, in euro'},
                            per dag.
                            {formData.model.date_ranges.length > 1 &&
                              <>&nbsp;Dus
                                voor {(days) === 1 ? days + ' dag' : days + ' dagen'}: {formatPrice(formData.model.negotiate_offers[index].value * formData.model.date_ranges.length)}</>
                            }
                          </p>
                          </div>
                        }
                      </div>
                    </div>
                  )
                })}
              </div>
            </>
          }
        </>
        :
        <>
          Er zijn geen onderhandelbare prijzen voor deze boeking
        </>
      }
    </div>
  )
}

export default BookingPrice
