import swal from "sweetalert";


const useSwal = () => {


  const popup = (props) => {

    const {
      title,
      text,
      buttonConfirmLabel,
      buttonCancelLabel,
      callbackConfirm,
      callbackCancel,
      dangerMode,
      buttonConfirmClassname,
      buttonCancelClassname,
      hideButtonCancel,
      hideButtonConfirm,
    } = props

    swal({
      title: title,
      text: text,
      icon: '', //question
      dangerMode: dangerMode?dangerMode:true,
      buttons: {
        cancel: (!hideButtonCancel)?{
          text: buttonCancelLabel ? buttonCancelLabel : "Annuleren",
          value: null,
          visible: true,
          className: (buttonConfirmClassname)?buttonConfirmClassname:'btn-color-grey btn-size-m',
          closeModal: true,
        }: undefined,
        confirm: (!hideButtonConfirm)?{
          text: buttonConfirmLabel ? buttonConfirmLabel : "Ok",
          value: true,
          visible: true,
          className: (buttonCancelClassname)?buttonCancelClassname:'btn-color-green btn-size-m',
          closeModal: true,
        }: undefined
      }
    }).then((result) => {
      if (result) {
        callbackConfirm && callbackConfirm()
      } else {
        callbackCancel && callbackCancel()
      }
    })
  }


  return {
    popup
  }
}


export default useSwal
