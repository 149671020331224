import React, {useEffect, useState} from "react";
import {DateObject} from "react-multi-date-picker";
import PropTypes from "prop-types";
import useBookableService from "../../api/services/useBookableService";
import CalendarBooking from "../CalendarBooking/CalendarBooking";
import SpotMapPublic from "../SpotMap/SpotMapPublic";
import useBooking from "../../hooks/useBooking";
import SpotPopup from "../SpotPopup/SpotPopup";
import Button from "../Button/Button";
import "./BookingSelectSpotMapDates-style.scss"

const BookingSelectSpotMapDates = (props) => {

  const {
    className,
    formData,
    save,
  } = props

  const [showPopupSpot, setShowPopupSpot] = useState([])
  const [isLoadingBookings, setIsLoadingBookings] = useState(false)
  const {showBookableMonthBookings} = useBookableService()
  const {parseSpotGroupBookings, datesToStrings, toggleSpotInBooking} = useBooking()
  const booking = formData.model;

  /**
   *
   */
  useEffect(() => {

      /*
       * Load bookings of this month
       */
      loadMonthBookings();
    }, []
  );

  /**
   *
   */
  useEffect(() => {
    console.log('bookingS', booking.dates)




  }, [booking.dates]);



  /**
   * Set the initial dates back on reset
   */
  useEffect(() => {
    if (!formData.isChanged) {
      setDatesProperty()
      save(false)
    }
  }, [formData.isChanged]);

  /**
   * set the booking.dates property based on booking.date_ranges values
   */
  const setDatesProperty = () => {
    booking.date_ranges.map(dateRange => {

      if ('is_trash' in dateRange && dateRange.is_trash){
        return
      }

      if (!booking.dates) {
        booking.dates = []
      }
      const newDate = new DateObject(dateRange.start_date);
      const newDateString = newDate.format("YYYY-MM-DD");

      // Check if the date is already in booking.dates
      const dateExists = booking.dates.find(date => {
        const existingDateString = date.format("YYYY-MM-DD");
        return newDateString === existingDateString;
      });

      // If the date does not exist, push it to booking.dates
      if (!dateExists) {
        booking.dates.push(newDate);
      }
      return dateRange
    })
  }

  /**
   *
   * @param date
   */
  const loadMonthBookings = (date = null) => {
    setIsLoadingBookings(true)
    showBookableMonthBookings((newBookings) => {
        if ('stand' in newBookings) {
          booking.stand.bookings = newBookings.stand
        }
        if ('spotGroup' in newBookings) {
          booking.spot_group.bookings = newBookings.spot_group
          booking.spot_group = parseSpotGroupBookings(booking.spot_group)
        }
        save(false)
        setIsLoadingBookings(false)


      }, () => {
        setIsLoadingBookings(false)
      },
      'bookables',
      {
        ...(booking.spot_group_id && {spot_group: booking.spot_group_id}),
        ...(booking.stand_id && {stand: booking.stand_id}),
      },
      (date) ? date.format('YYYY-MM-DD') : new DateObject().toString(),
      datesToStrings(booking)
    )
  }


  return (
    <div className={className + 'booking-select-spot '}>
      {booking.direction === 'receiving' &&
        <>
          <h2>Op welke Standplaats wil je de Stand of Truck plaatsen?</h2>
        </>
      }
      {booking.direction === 'requesting' &&
        <>
          {/*<h2>Op welke Standplaats wil je de Stand of Truck uitnodigen?</h2>*/}
          {/*<p>Je kan ook later beslissen, of de beslissing voorleggen aan de Stand of Truck</p>*/}
        </>
      }

      <div className="row">
        {booking.spot_group_id &&
          <div className="col col-spot-map">
            {booking.spot_group.multi_spot ?
              <SpotMapPublic
                spotGroup={booking.spot_group}
                booking={booking}
                setBooking={(newBooking) => {
                  formData.model = newBooking
                  save()
                }}
                onSpotClick={(booking, setBooking, spot) => {
                  toggleSpotInBooking(booking, setBooking, spot)
                }}
              />
              :
              <>
                <Button
                  label="Meer info over deze standplaats"
                  onClick={() => setShowPopupSpot({...showPopupSpot, [0]: true})}
                  color="grey"
                />
                <SpotPopup
                  spot={booking.spot_group.spots[0]}
                  iteration={0}
                  setShowPopupSpot={setShowPopupSpot}
                  showPopupSpot={showPopupSpot}
                />
              </>
            }
          </div>
        }

        <div className="col col-calendar">
          <CalendarBooking
            // hideCalendar={hideCalendar}
            // setHideCalendar={setHideCalendar}
            dates={booking.dates}
            enableDisabledDays={true}
            setDates={(dates) => {
              const datesText = []
              dates.map((date) => {
                datesText.push(date.toString())
                return date
              })
              booking.dates = dates
              booking.dates_text = datesText
              if (booking.dates_text.length === 0) {
                booking.dates_text = ['empty']
              }
              save()
            }}

            booking={booking}
            isLoadingBookings={isLoadingBookings}
            loadMonthBookings={loadMonthBookings}
          />
        </div>

      </div>
    </div>
  )
}

BookingSelectSpotMapDates.defaultProps = {
  name: '',
}

BookingSelectSpotMapDates.propTypes = {
  name: PropTypes.string.isRequired,
}

export default BookingSelectSpotMapDates
