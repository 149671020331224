import React from "react";
import {Form, Fields} from "../../components/Form/Form";
import Button from "../../components/Button/Button";
import {useNavigate} from "react-router-dom";
import DoubleInput from "../../components/Form/DoubleInput";
import useFunction from "../../hooks/useFunction";
import useUserService from "../../api/services/useUserService";
import useForm from "../../hooks/useForm";
import accountIcon from "../../assets/icons/account-white.svg"

const UserAccountEmailChange = () => {

  const {removeToken} = useFunction()
  const {userEmailChange} = useUserService()
  const form = useForm()
  const navigate = useNavigate();

  const handleLogout = () => {
    removeToken()
    navigate('/inloggen')
  }

  return (
    <div>
      <Button
        label="Terug naar Account"
        link={'/user/account'}
        icon={accountIcon}
      />
      <h1>Account: Emailadres wijzigen</h1>
      <p>Na het wijzigen van je emailadres krijg je een verificatie email op je nieuwe emailadres.</p>
      <p color="red"><strong>LET OP dat je wel nieuwe email correct is ingevoerd!</strong></p>
      <Form>
        <Fields
          form={form}
          apiService={{userStore:userEmailChange}}
          initData={{email:''}}
          updateSuccess={handleLogout}
          GAEventLabel="User Change Email address"
        >
          <DoubleInput
            label="Nieuw Emailadres"
            name="email"
            autoFocus="autofocus"
          />
        </Fields>
      </Form>

    </div>
  )
}

export default UserAccountEmailChange
