import React from "react";
import PropTypes from "prop-types";
import Button from "../Button/Button";
import BookableStatus from "../BookableStatus/BookableStatus";
import {useNavigate} from "react-router-dom";
import Media from "../Media/Media";
import {useLocation} from "react-router-dom";
import './style.scss'

const Tile = (props) => {
  const {
    bookable,
    showEditButton,
    editLinkWizard,
    editButtonHook,
  } = props
  const location = useLocation()
  const baseUrl = (bookable.object_type === 'stand') ? 'stand' : 'locatie'
  const editLink = (editLinkWizard)
    ? '/wizard/' + baseUrl + '/new/' + bookable.id
    : '/user/' + baseUrl + '/' + bookable.id + '/edit'
  const manageLink = '/user/' + baseUrl + '/' + bookable.id + '/manager'
  const navigate = useNavigate()

  /**
   *
   */
  const editButtonHookFunction = () => {
    editButtonHook()
    navigate(editLink, {state:(editLinkWizard)
        ? {buttonPrev:{new:{onClick:{navigate:location.pathname}}}} //todo not tested yet
        : {}
    })
  }

  return (
    <div className="component-tile">
      {bookable.overview_media &&
        <div className="col image">
          <Media media={bookable.overview_media} size={'logo'}/>
        </div>
      }
      <div className="col label">
        <h4 className="label">{bookable.label}</h4>
        <BookableStatus bookable={bookable}/>
      </div>
      <div className="col btns">
        {bookable.slug &&
          <Button
            label="Weergeven"
            color="green-ghost"
            size="xs"
            target="_blank"
            link={'/' + baseUrl + '/' + bookable.slug}
          />
        }
        {showEditButton &&
          <>
            {!editLinkWizard &&
              <Button
                label="Management"
                color="blue-ghost"
                size="xs"
                link={manageLink}
              />
            }
            <Button
              label="Bewerken"
              color="grey-ghost"
              size="xs"
              link={(editButtonHook) ? null : editLink}
              onClick={(editButtonHook) ? () => editButtonHookFunction() : null}
            />
          </>
        }

      </div>
    </div>
  )
}

Tile.defaultProps = {
  label: '',
  media: null,
  buttons: '',
}
Tile.propTypes = {
  label: PropTypes.string,
  media: PropTypes.object,
  buttons: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string
  ])
}

export default Tile
