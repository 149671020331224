import initPrice from "./initPrice";
import initAvailability from "./initAvailability";

const initSpot = {
  available: 1,
  rotate: 0,
  top: 0, //todo make dynamic, based on empty space in map
  left: 0, //todo make dynamic, based on empty space in map
  width: 4, //todo make dynamic, based on map size
  length: 4, //todo make dynamic, based on map size
  label: 'Nieuw',
  description: '',
  object_type: 'spot',
  spot_cluster_id: null,
  price: initPrice,
  available_dates:[],
  availability_on_going:1,
  availabilities:[
    initAvailability
  ],
}

export default initSpot
