import React from "react";
import {Input, Media} from "./Form";
import Verified from "./Verified";
import useValidationIban from "../../hooks/useValidationIban";

const IbanMedia = (props) => {
  const {
    formData,
    hasError,
    model,
    name,
    form,
    id,
    save,
  } = props

  const {onClickValidateIban} = useValidationIban(id, form, '/user/organisatie/' + id + '/edit/')
  const hasId = model[name+'_id']

  let newProps = {...props}
  newProps.name = 'iban_media'
  newProps.allowSvg = false

  return (
    <div className="form-component-iban-media">
      <p>Voordat Standplaats.nl inkomsten uit verhuur kan uitbetalen is het nodig om het bankrekening nummer te valideerd.</p>
      <Verified
        formData={formData}
        className="form-component-verified "
        form={form}
        hasError={hasError}
        value={formData.model.iban_verified_at}
        id={id}
        label="IBAN nummer"
        name="iban_verified_at"
        save={save}
        validateButtonOnClick={onClickValidateIban}
        validateButtonLabel={"IBAN ophalen via iDeal"}
        removeOtherFields={['iban_number', 'iban_holder_name']}
      />

      {formData.model.iban_verified_at
        ?
        <></>
        :
        <>
          {hasId ?
            <h4>Verificatie Status: Foto geupload, wachtend op handmatige goedkeuring</h4>
            :
            <>
              <h4>Geen iDeal?</h4>
              <p>Upload handmatig dmv een scan of duidelijk leesbare foto van een bankpas of rekeningafschrift</p>
              <p>Deze handmatige verificatie kan enkele dagen duren.</p>
              <Media {...newProps}/>
            </>
          }
        </>
      }

      <Input
        label="IBAN account nummer"
        // placeholder="NL99BANK0000000000"
        name="iban_number"
        disabled={true}
      />
      <Input
        label="IBAN tenaamstelling"
        name="iban_holder_name"
        disabled={true}
      />

    </div>
  )
}

export default IbanMedia
