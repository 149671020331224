import React from "react";
import PropTypes from "prop-types";
import useForm from "../../hooks/useForm";

const Checkbox = (props) => {

  const {getValue} = useForm()

  const {
    label,
    name,
    text,
    value,
    className,
    hasError,
    error,
    isLoading,
    afterLabel,
    reversed,
    formData,
    setFormData,
  } = props

  /**
   *
   * @param e
   */
  const onChange = (e) => {
    if (reversed){
      formData.model[e.target.name] = (e.target.checked) ? 0 : 1
    } else {
      formData.model[e.target.name] = (e.target.checked) ? 1 : 0
    }

    setFormData(prevFormData => {
        return {
          ...prevFormData,
          collection: formData.collection,
          isChanged: true,
        }
      }
    )
  }

  return (
    <div className={className+ 'checkbox '}>
      <label>{label}
        {text && text !== '' &&
          <p className="text-small">{text}</p>
        }
        {hasError && <span className="error"> - {error}</span>}
        <div className="form-row">
          <input
            disabled={isLoading}
            name={name}
            checked={(reversed)?!getValue(value):getValue(value)}
            onChange={onChange}
            type='checkbox'
          />
          {afterLabel &&
            <span className="after-label">{afterLabel}</span>
          }
        </div>
      </label>
    </div>
  )
}

Checkbox.defaultProps = {
  label: '',
  name: '',
  text: '',
  value: '',
  className: '',
  hasError: false,
  onChange: () => {},
  error: '',
  reversed: false,
}

Checkbox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  className: PropTypes.string,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  reversed: PropTypes.bool,
}

export default Checkbox
