const filter = {
  direction: 'search',      //search, offer
  search_model: 'mixed',    //stand spot_group mixed
  offer_model: 'stand',         //stand spot_group mixed
  types: [],  //array of id's
  where: {
    city: '',
    postal_code: '',
    radius: 75,
  },
  when: null,           //
  industries: [],       //array of id's

  sort: 'updated_at_desc',       //price_asc, price_desc, rating_desc, distance_asc, created_at_desc, updated_at_desc
  page: 0, //{number} 'map'

  map:{
    radius: 300,
    lat: 52.497118623051904,
    lng: 5.5198451726829845
  }
}

export default filter
