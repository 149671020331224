import React, {useContext} from "react";
import AuthContext from "../../context/AuthContext";
import PropTypes from "prop-types";
import yesIcon from "../../assets/icons/yes.svg";
import noIcon from "../../assets/icons/no.svg";
import orangeIcon from "../../assets/icons/orange.svg";
import Button from "../Button/Button";
import {useNavigate} from "react-router-dom";
import useBookable from "../../hooks/useBookable";
import "./style.scss"

const ProfileStatus = (props) => {

  const {
    model,
  } = props

  const {user} = useContext(AuthContext)
  const {getUserActiveCompanyErrors, getModelErrors, getSolutionUrl, getBookableStatus} = useBookable()
  const navigate = useNavigate()
  const {isSpotGroup} = useBookable()
  const companyErrors = getUserActiveCompanyErrors(user)
  const modelErrors = getModelErrors(model)
  const {isStand} = useBookable()
  const bookableStatus = getBookableStatus(model)

  /**
   * Error component
   * @param props
   * @returns {JSX.Element}
   * @constructor
   */
  const Error = (props) => {
    const {error, onClick, showButton} = props
    return (
      <div className="error">
        <img className="status-icon" src={noIcon} alt="status"/>
        <div className="message">{error.message}</div>
        {showButton &&
          <Button
            label="Aanpassen"
            size="s"
            onClick={(onClick) ? onClick : null}
          />
        }
      </div>
    )
  }

  /**
   *
   * @returns {*}
   */
  const getPublicErrorButtonUrl = () => {
    return getSolutionUrl((isSpotGroup(model)) ? 'spot_group_public' : 'stand_public', (user.active_company) ? user.active_company : null, model)
  }

  /**
   * @returns {string}
   */
  const getLink = () => {
    return (isSpotGroup(model))
      ? '/locatie/' + model.slug
      : '/stand/' + model.slug
  }

  return (
    <div className="component-profile-status">
      {model.public ?
        <>
          {!!companyErrors.length || !!modelErrors.length
            ?
            <>
              <h3>Dit profiel is nu niet voor anderen zichtbaar</h3>
              <p>Om het profiel zichtbaar en boekbaar te maken voor iedereen, vul de volgende gegevens aan:</p>
              {!!companyErrors.length &&
                <>
                  <p>Organisatie: {user && 'active_company' in user && user.active_company && user.active_company.legal_name}</p>
                  <div className="errors company-errors">
                    {companyErrors.map((error, i) => {
                      const solutionUrl = getSolutionUrl(error.key, (user.active_company) ? user.active_company : null, model)
                      return (<Error
                        key={i}
                        error={error}
                        showButton={solutionUrl}
                        onClick={() => navigate(solutionUrl)}
                      />)
                    })}
                  </div>
                </>
              }
              {!!modelErrors.length &&
                <>
                  <p>{(isSpotGroup(model)) ? 'Locatie:' : 'Stand of Truck:'}</p>
                  <div className="errors model-errors">
                    {modelErrors.map((error, i) => {
                      const solutionUrl = getSolutionUrl(error.key, (user.active_company) ? user.active_company : null, model)
                      return (<Error
                        key={i}
                        error={error}
                        showButton={solutionUrl}
                        onClick={() => navigate(solutionUrl)}
                      />)
                    })}
                  </div>
                </>
              }
            </>
            :
            <>
              <div className="no-error">
                {bookableStatus.key === 'not-public' &&
                  <div className="row">
                    <img className="status-icon" src={orangeIcon} alt="status"/>
                    <p>Profiel niet openbaar, wel beschikbaar voor boekingen</p>
                  </div>
                }
                {bookableStatus.key === 'not-bookable' &&
                  <div className="row">
                    <img className="status-icon" src={noIcon} alt="status"/>
                    <p>Profiel niet openbaar en niet beschikbaar</p>
                  </div>
                }
                {bookableStatus.key === 'bookable' &&
                  <div className="row">
                    <img className="status-icon" src={yesIcon} alt="status"/>
                    <p>Profiel openbaar en beschikbaar</p>
                  </div>
                }
                {bookableStatus.key === 'closed' &&
                  <div className="row">
                    <img className="status-icon" src={orangeIcon} alt="status"/>
                    <p>Profiel openbaar, maar (tijdelijk) gesloten voor boekingen.</p>
                  </div>
                }
                <div className="row">
                  <Button
                    label="Bekijk je profiel"
                    link={getLink()}
                    target='_blank'
                  />
                </div>
              </div>
            </>
          }
        </>
        :
        <div>
          <h3>Profiel is verborgen</h3>
          <p>Dit profiel wordt niet weergegeven in de zoekresultaten en is dus niet door anderen te boeken.</p>
          <p>Het profiel is wel zichtbaar voor {isStand(model)?'Locatie':'Stands of Truck'} eigenaren waar jij boekingsaanvragen naartoe stuurt</p>
          <p>Je kan zelf ook {isStand(model)?'Locaties':'Stands of Trucks'} vinden en boeken met deze {isStand(model)?'Stand of Truck':'Locatie'}.</p>
          <div className="errors">
            <Error
              error={{key: 'public', message: 'Profiel verborgen'}}
              showButton={getPublicErrorButtonUrl()}
              onClick={() => navigate(getPublicErrorButtonUrl())}
            />
          </div>
        </div>
      }
    </div>
  )
}

ProfileStatus.propTypes = {
  model: PropTypes.object.isRequired,
}

export default ProfileStatus



















