import React from "react";
import {Link} from "react-router-dom"
import PropTypes from 'prop-types'
import './style.scss'

const Button = (props) => {

  const {
    onClick,
    onMouseEnter,
    onMouseLeave,
    label,
    disabled,
    className,
    link,
    linkState,
    linkOutside,
    icon,
    iconSize,
    target,
    style,
    fullWidth,
    iconReversed,
    justifyContent,
    size,
    color,
    children,
  } = props

  /**
   * scenarios:
   * - <a> (external)
   * - <Link> (internal)
   * - <button> onClick/no link
   *
   * props
   * - multiple icons: hover and normal
   * - icon alignment: left, right, center (when no label)
   * - disabled
   * - link
   * - onClick (function callback)
   * - className: small, medium, large, colors: red-green-yellow-orange, style: ghost, solid, shadow/no shadow
   * - linkState
   * - style
   * - target
   *
   */

  const clickHandler = (e) => {
    e.preventDefault()
    onClick(e)
  }

  const getStyle = () => {
    if (justifyContent){
      return Object.assign({}, {justifyContent:justifyContent} , style)
    } else {
      return style
    }
  }

  const getClassName = () => {
    let string = "component-button  "
    if (className) {
      string = "component-button " + className
    }
    if (disabled) {
      string += " disabled "
    }
    if (fullWidth) {
      string += " btn-full-width "
    }
    if (iconReversed) {
      string += " btn-icon-reversed "
    }
    if (size) {
      string += " btn-size-" + size
    }
    if (color) {
      string += " btn-color-" + color
    }
    if (children){
      string += " with-children "
    }
    return string
  }

  return (
    <>
      {linkOutside &&
        <a
          className={getClassName()}
          style={getStyle()}
          href={linkOutside}
          target={target}
          title={label}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {icon && <img src={icon} width={iconSize} alt={label}/>}
          {label}
          {children && children}
        </a>
      }

      {link &&
        <Link
          className={getClassName()}
          to={link}
          style={getStyle()}
          state={linkState}
          target={target}
          disabled={disabled}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {icon && <img src={icon} width={iconSize} alt={label}/>}
          {label}
          {children && children}
        </Link>
      }

      {(onClick || (!linkOutside && !link)) &&
        <button
          className={getClassName()}
          onClick={(onClick && !disabled && !linkOutside) ? clickHandler : undefined}
          type="button"
          style={getStyle()}
          disabled={disabled}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {icon && <img src={icon} width={iconSize} alt={label}/>}
          {label}
          {children && children}
        </button>
      }
    </>
  )
}

Button.defaultProps = {
  label: 'Button Label',
  link: null,
  style: {},
  linkState: null,
  onClick: null,
  className: null,
  class: "btn-dark",
  disabled: false,
  icon: null,
  iconSize: null,
  target: null,
  fullWidth: false,
  iconReversed: false,
  justifyContent:"center",
  size:"m",
  color:"green",
}

Button.propTypes = {
  label: PropTypes.string,
  link: PropTypes.string,
  style: PropTypes.object,
  linkState: PropTypes.object,
  onClick: PropTypes.func,
  class: PropTypes.string,
  disabled: PropTypes.bool,
  // icon: PropTypes,
  iconSize: PropTypes.number,
  target: PropTypes.string,
  fullWidth: PropTypes.bool,
  iconReversed: PropTypes.bool,
  justifyContent: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
}

export default Button
