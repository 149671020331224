import React from "react";
import {
  Form,  Fields, Input, Textarea, SelectBlock, Price, Media,
} from "../../components/Form/Form";
import {useParams} from "react-router-dom";
import useSpotService from "../../api/services/useSpotService";
import Availabilities from "../../components/Form/Availabilities";
import industries from "../../data/industries";
import useForm from "../../hooks/useForm";
import Three from "../../components/Form/Three";

const UserSpot = () => {

  const spotService = useSpotService()
  const {id} = useParams();
  const form = useForm()

  return (
    <div>
      <h1>Spot</h1>

      <Form>
        <Fields
          id={id}
          form={form}
          apiService={spotService}
          GAEventLabel="User Spot Edit"
        >
          <Input
            label="ID/Label/Naam"
            name="label"
          />
          <Textarea
            label="Beschrijving"
            name="description"
          />

          <Input
            label="Breedte (let op: veranderingen hier zijn zichtbaar op spot kaart)"
            name="width"
            type="meter"
          />
          <Input
            label="Lengte (let op: veranderingen hier zijn zichtbaar op de spot kaart)"
            name="length" //note: 'length' is used in the database and backend. 'height' is used in the front-end.
            type="meter"
          />

          <SelectBlock
            label="Alle producten/branches die je zoekt voor DEZE Standplaats/Spot/Plek"
            name="industries_ids"
            data={industries}
          />

          <Price
            // label="Prijs voor specifiek deze Standplaats/Spot/Plek"
            name="price"
          />

          <Three
            label="Is deze Spot elke dag beschikbaar?"
            name="available"
          />
          <Availabilities
            label="Beschikbaarheid specifiek voor deze Standplaats/Spot/Plek"
            name="availabilities"
            enabledBy={[{key:'available', value:2}]}
          />

          <Media
            label="Afbeelding van deze plek"
            name="overview_media"
            width={900}
            height={450}
            size="normal"
          />

        </Fields>
      </Form>
    </div>
  )
}


export default UserSpot
