import React from "react";
import PropTypes from "prop-types";
import usePrice from "../../hooks/usePrice";
import useBookable from "../../hooks/useBookable";
import "./BookingSelectOptions-style.scss"

const BookingSelectOptions = (props) => {

  const maxOptions = 50 //todo is 50  de max?

  const {
    className,
    formData,
    save,
    value,
  } = props

  const booking = formData.model;
  const bookable = value



  const {formatPrice,getBookingOptionPrice} = usePrice()
  const {isStand} = useBookable()

  /**
   * Get the maximum amount for select field.
   * Based on the max_per_booking and max_per_spot settings
   *
   * @param option
   * @returns {number|*|number}
   */
  const getMaxAmount = (option) => {
    const spotMax = (booking.spots.length * option.max_per_spot)
    const max = (option.max_per_booking < spotMax)
      ? option.max_per_booking
      : spotMax
    return (max) ? max : maxOptions
  }

  /**
   * Get select option value by option object
   *
   * @param bookableOption
   * @returns {*|number}
   */
  const getValue = (bookableOption) => {
    const bookingOption = booking.booking_options.find(item => item.bookable_option_id === bookableOption.id)
    return (bookingOption) ? bookingOption.amount : 0
  }
  //
  // /**
  //  *
  //  * @param option
  //  * @returns {number}
  //  */
  // const getTotalOptionPrice = (option) => {
  //   // if (isSpotGroup(bookable)) {
  //   //   return priceOption(booking, option) * booking.date_ranges.length * booking.spots.length * option.amount
  //   // } else {
  //   //   return priceOption(booking, option) * booking.date_ranges.length * option.amount
  //   // }
  // }

  /**
   *
   * @param e
   */
  const onChangeAmount = (e) => {
    /*
     * Check and adds bookingOption to booking state in booking.booking_options array
     * It gets the bookingOption object from the bookable.bookable_options.find(id)
     */

    const amount = parseInt(e.target.value)
    const bookableOptionId = parseInt(e.target.name)
    const bookingOption = booking.booking_options.find(item => item.bookable_option_id === bookableOptionId)

    if (bookingOption){
      if (amount === 0) {
        // booking.booking_options.splice(booking.booking_options.indexOf(bookingOption), 1)
        bookingOption.amount = 'null'
      } else {
        bookingOption.amount = amount
      }

    } else {
      const bookableOption = bookable.bookable_options.find(({id}) => id === bookableOptionId)

      booking.booking_options.push({
        temp_id:Date.now(),
        amount:amount,
        prices:null,
        bookable_option_id: bookableOptionId,
        bookable_option:bookableOption,
        option:bookableOption.option,
      })
    }
    save()
  }

  return (
    <div className={className + 'booking-select-options '}>
      {bookable.bookable_options && bookable.bookable_options.length ?
        bookable.bookable_options.map((bookableOption, i) => {
          return (
            <div key={i} className="booking-option">
              <label>
                <div className="row">
                  <div className="col text">{bookableOption.option.label + ' - ' + bookableOption.label}</div>
                  <div className="col prices">
                    <div className="amount">
                      <p>Aantal:</p>
                      <select
                        name={bookableOption.id}
                        id="amount"
                        onChange={onChangeAmount}
                        value={getValue(bookableOption)}
                      >
                        <option value="0">Geen</option>
                        {Array.apply(null, {length: getMaxAmount(bookableOption)}).map((i, i2) => {
                          return (<option key={i2} value={i2 + 1}>{i2 + 1}</option>)
                        })}
                      </select>
                    </div>
                    <div
                      className="price">Voor <br/>{(booking.date_ranges.length === 1) ? ' 1 dag' : booking.date_ranges.length + ' dagen'}<br/>
                    </div>
                    <div
                      className="total-price">Totaal: <br/>{formatPrice(getBookingOptionPrice(booking, bookableOption))}
                    </div>
                  </div>
                </div>
              </label>
            </div>
          )
        })
        :
        <p>
          Geen extra opties beschikbaar voor deze {(isStand(bookable)) ? 'Stand of Truck' : 'Locatie'}
        </p>
      }
    </div>
  )
}

BookingSelectOptions.defaultProps = {
  name: '',
}

BookingSelectOptions.propTypes = {
  name: PropTypes.string.isRequired,
}

export default BookingSelectOptions
