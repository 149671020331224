import {useParams, useSearchParams} from "react-router-dom";
import useCompanyService from "../api/services/useCompanyService";
import {useEffect} from "react";
import cloneDeep from 'lodash/cloneDeep';
import swal from "sweetalert";


const useValidationIban = (id, form, callbackUrl) => {
  const companyService = useCompanyService()
  const params = useParams()
  const [searchParams, setSearchParams] = useSearchParams();


  useEffect(() => {
    userValidateIbanCallback()
  }, [params])

  const onClickValidateIban = () => {
    companyService.userValidateIban(
      (data) => {
        if ('url' in data) {
          window.location.href = data.url
        } else {

          //todo do error


        }
      },
      () => {
      },
      id,
      callbackUrl,
    )
  }

  const userValidateIbanCallback = () => {
    const validateIban = searchParams.get("validate_iban")
    if (id && validateIban) {
      companyService.userValidateIbanCallback(
        (company) => {
          if (company.iban_verified_at) {
            form.formData.model = company
            form.setOriginalFormDataModel(cloneDeep(company))
            form.save(false)
            searchParams.delete('validate_iban')
            setSearchParams(searchParams);

            swal({
              title: 'Success',
              icon: 'success',
              text: 'Validatie Gelukt',
              buttons: {
                confirm: {
                  text: "Oke",
                  value: true,
                  visible: true,
                  className: 'btn-color-grey btn-size-m',
                  closeModal: true,
                }
              }
            })


          } else {

            swal({
              title: 'Error',
              icon: 'error',
              text: 'Validatie Mislukt',
              buttons: {
                confirm: {
                  text: "Oke",
                  value: true,
                  visible: true,
                  className: 'btn-color-grey btn-size-m',
                  closeModal: true,
                }
              }
            })
            //todo show error

          }
        },
        () => {
        },
        id,
        validateIban,
      )
    }
  }

  return {
    onClickValidateIban,
    userValidateIbanCallback,
  }
}

export default useValidationIban
