import useApi from "../../api/useApi";
import useFunction from "../../hooks/useFunction";

const useFavoriteService = () => {

  const {api} = useApi()
  const {c} = useFunction()

  const userShowStand = (callbackSuccess, callbackValidationErrors, standId) => {
    return api(
      'get',
      'user/favorites/add/stand/' + standId,
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors), //todo do error handling here
      error =>  c('Error in Service', error), //todo do error handling here (and everywhere else in services)
    )
  }

  const userShowSpotGroup = (callbackSuccess, callbackValidationErrors, standId) => {
    return api(
      'get',
      'user/favorites/add/stand/' + standId,
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in Service', error),
    )
  }

  const userShowAll = (callbackSuccess, callbackValidationErrors) => {
    return api(
      'get',
      'user/favorites/show/all',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in Service', error),
    )
  }

  const userAddStand = (callbackSuccess, callbackValidationErrors, standId) => {
    return api(
      'get',
      'user/favorites/add/stand/' + standId,
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in Service', error),
    )
  }

  const userAddSpotGroup = (callbackSuccess, callbackValidationErrors, spotGroupId) => {
    return api(
      'get',
      'user/favorites/add/spot_group/' + spotGroupId,
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in Service', error),
    )
  }

  const userRemoveStand = (callbackSuccess, callbackValidationErrors, standId) => {
    return api(
      'get',
      'user/favorites/remove/stand/' + standId,
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in Service', error),
    )
  }

  const userRemoveSpotGroup = (callbackSuccess, callbackValidationErrors, spotGroupId) => {
    return api(
      'get',
      'user/favorites/remove/spot_group/' + spotGroupId,
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in Service', error),
    )
  }

  return {
    userShowStand,
    userShowSpotGroup,
    userShowAll,
    userAddStand,
    userAddSpotGroup,
    userRemoveStand,
    userRemoveSpotGroup,
  }
}

export default useFavoriteService
