import React from "react";
import Button from "../Button/Button";

const AdminNav = () => {
  return(
    <div className="component-admin-nav">
      <h1>Admin</h1>
      <Button
        label="Admin Ttools"
        link="/user/admin/tools"
      />
      <Button
        label="Users"
        link="/user/admin/users"
      />
      <hr/>
    </div>
  )
}
export default AdminNav
