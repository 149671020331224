import useApi from "../../api/useApi";
import useFunction from "../../hooks/useFunction";

const useMessengerService = () => {

  const {api} = useApi()
  const {c} = useFunction()

  const userShow = (callbackSuccess, callbackValidationErrors, latestMessageId) => {

    return api(
      'get',
      'user/messages/'+latestMessageId,
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in Service', error),
    )
  }

  const userSend = (callbackSuccess, callbackValidationErrors, data) => {
    return api(
      'post',
      'user/messages/send',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in Service', error),
      data,
    )
  }

  const userRead = (callbackSuccess, callbackValidationErrors, conversationId) => {
    return api(
      'post',
      'user/messages/read',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in Service', error),
      conversationId,
    )
  }

  const countNotifications = (callbackSuccess, callbackValidationErrors, companyId) => {
    return api(
      'get',
      'user/notifications/'+companyId,
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in Service', error),
    )
  }

  return {
    userSend,
    userShow,
    userRead,
    countNotifications,
  }
}

export default useMessengerService
