import React, {useState} from "react"
import PropTypes from "prop-types";
import Button from "../Button/Button";
import swal from "sweetalert";
import useFunction from "../../hooks/useFunction";
import './AddMultiBlock-style.scss'

const AddMultiBlock = (props) => {

  const {c} = useFunction()

  const {
    label,
    value,
    className,
    hasError,
    error,
    isLoading,
    type,
    placeholder,
    formData,
    setFormData,
    keyLabel,
    confirmAdd,
    searchDataService,
    confirmAddTitle,
  } = props

  const [inputData, setInputData] = useState({value: ''})
  const [clientError, setClientError] = useState({hasError: false, text: ''})
  const [searchData, setSearchData] = useState({data: null, status: 'unset', date: null, results: null});
  const [showMenu, setShowMenu] = useState(false)


  /**
   * Validate email
   *
   * //todo move to helper class or better location
   * @param string
   * @return {boolean}
   */
  const validateEmail = (string) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return (re.test(string))
  }

  /**
   *
   */
  const handeAdd = () => {
    const id = value.length
    if (type === 'email') {
      if (validateEmail(inputData.value)) {
        let doubleEmail = false
        for (let index = 0; index < value.length; index++) {
          let item = value[index]
          if (item.email === inputData.value) {
            doubleEmail = true
          }
        }
        if (doubleEmail) {
          setClientError(prevClientError => {
            return {
              ...prevClientError,
              hasError: true,
              text: 'E-mailadres is al toegevoegd',
            }
          })
          return
        }
      } else {
        setClientError(prevClientError => {
          return {
            ...prevClientError,
            hasError: true,
            text: 'Voer een geldig e-mailadres in van een geregistreerde gebruiker',
          }
        })
        return
      }
    }

    if (confirmAdd === '' || !confirmAdd) {
      add(id)
    } else {
      swal({
        title: confirmAddTitle.replace('%s', inputData.value),
        icon: 'warning',
        text: confirmAdd.replace('%s', inputData.value),
        dangerMode:true,
        buttons: {
          cancel: {
            text: 'Annuleren',
            value: null,
            visible: true,
            className: 'btn-color-grey btn-size-m',
            closeModal: true,
          },
          confirm: {
            text: 'OK',
            value: true,
            visible: true,
            className: 'btn-color-red btn-size-m',
            closeModel: true,
          }
        }
      }).then((result) => {
        if (result) {
          add(id)
        }
      })
    }
  }

  /**
   *
   * @param id
   */
  const add = (id) => {
    setInputData(prevInputData => {
      return {
        ...prevInputData,
        value: '',
      }
    })

    value[id] = {
      id: id,
      [keyLabel]: inputData.value,
      status: 'concept'
    }

    setFormData(prevFormData => {
      return {
        ...prevFormData,
        collection: formData.collection, //todo change to .model
        isChanged: true,
      }
    })
  }

  /**
   *
   * @param e
   */
  const handleChange = (e) => {
    setClientError(prevClientError => {
      return {
        ...prevClientError,
        hasError: false,
        text: '',
      }
    })

    setInputData(prevInputData => {
      return {
        ...prevInputData,
        value: e.target.value,
      }
    })

    if (searchDataService && searchData.status !== 'unset') {
      let results = []

      results = searchData.data.filter((x, i) => {
        return x.description.toLowerCase().includes(e.target.value.toLowerCase())
      })

      setSearchData(prevSearchData => {
        return {
          ...prevSearchData,
          results: results
        }
      })
    }
  }


  /**
   * //todo at some point. add check that when data is too old, refresh it.
   * //todo add React.memo to data, so data does not need to download all the time..
   *
   *
   * //todo disbale input fields on loading
   */
  const handleFocus = () => {

    setShowMenu(true)

    if (searchDataService && searchData.status === 'unset') {
      searchDataService(
        //callbackSuccess
        (data) => {
          c('data', data)

          setSearchData(prevSearchData => {
            return {
              ...prevSearchData,
              status: 'set',
              date: Date.now(),
              data: data,
            }
          })
        },
        //callbackValidationErrors
        (errors) => {
          c('errors', errors)
        }
      )
    }
  }

  const classNameBase = className + 'add-multi-block '

  const handleBlur = () => {
    setShowMenu(false)
  }

  const handleAdd = () => {

  }

  return (
    <div className={clientError.hasError ? classNameBase + ' has-error' : classNameBase}>
      <label>{label}
        {hasError && <span className="error"> - {error}</span>}
        {clientError.hasError && <span className="error client-error"> - {clientError.text}</span>}
        <div className="form-row">
          <input
            disabled={isLoading || (searchDataService && searchData.status === 'unset' && searchData.date !== null)}
            type={type}
            value={inputData.value ? inputData.value : ''}
            onChange={handleChange}
            placeholder={placeholder}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />


          {showMenu && searchData.results &&
            <div className="results">
              {
                searchData.results.slice(0, 100).map((result, i) => {
                  return (
                    <div
                      key={i}
                      className="result"
                    >{result[keyLabel]}</div>
                  )
                })
              }

              {searchData.results.length > 4 ?
                <></>
                :
                <div className="add-new">
                  <p>Staat er niet tussen wat je zoekt?</p>
                  <Button
                    label="Nieuwe aanmaken"
                    onClick={handleAdd}
                  />
                </div>
              }
            </div>
          }

          <Button
            label="Zoeken"
            className="add-button btn"
            onClick={handeAdd}
            disabled={!inputData.value.length}
            size="l"
          />
        </div>
      </label>
    </div>
  )
}

//todo update defaults and types
AddMultiBlock.defaultProps = {
  label: '',
  // name: '',
  value: [],
  confirmAdd: '',
  type: 'text',
  // className: '',
  // hasError: false,
  // onChange: () => {
  // },
  // error: '',
  placeholder: '',
  searchDataService: null,
}

AddMultiBlock.propTypes = {
  label: PropTypes.string,
  confirmAdd: PropTypes.string,
  // name: PropTypes.string,
  value: PropTypes.array,
  type: PropTypes.string,
  // className: PropTypes.string,
  // hasError: PropTypes.bool,
  // onChange: PropTypes.func,
  // error: PropTypes.string,
  placeholder: PropTypes.string,
  searchDataService: PropTypes.func,
}

export default AddMultiBlock
