import React, {useEffect} from "react";
import ReactDOM from 'react-dom'
import PropTypes from "prop-types";
import Button from "../Button/Button";
import './styles.scss'

const Popup = (props) => {

  const {
    trigger,
    children,
    buttons,
    postButtons,
    title,
    hideOnOutsideClick,
    showCloseButton,
    closeButtonLabel,
    closeButtonClassName,
    disableButtons,
    showPopup,
    setShowPopup,
    iteration,
    callbackOpenPopup,
  } = props

  const portalContainer = document.getElementById("portal-container");

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        setShowPopupBool(false)
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  /**
   *
   */
  const onClick = () => {
    hideOnOutsideClick && setShowPopupBool(false)
  }

  /**
   * handle setShowPopup in correct prototype showPopup:
   * showPopup can be bool or array.
   *
   * @param bool
   */
  const setShowPopupBool = (bool) => {
    if (iteration !== false) {
      setShowPopup({...showPopup, [iteration]: bool})
    } else {
      setShowPopup(bool)
    }

    if (callbackOpenPopup){
      callbackOpenPopup()
    }
  }

  /**
   * handle showPopup in correct prototype:
   * showPopup can be bool or array.
   *
   * @returns {*|false}
   */
  const showPopupBool = () => {
    return (
      (iteration !== false && showPopup[iteration]) ||
      (iteration === false && showPopup))
  }


  return (
    <div className="component-popup">

      {trigger &&
        <div
          className="popup-trigger"
        >{React.cloneElement(trigger, {
          onClick: () => setShowPopupBool(true),
        })}</div>
      }

      {showPopupBool() && ReactDOM.createPortal(
        <>
          <div
            className="popup-backdrop"
            onClick={onClick}
          ></div>
          <div className="popup-modal">
            <div className="modal">
              <div className="scroll-box">
                <h1 className="title">{title}</h1>
                <div className="content">
                  {children}
                </div>
              </div>
              <div className="actions">
                {buttons && buttons}
                {showCloseButton &&
                  <Button
                    label={closeButtonLabel}
                    onClick={() => setShowPopupBool(false)}
                    className={closeButtonClassName}
                    disabled={disableButtons}
                  />
                }
                {postButtons && postButtons}
              </div>
            </div>
          </div>
        </>
        ,
        portalContainer
      )}
    </div>
  )
}

Popup.defaultProps = {
  showCloseButton: true,
  disableButtons: false,
  hideOnOutsideClick: true,
  title: '',
  closeButtonLabel: 'Sluiten',
  closeButtonClassName: 'btn',
  iteration: false,
  showPopup: false,
  setShowPopup: () => {
  },
}

Popup.propTypes = {
  title: PropTypes.string,
  closeButtonLabel: PropTypes.string,
  closeButtonClassName: PropTypes.string,
  showCloseButton: PropTypes.bool,
  disableButtons: PropTypes.bool,
  hideOnClickOutside: PropTypes.bool,
  buttons: PropTypes.func,
  postButtons: PropTypes.func,
  trigger: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  iteration: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  showPopup: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]).isRequired,
  setShowPopup: PropTypes.func.isRequired,
}
export default Popup
