/**
 * Debug flag
 *
 *
 *
 * @type {boolean}
 */
const script = document.currentScript;
const localhost = (script && (script.src.includes('http://localhost:') || script.src.includes('https://localhost:')))
const dev = (script && (script.src.includes('https://dev.standplaats.nl') || script.src.includes('https://dev.standplaats.nl')))
const mainPageTitle = 'Standplaatsen voor de ambulante handel, food-trucks, marktkramen & mobiele ondernemers';

let debug, backendUrl, googleMapsApiKey, GACode, logRocketCode, hotJarCode, hotJarVersion

/**
 * define env vars
 */
if (localhost){
  console.log('ENV: localhost')
  backendUrl = 'http://localhost'
  googleMapsApiKey = null
  debug = true
} else if (dev){
  console.log('ENV: dev')
  backendUrl = 'https://dev-backend.standplaats.nl'
  googleMapsApiKey = 'AIzaSyAOYi68VYmwSqolqD4euLGSgyDPFDqoP2c'
  debug = true
} else {
  backendUrl = 'https://backend.standplaats.nl'
  googleMapsApiKey = 'AIzaSyAOYi68VYmwSqolqD4euLGSgyDPFDqoP2c'
  debug  = true
  GACode = "G-CHWBFBR54L"; //Google Analytics UA code
  logRocketCode = 'towf33/standplaatsnl'
  hotJarCode = 3924747;
  hotJarVersion = 6;
}

debug && console.log('Debug', debug)

export {
  mainPageTitle,
  backendUrl,
  debug,
  dev,
  localhost,
  googleMapsApiKey,
  GACode,
  logRocketCode,
  hotJarCode,
  hotJarVersion,
}
