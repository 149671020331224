import React from "react";
import './style.scss'

const Portal = () => {
  return (
    <div className="component-backdrop">
      {/*<div id="backdrop"></div>*/}
      <div id="portal-container"></div>
    </div>
  )
}

export default Portal
