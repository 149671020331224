import React, {useEffect} from "react";
import infoIcon from "../../assets/icons/info.svg";
import infoIconOrange from "../../assets/icons/info-orange.svg";
import {Tooltip} from "react-tooltip";
import './styles.scss'

const Info = (props) => {
  const {content, variant, children, iconColor} = props

  const rand = (Math.random() + 1).toString(36).substring(7)

  useEffect(() => {
    const root = document.querySelector(':root');
    root.style.setProperty('--rt-color-dark', '#2D3040');
    root.style.setProperty('--rt-color-white', '#F7F7F7');
  }, [])


  return (
    <>
      <Tooltip
        id={rand}
        clickable={true}
        // isOpen={true}
      >
        {content}
        {children}
      </Tooltip>
      <div
        className="component-info"
        data-tooltip-id={rand}
        data-tooltip-delay-hide={500}
        // data-tooltip-content={props.content}

        data-tooltip-variant={(variant)?variant:'dark'}
      >
        <img src={(iconColor && iconColor === 'orange')?infoIconOrange:infoIcon} alt="Informatie"/>
      </div>
    </>
  )
}

export default Info
