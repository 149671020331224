import {useLocation} from "react-router-dom";


const useLocationState = () => {
  const location = useLocation()

  /**
   *
   * @returns {false|any}
   */
  const hasLocationState = (key) => {
    return (
      location &&
      'state' in location &&
      location.state &&
      key in location.state &&
      location.state[key]
    )
  }

  /**
   * locationState object to go to and get back from WizardBooking
   * It changes the back buttons in first 2 slides
   *
   * @param booking
   // * @param parentWizard
   * @returns {{booking, buttonPrev: {select_stand: {onClick: {navigate: string}, className: string, label: string}, select_spot_group: {onClick: {navigate: string}, className: string, label: string}, start: {onClick: {navigate: string}, className: string, label: string}}}}
   */
  const getWizardBookingState = (
    booking = null,
    // parentWizard = null
  ) => {
    return {
      // parentWizard: parentWizard,
      booking: booking,
      buttonPrev: {
        start: {
          onClick: {navigate: location.pathname},
          className: 'btn-red',
          label: 'Terug',
        },
        select_stand: {
          onClick: {navigate: location.pathname},
          className: 'btn-red',
          label: 'Terug',
        },
        select_spot_group: {
          onClick: {navigate: location.pathname},
          className: 'btn-red',
          label: 'Terug',
        }
      }
    }
  }

  /**
   * locationState object to go to and get back from WizardAddStand / WizardAddSpotGroup
   * It changes the back buttons in first slide and buttonNext in finish slide
   *
   * @returns {{buttonPrev: {new: {onClick: {navigate: string}, visible: boolean, disabled: boolean, label: string}}, buttonNext: {finish: {onClick: {navigate: string}, visible: boolean, disabled: boolean, label: string}}}}
   */
  const getWizardBookableState = (
    // parentWizard = null
  ) => {

    // console.log('parentWizard', parentWizard)
    // console.log('parentWizard l', parentWizard.locationStateButtonPrev)
    // console.log('parentWizard n', parentWizard.locationStateButtonNext)

    return {
      buttonNext: {
        finish: {
          label: 'Verder met boeking',
          visible: true,
          disabled: false,
          onClick: {
            navigate: location.pathname,
            // state: (parentWizard) ? {
            //   buttonPrev: parentWizard.locationStateButtonPrev,
            //   buttonNext: parentWizard.locationStateButtonNext,
            // } : {}
          },
        }
      },
      buttonPrev: {
        new: {
          label: 'Terug',
          visible: true,
          disabled: false,
          onClick: {navigate: location.pathname},
        }
      }
    }
  }

  return {
    location,
    hasLocationState,
    getWizardBookingState,
    getWizardBookableState
  }
}

export default useLocationState;
