import useFunction from "../../hooks/useFunction";
import useApi from "../../api/useApi";
import {useContext} from "react";
import AuthContext from "../../context/AuthContext";

const useUserSettingsService = () => {

  const {c} = useFunction()
  const {api} = useApi()
  const {user, setUser} = useContext(AuthContext)

  const updateUserAfterSuccess = (setUser, callbackSuccess, responseData) => {
    setUser({...user, settings:responseData})
    callbackSuccess(responseData)
  }

  const userShow = (callbackSuccess, callbackValidationErrors) => {
    return api(
      'get',
      'user/settings',
      response => updateUserAfterSuccess(setUser, callbackSuccess, response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in show service', error),
    )
  }

  const userUpdate = (callbackSuccess, callbackValidationErrors, data) => {
    return api(
      'post',
      'user/settings/update',
      response => updateUserAfterSuccess(setUser, callbackSuccess, response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in update service', error),
      data,
    )
  }

  return {
    objectType:'user',
    userShow,
    userUpdate,
  }
}
export default useUserSettingsService
