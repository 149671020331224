import React from "react";
import Button from "../../components/Button/Button";
import {default as Error404Comp} from "../../components/Error404/Error404";
import './style.scss'

const Error404 = () => {
  return (
    <div className="page-error-404">
      <section className="section-container">
        <Error404Comp
          label="Oeps! Pagina niet gevonden"
          text="Onze excusses voor het ongemak..."
        />
        <div className="buttons">
          <Button
            label="Neem Contact op"
            linkOutside={'/service/contact/'}
            color="grey-ghost"
          />
          <Button
            label="Bezoek Homepagina"
            link="/"
            color="grey-ghost"
          />
        </div>
      </section>
    </div>
  )
}

export default Error404
