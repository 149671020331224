const initialBooking = {
  status: 'concept', //concept, requested, accepted, rejected, paid, canceled, completed, invalid
  prices:{}, //array
  type: null, //stand-requests-spot, spot-group-requests-stand, = required

  requested_terms_accepted_at: null,
  received_terms_accepted_at: null,
  requested_by_company_id: null,
  received_by_company_id: null,

  spots: [],
  spots_ids: [],
  dates: [],      //required array of DateObjects
  dates_text: [], //required array of strings

  stand: {},
  spot_group: {},
  booking_options: [],

  stand_id: null,
  spot_group_id: null,
}

export default initialBooking
