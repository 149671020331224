import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import usePaymentService from "../../api/services/usePaymentService";
import Loading from "../../components/Loading/Loading";
import Button from "../../components/Button/Button";
import './style.scss';

const ProcessPayment = () => {
  const {check} = usePaymentService()
  const {id} = useParams()
  const [status, setStatus] = useState(null)

  useEffect(() => {
    check(
      (status) => {
        // c('status', status)
        setStatus(status)
      },
      () => {
      },
      id
    )
  }, [])

  return (
    <div className="page-process-payment">
      <div className="section-container">
        <div className="center">
          {status ?
            <div>
              {status.status === 'paid' ?
                <>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <h3>Bedankt voor je betaling!</h3>
                  <br/>
                  <p>Jouw boeking is hiermee definitief. Check je email voor alle boeking details</p>
                  <br/>
                  <br/>
                  <br/>
                  <div className="row">
                    <Button
                      label="Homepagina"
                      link="/"
                    />
                    <Button
                      label="Mijn Boekingen"
                      link="/user/boekingen"
                    />
                  </div>
                </>
                :
                <>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <h3>Betaling niet gelukt</h3>
                  <br/>
                  <p>Je boeking is pas definitief als de betaling gelukt is!</p>
                  <br/>
                  <br/>
                  <br/>
                  <div className="row">
                    <Button
                      label="Opnieuw betalen"
                      linkOutside={status.payment_url}
                    />
                  </div>
                </>
              }
            </div>
            :
            <Loading/>
          }
        </div>
      </div>
    </div>
  )
}

export default ProcessPayment
