import React, {useState} from "react";
import {Tooltip} from "react-tooltip";
import InfoOrange from "../../assets/icons/info-orange.svg";
import Info from "../../assets/icons/info.svg";
import useIndustry from "../../hooks/useIndustry";
import useBooking from "../../hooks/useBooking";
import useSpotMap from "../../hooks/useSpotMap";
import SpotPopup from "../SpotPopup/SpotPopup";
import SpotMap from "./SpotMap";
import Price from "../Price/Price";
import Media from "../Media/Media";
import Popup from "../Popup/Popup";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SpotMapPublic = (props) => {
  const {
    spotGroup,
    booking,
    setBooking,
    onSpotClick,
  } = props
  const map = useSpotMap(spotGroup)
  return (
    <div className="component-spot-map-public">
      <div className="inner">
        <SpotMap
          map={map}
        >
          <>
            {spotGroup.spots && spotGroup.spots.length &&
              <SpotMapPublicSpots
                map={map}
                spots={spotGroup.spots.filter(spot => !spot.spot_cluster_id)} // Filter spots without cluster ID
                // spots={spotGroup.spots}
                booking={booking}
                setBooking={setBooking}
                onSpotClick={onSpotClick}
              />
            }
            {spotGroup.spot_clusters && spotGroup.spot_clusters.length &&
              <SpotMapPublicSpotClusters
                map={map}
                spotClusters={spotGroup.spot_clusters}
                spotGroup={spotGroup}
                booking={booking}
                setBooking={setBooking}
                onSpotClick={onSpotClick}
              />
            }
          </>
        </SpotMap>
      </div>
      <div className="legend">
        <div className="row ">
          <div className="dot white"></div>
          <div className="label">Standplaats beschikbaar</div>
        </div>
        <div className="row ">
          <div className="dot grey"></div>
          <div className="label">Standplaats niet beschikbaar</div>
        </div>
        <div className="row">
          <div className="dot green"></div>
          <div className="label">Standplaats geselecteerd voor boeking</div>
        </div>
      </div>
    </div>
  )
}

/**
 *
 * @param spotCluster
 * @param spots
 * @param booking
 * @param setBooking
 * @param onSpotClick
 * @returns {JSX.Element}
 * @constructor
 */
const SpotMapPublicCluster = ({spotCluster, spots, booking, setBooking, onSpotClick}) => {
  const map = useSpotMap(spotCluster)
  return (
    <div className="sub-component-spot-map-public-spot-map-public-cluster">
      <SpotMap
        map={map}
        disabled={true}
      >
        <SpotMapPublicSpots
          map={map}
          spots={spots}
          booking={booking}
          setBooking={setBooking}
          onSpotClick={onSpotClick}
        />
      </SpotMap>
    </div>
  )
}

/**
 *
 * @param map
 * @param spots
 * @param booking
 * @param setBooking
 * @param onSpotClick
 * @returns {JSX.Element}
 * @constructor
 */
const SpotMapPublicSpots = ({map, spots, booking, setBooking, onSpotClick}) => {
  const {getIndustry} = useIndustry()
  const [showPopupSpot, setShowPopupSpot] = useState([])
  const {getSpotStateClass} = useBooking()
  const [tooltipVisibility, setTooltipVisibility] = useState(null); // New state for tooltip visibility

  const getIndustryLabel = (spot) => {
    if (spot.industries_ids && spot.industries_ids.length){
      return spot.industries_ids.map((industry_id, i) => {
        if (i < 4) {
          const industry = getIndustry(industry_id)
          return (<span key={i}>{industry.label}, </span>)
        } else if (i === 4) {
          return (<span key={i}>...</span>)
        }
      })
    } else {
      return <span>Alle branches</span>
    }
  }

  return (
    <>
      {spots.map((spot, i) => {
        const spotClasses = getSpotStateClass(spot, booking)
        return (
          <div
            key={i}
            className={"mappable spot " + spotClasses}
            style={{
              ...map.getMappableStyle(spot), ...{
                outlineWidth: `${2 / map.zoomLevel}px`,
                borderRadius: `${3 / map.zoomLevel}px`,
                zIndex: (tooltipVisibility && tooltipVisibility === spot.id) ? 1000 : 1,
              }
            }}
            id={spot.id}
            data-tooltip-id={'spot_' + spot.id}
            data-tooltip-delay-show={600}
            onMouseEnter={() => setTooltipVisibility(spot.id)}
            onMouseLeave={() => setTooltipVisibility(null)}
          >
            <div
              className="clickable"
              onClick={() => onSpotClick && onSpotClick(booking, setBooking, spot)}
            >
              {spotClasses.includes('requested') &&
                <div
                  className="notify requested"
                  style={{
                    // scale: `${2 / map.zoomLevel}`,
                  }}
                >
                  <img src={InfoOrange} alt="info"/>
                </div>
              }
              {spotClasses.includes('occupied') &&
                <div
                  className="notify occupied"
                  style={{
                    // scale: `${1 / map.zoomLevel}`,
                  }}
                >
                  <img src={Info} alt="info"/>
                </div>
              }
            </div>
            <div
              className="label"
              style={{
                fontSize: `${16 / map.zoomLevel}px`,
                transform: 'rotate(' + (360 - spot.rotate) + 'deg)',
                textShadow: `-${1 / map.zoomLevel}px -${1 / map.zoomLevel}px 0 white,
                ${1 / map.zoomLevel}px -${1 / map.zoomLevel}px 0 white,
                -${1 / map.zoomLevel}px ${1 / map.zoomLevel}px 0 white,
                ${1 / map.zoomLevel}px ${1 / map.zoomLevel}px 0 white`
              }}
            >
              {spot.label}
              {/*{map.getMappableStyle(spot).width / map.scaleFactor > 100 && map.getMappableStyle(spot).height/ map.scaleFactor > 100 &&*/}
              {/*  <p>{getIndustryLabel(spot)}</p>*/}
              {/*}*/}
            </div>
            <div
              style={{
                zoom: `${1 / map.zoomLevel}`,
              }}>
              <Tooltip
                id={'spot_' + spot.id}
                clickable={true}
                positionStrategy={'fixed'}
                style={{
                  transform: 'rotate(' + (360 - spot.rotate) + 'deg)'
                }}
                offset={0}
                isOpen={(tooltipVisibility === spot.id)}
                // setIsOpen={(state) => {
                //   if (state) {
                //     setTooltipVisibility(spot.id)
                //   } else {
                //     setTooltipVisibility(false)
                //   }
                // }}

                // isOpen={(tooltipVisibility === 'spot_' + spot.id)}
                // afterShow={() =>
                //   setTooltipVisibility('spot_' + spot.id)
                // }
                // afterHide={() => {
                //   console.log('TEST cluster')
                //   // setTooltipVisibility(null)
                // }}
              >
                {spotClasses.includes('requested') &&
                  <div className="notify requested">Wees snel, er zijn meer aanvragen!</div>
                }
                {spotClasses.includes('occupied') &&
                  <div className="notify occupied">Deze plek is wel beschikbaar op andere data</div>
                }
                <Price
                  bookable={spot}
                  showTooltip={false}
                />
                {spot.overview_media &&
                  <div className="image-container">
                    <Media
                      media={spot.overview_media}
                      size="tile"
                    />
                  </div>
                }
                Afmetingen: {spot.width} x {spot.length} meter
                {'industries_ids' in spot && !!spot.industries_ids.length &&
                  <div className="industries">
                    Mogelijke branches: {spot.industries_ids.length}:&nbsp;&nbsp;
                    {getIndustryLabel(spot)}
                  </div>
                }
                <div
                  className="tooltip-button"
                  onClick={() => setShowPopupSpot({...showPopupSpot, [i]: true})}
                >
                  <span>Meer info</span>
                </div>
              </Tooltip>
            </div>
            <SpotPopup
              spot={spot}
              iteration={i}
              setShowPopupSpot={setShowPopupSpot}
              showPopupSpot={showPopupSpot}
            />
          </div>
        )
      })}
    </>
  )
}

/**
 *
 * @param map
 * @param spotClusters
 * @param spotGroup
 * @param booking
 * @param setBooking
 * @param onSpotClick
 * @returns {JSX.Element}
 * @constructor
 */
const SpotMapPublicSpotClusters = ({map, spotClusters, spotGroup, booking, setBooking, onSpotClick}) => {

  const [showPopupSpotCluster, setShowPopupSpotCluster] = useState([])
  const [tooltipVisibility, setTooltipVisibility] = useState(null); // New state for tooltip visibility
  const {getSpotStateClass} = useBooking()

  return (
    <>
      {spotClusters.map((spotCluster, i) => {
        return (
          <div
            key={i}
            className="mappable spot-cluster"
            style={{
              ...map.getMappableStyle(spotCluster), ...{
                outlineWidth: `${2 / map.zoomLevel}px`,
                borderRadius: `${3 / map.zoomLevel}px`,
                zIndex: (tooltipVisibility && tooltipVisibility === spotCluster.id) ? 1000 : 1,
              }
            }}
            id={spotCluster.id}
            data-tooltip-id={'spot-cluster_' + spotCluster.id}
            data-tooltip-delay-show={600}
            //data-tooltip-delay-hide={99999}
            onClick={() => !showPopupSpotCluster[i] && setShowPopupSpotCluster({...showPopupSpotCluster, [i]: true})}
            onMouseEnter={() => setTooltipVisibility(spotCluster.id)}
            onMouseLeave={() => setTooltipVisibility(null)}
          >
            <div
              className="label"
              style={{
                fontSize: `${16 / map.zoomLevel}px`,
                transform: 'rotate(' + (360 - spotCluster.rotate) + 'deg)',
                textShadow: `-${1 / map.zoomLevel}px -${1 / map.zoomLevel}px 0 white,
                ${1 / map.zoomLevel}px -${1 / map.zoomLevel}px 0 white,
                -${1 / map.zoomLevel}px ${1 / map.zoomLevel}px 0 white,
                ${1 / map.zoomLevel}px ${1 / map.zoomLevel}px 0 white`
              }}
            >
              <div className="label-bg">
                {spotCluster.label}
              </div>
            </div>

            <div className="children">
              {spotGroup.spots.filter(spot => spot.spot_cluster_id === spotCluster.id).map((spot, index) => {
                const spotClasses = (booking) ? getSpotStateClass(spot, booking) : ''
                const widthPercentage = (spot.width / spotCluster.width) * 100;
                const heightPercentage = (spot.length / spotCluster.length) * 100;
                const topPercentage = (spot.top / spotCluster.length) * 100;
                const leftPercentage = (spot.left / spotCluster.width) * 100;
                return (
                  <div
                    key={index}
                    className={"child " + spotClasses}
                    style={{
                      width: widthPercentage + '%',
                      height: heightPercentage + '%',
                      top: topPercentage + '%',
                      left: leftPercentage + '%',
                    }}
                  ></div>
                )
              })}
            </div>
            <div
              style={{
                zoom: `${1 / map.zoomLevel}`,
              }}
            >
              <Tooltip
                id={'spot-cluster_' + spotCluster.id}
                clickable={true}
                positionStrategy={'fixed'}
                offset={0}
                isOpen={(tooltipVisibility === spotCluster.id)}
                // setIsOpen={(state) => {
                //   if (state) {
                //     setTooltipVisibility(spotCluster.id)
                //   } else {
                //     setTooltipVisibility(false)
                //   }
                // }}
                style={{
                  transform: 'rotate(' + (360 - spotCluster.rotate) + 'deg)'
                }}
                // afterShow={() =>
                //   setTooltipVisibility('cluster_' + spotCluster.id)
                // }
                // afterHide={() => {
                //
                //   console.log('TEST cluster')
                //
                //   // setTooltipVisibility(null)
                // }}
              >
                <div
                  className="tooltip-button"
                ><span>Meer info</span></div>
              </Tooltip>
            </div>
            <Popup
              showPopup={showPopupSpotCluster}
              setShowPopup={setShowPopupSpotCluster}
              iteration={i}
              triggerPopup={() => showPopupSpotCluster}
              title={'Standplaats Groep: ' + spotCluster.label}
            >
              <div className="component-spot-popup-content">
                <p>Selecteer het aantal benodigde meter door één of meerdere blokken hieronder aan te klikken.</p>
                <SpotMapPublicCluster
                  spotCluster={spotCluster}
                  spots={spotGroup.spots.filter(item => item.spot_cluster_id === spotCluster.id)}
                  booking={booking}
                  setBooking={setBooking}
                  onSpotClick={onSpotClick}
                />
              </div>
            </Popup>
          </div>
        )
      })}
    </>
  )
}


export default SpotMapPublic
