import React from "react"
import {DateObject} from "react-multi-date-picker";
import useBookable from "../../hooks/useBookable";
import useBooking from "../../hooks/useBooking";
import Calendar from "../Calendar/Calendar"
import usePrice from "../../hooks/usePrice";
import Price from "../../components/Price/Price";
import './style.scss'

const CalendarProfile = (props) => {

  const {
    dates,
    setDates,
    bookable,
    booking,
    isLoadingBookings,
    showHeader,
    hideCalendar,
    setHideCalendar,
    enableDisabledDays,
    loadMonthBookings,

  } = props


  const {
    getDayPriceByKey,
    getBookingPriceDaySpot,
    getDayKey,
    addBookingFeeIfNeeded,
    hasDifferentDayPrices,
  } = usePrice()
  const {isStand} = useBookable()
  const {isBookedBooking, isAvailableBooking, isDateInBooking} = useBooking()

  /*
   * Set min,max Calendar dates to let the calendar focus on first available dates.
   * Somehow the Calendar does not accept an updated state, therefore set here without states.
   */
  let min, max, current

  const items = (bookable.availabilities && bookable.availabilities.length)
    ? bookable.availabilities.filter(a => a['mo'] || a['tu'] || a['we'] || a['th'] || a['fr'] || a['sa'] || a['su'])
    : []

  if (items.length) {
    items.sort((a, b) => {
      return new Date(a.start_date) - new Date(b.start_date);
    });
    current = new DateObject(items[0].start_date)
  }
  if (
    bookable && parseInt(bookable.available) === 2 &&
    bookable.available_dates &&
    bookable.available_dates.length &&
    !bookable.availability_on_going
  ) {
    bookable.available_dates.sort((a, b) => {
      return new Date(a.date) - new Date(b.date);
    });
    min = new DateObject(bookable.available_dates[0].date)
    max = new DateObject(bookable.available_dates[bookable.available_dates.length - 1].date)
    current = min
  }

  /**
   *
   * @param date
   * @param className
   * @returns {JSX.Element}
   */
  const calendarDay = (date, className = '') => {
    let dayPrice = 0
    const dayKey = getDayKey(date.weekDay.index)
    const newDate = new DateObject(date)

    if (isStand(bookable)) {
      dayPrice = getDayPriceByKey(bookable, dayKey)
    } else {

      let spotsHaveDifferentDayPrices =  false

      booking.spots && booking.spots.map((spot) => {

        if (hasDifferentDayPrices(spot)){
          spotsHaveDifferentDayPrices = true
        }

        /*
         * Only show dayPrices if price for day in the week is different.
         * check if booking has spots with different price types,
         * if so, do not show price
         */
        if (!spotsHaveDifferentDayPrices){
          dayPrice = null
        } else if (spot.price.type !== 'negotiate' && spot.price.type !== 'relative' && booking.spots[0].price.type === spot.price.type) {
          dayPrice += addBookingFeeIfNeeded(booking, bookable, getBookingPriceDaySpot(spot, bookable, dayKey))
        } else if (spot.price.type === 'relative' && booking.spots[0].price.type === spot.price.type) {
          //todo could be possible to show average of relative prices
          //or like getBookingTotalFormattedPercentagesRelative() (see Totals.js)
          if (booking.spots.length === 1) {
            dayPrice = addBookingFeeIfNeeded(booking, bookable, getBookingPriceDaySpot(spot, bookable, dayKey))
          } else {
            dayPrice = null
          }

        } else {
          dayPrice = null
        }
        return spot
      })
    }

    return (<div className={"calendar-day " + className}>
      <div className="date">{newDate.format("D")}</div>
      {!enableDisabledDays && <div className="price">
        {(dayPrice === null) && '...'}
        {(dayPrice === 0) && 0}
        {(dayPrice > 0) && dayPrice}</div>}
    </div>)
  }

  const mapDays = ({date, today, selectedDate, currentMonth, isSameDate}) => {
    const daysFromNow = date.toDays() - today.toDays()
    let props = {}
    let extraClassName = ''
    if (daysFromNow < 0) {
      return {
        disabled: true,
        // style: (!enableDisabledDays) ? {color: "#ccc"} : {}, // onClick: () => swal("Deze dag is al geweest")
        style: {color: "#ccc"}, // onClick: () => swal("Deze dag is al geweest")
      }
    }

    if (isAvailableBooking(bookable, booking, date)) {
      if (isBookedBooking(bookable, booking, date)) {
        return {
          className: (isDateInBooking(booking, date)) ? ' unavailable-but-selected ' : '',
          // disabled: true,
          // style: {color: "red"},
          // onClick: () => swal("Een of meer van de nu geselecteerde (dus groene) Standplaatsen/Spots zijn niet vrij op deze dag. Probeer een of meer andere andere Standplaatsen")
          // className: (enableDisabledDays)?' selected-but-unavailable1 ':'',
          // disabled: (!enableDisabledDays),
          // style: (!enableDisabledDays) ? {color: "#ccc"} : {},
          // onClick: () => swal("Deze dag is niet beschikbaar")
          // onClick: (enableDisabledDays) ? null : () => swal("Deze dag is niet beschikbaar")
          disabled: (!isDateInBooking(booking, date)),
          style: {color: "#ccc"},
        }
      }
    } else {
      return {
        className: (isDateInBooking(booking, date)) ? ' unavailable-but-selected ' : '',
        // className: (enableDisabledDays)?' selected-but-unavailable2 ':'',
        // disabled: (!enableDisabledDays),
        disabled: (!isDateInBooking(booking, date)),
        // style: (!enableDisabledDays) ? {color: "#ccc"} : {},
        style: {color: "#ccc"},
        // onClick: (enableDisabledDays) ? null : () => swal("Deze dag is niet beschikbaar")
        // onClick: () => swal("Deze dag is niet beschikbaar")
      }
    }

    if (daysFromNow === 0) props.title = "Vandaag"
    if (daysFromNow === 1) props.title = "morgen"
    props.children = calendarDay(date, extraClassName)
    return props
  }

  return (
    <div className="component-calendar-profile">
      {showHeader && <div className="header">
        <div className="price">
          <Price bookable={bookable} showTooltip={true} showInfo={true}/>
        </div>
      </div>}

      <Calendar
        dates={dates}
        setDates={setDates}
        isLoadingBookings={isLoadingBookings}
        loadMonthBookings={loadMonthBookings}
        min={min}
        max={max}
        current={current}
        mapDays={mapDays}
      />
    </div>)
}


CalendarProfile.defaultProps = {
  showHeader: true, enableDisabledDays: false,
}

CalendarProfile.propTypes = {
  // enableDisabledDays: PropTypes.bool,
  // showHeader: PropTypes.bool,
  // dates: PropTypes.object.isRequired,
  // setDates: PropTypes.object.isRequired,
  // bookable: PropTypes.object.isRequired,
  // booking: PropTypes.object.isRequired,
}

export default CalendarProfile
