import React from "react";
import {DoubleInput, Fields, Form} from "../../components/Form/Form";
import {useNavigate} from "react-router-dom";
import useAuthService from "../../api/services/useAuthService";
import useForm from "../../hooks/useForm";
import swal from "sweetalert";
import './style.scss';


const ForgotPassword = () => {

  const navigate = useNavigate();
  const form = useForm();
  const {passwordForgot} = useAuthService()

  const handleRequest = () => {
    swal({
      title: "Check email",
      text: "Als het e-mailadres bij ons bekend is, dan is er een nieuw wachtwoord naar dit e-mailadres verstuurd. Geen mail ontvangen? Wacht enkele minuten, en bekijk de spamfolder. Nog steeds niks? Neem contact op!",
      icon: "warning",
      buttons: {
        confirm: {
          text:'Oke',
          value:true
        }
      },
      dangerMode: false,
    }).then((value) => {
      value && navigate('/inloggen')
    })
  }

  return (
    <div className="page-forgot-password">
      <h1>Nieuw wachtwoord aanvragen</h1>
      <Form>
        <Fields
          form={form}
          apiService={{userStore: passwordForgot}}
          initData={{email: ''}}
          updateSuccess={handleRequest}
          buttonConfirmLabel="Versturen"
          buttonCancelLabel="Annuleren"
          fixedFormButtons={false}
          GAEventLabel="Forgot password"
        >
          <DoubleInput
            label="E-mail"
            name="email"
            autoFocus="autofocus"
          />
        </Fields>
      </Form>
    </div>
  )
}

export default ForgotPassword
