import useApi from "../../api/useApi";
import useFunction from "../../hooks/useFunction";

const useAlertService = () => {

  const {api} = useApi()
  const {c} = useFunction()

  const userShowCollection = (callbackSuccess, callbackValidationErrors) => {
    return api(
      'get',
      'user/alerts',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in Service', error), //todo do error handling here (and everywhere else in services)
    )
  }

  const userStore = (callbackSuccess, callbackValidationErrors, filter) => {
    return api(
      'post',
      'user/alert/store',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in Service', error),
      filter
    )
  }

  const userDelete = (callbackSuccess, callbackValidationErrors, alertId) => {
    return api(
      'post',
      'user/alert/' + alertId + '/delete',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in Service', error),
    )
  }

  return {
    userShowCollection,
    userStore,
    userDelete
  }
}

export default useAlertService
