import React, {useEffect, useState} from "react"
import industries from "../../data/industries"
import spotGroupIcon from "../../assets/icons/spot-group-white.svg"
import {Fields, Form, Input, Media, SelectBlock, Textarea} from "../../components/Form/Form"
import useSpotGroupSpotsService from "../../api/services/useSpotGroupSpotsService";
import {useParams} from 'react-router-dom'
import SpotMapForm from "../../components/SpotMap/SpotMapForm"
import useForm from "../../hooks/useForm"
import useFunction from "../../hooks/useFunction"
import useSpotService from "../../api/services/useSpotService"
import Button from "../../components/Button/Button"
import './style.scss'

const UserSpots = () => {

  const {spotGroupId} = useParams();
  const form = useForm()
  const spotGroupService = useSpotGroupSpotsService()
  const [spotGroup, setSpotGroup] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const spotService = useSpotService()
  const {c} = useFunction()

  /**
   * //todo could me more efficient. Now the spotGroup is loaded twice when <SpotMapForm> is used
   */
  useEffect(() => {
    setIsLoading(true)
    spotGroupService.userSpotMapShow(
      (spotGroup) => {
        setSpotGroup(spotGroup)
        setIsLoading(false)
      },
      (errors) => {
        c('Validation errors', errors)
      },
      spotGroupId
    )
  }, [])

  return (
    <div className="page-user-spots">
      {!isLoading && spotGroup &&
        <>
          {spotGroup.multi_spot ?
            <>
              <div className="buttons">
                <Button
                  label="Locatie Instellingen"
                  icon={spotGroupIcon}
                  link={'/user/locatie/' + spotGroup.id + '/edit'}
                  size="s"
                />
                {spotGroup.slug &&
                  <Button
                    label="Profiel bekijken"
                    icon={spotGroupIcon}
                    link={'/locatie/' + spotGroup.slug}
                    target="_blank"
                    size="s"
                  />
                }
              </div>
              <SpotMapForm
                id={spotGroupId}
                form={form}
              />
            </>
            :
            <>
              <h1>Beheer standplaats {(spotGroup && spotGroup.label) ? 'van ' + spotGroup.label : ''}</h1>
              <Button
                label="Locatie Instellingen"
                icon={spotGroupIcon}
                link={'/user/locatie/' + spotGroup.id + '/edit'}
              />
              <br/>
              <br/>
              <Form>
                <Fields
                  id={spotGroup.spots[0].id}
                  form={form}
                  apiService={spotService}
                  showDelete={false}
                  GAEventLabel="User Single Spot Edit"
                >
                  <Input
                    label="ID/Label/Naam"
                    name="label"
                  />
                  <Textarea
                    label="Beschrijving"
                    name="description"
                  />
                  <Input
                    label="Lengte (verticaal)"
                    name="length" //note: 'length' is used in the database and backend. 'height' is used in the front-end.
                    type="meter"
                  />
                  <Input
                    label="Breedte (horizontaal)"
                    name="width"
                    type="meter"
                  />
                  <SelectBlock
                    label="Alle producten/branches die je zoekt voor DEZE Standplaats/Spot/Plek"
                    name="industries_ids"
                    data={industries}
                  />
                  <Media
                    label="Afbeelding van deze Standplaats/Spot/Plek"
                    name="overview_media"
                    width={900}
                    height={450}
                    size="normal"
                  />
                </Fields>
              </Form>
            </>
          }
        </>
      }
    </div>
  )
}

export default UserSpots
