import React from "react";
import PropTypes from "prop-types";
import {Fields, Form, Input} from "../Form/Form";
import SocialLoginButtons from "../SocialLoginButtons/SocialLoginButtons";
import useAuthService from "../../api/services/useAuthService";
import {useNavigate} from "react-router-dom";
import useForm from "../../hooks/useForm";
import Button from "../Button/Button";
import "./style.scss"

const Login = (props) => {

  const {
    showRegisterButton,
    updateSuccess,
  } = props
  const {login} = useAuthService()
  const navigate = useNavigate();
  const form = useForm();

  const handleLogin = (data) => {
    if (updateSuccess){
      updateSuccess()
    } else {
      navigate('/user/mijn-standplaats')
    }
  }

  return(
    <div className="component-login">
      <h3>Inloggen:</h3>
      <SocialLoginButtons/>
      <Form>
        <Fields
          form={form}
          apiService={{userStore:login}}
          initData={{email: '', password: ''}}
          updateSuccess={handleLogin}
          buttonConfirmLabel="Inloggen"
          buttonCancelLabel="Annuleren"
          fixedFormButtons={false}
          GAEventLabel="Login"
        >
          <Input
            label="E-mail"
            name="email"
            autoFocus="autofocus"
          />
          <Input
            label="Wachtwoord"
            name="password"
            type="password"
          />
        </Fields>
      </Form>

      <br/>
      <br/>
      <br/>
      <div className="register">
        <Button
          color="grey-ghost"
          label="Wachtoord vergeten?"
          link={'/wachtwoord/vergeten'}
        />
      </div>
      {showRegisterButton &&
        <div className="register">
          <p>Nog geen account?</p>
          <Button
            link="/registreren"
            label="Registreer nu!"
          />
          <br/>
        </div>
      }
    </div>
  )
}


Login.defaultProps = {
  showRegisterButton: true,
  updateSuccess: null,
}

Login.prototype = {
  showRegisterButton: PropTypes.bool,
  updateSuccess: PropTypes.func,
}

export default Login
