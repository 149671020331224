/*
 * Mollie payment status:
 * open
 * canceled
 * pending
 * authorized //not possible in iDeal
 * expired
 * failed
 * paid
 */

const paymentStatus = [
  {
    label:'Te betalen',
    value: 'open',
  },
  {
    label:'Geannuleerd',
    value: 'canceled',
  },
  {
    label:'Te betalen',
    value: 'pending',
  },
  {
    label:'Te betalen, Authorized',
    value: 'authorized',
  },
  {
    label:'Verlopen',
    value: 'expired',
  },
  {
    label:'Mislukt',
    value: 'failed',
  },
  {
    label:'Betaald',
    value: 'paid',
  },
];

export default paymentStatus
