import {useEffect, useRef, useState} from "react";
import useBookable from "./useBookable";
import debounce from 'lodash.debounce'; // Assuming you have lodash installed

const useSpotMap = (
  model //spotGroup or spotCluster
) => {
  const [activeSpotMapIndex, setActiveSpotMapIndex] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(1); // Initialize with a default value
  const transformWrapperRef = useRef(null);
  const [scaleFactor, setScaleFactor] = useState(1);
  const {isSpotGroup, isSpotCluster, isSpotGroupSpots} = useBookable()


  const getAreaWidth = () => {
    if (isSpotGroup(model) || isSpotGroupSpots(model)) {
      return getActiveSpotMap().area_width;
    }
    if (isSpotCluster(model)) {
      return model.width
    }
  }

  const getAreaLength = () => {
    if (isSpotGroup(model) || isSpotGroupSpots(model)) {
      return getActiveSpotMap().area_length;
    }
    if (isSpotCluster(model)) {
      return model.length
    }
  }

  /**
   *
   * @returns {number|*}
   */
  const getWidth = () => {
    return getAreaWidth();
  };

  /**
   *
   * @returns {number|*}
   */
  const getHeight = () => {
    return getAreaLength();
  };


  useEffect(() => {
    const resizeObserver = new ResizeObserver(
      debounce(entries => {
        let scaleFactorChanged = false;
        let newScaleFactor = 1;

        for (let entry of entries) {
          const entryWidth = entry.contentRect.width;
          const entryHeight = entry.contentRect.height;

          const areaWidthInMeters = getAreaWidth();

          let widthScaleFactor = entryWidth / getWidth();
          let heightScaleFactor = entryHeight / getHeight();

          if (areaWidthInMeters < 20) {
            widthScaleFactor = (entryWidth / getWidth()) * 0.8;
            heightScaleFactor = (entryHeight / getHeight()) * 0.8;
          }
          newScaleFactor = Math.min(widthScaleFactor, heightScaleFactor);
          if (Math.abs(scaleFactor - newScaleFactor) > 0.01) {
            scaleFactorChanged = true;
            break;
          }
        }

        if (scaleFactorChanged) {
          setScaleFactor(newScaleFactor);
        }
      }, 5)
    );

    if (transformWrapperRef.current) {
      resizeObserver.observe(transformWrapperRef.current);
    }

    return () => resizeObserver.disconnect();
  }, [getWidth, getHeight]); // Make sure getWidth and getHeight are stable references
  /**
   *
   * @returns {null|*}
   */
  const getSpotMapMedia = () => {
    if (isSpotGroup(model) || isSpotGroupSpots(model)) {
      return getActiveSpotMap().spot_map_media;
    }
  }

  const getActiveSpotMap = () => {
    return model.spot_maps[activeSpotMapIndex]
  }

  /**
   *
   * @returns {number}
   */
  const getScaledWidth = () => {


    return getWidth() * scaleFactor
  }

  /**
   *
   * @returns {number}
   */
  const getScaledHeight = () => {


    return getHeight() * scaleFactor
  }

  /**
   *
   * @param mappable
   * @returns {{transform: string, top: number, left: number, width: number, height: number}}
   */
  const getMappableStyle = (mappable) => {
    return {
      left: parseFloat(mappable.left) * scaleFactor,
      top: parseFloat(mappable.top) * scaleFactor,
      width: parseFloat(mappable.width) * scaleFactor,
      height: parseFloat(mappable.length) * scaleFactor, //ResizableRect.height === spot.length
      transform: 'rotate(' + parseFloat(mappable.rotate) + 'deg)',
    }
  }


  return {
    getSpotMapMedia,
    getWidth,
    getHeight,
    getAreaWidth,
    getAreaLength,
    getActiveSpotMap,
    zoomLevel,
    setZoomLevel,
    scaleFactor,
    transformWrapperRef, // Expose the ref for binding in the component
    getScaledWidth,
    getScaledHeight,
    getMappableStyle,
    activeSpotMapIndex,
    setActiveSpotMapIndex,
  };
};

export default useSpotMap;
