import {DateObject} from "react-multi-date-picker";
import useBookable from "./useBookable";
import useBooking from "./useBooking";
import useFunction from "./useFunction";

/**
 * Bij Stands, SpotGroups, Spots
 *
 * Positive:
 * r.1. 'dagprijs:'
 * r.2. * Tussen:  {min} en {max}
 * r.2. * {exact}
 *
 * Negative:
 * r.1. 'Verdien per dag:' -> tooltip: je krijgt geld als je deze dag boekt.
 * r.2. * Tussen:  {min} en {max}
 * r.2. * {exact}
 *
 * Negotiate:
 * r.1. 'Prijs in overleg (n.o.t.k.):'
 *
 * Relative:
 * r.1 'Prijs op basis van omzet'
 *
 *
 * Bij SpotGroups toevoegen:
 *
 * r.3 ((hasDifferentSpotsPrices())?'Prijs zijn per Standplaats verschillend, bekijk de prijs per Standplaats op de map':'')
 * r.3 (hasMultipleSpotTypes())'PrijsTYPE kan afwijken per Standplaats, bekijk de prijstyoe per Standplaats op de map:''
 * r.3 ((hasDifferentSpotsPrices() && hasMultipleSpotTypes())?'Prijs en prijstype kan afwijken per Standplaats, bekijk de prijs per Standplaats op de map'
 *
 *
 * //todo now its not checking against spot prices. They could be different type.
 * //maybe add other case for mixed prices (from negative to positive)
 *
 */
const usePrice = () => {

  const {isStand, isSpotGroup, isSpot} = useBookable()
  const {getBookingDates} = useBooking()
  const {objIsEmpty} = useFunction()
  const transactionFee = 5
  const fixedDayFee = 8
  const bookingFeePercentage = 0.15

  /**
   * //todo duplicate from useAvailable.js
   * @type {{"0": string, "1": string, "2": string, "3": string, "4": string, "5": string, "6": string}}
   */
  const dayKeys = {
    0: 'su',
    1: 'mo',
    2: 'tu',
    3: 'we',
    4: 'th',
    5: 'fr',
    6: 'sa',
  }

  /**
   * //todo duplicate from useAvailable.js
   *
   * @param weekDayIndex
   * @return {string}
   */
  const getDayKey = (weekDayIndex) => {
    if (weekDayIndex in dayKeys) {
      return dayKeys[weekDayIndex]
    }
  }

  /**
   * //todo now its not checking against spot prices. They could be different type.
   *
   *
   * @param bookable
   * @returns {*}
   */
  const getLowestDayPrice = (bookable) => {
    return getDayPrices(bookable).sort()[0]
  }

  /**
   * //todo now its not checking against spot prices. They could be different type.
   *
   * @param bookable
   * @returns {*}
   */
  const getHighestDayPrice = (bookable) => {
    const prices = getDayPrices(bookable)
    return prices.sort()[prices.length - 1]
  }

  /**
   *
   * @param bookable
   * @returns {*|null}
   */
  const getBookableLowestPrice = (bookable) => {
    if (isStand(bookable)) {
      return bookable; // Stands are directly returned since they are singular.
    }
    if (isSpotGroup(bookable)) {
      const lowestPriceSpot = getSpotLowestPrice(bookable.spots);
      // Assuming getDayPrices returns an array of prices for the given dayKeys or default
      const bookablePrices = getDayPrices(bookable);
      const lowestBookablePrice = Math.min(...bookablePrices);
      const lowestSpotPrice = Math.min(...getDayPrices(lowestPriceSpot));
      return lowestSpotPrice < lowestBookablePrice ? lowestPriceSpot : bookable;
    }
  };

  /**
   *
   * @param bookable
   * @returns {*|null}
   */
  const getBookableHighestPrice = (bookable) => {
    if (isStand(bookable)) {
      return bookable; // Stands are directly returned since they are singular.
    }
    if (isSpotGroup(bookable)) {
      const highestPriceSpot = getSpotHighestPrice(bookable.spots);
      const bookablePrices = getDayPrices(bookable);
      const highestBookablePrice = Math.max(...bookablePrices);
      const highestSpotPrice = Math.max(...getDayPrices(highestPriceSpot));
      return highestSpotPrice > highestBookablePrice ? highestPriceSpot : bookable;
    }
  };

  /**
   *
   * @param spots
   * @returns {null}
   */
  const getSpotLowestPrice = (spots) => {
    let lowestPriceModel = null;
    let lowestPrice = Infinity;

    spots.map(spot => {
      // Ensure spot has a price property and it's not null
      if (spot.price) {
        Object.keys(dayKeys).forEach(dayKey => {
          // Use specific day price or fallback to default price
          const price = spot.price[dayKeys[dayKey]] ?? spot.price.default;
          if (price < lowestPrice) {
            lowestPrice = price;
            lowestPriceModel = spot;
          }
        });
      }
    });
    return lowestPriceModel;
  };

  /**
   *
   * @param spots
   * @returns {null}
   */
  const getSpotHighestPrice = (spots) => {
    let highestPriceModel = null;
    let highestPrice = -Infinity;

    spots.map(spot => {
      // Ensure spot has a price property and it's not null
      if (spot.price) {
        Object.keys(dayKeys).forEach(dayKey => {
          // Use specific day price or fallback to default price
          const price = spot.price[dayKeys[dayKey]] ?? spot.price.default;
          if (price > highestPrice) {
            highestPrice = price;
            highestPriceModel = spot;
          }
        });
      }
    });

    return highestPriceModel;
  };

  /**
   * Get all prices for a bookable
   *
   * @param model (spot|stand|spotGroup|bookable)
   * @returns {*[]}
   */
  const getDayPrices = (model) => {
    const prices = []
    Object.keys(dayKeys).map((dayKey, index) => {
      const price = model.price[dayKeys[index]] || model.price.default
      prices.push(price)
      return dayKey
    })
    return prices
  }

  /**
   * Checks spots in a spotGroup if they have different price types
   *
   * @param spotGroup
   */
  const hasMultipleSpotTypes = (spotGroup) => {
    let hasMultiple = false
    spotGroupSpotLoop(spotGroup, (i, spot) => {
      if (spot.price.type !== spotGroup.price.type) {
        hasMultiple = true
      }
    })
    return hasMultiple
  }

  /**
   * Checks spots in a spotGroup if they have different prices
   *
   * @param spotGroup
   * @return boolean
   */
  const hasDifferentSpotsPrices = (spotGroup) => {
    let isDifferent = false
    const lowestDayPrice = getLowestDayPrice(spotGroup)
    const highestDayPrice = getHighestDayPrice(spotGroup)
    spotGroupSpotLoop(spotGroup, (i, spot) => {
      Object.keys(dayKeys).map((dayKey, index) => {
        const price = spot.price[dayKey] || spot.price.default
        if (price < lowestDayPrice || price > highestDayPrice) {
          isDifferent = true
        }
        return dayKey
      })
    })

    return isDifferent
  }

  /**
   * Checks if the bookable has different prices per day
   *
   * @param model (bookable, stand, spotGroup, spot)
   */
  const hasDifferentDayPrices = (model) => {
    let isDifferent = false
    Object.keys(dayKeys).map((dayKey, index) => {
      if (
        model.price &&
        model.price[dayKey] !== null &&
        model.price.default !== model.price[dayKey] &&
        getLowestDayPrice(model) !== getHighestDayPrice(model)
      ) {
        isDifferent = true
      }
      return dayKey
    })
    return isDifferent
  }

  /**
   * Checks if the booking has a price type of 'positive'
   *
   * @param booking
   * @param bookable
   * @returns {number}
   */
  const countBookingPriceTypePositive = (booking, bookable) => {
    return countBookingPriceType(booking, bookable, 'positive')
  }

  /**
   * Checks if the booking has a price type of 'negative'
   *
   * @param booking
   * @param bookable
   * @returns {number}
   */
  const countBookingPriceTypeNegative = (booking, bookable) => {
    return countBookingPriceType(booking, bookable, 'negative')
  }

  /**
   * Checks if the booking has a price type of 'negotiate'
   *
   * @param booking
   * @param bookable
   * @returns {number}
   */
  const countBookingPriceTypeNegotiate = (booking, bookable) => {
    return countBookingPriceType(booking, bookable, 'negotiate')
  }

  /**
   * Checks if the booking has a price type of 'relative'
   *
   * @param booking
   * @param bookable
   * @returns {number}
   */
  const countBookingPriceTypeRelative = (booking, bookable) => {
    return countBookingPriceType(booking, bookable, 'relative')
  }

  /**
   * Checks if the booking has a price type
   * @param booking
   * @param bookable
   * @param priceType
   * @returns {number}
   **/
  const countBookingPriceType = (booking, bookable, priceType) => {
    let countPriceType = 0
    bookingSpotStandPriceTypeLoop(booking, bookable, priceType, () => {
      countPriceType++
    })
    return countPriceType
  }


  /**
   * Format price
   *
   * @param price
   * @returns {string}
   */
  const formatPrice = (price) => {
    if (price === 0 || price) {
      price = round(Math.abs(price))
      return price.toLocaleString('nl-NL', {currency: 'EUR', style: 'currency'});
    }
  }

  /**
   * Format percent
   *
   * @param percent
   * @returns {string}
   */
  const formatPercent = (percent) => {
    if (percent === 0 || percent) {
      percent = round(percent)
      return percent + '%'
    }
  }

  /**
   * Round number to 2 decimals
   *
   * @param num
   * @returns {number}
   */
  const round = (num) => {
    return Math.round((parseFloat(num) + Number.EPSILON) * 100) / 100
  }

  /**
   * Round number to 2 decimals
   *
   * @param num
   * @returns {number}
   */
  const roundAbs = (num) => {
    return Math.abs(round(num))
  }

  /**
   * Get the price of a model (Bookable|SpotGroup|Stand|Spot)
   *
   * @param model
   * @param dayKey
   */
  const getDayPriceByKey = (model, dayKey) => {
    let price
    if (model.price.type === 'negotiate') {
      price = undefined
    } else if (model.price[dayKey]) {
      price = model.price[dayKey]
    } else {
      price = model.price.default
    }
    return price
  }

  /**
   * Get formatted price of a model for a specific day
   *
   * @param model
   * @param dayKey
   */
  const getFormattedDayPriceByKey = (model, dayKey) => {
    if (model.price.type === 'negotiate') {
      return undefined
    } else if (model.price.type === 'relative') {
      return formatPercent(getDayPriceByKey(model, dayKey))
    } else {
      return formatPrice(addBookingFeeIfNeededForSingleModel(model, getDayPriceByKey(model, dayKey)))
    }
  }

  const getLatestOfferIfExists = (booking, model) => {
    if (isStand(model)){
      if (
        booking.prices &&
        booking.prices.stand &&
        'offers' in booking.prices.stand &&
        Array.isArray(booking.prices.stand.offers) &&
        booking.prices.stand.offers.length){
        return booking.prices.stand.offers[0]
      }
    }
    if (isSpot(model)){
      if (booking.prices && booking.prices.spots && Array.isArray(booking.prices.spots) && booking.prices.spots.length > 0) {
        const item = booking.prices.spots.find((item) => item.id === model.id)
        if (item && 'offers' in item && Array.isArray(item.offers) && item.offers.length > 0) {
          return item.offers[0]
        }
      }
    }
    return null
  }

  /**
   * Get the total booking (spots/stand) price per price->type
   * handles offers if they exist in a booking
   *
   * @param booking
   * @param bookable
   * @param priceType
   * @param callback
   * @returns {number}
   */
  const getBookingTotalPricePerType = (booking, bookable, priceType, callback = null) => {
    let price = 0
    getBookingDates(booking).map((date) => {
      const dayKey = getDayKey(date.weekDay.index)
      bookingSpotStandPriceTypeLoop(booking, bookable, priceType, (index, spot) => {
        if (isStand(bookable)){
          if (priceType === 'negotiate') {
            /*
             * Maybe add latest offer
             */
           const offer = getLatestOfferIfExists(booking, bookable)
            if (offer){
              return offer.value
            }
          } else {
            price += getBookingPriceDayStand(bookable, dayKey)
          }
        } else {
          //bookable = SpotGroup

          //check if price type is negotiate, or if $bookable is not 'multi_spot', then the price type
          //of the spotGroup should be checked
          if (
            (bookable.multi_spot && priceType === 'negotiate') ||
            (!bookable.multi_spot && bookable.price.type === 'negotiate')
          ) {
            /*
             * Maybe add latest offer
             */
            const offer = getLatestOfferIfExists(booking, spot)
            if (offer){
              return offer.value
            }
          } else {
            price += getBookingPriceDaySpot(spot, bookable, dayKey)
          }
        }
        if (callback) {
          callback()
        }
      })
      return date
    })

    return price
  }

  /**
   * Get the total price of a booking with price type 'positive'
   *
   * @param booking
   * @param bookable
   * @param callback
   * @returns {number}
   */
  const getBookingTotalPricePositive = (booking, bookable, callback = null) => {
    return getBookingTotalPricePerType(booking, bookable, 'positive', callback)
  }

  /**
   * Get the total price of a booking with price type 'negative'
   *
   * @param booking
   * @param bookable
   * @param callback
   * @returns {number}
   */
  const getBookingTotalPriceNegative = (booking, bookable, callback = null) => {
    return getBookingTotalPricePerType(booking, bookable, 'negative', callback)
  }

  /**
   * Get the total price of a booking with price type 'negotiate'
   *
   * @param booking
   * @param bookable
   * @param callback
   * @returns {number}
   */
  const getBookingTotalPriceNegotiate = (booking, bookable, callback = null) => {
    return getBookingTotalPricePerType(booking, bookable, 'negotiate', callback)
  }

  /**
   * Get the total price of a booking with price type 'relative'
   *
   * @param booking
   * @param bookable
   * @param callback
   * @returns {number}
   */
  const getBookingTotalPriceRelative = (booking, bookable, callback = null) => {
    let bookingFee = 0
    bookingSpotStandPriceTypeLoop(booking, bookable, 'relative', () => {
      bookingFee += getBookingDates(booking).length * fixedDayFee
      if (callback) {
        callback()
      }
    })
    return bookingFee
  }

  /**
   * Helper function for spotGroup spot loop
   *
   * @param spotGroup
   * @param callback
   */
  const spotGroupSpotLoop = (spotGroup, callback) => {
    if (spotGroup.multi_spot) {
      spotLoop(spotGroup, spotGroup, callback)
    } else {
      const spot = spotGroup.spots[0]

      /* Use Price and Availability from spotGroup since single spots don't have these settings */
      spot.price = spotGroup.price
      spot.availablity = spotGroup.availablity
      callback(0, spot)
    }
  }

  /**
   * Helper function for booking prices - by type - loop (stand or spotGroup.spot)
   *
   * @param booking
   * @param bookable
   * @param priceType
   * @param callback
   */
  const bookingSpotStandPriceTypeLoop = (booking, bookable, priceType, callback) => {
    if (isStand(bookable)) {
      if (bookable.price.type === priceType) {
        callback(0)
      }
    } else {
      spotLoop(booking, bookable, (index, spot) => {
        if (!spot.price || spot.price.default === null){
          spot.price = bookable.price
        }
        if (spot.price.type === priceType) {
          callback(index, spot)
        }
      })
    }
  }

  /**
   *   * Helper function for spotGroup or booking spot loop, inherits price from spotGroup object if price is empty   *
   * @param model (spotGroup|booking)
   * @param spotGroup
   * @param callback
   */
  const spotLoop = (model, spotGroup, callback) => {
    model.spots.map((spot, i) => {
      if (!spot.price || spot.price.default === null) {
        spot.price = spotGroup.price
      }
      callback(i, spot)
      return spot
    })
  }

  /**
   * Get the min-max percentage string of a booking with price type 'relative'
   *
   * @param booking
   * @param bookable
   * @returns string
   */
  const getBookingTotalFormattedPercentagesRelative = (booking, bookable) => {
    let percent = []
    getBookingDates(booking).map((date) => {
      const dayKey = getDayKey(date.weekDay.index)
      bookingSpotStandPriceTypeLoop(booking, bookable, 'relative', (index, spot) => {
        if (spot) {
          percent.push(getBookingPriceDaySpot(spot, bookable, dayKey))
        } else {
          percent.push(getBookingPriceDayStand(bookable, dayKey))
        }
      })
      return date
    })

    if (percent.length === 0) {
      return '-'
    } else if (percent.length === 1) {
      return formatPercent(percent.sort()[0])
    } else {
      return formatPercent(percent.sort()[0]) + ' - ' + formatPercent(percent.sort()[percent.length - 1])
    }
  }

  /**
   * Get the total amount of spots with price type 'negotiate'
   *
   * @param bookable
   * @param booking
   * @returns {number}
   */
  const getBookingTotalAmountNegotiate = (bookable, booking) => {
    let amount = 0
    bookingSpotStandPriceTypeLoop(booking, bookable, 'negotiate', () => {
      amount++
    })
    return amount
  }

  /**
   * Order of setting price: (if 0, go to next)
   * 1. spot dayPrice
   * 2. spot default
   * 3. spotGroup dayPrice
   * 4. spotGroup default
   *
   * @param spot
   * @param spotGroup
   * @param dayKey
   * @return {*}
   */
  const getBookingPriceDaySpot = (spot, spotGroup, dayKey) => {
    let price
    if ('price' in spot && spot.price && spot.price[dayKey]) {
      price = spot.price[dayKey]
    } else if ('price' in spot && spot.price && spot.price.default) {
      price = spot.price.default
    } else if (spotGroup.price[dayKey]) {
      price = spotGroup.price[dayKey]
    } else {
      price = spotGroup.price.default
    }
    return price || 0
  }

  /**
   *
   * @param stand
   * @param dayKey
   * @return {*}
   */
  const getBookingPriceDayStand = (stand, dayKey) => {
    let price = 0
    if (stand.price[dayKey]) {
      price = stand.price[dayKey]
    } else if (stand.price.default) {
      price = stand.price.default
    }
    return price || 0
  }

  /**
   * Get the total booking price of a Stand or Spot
   *
   * @param booking
   * @param model (stand|spot)
   * @returns {number}
   */
  const getBookingStandOrSpotTotalPrice = (booking, model) => {
    let price = 0
    getBookingDates(booking).map((dateRange) => {
      const date = new DateObject(dateRange.start_date)
      const dayKey = getDayKey(date.weekDay.index)

      if (isStand(model)) {
        price += getBookingPriceDayStand(model, dayKey)
      }
      if (isSpot(model)) {
        price += getBookingPriceDaySpot(model, booking.spot_group, dayKey)
      }
      return dateRange
    })
    return price
  }

  /**
   * Get the total fee of a booking
   *
   * @param booking
   * @param bookable
   * @returns {number}
   */
  const getBookingFeeAndTransactionFee = (booking, bookable) => {

    let bookingFee = 0
    let hasTransactionFee = false

    bookingFee += getBookingTotalPricePositive(booking, bookable, () => hasTransactionFee = true
    ) * bookingFeePercentage

    bookingFee += getBookingTotalPriceNegative(booking, bookable, () => hasTransactionFee = true
    ) * bookingFeePercentage

    bookingFee += getBookingTotalPriceNegotiate(booking, bookable, () => hasTransactionFee = true
    ) * bookingFeePercentage

    bookingFee += getBookingTotalPriceRelative(booking, bookable, () => hasTransactionFee = true
    )

    bookingFee += (hasTransactionFee) ? transactionFee : 0
    return bookingFee
  }

  /**
   * Get the total price of a booking, without options
   *
   * @param booking
   * @param bookable
   * @returns {number}
   */
  const getBookingPriceTotalWithoutOptions = (booking, bookable) => {
    let total = 0
    total += getBookingFeeAndTransactionFee(booking, bookable)
    total += getBookingPriceTotalWithoutFeeAndOptions(booking, bookable)
    return total
  }

  /**
   * Get the total price of a booking, without fee and options
   *
   * @param booking
   * @param bookable
   * @returns {number}
   */
  const getBookingPriceTotalWithoutFeeAndOptions = (booking, bookable) => {
    let total = 0
    total += getBookingTotalPricePositive(booking, bookable)
    total -= getBookingTotalPriceNegative(booking, bookable)
    total += getBookingTotalPriceNegotiate(booking, bookable)
    return total
  }

  /**
   * Get the total price of a bookingOption
   * counts number of days in booking
   * counts amount of added bookingOptions
   *
   * @param booking
   * @param bookableOption
   * @returns {number}
   */
  const getBookingOptionPrice = (booking, bookableOption) => {
    let price = 0
    getBookingDates(booking).map((dateRange) => {
      const date = new DateObject(dateRange.start_date)
      const dayKey = getDayKey(date.weekDay.index)
      booking.booking_options.map((bookingOption) => {
        if (
          ('temp_id' in bookableOption &&
            bookingOption.bookable_option.temp_id === bookableOption.temp_id)
          ||
          ('id' in bookableOption &&
            bookingOption.bookable_option.id === bookableOption.id)
        ) {
          price += getDayPriceByKey(bookableOption, dayKey) * bookingOption.amount
        }
        return bookingOption
      })
      return date
    })
    return price
  }

  /**
   * Get the total price of all bookingOptions
   *
   * @param booking
   * @returns {number}
   */
  const getBookingOptionsTotalPrice = (booking) => {
    let price = 0
    booking.booking_options.map((bookingOptions) => {
      price += getBookingOptionPrice(booking, bookingOptions.bookable_option)
      return bookingOptions
    })
    return price
  }

  /**
   * Get the total price of a booking
   *
   * @param booking
   * @param bookable
   * @returns {number}
   */
  const getBookingPriceTotal = (booking, bookable) => {
    return getBookingPriceTotalWithoutOptions(booking, bookable)
      + getBookingOptionsTotalPrice(booking)
  }

  /**
   * Get the total price of a booking
   *
   * @param booking
   * @param bookable
   * @returns {number}
   */
  const getBookingPriceTotalWithoutFee = (booking, bookable) => {
    return getBookingPriceTotalWithoutFeeAndOptions(booking, bookable)
      + getBookingOptionsTotalPrice(booking)
  }

  /**
   * get/update the prices prop of a booking
   *
   * @param booking
   * @param negotiateOffer example:{
   *             spot_id: 1,
   *             date: '2020-01-01',
   *             value: 100, //total amount for all booking dates
   *             type: 'negotiate',
   *             user_id: 1,
   *           }
   * @returns {{}}
   */
  const updateBookingPricesProperty = (booking, negotiateOffer = false) => {

    if (!booking.prices) {
      booking.prices = {
        spots: [],
        stand: {},
        booking_options: [],
      }
    }

    /*
     * Example object
     *
     * prices.spots = [
     *   {
     *     id: 1,
     *     type: 'negotiate',
     *     value: [
     *       {
     *         date: '2020-01-01',
     *         value: 100,
     *         user_id: 1,
     *       },
     *     ],
     *   },
     *   {
     *     id: 1,
     *     type: 'positive',
     *     value: 100,
     *   }
     * ]
     * prices.stand = {
     *   id: 1,
     *   type: 'negotiate',
     *   value: [
     *     {
     *       date: '2020-01-01',
     *       value: 100,
     *       user_id: 1,
     *     },
     *   ],
     * }
     * prices.booking_options = [
     *   {
     *     id: 123,
     *     value: 123,
     *   },
     * ]
     *
     */

    if (booking.type === 'stand-requests-spot') {
      spotLoop(booking, booking.spot_group, (i, spot) => {
        const spotIndex = booking.prices.spots.findIndex(item => item.id === spot.id)
        if (spotIndex !== -1) {
          /* spot already there, so only add negotiateOffer */
          if (negotiateOffer && negotiateOffer.spot_id === spot.id) {
            booking.prices.spots[spotIndex].value.push(negotiateOffer)
          }
        } else {
          booking.prices.spots.push({
            id: spot.id,
            type: spot.price.type,
            value: (spot.price.type === 'negotiate')
              ? [(negotiateOffer) ? negotiateOffer : []] //todo aray merge?
              : getBookingStandOrSpotTotalPrice(booking, spot),
          })
        }
      })
    } else if (booking.type === 'spot-group-requests-stand') {
      if (booking.prices.stand && objIsEmpty(booking.prices.stand)) {
        if (negotiateOffer) {
          booking.prices.stand.value.push(negotiateOffer)
        }
      } else {
        booking.prices.stand = {
          id: booking.stand.id,
          type: booking.stand.price.type,
          value: (booking.stand.price.type === 'negotiate')
            ? [(negotiateOffer) ? negotiateOffer : []] //todo aray merge?
            : getBookingStandOrSpotTotalPrice(booking, booking.stand),
        }
      }
    }

    booking.prices.booking_options = []
    booking.booking_options.map((bookingOption) => {
      booking.prices.booking_options.push({
        id: bookingOption.id,
        bookable_option_id: bookingOption.bookable_option.id,
        value: getBookingOptionPrice(booking, bookingOption.bookable_option),
      })

      return bookingOption
    })

    return booking
  }

  /**
   * Get the total price of a booking with price type 'negotiate'
   *
   * @param booking
   * @returns {number}
   */
  const getLatestNegotiationTotalPrice = (booking) => {
    let price = 0
    booking.prices.spots.map((spot, i) => {
      if (spot.price_type === 'negotiate' && spot.offers && Array.isArray(spot.offers) && spot.offers.length > 0) {
        price += spot.offers[0].value
      }
    })
    return price
  }

  const getBookable = (booking) => {
    return (booking.type === 'stand-requests-spot') ? booking.spot_group : booking.stand
  }

  const addBookingFee = (price) => {
    return price + (price * bookingFeePercentage)
    // return price + (price * bookingFeePercentage) + transactionFee
  }

  const addBookingFeeIfNeeded = (booking, bookable, price) => {

    //todo needs testing.
    // if (getBookingPriceTotal(booking, bookable) > 0){
    //   return addBookingFee(price)
    // } else {
    //   return price
    // }
    if (isPositivePrice(booking, bookable)){
      return addBookingFee(price)
    } else {
      return price
    }
  }

  const addBookingFeeIfNeededForSingleModel = (model, price) => {
    if (model.price.type === 'positive'){
      return addBookingFee(price)
    } else {
      return price
    }
  }

  const isPositivePrice = (booking, bookable) => {
    if (getBookingPriceTotalWithoutFee(booking, bookable) > 0){
      return true
    } else {
      return !!countBookingPriceTypePositive(booking, bookable);
    }
  }

  return {
    dayKeys,
    addBookingFee,
    addBookingFeeIfNeeded,
    formatPrice,
    formatPercent,
    round,
    roundAbs,
    hasDifferentDayPrices,
    getLowestDayPrice,
    getHighestDayPrice,
    hasMultipleSpotTypes,
    hasDifferentSpotsPrices,
    getDayKey,
    getBookingPriceDaySpot,
    getDayPriceByKey,
    getFormattedDayPriceByKey,
    countBookingPriceTypePositive,
    countBookingPriceTypeNegative,
    countBookingPriceTypeNegotiate,
    countBookingPriceTypeRelative,
    getBookingTotalPricePositive,
    getBookingTotalPriceNegative,
    getBookingTotalFormattedPercentagesRelative,
    getBookingTotalAmountNegotiate,
    getBookingOptionPrice,
    getBookingOptionsTotalPrice,
    getBookingStandOrSpotTotalPrice,
    getBookingFeeAndTransactionFee,
    getBookingPriceTotalWithoutOptions,
    getBookingPriceTotalWithoutFeeAndOptions,
    getBookingDates,
    updateBookingPricesProperty,
    spotGroupSpotLoop,
    bookingSpotStandPriceTypeLoop,
    getBookingPriceTotal,
    getBookingPriceTotalWithoutFee,
    getLatestNegotiationTotalPrice,
    getBookable,
    isPositivePrice,
    addBookingFeeIfNeededForSingleModel,
    transactionFee,
    getBookableLowestPrice,
    getBookableHighestPrice,
    getSpotLowestPrice,
    getSpotHighestPrice,
  }
}


export default usePrice
