import {useContext} from "react";
import useFunction from "./useFunction";
import AuthContext from "../context/AuthContext";

/**
 * General functions for Stand and SpotGroup models
 *
 * @returns {{isPublic: (function(*))}}
 */

const useBookable = () => {

  const {user} = useContext(AuthContext)
  const {objIsEmpty} = useFunction()

  /**
   * Get the URL of the solution by error key
   *
   * @param errorKey
   * @param company
   * @param model
   * @returns {null}
   */
  const getSolutionUrl = (errorKey, company, model) => {

    /*
     * Possible key's:
     * Company:
     *  company_legal_name
     *  company_vat_number
     *  company_coc_number
     *  company_iban
     *  company_address
     *
     * SpotGroup:
     *  spot_group_public
     *  spot_group_slug
     *  spot_group_overview_media
     *  spot_group_address
     *  spot_group_coordinates
     *
     * Stand:
     *  stand_public
     *  stand_slug
     *  stand_overview_media
     *  stand_action_media
     *  stand_product_media
     *  stand_coordinates
     */

    switch (errorKey) {
      case 'company_legal_name':
        return (company) ? '/user/organisatie/' + company.id + '/edit' : null
      case 'company_vat_number':
        return (company) ? '/user/organisatie/' + company.id + '/edit' : null
      case 'company_coc_number':
        return (company) ? '/user/organisatie/' + company.id + '/edit' : null
      case 'company_iban':
        return (company) ? '/user/organisatie/' + company.id + '/edit' : null
      case 'company_address':
        return (company) ? '/user/organisatie/' + company.id + '/edit' : null

      case 'spot_group_public':
        return (model) ? '/wizard/locatie/profile/' + model.id : null
      case 'spot_group_slug':
        return (model) ? '/wizard/locatie/profile/' + model.id : null
      case 'spot_group_overview_media':
        return (model) ? '/wizard/locatie/images/' + model.id : null
      case 'spot_group_address':
        return (model) ? '/wizard/locatie/address/' + model.id : null
      case 'spot_group_coordinates':
        return (model) ? '/wizard/locatie/map/' + model.id : null

      case 'stand_public':
        return (model) ? '/wizard/stand/profile/' + model.id : null
      case 'stand_slug':
        return (model) ? '/wizard/stand/profile/' + model.id : null
      case 'stand_overview_media':
        return (model) ? '/wizard/stand/images/' + model.id : null
      case 'stand_action_media':
        return (model) ? '/wizard/stand/images/' + model.id : null
      case 'stand_product_media':
        return (model) ? '/wizard/stand/images/' + model.id : null
      case 'stand_coordinates':
        return (model) ? '/wizard/stand/map/' + model.id : null

      default:
        return null
    }
  }

  /**
   *
   * @param user
   * @returns {*[]}
   */
  const getUserActiveCompanyErrors = (user) => {
    return (
      user &&
      'active_company' in user &&
      user.active_company
    )
      ? getCompanyErrors(user.active_company)
      : []
  }

  /**
   *
   * @param company
   * @returns {*[]}
   */
  const getCompanyErrors = (company) => {
    const errors = []
    if (
      company &&
      'public_errors' in company &&
      company.public_errors
    ) {
      company.public_errors.map(error => {
        errors.push(error)
        return error
      })
    }
    return errors
  }

  /**
   *
   * @param model
   * @returns {*[]}
   */
  const getModelErrors = (model) => {
    const errors = []
    if (model && 'public_errors' in model && model.public_errors) {
      model.public_errors.map(error => {
        errors.push(error)
        return error
      })
    }
    return errors
  }

  /**
   * Check if profile is currently public
   *
   * @param model
   * @returns {boolean}
   */
  const isPublic = (model) => {
    return !!model.is_public
  }

  /**
   *
   *
   * model.available can be 0, 1 or 2.
   * 0 != available
   * 1 === available
   * 2 === available if availabilities are
   *
   * @param model
   * @returns {{label: string, key: string}}
   */
  const getBookableStatus = (model) => {
    if (!isPublic(model) && model.available) {
      return {key: 'not-public', label: 'Niet openbaar'} //orange
    }
    if (!isPublic(model) && !model.available) {
      return {key: 'not-bookable', label: 'Niet boekbaar'} //red
    }
    if (model.available && isPublic(model)) {
      return {key: 'bookable', label: 'Openbaar'} //green
    }
    if (!model.available && isPublic(model)) {
      return {key: 'closed', label: '(Tijdelijk) gesloten'} //orange
    }
  }

  /**
   * Get all errors in array
   *
   * @param model
   * @param user
   * @returns {*[]|null}
   */
  const getPublicErrors = (model, user) => {
    const companyErrors = getUserActiveCompanyErrors(user)
    const modelErrors = getModelErrors(model)
    const merged = [...companyErrors, ...modelErrors]
    return (merged.length) ? merged : false
  }

  /**
   *
   * @param bookable
   * @returns {boolean}
   */
  const isStand = (bookable) => {
    return (bookable.object_type === 'stand')
  }

  /**
   *
   * @param bookable
   * @returns {boolean}
   */
  const isSpotGroup = (bookable) => {
    return (bookable.object_type === 'spot_group')
  }

  /**
   *
   * @param bookable
   * @returns {boolean}
   */
  const isSpotGroupSpots = (bookable) => {
    return (bookable.object_type === 'spot_group_spots')
  }

  /**
   *
   * @param model
   * @returns {boolean}
   */
  const isSpot = (model) => {
    return (model.object_type === 'spot')
  }

  /**
   *
   * @param model
   * @returns {boolean}
   */
  const isSpotCluster = (model) => {
    return (model.object_type === 'spot_cluster')
  }

  /**
   *
   *
   * @param model
   * @returns {boolean}
   */
  const isBookable = (model) => {
    return (isSpotGroup(model) || isStand(model))
  }

  /**
   * Get the type of bookable string for text
   *
   * @param bookable
   * @param capitalize
   * @returns {string}
   */
  const getBookableTypeString = (bookable, capitalize = true) => {
    if (capitalize) {
      return (isSpotGroup(bookable)) ? 'Locatie' : 'Stand of Truck'
    } else {
      return (isSpotGroup(bookable)) ? 'locatie' : 'stand of truck'
    }
  }

  /**
   * Check if bookable is owned by a company of current user - if logged id
   * Does not look at active company!
   *
   * @param bookable
   * @returns {false|*}
   */
  const isOwnedByCurrentUser = (bookable) => {
    return !objIsEmpty(user) && user.companies.find(c => c.id === bookable.company_id)
  }

  /**
   * Check if bookable is owned by the active company of current user - if logged id
   *
   * @param bookable
   * @returns {boolean}
   */
  const isOwnedByCurrentActiveCompany = (bookable) => {
    return !objIsEmpty(user) &&
      user.active_company_id &&
      user.active_company_id === bookable.company_id
  }

  /**
   *
   * @param bookable
   * @returns {string}
   */
  const getEditLink = (bookable) => {
    return (isStand(bookable))
      ? '/user/stand/' + bookable.id + '/edit'
      : '/user/locatie/' + bookable.id + '/edit'
  }

  const calcAverageSpotSurface = (spotGroup) => {
    if (!isSpotGroup(spotGroup) || !('spots' in spotGroup) || !spotGroup.spots) {
      return null;
    }
    const totalSize = spotGroup.spots.reduce((acc, spot) => ({
      width: acc.width + spot.width,
      length: acc.length + spot.length,
    }), {width: 0, length: 0});
    return {
      width: Math.round(totalSize.width / spotGroup.spots.length),
      length: Math.round(totalSize.length / spotGroup.spots.length)
    };
  };

  return {
    getUserActiveCompanyErrors,
    getCompanyErrors,
    getModelErrors,
    isPublic,
    getPublicErrors,
    getSolutionUrl,
    getBookableStatus,
    getBookableTypeString,
    isStand,
    isSpotGroup,
    isSpotGroupSpots,
    isSpot, //maybe not suppose to be in useBookable... but somehow it is... weird...
    isSpotCluster, //maybe not suppose to be in useBookable... but somehow it is... weird...
    isOwnedByCurrentUser,
    isOwnedByCurrentActiveCompany,
    isBookable,
    getEditLink,
    calcAverageSpotSurface,
  }
}


export default useBookable
