import React, {useEffect, useState} from "react";
import cloneDeep from 'lodash/cloneDeep';
import {Route, useNavigate} from "react-router-dom";
import {Fields, Form} from "../../components/Form/Form";
import BookingSelectSpotMapDates from "../../components/Form/BookingSelectSpotMapDates";
import BookingSelectSpotGroup from "../../components/Form/BookingSelectSpotGroup";
import BookingSelectOptions from "../../components/Form/BookingSelectOptions";
import useSpotGroupService from "../../api/services/useSpotGroupService";
import BookingSelectStand from "../../components/Form/BookingSelectStand";
import useBookingService from "../../api/services/useBookingService";
import useLocationState from "../../hooks/useLocationState";
import BookingOverview from "../../components/Form/BookingOverview";
import useStandService from "../../api/services/useStandService";
import BookingFinish from "../../components/Form/BookingFinish";
import BookingPrice from "../../components/Form/BookingPrice";
import useWizard from "../../hooks/useWizard";
import usePrice from "../../hooks/usePrice";
import Loading from "../../components/Loading/Loading";
import useForm from "../../hooks/useForm";
import Wizard from "../../layouts/Wizard/Wizard";
import Button from "../../components/Button/Button";
import './style.scss'


const WizardBooking = () => {

  const pathname = "/wizard/boek"
  const form = useForm()
  const {location, hasLocationState} = useLocationState()
  const navigate = useNavigate()
  const steps = [
    {slug: 'start', label: 'Start', hidden: true},
    {slug: 'select_stand', label: 'Selecteer Stand/Truck/Act'},
    {slug: 'stand_options', label: 'Stand Opties'},
    {slug: 'select_spot_group', label: 'Selecteer Locatie'},
    {slug: 'spot_group_options', label: 'Locatie Opties'},
    {slug: 'spot_map_dates', label: 'Selecteer Standplaats & Datums'},
    {slug: 'negotiate_offers', label: 'Onderhandel Prijs'},
    {slug: 'summary', label: 'Samenvatting'},
    {slug: 'finish', label: 'Finish'}
  ]

  const initialButtonPrev = {
    start: {
      onClick: () => {
        navigate(getBackupLinkBack())
      },
      className: 'btn-red',
      label: 'Terug',
    },

    select_stand: hasLocationState('hideRequestedBookableSteps') ? {
      onClick: () => {
        navigate(getBackupLinkBack())
      },
      className: 'btn-red',
      label: 'Terug',
    } : {},

    stand_options: hasLocationState('hideRequestedBookableSteps') ? {
      onClick: () => {
        navigate(getBackupLinkBack())
      },
      className: 'btn-red',
      label: 'Terug',
    } : {},

    select_spot_group: hasLocationState('hideRequestedBookableSteps') ? {
      onClick: () => {
        navigate(getBackupLinkBack())
      },
      className: 'btn-red',
      label: 'Terug',
    } : {},

    spot_group_options: hasLocationState('hideRequestedBookableSteps') ? {
      onClick: () => {
        navigate(getBackupLinkBack())
      },
      className: 'btn-red',
      label: 'Terug',
    } : {},

  }
  const initialButtonNext = {
    start: {
      disabled: true,
      visible: false,
    },
    select_stand: {
      visible: ['if', 'stand_id'],
      disabled: ['ifNot', 'stand_id'],
    },

    //todo the next button should be disabled if the stand has booking_without_spot_group:true
    // select_spot_group: {
    //   visible: ['if', 'spot_group_id'],
    //   disabled: ['ifNot', 'spot_group_id'],
    // },

    //todo the next button should be disabled if the spot_group has booking_without_spot:true
    // spot_map_dates: {
    //   visible: ['if', 'spots'],
    //   disabled: ['ifNot', 'spots'],
    // },

    summary: {
      disabled: true,
      visible: false,
    },
    finish: {
      label: 'Mijn Boekingen',
      onClick: () => navigate('/user/boekingen')
    }
  }

  const [spotGroups, setSpotGroups] = useState([])
  const [stands, setStands] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const bookingService = useBookingService()
  const spotGroupService = useSpotGroupService()
  const standService = useStandService()
  const [hideRequestedBookableSteps, setHideRequestedBookableSteps] = useState(false)
  const {countBookingPriceTypeNegotiate, getBookable} = usePrice()

  /**
   * Hide a step
   *
   * @param steps
   * @param slug
   */
  const hideStep = (steps, slug) => {
    const index = steps.findIndex(step => step.slug === slug)
    if (index !== -1) {
      steps[index].hidden = true
    }
    return steps
  }

  /**
   * Show a step
   *
   * @param steps
   * @param slug
   */
  const showStep = (steps, slug) => {
    const index = steps.findIndex(step => step.slug === slug)
    if (index !== -1) {
      steps[index].hidden = false
    }
    return steps
  }

  /**
   *
   * @param steps
   * @param booking
   * @returns {*}
   */
  const updateSteps = (steps, booking) => {
    const bookable = getBookable(booking);

    /*
     * Hide steps when user comes from a profile page (hideRequestedBookableSteps:true)
     */
    if (hideRequestedBookableSteps) {
      if (booking.type === 'stand-requests-spot') {
        steps = hideStep(steps, 'select_spot_group')
        steps = hideStep(steps, 'stand_options')
        steps = hideStep(steps, 'spot_map_dates')

      } else if (booking.type === 'spot-group-requests-stand') {
        steps = hideStep(steps, 'select_stand')
        steps = hideStep(steps, 'spot_group_options')

        if (booking.spot_group_id) {
          steps= showStep(steps, 'spot_map_dates')
        } else {
          steps = hideStep(steps, 'spot_map_dates')
        }
      }
    }

    /*
     * Hide steps
     */
    if (bookable && !countBookingPriceTypeNegotiate(booking, bookable)) {
      steps = hideStep(steps, 'negotiate_offers')
    }

    if (booking.type === 'stand-requests-spot') {
      steps = hideStep(steps, 'stand_options')
      if (booking.spot_group_id) {
        if (booking.spot_group.bookable_options.length === 0) {
          steps = hideStep(steps, 'spot_group_options')
        } else {
          steps = showStep(steps,'spot_group_options')
        }
      } else {
        steps = hideStep(steps, 'spot_group_options')
      }

    } else if (booking.type === 'spot-group-requests-stand') {
      steps = hideStep(steps, 'spot_group_options')
      if (booking.stand_id) {
        if (booking.stand.bookable_options.length === 0) {
          steps = hideStep(steps, 'stand_options')
        } else {
          steps = showStep(steps,'stand_options')
        }
      } else {
        steps = hideStep(steps, 'stand_options')
      }
    }
    return steps
  }

  const wizard = useWizard(
    steps,
    updateSteps,
    pathname,
    initialButtonPrev,
    initialButtonNext,
    form,
  )

  useEffect(() => {
    if (hasLocationState('hideRequestedBookableSteps')) {
      setHideRequestedBookableSteps(true)
    }
    if (hasLocationState('setFinished')) { //todo implement from user/boekingen etc (mss niet doen als = concept)
      !wizard.finished && wizard.setFinished(true)
    }
  }, []);


  const updateSuccessNextStep = (booking) => {
    wizard.nextStep(booking.id, booking);
  };


  useEffect(() => {
    if (wizard.step === 'start') {
      if (wizard.id) {
        setIsLoading(true)
        bookingService.userShow(
          (booking) => {
            form.setOriginalFormDataModel(cloneDeep(booking))
            form.setFormData(prevFormData => {
                return {
                  ...prevFormData,
                  model: booking,
                  isLoading: false,
                }
              }
            )
            setIsLoading(false)
            wizard.nextStep(booking.id, updateSteps(steps, booking))
          },
          () => {
            setIsLoading(false)
            //todo error handling
          },
          wizard.id,
        )
      } else if (location.state.booking) {

        //do the actual storage of the booking concept
        bookingService.userStore(
          (bookingConcept) => {
            //back to beginning, but with id
            navigate(pathname + '/start/' + bookingConcept.id)
          },
          () => {
            setIsLoading(false)
            //todo error handling
          }, bookingService.preService(location.state.booking)
        )
      }
    }

    if (wizard.step === 'select_spot_group') {
      if (!spotGroups.length) {
        setIsLoading(true)
        spotGroupService.userShowCollection(
          (data) => {
            setSpotGroups(data)
            setIsLoading(false)
          },
          () => {
            //todo error handling
            setIsLoading(false)
          }
        )
      }
    }

    if (wizard.step === 'select_stand' && !stands.length) {
      setIsLoading(true)
      standService.userShowCollection(
        (data) => {
          setStands(data)
          setIsLoading(false)
        },
        () => {
          //todo error handling
          setIsLoading(false)
        },
      )
    }
  }, [wizard.step, wizard.id])


  /**
   * BACKUP LINK, in case the user is using another wizard, the backlink will be overwritten..
   * @returns {string}
   */
  const getBackupLinkBack = () => {
    let booking = form.formData.model
    if (!booking) {
      return '/user/boekingen'
    } else {
      if ('type' in booking && booking.type) {
        if (booking.type === 'stand-requests-spot') {
          return '/locatie/' + booking.spot_group.slug
        } else {
          return '/stand/' + booking.stand.slug
        }
      } else {
        return '/?direction=search&search_model=spot_group'
      }
    }
  }

  return (
    <div className="wizard-booking">
      <Wizard
        form={form}
        wizard={wizard}
        buttonTopRight={
          <>
            {form.formData.model && form.formData.model.id && form.formData.model.direction === 'receiving' &&
              <Button
                label={(form.formData.model.type === 'stand-requests-spot') ? "Chat met Stand" : "Chat met Locatie"}
                link={"/user/berichten/" + form.formData.model.requested_by_company_id}
                linkState={{company: form.formData.model.requested_by_company}}
                color="grey-ghost"
                size="s"
              />
            }
            {form.formData.model && form.formData.model.id && form.formData.model.direction === 'requesting' &&
              <Button
                label={(form.formData.model.type === 'stand-requests-spot') ? "Chat met Locatie" : "Chat met Stand"}
                link={"/user/berichten/" + form.formData.model.received_by_company_id}
                linkState={{company: form.formData.model.received_by_company}}
                color="grey-ghost"
                size="s"
              />
            }
          </>
        }
      >
        <Route path="/start/*" element={
          <div className="center">
            <h1>Reserveren</h1>
            <Loading/>
          </div>
        }/>

        <Route path="/select_stand/*" element={
          <div className="center">
            {isLoading
              ? <Loading/>
              :
              <div className="width-medium center">
                <Form>
                  <Fields
                    id={wizard.id}
                    form={form}
                    wizard={wizard}
                    apiService={bookingService}
                    updateSuccess={updateSuccessNextStep}
                    onChange={() => wizard.nextButtonDisabled()}
                    onReset={() => wizard.setInitialButtons()}
                    preService={bookingService.preService}
                  >
                    <BookingSelectStand
                      stands={stands}
                      // wizard={wizard}
                    />
                  </Fields>
                </Form>
              </div>
            }
          </div>
        }/>

        <Route path="/stand_options/*" element={
          <div className="center">
            <h1>Selecteer Opties</h1>
            <Form>
              <Fields
                id={wizard.id}
                form={form}
                wizard={wizard}
                apiService={bookingService}
                updateSuccess={updateSuccessNextStep}
                onChange={() => wizard.nextButtonDisabled()}
                onReset={() => wizard.setInitialButtons()}
                preService={bookingService.preService}
              >
                <BookingSelectOptions
                  name="stand"
                />
              </Fields>
            </Form>
          </div>
        }/>

        <Route path="/select_spot_group/*" element={
          <div className="center">
            {isLoading
              ? <Loading/>
              :
              <div className="width-medium">
                <Form>
                  <Fields
                    id={wizard.id}
                    form={form}
                    wizard={wizard}
                    apiService={bookingService}
                    updateSuccess={updateSuccessNextStep}
                    onChange={() => wizard.nextButtonDisabled()}
                    onReset={() => wizard.setInitialButtons()}
                    preService={bookingService.preService}
                  >
                    <BookingSelectSpotGroup
                      spotGroups={spotGroups}
                    />
                  </Fields>
                </Form>
              </div>
            }
          </div>
        }/>

        <Route path="/spot_group_options/*" element={
          <div className="center">
            <h1>Selecteer Opties</h1>
            <Form>
              <Fields
                id={wizard.id}
                form={form}
                wizard={wizard}
                apiService={bookingService}
                updateSuccess={updateSuccessNextStep}
                onChange={() => wizard.nextButtonDisabled()}
                onReset={() => wizard.setInitialButtons()}
                preService={bookingService.preService}
              >
                <BookingSelectOptions
                  name="spot_group"
                />
              </Fields>
            </Form>
          </div>
        }/>

        <Route path="/spot_map_dates/*" element={
          <div className="center">
            <Form>
              <Fields
                id={wizard.id}
                form={form}
                wizard={wizard}
                apiService={bookingService}
                updateSuccess={updateSuccessNextStep}
                onChange={() => wizard.nextButtonDisabled()}
                onReset={() => wizard.setInitialButtons()}
                preService={bookingService.preService}
              >
                <BookingSelectSpotMapDates/>
              </Fields>
            </Form>
          </div>
        }/>


        <Route path="/negotiate_offers/*" element={
          <div className="center">
            <Form>
              <Fields
                id={wizard.id}
                form={form}
                wizard={wizard}
                apiService={bookingService}
                updateSuccess={(model) => {
                  wizard.setInitialButtons()
                  updateSuccessNextStep(model)
                }}
                onChange={() => wizard.nextButtonDisabled()}
                onReset={() => wizard.setInitialButtons()}
                preService={bookingService.preService}
              >
                <BookingPrice
                  name="negotiate_offers"
                  wizard={wizard}
                />
              </Fields>
            </Form>
          </div>
        }/>

        <Route path="/summary/*" element={
          <div className="center">
            <Form>
              <Fields
                id={wizard.id}
                form={form}
                wizard={wizard}
                apiService={bookingService}
                updateSuccess={updateSuccessNextStep}
                onChange={() => wizard.nextButtonDisabled()}
                onReset={() => wizard.setInitialButtons()}
                preService={bookingService.preService}
              >
                <BookingOverview
                  wizard={wizard}
                  form={form}
                  name="stand"
                />
              </Fields>
            </Form>
          </div>
        }/>

        <Route path="/finish/*" element={
          <div className="center">
            <h1>Finish</h1>
            <Form>
              <Fields
                id={wizard.id}
                form={form}
                wizard={wizard}
                apiService={bookingService}
                updateSuccess={updateSuccessNextStep}
                onChange={() => wizard.nextButtonDisabled()}
                onReset={() => wizard.setInitialButtons()}
                preService={bookingService.preService}
              >
                <BookingFinish
                  name="type"
                />
              </Fields>
            </Form>
          </div>
        }/>

      </Wizard>
    </div>
  )
}


export default WizardBooking
