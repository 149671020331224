import useFunction from "../../hooks/useFunction";
import useApi from "../../api/useApi";

const useBookingService = () => {

  const {c} = useFunction()
  const {api} = useApi()
  const {getIDsArray} = useFunction()

  const userShow = (callbackSuccess, callbackValidationErrors, bookingId) => {
    return api(
      'get',
      (bookingId) ? 'user/booking/' + bookingId : 'user/bookings',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error => c('Error in Service', error),
    )
  }

  const userUpdate = (callbackSuccess, callbackValidationErrors, bookingId, booking) => {
    return api(
      'post',
      'user/booking/' + bookingId + '/update',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error => c('Error in Service', error),
      booking,
    )
  }

  const userAction = (callbackSuccess, callbackValidationErrors, bookingId, action) => {
    return api(
      'post',
      'user/booking/' + bookingId + '/action',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error => c('Error in Service', error),
      action,
    )
  }

  const userStore = (callbackSuccess, callbackValidationErrors, booking) => {
    return api(
      'post',
      'user/booking/store',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error => c('Error in Service', error),
      booking,
    )
  }

  const preService = (booking) => {

    /*
     * Remove props from booking,
     *
     * //todo this might cause problems, e.g. updateWizard steps function might need these vars.
     *
     */

    if ('spot_group' in booking && booking.spot_group){
      booking.spot_group.spots = null
      booking.spot_group.spot_clusters = null
      booking.spot_group.spot_maps = null
    }

    // booking.stand = null
    // booking.prices = {
    //   booking: {},
    //   spot: [],
    //   booking_options: [],
    // }

    /*
     * Add props to booking
     */
    return {
      ...booking,
      spots_ids: getIDsArray(booking.spots),
    }
  }

  return {
    objectType: 'booking',
    userShow,
    userUpdate,
    userStore,
    preService,
    userAction,
  }
}

export default useBookingService
