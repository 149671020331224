import React, {useEffect, useRef, useState} from "react";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import arrowDownIcon from '../../assets/icons/arrow-down.svg'
import arrowUpIcon from '../../assets/icons/arrow-up.svg'
import Button from "../Button/Button";
import {gsap} from "gsap";
import "./style.scss"

const SelectFilter = (props) => {

  const {
    menuId,
    children,
    open,
    toggleOpen,
    label,
    fieldLabel,
    dynamicLabel,
    handleSearch,
    handleRegister,
    nextOnClick,
    nextLabel,
    filterMenuStyle,
    isChanged,
    handleReset,
  } = props

  gsap.registerPlugin(ScrollTrigger);

  const fieldRef = useRef(null);
  const menuRef = useRef(null);
  const [menuStyle, setMenuStyle] = useState(filterMenuStyle);

  /**
   *
   */
  useEffect(() => {
    handleResize()
  }, [open])

  /**
   *
   */
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [])

  /**
   *
   */
  const handleResize = () => {
    const isInViewportVertical = ScrollTrigger.isInViewport(menuRef.current, 1);
    const isInViewportHorizontal = ScrollTrigger.isInViewport(menuRef.current, 1, true);
    const saveTop = window.innerHeight - menuRef.current.clientHeight;
    const saveLeft = window.innerWidth - menuRef.current.clientWidth;

    if (window.innerWidth < 768) {

      setMenuStyle({
        ...menuStyle,
        position: 'static',
        top: 'unset',
        left: 'unset',
        minWidth: '100%'
      })

    } else {
      if (isInViewportVertical && isInViewportHorizontal) {
        setMenuStyle({
          ...menuStyle,
          position: 'absolute',
          top: 0,
          left: '100%',
          minWidth: fieldRef.current.clientWidth
        })
      } else if (!isInViewportVertical && !isInViewportHorizontal) {
        // setMenuStyle({
        //   ...menuStyle,
        //   position: 'fixed',
        //   top: 0,
        //   left: 0,
        //   minWidth: fieldRef.current.clientWidth
        // })
      } else if (!isInViewportVertical && isInViewportHorizontal) {
        setMenuStyle({
          ...menuStyle,
          position: 'fixed',
          top: saveTop,
          left: fieldRef.current.getBoundingClientRect().left + fieldRef.current.clientWidth,
          minWidth: fieldRef.current.clientWidth
        })
      } else if (isInViewportVertical && !isInViewportHorizontal) {
        setMenuStyle({
          ...menuStyle,
          position: 'fixed',
          top: fieldRef.current.getBoundingClientRect().top,
          left: saveLeft,
          minWidth: fieldRef.current.clientWidth
        })
      }
    }
  }

  const getFieldLabel = () => {
    let base = label
    if (fieldLabel) {
      base = fieldLabel
    }
   return (dynamicLabel) ? dynamicLabel : base
  }

  return (
    <div className={(open)?"component-select-filter open":"component-select-filter"} data-menu-id={menuId}>
      <div className="label">{label}</div>
      <div className="field" ref={fieldRef}>
        <div className="field-inner" onClick={() => toggleOpen(true)}>
          <div className={(dynamicLabel) ? "label dynamic" : "label"}>{getFieldLabel()}</div>
          <div className="icon">
            {!dynamicLabel &&
              <img src={(open) ? arrowUpIcon : arrowDownIcon} alt="arrow"/>
            }
          </div>
        </div>
        {dynamicLabel &&
          <div
            className="close"
            onClick={handleReset}
          >&#10005;</div>
        }
        <div
          className={(open) ? "filter-menu show" : "filter-menu"}
          ref={menuRef}
          style={menuStyle}
        >
          <div className="filter-menu-inner">
            {children}
            {(handleSearch || nextOnClick || handleRegister) &&
              <div className="buttons">
                {nextOnClick && nextLabel &&
                  <Button
                    label={nextLabel}
                    color="blue-ghost"
                    onClick={nextOnClick}
                    className={"next-button"}
                  />
                }
                {handleSearch &&
                  <Button
                    label="Zoeken"
                    onClick={handleSearch}
                    disabled={!isChanged}
                    className="search-button"
                  />
                }
                {handleRegister &&
                  <Button
                    label="Aanmelden"
                    onClick={handleRegister}
                  />
                }
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectFilter
