import React, {useEffect, useState} from "react";
import initialBooking from "../../data/initialBooking";
import standIcon from "../../assets/icons/stand-white.svg";
import {useParams} from "react-router-dom";
import {useAppContext} from "../../context/AppContextProvider";
import useStandService from "../../api/services/useStandService";
import Loading from "../../components/Loading/Loading";
import Profile from "../../components/Profile/Profile";
import Error404 from "../../components/Error404/Error404";
import Button from "../../components/Button/Button";
import useBookable from "../../hooks/useBookable";
import './style.scss'


const Stand = () => {

  const {show} = useStandService()
  const {standSlug} = useParams();
  const [error, setError] = useState(null);
  const {isStand} = useBookable()

  const [booking, setBooking] = useState(initialBooking)



  // const {
  //   booking,
  //   setBooking,
  //   // stand,
  //   // setStand
  // } = useAppContext()


  const [stand, setStand] = useState(null)


  useEffect(() => {
    loadStand()
  }, [])

  const loadStand = () => {
    setStand(null) //removes old Stand from Context
    show(
      (stand) => {
        if (isStand(stand)) {
          setBooking({
            ...initialBooking,
            type: 'spot-group-requests-stand',
            received_by_company_id: stand.company.id,
            stand: stand,
            stand_id: stand.id,
          })
        }
        setStand(stand)
      },
      (errors) => {
      },
      standSlug,
      (error) => {
        if (error.status === 404) {
          setError(error)
        }
      }
    )
  }


  return (
    <div className="page-stand">
      {!stand && !error &&
        <>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <Loading/>
        </>
      }
      {stand &&
        <Profile
          bookable={stand}
          setBookable={setStand}
          booking={booking}
          setBooking={setBooking}
          reloadBookable={loadStand}
        />
      }
      {error &&
        <Error404
          label="Stand niet gevonden"
          text="Mogelijk is deze stand niet meer beschikbaar."
          button={<Button
            label={'Alle Stands & Trucks'}
            link={'/?direction=search&search_model=stand'}
            icon={standIcon}
          />}
        />
      }
    </div>
  )
}

export default Stand
