import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import BookableStatus from "../BookableStatus/BookableStatus";
import useBookable from "../../hooks/useBookable";
import useFunction from "../../hooks/useFunction";
import EditButton from "../Form/EditButton";
import Loading from "../Loading/Loading";
import Button from "../Button/Button";
import Media from "../Media/Media";
import {localhost} from "../../env";
import "./style.scss"

const TileList = (props) => {

  const {
    apiServiceShow,
    modelLabelKey,
    modelImageKey,
    showBookableStatus,

    buttonOneDynamicUrl,
    buttonOneLabel,
    buttonOneClassName,
    buttonOneColor,

    buttonTwoDynamicUrl,
    buttonTwoLabel,
    buttonTwoClassName,
    buttonTwoColor,

    buttonThreeDynamicUrl,
    buttonThreeLabel,
    buttonThreeClassName,
    buttonThreeColor,

    buttonProfileBaseUrl,
    smallImage,

    imageSize,
  } = props
  const [models, setModels] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const {c} = useFunction()
  const {isPublic, isBookable} = useBookable()

  useEffect(() => {
    apiServiceShow(
      (models) => {
        setModels(models)
        setIsLoading(false)
      },
      (error) => {
        c('error in TieList component', error)
      },
    )
  }, [])

  return (
    <div className="component-tile-list">
      {isLoading
        ?
        <Loading/>
        :
        <div className="list">
          {models.map((model, i) => {
            return (
              <div key={i} className="model">
                {model[modelImageKey] &&
                  <div className={(smallImage)?'image small':'image'}>
                    <Media media={(modelImageKey in model)?model[modelImageKey]:null} size={imageSize} />
                  </div>
                }
                <div className="label">
                  <h1>{model[modelLabelKey]}</h1>
                  {showBookableStatus &&
                    <BookableStatus bookable={model}/>
                  }
                </div>
                <div className="buttons">
                  {
                    buttonProfileBaseUrl &&
                    'slug' in model &&
                    model.slug &&
                    isBookable(model) &&
                    <Button
                      label={(isPublic(model)) ? 'Weergeven' : 'Profiel niet openbaar'}
                      link={buttonProfileBaseUrl + model.slug}
                      target={(localhost)?"":"_blank"}
                      disabled={!isPublic(model)}
                      size="s"
                      color="green"
                    />
                  }

                  {buttonOneDynamicUrl && buttonOneLabel &&
                    <EditButton
                      label={buttonOneLabel}
                      link={buttonOneDynamicUrl}
                      color={buttonOneColor}
                      className={buttonOneClassName}
                      model={model}
                    />
                  }

                  {buttonTwoDynamicUrl && buttonTwoLabel &&
                    <EditButton
                      label={buttonTwoLabel}
                      link={buttonTwoDynamicUrl}
                      color={buttonTwoColor}
                      className={buttonTwoClassName}
                      model={model}
                    />
                  }

                  {buttonThreeDynamicUrl && buttonThreeLabel &&
                    <EditButton
                      label={buttonThreeLabel}
                      link={buttonThreeDynamicUrl}
                      color={buttonThreeColor}
                      className={buttonThreeClassName}
                      model={model}
                    />
                  }

                </div>
              </div>
            )
          })}
        </div>
      }
    </div>
  )
}

TileList.defaultProps = {
  modelLabelKey: null,
  modelImageKey: 'overview_media',
  buttonOneDynamicUrl: null,
  buttonOneLabel: null,
  buttonTwoDynamicUrl: null,
  buttonTwoLabel: null,
  buttonProfileBaseUrl: null,
  smallImage: false,
  imageSize: 'tile',
}

TileList.propTypes = {
  apiServiceShow: PropTypes.func.isRequired,
  modelLabelKey: PropTypes.string,
  modelImageKey: PropTypes.string,
  buttonOneDynamicUrl: PropTypes.string,
  buttonOneLabel: PropTypes.string,
  buttonTwoDynamicUrl: PropTypes.string,
  buttonTwoLabel: PropTypes.string,
  buttonProfileBaseUrl: PropTypes.string,
  smallImage: PropTypes.bool,
  imageSize: PropTypes.string,
}

export default TileList
