import React from "react";
import ProfileStatus from "../ProfileStatus/ProfileStatus";
import "./ProfileStatusForm-style.scss"

const ProfileStatusForm = (props) => {

  const {
    formData,
    originalFormDataModel,
    className,
  } = props

  const model = (originalFormDataModel) ? originalFormDataModel : formData.model

  return (
    <div className={className + 'profile-status-form '}>
      <ProfileStatus
        model={model}
      />
    </div>
  )
}


export default ProfileStatusForm



















