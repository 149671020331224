import React from "react";
import PropTypes from "prop-types";
import swal from "sweetalert";
import './MultiBlock-style.scss'

const MultiBlock = (props) => {

  const {
    label,
    model,
    className,
    keyLabel,
    hasError,
    error,
    formData,
    setFormData,
    value,
    confirmDelete,
    confirmDeleteTitle,
    confirmDeleteLabel,
  } = props

  const handleDelete = (id) => {
    if (confirmDelete === '' || !confirmDelete){
      deleteRow(id)
    } else {
      swal({
        title: confirmDeleteTitle,
        icon: 'warning',
        text: confirmDelete,
        dangerMode:true,
        buttons: {
          cancel: {
            text: 'Annuleren',
            value: null,
            visible: true,
            className: 'btn-color-grey btn-size-m',
            closeModal: true,
          },
          confirm: {
            text: confirmDeleteLabel,
            value: true,
            visible: true,
            className: 'btn-color-red btn-size-m',
            closeModel: true,
          }
        }
      }).then((result) => {
        if (result) {
          deleteRow(id)
        }
      })
    }
  }

  const deleteRow = (id) => {
    value[id].is_trash = true
    setFormData(prevFormData => {
        return {
          ...prevFormData,
          collection: formData.collection,
          isChanged: true,
        }
      }
    )
  }

  const handleUndoDelete = (id) => {
    delete value[id].is_trash   //todo use state.splice(state.indexOf(item.id), 1)
    setFormData(prevFormData => {
      return {
        ...prevFormData,
        collection: formData.collection,
      }
    })
  }

  //todo add isLoading style

  return (
    <div className={className + 'multi-block '}>
      <label>{label}
        {hasError && <span className="error"> - {error}</span>}
        <div className="multi-block-container">
          {model && value && value.map((item, i) => {
            return (
              <div className="item-container" key={i}>
                {'is_trash' in item && item.is_trash
                  ?
                  <div className="item item-deleted" >
                    {item[keyLabel]}
                    <div
                      className="side-button"
                      onClick={() => handleUndoDelete(i)}
                    >&#10554;
                    </div>
                  </div>
                  :
                  <div className={'status' in item ? "item item-" + item.status : 'item' }>
                    {item[keyLabel]}
                    <div
                      className="side-button"
                      onClick={() => handleDelete(i)}
                    >&#215;
                    </div>
                  </div>
                }
              </div>
            )
            }
          )}
        </div>
      </label>
    </div>
  )
}


//todo update defaults and types
MultiBlock.defaultProps = {
  label: '',
  name: '',
  value: [],
  model: null,
  className: '',
  relation: '',
  keyLabel: '',
  confirmDelete: '',
  confirmDeleteTitle: 'Verwijderen?',
  confirmDeleteLabel: 'Verwijderen',
  hasError: false,
  onChange: () => {
  },
  error: '',
}

MultiBlock.propTypes = {
  label: PropTypes.string,
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  confirmDelete: PropTypes.string,
  confirmDeleteTitle: PropTypes.string,
  confirmDeleteLabel: PropTypes.string,
  model: PropTypes.object,
  className: PropTypes.string,
  relation: PropTypes.string,
  keyLabel: PropTypes.string,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.string,
}
export default MultiBlock
