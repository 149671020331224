const initSpotCluster = {
    rotate: 0,
    top: 0, //todo make dynamic, based on empty space in map
    left: 0, //todo make dynamic, based on empty space in map
    width: 4, //todo make dynamic, based on map size
    length: 4, //todo make dynamic, based on map size
    label: 'Groep A',
    object_type: 'spot_cluster',
    spot_group_id: null,
  }
export default initSpotCluster
