import React from 'react'
import './style.scss'
import Button from "../Button/Button";


const Error404 = (props) => {

  const {label, text, button} = props

  return (
    <div className="component-error-404">

      <h1 style={{fontSize:'48px'}}>404</h1>
      {label &&
        <h2>{label}</h2>
      }
      {text &&
        <p>{text}</p>
      }
      {button &&
        button
      }
    </div>
  )
}


export default Error404
