import React from "react";
import Button from "../../components/Button/Button";
import './style.scss'

const ErrorReport = () => {
  return (
    <div className="page-error-404">
      <section className="section-container">
        <h3>Dank voor het versturen van de Error</h3>
        <p>Onze developers zijn op de hoogte gesteld, en we proberen het probleem zo snel mogelijk op te lossen. Probeer het later opnieuw. Mocht het probleem dan nog niet opgelost zijn, dan kunt u altijd contact met ons opnemen.</p>
        <div className="buttons">
          <Button
            label="Neem Contact op"
            linkOutside={'/service/contact/'}
            color="grey-ghost"
          />
          <Button
            label="Bezoek Homepagina"
            link="/"
            color="grey-ghost"
          />
        </div>
      </section>
    </div>
  )
}

export default ErrorReport
