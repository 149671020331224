import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import useForm from "../../hooks/useForm";
import useInterval from "../../hooks/useInterval";
import './Slug-style.scss'

const Slug = (props) => {

  const {getValue} = useForm()

  const {
    label,
    name,
    text,
    value,
    className,
    hasError,
    onChange,
    error,
    basedOnField,
    isLoading,
    formData,
    urlPostFix,
    save,
  } = props

  /**
   * Remove and re-create slug if formData.model[disabledByKey]] changes
   */
  useEffect(() => {
    if (!isLoading) {
      newSlugIfNotDisabledByKeys()
    }
  }, [isLoading])

  const [hasNewSlug, setHasNewSlug] = useState(false)


  /**
   * //todo this is a hack....
   * somehow the save(true) function doesnt work right after the
   * createNewSlug(), (sync issue?)
   * For now this interval is used to make sure the save() function is
   * called
   *
   *
   */
  useInterval(() => {
    if (hasNewSlug) {
      setHasNewSlug(false)
      save(true)
    }
  }, 1)


  /**
   *
   */
  const newSlugIfNotDisabledByKeys = () => {
    if (value === null || value === '') {
      createNewSlug()
      setHasNewSlug(true) //note hack, because save() is not working
    } else {
      //do nothing: slug is already set
    }
  }

  /**
   *
   */
  const createNewSlug = () => {
    formData.model[name] = slugify(formData.model[basedOnField]) + '-' + random(999, 10000)
    save(true)
  }

  /**
   * Convert string to slug
   *
   * @param str
   * @returns {string}
   */
  const slugify = (str) => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    let from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    let to = "aaaaeeeeiiiioooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }
    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

    return str;
  }

  /**
   * Generate random number
   *
   * @param min
   * @param max
   * @returns {number}
   */
  const random = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  return (
    <>
      <div className={className + 'slug '}>
        <label>{label}
          {text && text !== '' &&
            <p className="text-small">{text}</p>
          }
          {hasError && <span className="error"> - {error}</span>}
          <br/>
          {getValue(value).length > 0 &&
            <p className="slug">standplaats.nl/{urlPostFix}/{slugify(getValue(value))}</p>}
          <div className="form-row">
            <input
              disabled={isLoading}
              type="text"
              name={name}
              value={slugify(getValue(value))}
              onChange={onChange}
            />
          </div>
        </label>
      </div>
    </>
  )
}

Slug.defaultProps = {
  label: '',
  name: '',
  urlPostFix: '',
  basedOnField: '',
  text: '',
  value: '',
  type: 'text',
  className: '',
  hasError: false,
  onChange: () => {
  },
  error: '',
  // create: false,
  form: null,
}

Slug.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  urlPostFix: PropTypes.string,
  basedOnField: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  // create: PropTypes.bool,
  className: PropTypes.string,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  form: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
}

export default Slug
