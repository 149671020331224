import drinksIcon from "../assets/icons/drinks.svg";
import burgerIcon from "../assets/icons/burger.svg";
import fishIcon from "../assets/icons/fish.svg";
import appleIcon from "../assets/icons/apple.svg";
import meatIcon from "../assets/icons/meat.svg";
import cheeseIcon from "../assets/icons/cheese-1.svg";
import breadIcon from "../assets/icons/bread-1.svg";
import iceCreamIcon from "../assets/icons/ice-cream-2.svg";
import flowerIcon from "../assets/icons/flower.svg";
import shirtIcon from "../assets/icons/shirt.svg";
import bootsIcon from "../assets/icons/boots.svg";
import animalPrintIcon from "../assets/icons/animal-print.svg";
import amusementIcon from "../assets/icons/amusement.svg";
// import fruitIcon from "../../assets/icons/fruit-1.svg"
// import candyIcon from "../../assets/icons/candy.svg"
// import coffeeIconDark from "../../assets/icons/coffee.svg";
// import vegetablesIcon from "../../assets/icons/vegetables.svg"
import {useNavigate} from "react-router-dom";
import industries from "../data/industries";

const useIndustry = () => {

  const navigate = useNavigate()
  
  const mainIndustries = [
    {
      icon: drinksIcon,
      industryId: 2,//dranken
      // onClick: () => navigate('/locaties', {state: {preFilter: {industries: [2]}}}),
    },
    {
      icon: burgerIcon,
      iconSmall: true,
      industryId: 10, //Friet/snacks
      altLabel: 'Friet enz.',
      // onClick: () => navigate('/locaties', {state: {preFilter: {industries: [10]}}}),
    },
    {
      icon: fishIcon,
      iconSmall: true,
      industryId: 21, //vis
      altLabel: 'Vis',
      // onClick: () => navigate('/locaties', {state: {preFilter: {industries: [21]}}}),
    },
    {
      icon: appleIcon,
      industryId: 25, //Aardappelen, Groenten, Fruit & Bio"
      altLabel: 'Bio Gr. Fr.',
      // onClick: () => navigate('/locaties', {state: {preFilter: {industries: [25]}}}),
    },
    {
      icon: meatIcon,
      iconSmall: true,
      industryId: 32, //"Vlees & vleeswaren",
      altLabel: 'Vlees',
      // onClick: () => navigate('/locaties', {state: {preFilter: {industries: [32]}}}),
    },
    {
      icon: cheeseIcon,
      iconSmall: true,
      industryId: 37, //"Zuivel, kaas, noten & zuidvruchten",
      altLabel: 'Zuivel',
      // onClick: () => navigate('/locaties', {state: {preFilter: {industries: [37]}}}),
    },
    {
      icon: breadIcon,
      iconSmall: true,
      industryId: 43, //Brood, banket & Gebak
      altLabel: 'Bakker',
      // onClick: () => navigate('/locaties', {state: {preFilter: {industries: [43]}}}),
    },
    {
      icon: iceCreamIcon,
      iconSmall: true,
      industryId: 53, //IJs
      // onClick: () => navigate('/locaties', {state: {preFilter: {industries: [53]}}}),
    },
    {
      icon: flowerIcon,
      industryId: 57, //Bloemen en planten
      altLabel: 'Bloemen',
      // onClick: () => navigate('/locaties', {state: {preFilter: {industries: [57]}}}),
    },
    {
      icon: shirtIcon,
      industryId: 62, //Kleding
      // onClick: () => navigate('/locaties', {state: {preFilter: {industries: [62]}}}),
    },
    {
      icon: bootsIcon,
      industryId: 66, //Schoenen
      // onClick: () => navigate('/locaties', {state: {preFilter: {industries: [66]}}}),
    },
    {
      icon: animalPrintIcon,
      industryId: 77, //Dier artikelen & diervoeding
      altLabel: 'Dieren',
      // onClick: () => navigate('/locaties', {state: {preFilter: {industries: [77]}}}),
    },
    {
      icon: amusementIcon,
      industryId: 87, //Attracties
      // onClick: () => navigate('/locaties', {state: {preFilter: {industries: [87]}}}),
    },
  ]

  /**
   * Get industry by id
   *
   * @deprecated use useData getIndustryById(id) instead
   *
   * @param id
   * @param industryItems
   * @returns {null}
   */
  const getIndustry = (id, industryItems = null) => {
    industryItems = (industryItems) ? industryItems : industries
    for (let i = 0; i < industryItems.length; i++) {
      if (industryItems[i].id === id) {
        return industryItems[i]
      } else if ('children' in industryItems[i]) {
        const result = getIndustry(id, industryItems[i].children)
        if (result) {
          return result
        }
      }
    }
  }

  return {
    getIndustry,
    mainIndustries
  }
}


export default useIndustry
