import React from "react";
import PropTypes from "prop-types";
import {TransformWrapper, TransformComponent} from "react-zoom-pan-pinch";
import {backendUrl} from "../../env";
import Button from "../Button/Button";
import './style.scss';

const Controls = ({zoomIn, zoomOut, resetTransform}) => (
  <>
    <Button color="grey" size="s" onClick={() => {
      zoomIn()
    }} label="+"/>
    <Button color="grey" size="s" onClick={() => {
      zoomOut()
    }} label="-"/>
    <Button
      color="grey"
      size="s"
      onClick={() => {
        resetTransform()
      }} label="100 %"/>
  </>
);

const SpotMap = (props) => {
  const {map, children, onMouseDownMap, ControlButtons, disabled} = props
  const media = map.getSpotMapMedia()
  const mapWidth = map.getScaledWidth()
  const mapHeight = map.getScaledHeight()

  const getSrc = () => {
    if (media) {
      return backendUrl + (media.type === 'svg' ? media.path.filename : media.path.normal_full_height);
    }
  };

  const reset = (resetTransform) => {
    map.setZoomLevel(1)
    resetTransform()
  }

  return (
    <div className="component-spot-map" ref={map.transformWrapperRef}>
      <TransformWrapper
        defaultScale={1}
        minScale={1}
        maxScale={8}
        centerContent={true}
        disabled={disabled}
        disablePadding={true}
        doubleClick={{disabled: true}}
        // onZoom={(zoom) => {
        // }}
        onTransformed={(zoom) => {
          if (zoom.state.scale !== map.zoomLevel) {
            map.setZoomLevel(zoom.state.scale)
          }
        }}
      >
        {({zoomIn, zoomOut, resetTransform}) => {
          return (
            <>
              <div className="control-buttons-above">
                {/*<Controls map={map} zoomIn={() => zoomIn(state)} zoomOut={() => zoomOut(state)} resetTransform={() => reset(resetTransform, state)}/>*/}
              </div>
              <TransformComponent
                wrapperStyle={{
                  // width: "100%",
                  maxWidth:"100%",
                  width: "fit-content",
                  height: "100%",
                }}
                contentStyle={{
                  width: mapWidth,
                  height: mapHeight,
                }}
              >
                <div
                  className="grid-spot-map"
                  style={{
                    width: mapWidth,
                    height: mapHeight,
                  }}
                >
                  <div className="background" onMouseDown={onMouseDownMap && onMouseDownMap}>
                    {media &&
                      <img
                        src={getSrc(media)}
                        width="100%"
                        alt="Map"
                      />
                    }
                  </div>
                  <div className="mappables">
                    {children}
                  </div>
                </div>
              </TransformComponent>
              <div className="control-buttons-below">
                <div className="left">
                  <div className="size">
                    {map.getAreaWidth()}m. breed - {map.getAreaLength()}m. Lengte (hoog)
                  </div>
                </div>
                <div className="right">
                  {!disabled &&
                    <Controls
                      zoomIn={zoomIn}
                      zoomOut={zoomOut}
                      resetTransform={() => reset(resetTransform)}
                    />
                  }
                  {ControlButtons && ControlButtons()}
                </div>
              </div>
            </>
          )
        }}
      </TransformWrapper>
    </div>
  );
}


SpotMap.defaultProps = {
  // children:
}

SpotMap.propTypes = {
  map: PropTypes.object.isRequired,
}

export default SpotMap;
