import useApi from "../../api/useApi";
import useFunction from "../../hooks/useFunction";

const useSpotGroupService = () => {

  const {api} = useApi()
  const {c} = useFunction()

  const userShow = (callbackSuccess, callbackValidationErrors, spotGroupId) => {
    return api(
      'get',
      'user/spot_group/' + spotGroupId,
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error => c('Error in Service', error),
    )
  }

  const userShowCollection = (callbackSuccess, callbackValidationErrors) => {
    return api(
      'get',
      'user/spot_groups',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error => c('Error in Service', error),
    )
  }

  const userUpdate = (callbackSuccess, callbackValidationErrors, spotGroupId, data) => {
    return api(
      'post',
      'user/spot_group/' + spotGroupId + '/update',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error => c('Error in Service', error),
      data,
    )
  }

  const userStore = (callbackSuccess, callbackValidationErrors, spotGroupId, data) => {
    return api(
      'post',
      'user/spot_groups/store',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error => c('Error in Service', error),
      data,
    )
  }

  const userShowManager = (callbackSuccess, callbackValidationErrors, id, callbackFailed) => {
    return api(
      'get',
      'user/spot_group/' + id + '/management/show',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      (error) => callbackFailed(error),
    )
  }

  const filteredShow = (callbackSuccess, callbackValidationErrors, data) => {
    return api(
      'post',
      'spot_groups/filtered',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error => c('Error in Service', error),
      data,
    )
  }

  const show = (callbackSuccess, callbackValidationErrors, slug, callbackFailed) => {
    return api(
      'get',
      'spot_group/show/' + slug,
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      (error) => callbackFailed(error),
    )
  }


  const userDelete = (callbackSuccess, callbackValidationErrors, id) => {
    return api(
      'post',
      'user/spot_group/' + id + '/delete',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error => c('Error in Service', error),
    )
  }

  return {
    objectType: 'spot_group',
    userShow,
    userShowCollection,
    userUpdate,
    userStore,
    userShowManager,
    // userSpotMapShow,
    // userSpotMapUpdate,
    filteredShow,
    show,
    userDelete,
  }
}

export default useSpotGroupService
