/**
 * alright, let's talk about this...
 * Normally with remix, you'd update the params via useSearchParams from react-router-dom
 * and updating the search params will trigger the search to update for you.
 * However, it also triggers a navigation to the new url, which will trigger
 * the loader to run which we do not want because all our data is already
 * on the client and we're just doing client-side filtering of data we
 * already have. So we manually call `window.history.pushState` to avoid
 * the router from triggering the loader.
 */
import {DateObject} from "react-multi-date-picker";

const useQueryString = () => {

  const update = (queryKey, queryValue) => {
    if (queryKey === 'where'){
      update('where.city', queryValue.city)
      update('where.postal_code', queryValue.postal_code)
      update('where.radius', queryValue.radius)
    }

    let currentSearchParams = getURLSearchParams(
      queryKey,
      queryValue,
      new URLSearchParams(window.location.search)
    )
    if (currentSearchParams){
      const newUrl = [window.location.pathname, currentSearchParams.toString()]
        .filter(Boolean)
        .join('?')
      window.history.replaceState(null, '', newUrl)
    }
  }

  const getURLSearchParams = (
    queryKey,
    queryValue,
    currentSearchParams
  ) => {
    if (queryKey === 'where'){
      if (queryValue.city){
        currentSearchParams.set('where.city', queryValue.city)
      }
      if (queryValue.postal_code){
        currentSearchParams.set('where.postal_code', queryValue.postal_code)
      }
      if (queryValue.radius){
        currentSearchParams.set('where.radius', queryValue.radius)
      }
      return currentSearchParams
    }

    if (queryKey === 'map') {
      return currentSearchParams;
    }
    if (Array.isArray(queryValue) && queryValue[0] instanceof DateObject) {
      queryValue = queryValue.map(date => date.format("YYYY-M-D").toString()).join('_')
    } else if (Array.isArray(queryValue)) {
      queryValue = queryValue.join('_')
    }
    const oldQuery = currentSearchParams.get(queryKey) ?? ''
    if (queryValue === oldQuery) return currentSearchParams
    if (queryValue) {
      currentSearchParams.set(queryKey, queryValue)
    } else {
      currentSearchParams.delete(queryKey)
    }

    if (Array.isArray(queryValue) && queryValue.length === 0) {
      currentSearchParams.delete(queryKey)
    }
    return currentSearchParams;
  }

  const getUrl = (filter) => {
    let currentSearchParams = new URLSearchParams(window.location.search)
    Object.entries(filter).map((filterItem) => {
      currentSearchParams = getURLSearchParams(filterItem[0], filterItem[1], currentSearchParams)
    })
    return currentSearchParams.toString()
  }

  const getAll = () => {
    const currentSearchParams = new URLSearchParams(window.location.search)
    const entries = currentSearchParams.entries()
    const array = []
    for (let [key, value] of entries) {

      if (key === 'types' || key === 'industries') {
        value = value.split('_').map(Number)
      }
      if (key === 'when') {
        const valueCopy = value
        value = []
        valueCopy.split('_').map((dateString, i) => {
          value[i] = new DateObject(dateString)
        })
      }
      if (key !== 'map') {
        array.push({key, value})
      }
    }
    return array
  }

  return {
    update,
    getAll,
    getUrl,
  }
}

export default useQueryString
