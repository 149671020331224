import Moment from "moment/moment";
import swal from "sweetalert";
import {debug, backendUrl} from "../env";


const useFunction = () => {

  /**
   * Check if object is empty
   *
   * @param object
   * @returns {boolean}
   */
  const objIsEmpty = (object) => {
    return (Object.keys(object).length === 0)
  }

  /**
   * Get the login token from localstorage
   *
   * @returns {string}
   */
  const getToken = () => {
    return localStorage.getItem('token')
  }

  /**
   * Set the login token in localstorage
   * @param token
   */
  const setToken = (token) => {
    localStorage.setItem('token', token)
  }

  /**
   * Remove the login token from localstorage
   */
  const removeToken = () => {
    localStorage.removeItem('token')
  }

  const setLoginData = (data, callbackSuccess, setUser) => {
    setToken(data.token)
    swal({
      title: 'Success',
      icon: 'success',
      timer: 650,
      buttons: false,
    })
    setUser(prevUser => {
      return {
        ...prevUser,
        ...data.user
      }
    })
    callbackSuccess(data)
  }

  /**
   * Get the full media URL
   * @param mediaUrl
   * @returns {string}
   */
  const mediaUrl = (mediaUrl) => {
    return backendUrl + mediaUrl
  }

  /**
   *
   * @param dateString
   * @returns {string}
   */
  const date = (dateString) => {
    return Moment(dateString).format('DD-MM-YYYY')
  }

  /**
   *
   * @param dateString
   * @returns {string}
   */
  const dateTime = (dateString) => {
    return Moment(dateString).format('DD-MM-YYYY HH:mm:ss')
  }

  /**
   *
   * @param string
   * @param number
   * @returns {string|*}
   */
  const truncate = (string, number) => {
    return (string.length > number) ? string.slice(0, number - 1) + '...' : string;
  }

  /**
   * Combine string with conditional other string
   *
   * //todo implement this everywhere, or delete (its not used anywhere now)
   *
   *
   * @param base string   classname
   * @param bool boolean  condition to add the 'add' string in the classname
   * @param add string    conditional classname(s)
   * @return {*}
   */
  const combineClass = (base, bool, add) => {
    if (bool) {
      base += ' ' + add
    }
    return base
  }

  /**
   * Console.log, but checks for debug flag.
   *
   * @param info
   */
  const c = (...info) => {
    if (debug) {
      console.log(...info)
    }
  }

  /**
   *
   */
  const handleNotYetFinished = () => {
    swal({
      title: 'Nog niet af..',
      icon: 'warning', //todo question
      text: 'We werken hard aan de nieuwe site, maar helaas is nog niet alles af..',
      buttons: {
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'btn-color-green btn-size-m',
          closeModel: true,
        }
      }
    })
  }

  /**
   * Get value of object via path string
   * https://www.30secondsofcode.org/js/s/get/
   *
   * //todo maybe use this in other places (like wizard buttons)?
   * getWithPath(obj, 'selector.to.val', 'target[0]', 'target[2].a');
   *
   * @param obj
   * @param path
   * @returns {*[]}
   */
  const getWithPath = (obj, path) => {
    path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    path = path.replace(/^\./, '');           // strip a leading dot
    let a = path.split('.');
    for (let i = 0, n = a.length; i < n; ++i) {
      let k = a[i];
      if (k in obj) {
        obj = obj[k];
      } else {
        return null;
      }
    }
    return obj;
  }

  /**
   * Set value of object via path string
   *
   * set(obj, 'mongo.db.user', 'root');
   *
   * @param obj
   * @param path
   * @param value
   */
  const helper = (obj, path, value) => {
    // get the current and the remaining keys from the path
    let [current, ...rest] = path;

    // if there are more keys
    // add the value as an object or array
    // depending upon the typeof key
    if (rest.length > 0) {
      // if there is no key present
      // create a new one
      if (!obj[current]) {
        // if the key is numeric
        // add an array
        // else add an object
        const isNumber = `${+rest[0]}` === rest[0];
        obj[current] = isNumber ? [] : {};
      }

      // recursively update the remaining path
      // if the last path is not of object type
      // but key is then
      // create an object or array based on the key
      // and update the value
      if (typeof obj[current] !== 'object') {
        // determine if the key is string or numeric
        const isNumber = `${+rest[0]}` === rest[0];
        obj[current] = helper(isNumber ? [] : {}, rest, value)
      }
      // else directly update value
      else {
        obj[current] = helper(obj[current], rest, value);
      }
    }
    // else directly assing the value to the key
    else {
      obj[current] = value;
    }

    // return the updated obj
    return obj;
  }

  /**
   * Set value of object via path string
   *
   * @param obj
   * @param path
   * @param value
   * @returns {*}
   */
  const setWithPath = (obj, path, value) => {
    let pathArr = path;

    // if path is of string type
    // replace the special characters
    // and split the string on . to get the path keys array
    if (typeof path === 'string') {
      pathArr = path.replace('[', '.').replace(']', '').split(".");
    }

    // use the helper function to update
    return helper(obj, pathArr, value);
  };

  /**
   *
   * @returns {boolean|RegExpMatchArray}
   */
  const isTouchScreen = () => {
    const ua = navigator.userAgent;
    return 'createTouch' in document ||
      ua.match(/(iPhone|iPod|iPad)/) ||
      ua.match(/BlackBerry/) ||
      ua.match(/Android/);
  }

  /**
   *
   * @returns {boolean}
   */
  const isSmallScreen = (windowDimensionWidth, breakPoint = 768) => {
    return windowDimensionWidth <= breakPoint
  }

  /**
   *
   * @param condition
   * @param wrapperA
   * @param wrapperB
   * @param children
   * @returns {*}
   * @constructor
   */
  const ConditionalWrapper = ({condition, wrapperA, wrapperB, children}) => {
    return condition ? wrapperA(children) : wrapperB(children)
  }

  /**
   *
   *
   * @param arr
   * @param id
   * @returns {*}
   */
  const removeObjectWithId = (arr, id) => {
    const objWithIdIndex = arr.findIndex((obj) => obj.id === id);
    arr.splice(objWithIdIndex, 1);
    return arr;
  }

  /**
   * Get array of ids from array of objects, based on id property
   *
   * @param items
   * @param returnNullString
   * @returns {*[]}
   */
  const getIDsArray = (items, returnNullString = true) => {
    const arrayIds = []
    if (returnNullString && items.length === 0) return ['null']
    items.map((spot) => {
      arrayIds.push(spot.id)
      return spot
    })
    return arrayIds
  }

  /**
   *
   * @param object1
   * @param object2
   * @param deepSearch
   * @returns {boolean}
   */
  const isEqualObject = (object1, object2, deepSearch = true) => {

    const objKeys1 = Object.keys(object1);
    const objKeys2 = Object.keys(object2);

    if (objKeys1.length !== objKeys2.length) return false;

    for (let key of objKeys1) {
      const value1 = object1[key];
      const value2 = object2[key];

      const isObjects = isObject(value1) && isObject(value2);

      if ((isObjects && !isEqualObject(value1, value2, deepSearch)) ||
        (!isObjects && value1 !== value2)
      ) {
        return false;
      }
    }
    return true;
  }

  /**
   *
   * @param object
   * @returns {boolean}
   */
  const isObject = (object) => {
    return object != null && typeof object === 'object';
  }


  /**
   * @deprecated not used right now.
   * @param customLabel
   * @param error
   */
  const showErrorPopup = (customLabel, error) => {
    swal({
      title: customLabel,
      icon: 'error',
      text: error,
      buttons: null,
      //   confirm: {
      //     text: 'OK',
      //     value: true,
      //     visible: true,
      //     className: 'btn-color-green btn-size-m',
      //     closeModel: true,
      //   }
      // }
    })
  }

  return {
    c,
    objIsEmpty,
    getToken,
    setToken,
    setLoginData,
    removeToken,
    mediaUrl,
    combineClass,
    date,
    dateTime,
    truncate,
    handleNotYetFinished,
    getWithPath,
    setWithPath,
    isTouchScreen,
    isSmallScreen,
    ConditionalWrapper,
    removeObjectWithId,
    getIDsArray,
    isEqualObject,
    isObject,
    showErrorPopup,
  }
}
export default useFunction
