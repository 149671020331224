import React, {useState} from "react";
import {Media, Fields, Form, Input, Price, SelectBlock, Textarea} from "../Form/Form";
import initSpotCluster from "../../data/initSpotCluster";
import initialSpotMap from "../../data/initSpotMap";
import ResizableRect from "react-resizable-rotatable-draggable";
import industries from "../../data/industries";
import initSpot from "../../data/initSpot";
import AddIconWhite from "../../assets/icons/add-white.svg";
import useSpotClusterService from "../../api/services/useSpotClusterService";
import RelationCheckboxes from "../RelationCheckboxes/RelationCheckboxes";
import useSpotMapService from "../../api/services/useSpotMapService";
import useSpotService from "../../api/services/useSpotService";
import useSpotMapForm from "../../hooks/useSpotMapForm";
import Availabilities from "../Form/Availabilities";
import useBookable from "../../hooks/useBookable";
// import FormButtons from "../FormButtons/FormButtons";
import useSpotMap from "../../hooks/useSpotMap";
import useForm from "../../hooks/useForm";
import SpotMap from "../SpotMap/SpotMap";
import Loading from "../Loading/Loading";
import Button from "../Button/Button";
import Three from "../Form/Three";
import Popup from "../Popup/Popup";
import './style.scss'

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SpotMapForm = (props) => {
  const spotMapForm = useSpotMapForm(props)
  return (
    <div className="component-spot-map-form">
      <div className="inner">
        {spotMapForm.spotGroup && !spotMapForm.isLoading ?
          <SpotMapPublic
            spotMapForm={spotMapForm}
          />
          :
          <Loading/>
        }
      </div>
    </div>
  )
}


/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SpotMapPublic = (props) => {
  const {spotMapForm} = props
  const spotGroup = spotMapForm.spotGroup
  const map = useSpotMap(spotGroup)
  const [showPopupAddSpot, setShowPopupAddSpot] = useState(false)
  const [showPopupAddSpotCluster, setShowPopupAddSpotCluster] = useState(false)
  const [showPopupEditSpotCluster, setShowPopupEditSpotCluster] = useState(false)
  const [showPopupEditSpot, setShowPopupEditSpot] = useState(false)
  const [showPopupAddSpotMap, setShowPopupAddSpotMap] = useState(false)
  const [showPopupEditSpotMap, setShowPopupEditSpotMap] = useState(false)
  const spotMapService = useSpotMapService()
  const [menuOtherSide, setMenuOtherSide] = useState(true)
  const {isSpotCluster, isSpot} = useBookable()
  const spotForm = useForm()
  const spotService = useSpotService()
  const spotMapFormForm = useForm()
  const spotClusterForm = useForm()
  const spotClusterService = useSpotClusterService()

  const SpotMapPopup = (
    id,
    label,
    Trigger,
    stateShowPopup,
    setStateShowPopup,
  ) => {
    return (
      <Popup
        title={label}
        showPopup={stateShowPopup}
        setShowPopup={setStateShowPopup}
        trigger={Trigger}
      >
        <Form>
          <Fields
            id={id}
            initData={{...initialSpotMap, spot_group_id: spotGroup.id}}
            form={spotMapFormForm}
            apiService={spotMapService}
            updateSuccess={(newSpotMap) => {
              setStateShowPopup(false)
              if (id) {
                spotMapForm.form.formData.model.spot_maps.map((spotMap, i) => {
                  if (spotMap.id === newSpotMap.id) {
                    spotMapForm.form.formData.model.spot_maps[i] = newSpotMap
                  }
                  return spotMap
                })
                spotMapForm.form.save(false)
              } else {
                spotMapForm.form.formData.model.spot_maps.push(newSpotMap)
                spotMapForm.form.save(false)
              }
            }}
            showDelete={(spotMapForm.form.formData.model.spot_maps && spotMapForm.form.formData.model.spot_maps.length > 1 && id)}
            deleteLabel="Hiermee verwijder je deze spot kaart:"
            deleteSuccess={() => {








              // spotMap.setActiveSpotMapIndex(0)







              setStateShowPopup(false)
              spotMapForm.form.formData.model.spot_maps.map((spotMap, i) => {
                if (spotMap.id === id) {
                  spotMapForm.form.formData.model.spot_maps.splice(i, 1);
                }
                return spotMap
              })
              spotMapForm.form.save(false)
            }}
          >
            <Input
              label="Label"
              name="label"
            />
            <p>Stel je voor dat er een vierkant of rechthoek op je terrein ligt. Wat zijn dan de lengte
              en
              breedte? Vul die hieronder in.</p>
            <Input
              label="Volgorde (hoogste cijfers eerst)"
              name="sort"
              type="number"
              value={spotGroup.spot_maps[map.activeSpotMapIndex].order}
              onChange={spotMapForm.onchangeInputSpotGroup}
            />
            <Input
              label="Terrein lengte (verticaal)"
              name="area_length"
              type="meter"
              value={spotGroup.spot_maps[map.activeSpotMapIndex].area_length}
              onChange={spotMapForm.onchangeInputSpotGroup}
            />
            <Input
              label="Terrein breedte (horizontaal)"
              name="area_width"
              type="meter"
              value={spotGroup.spot_maps[map.activeSpotMapIndex].area_width}
              onChange={spotMapForm.onchangeInputSpotGroup}
            />
            <hr/>
            <h3>Afbeeldingen</h3>
            <Media
              label="Terrein achtergrond"
              name="spot_map_media"
              width={900}
              size="normal_full_height"
            />
          </Fields>
        </Form>
      </Popup>
    )
  }

  const SpotPopup = (
    id,
    label,
    Trigger,
    stateShowPopup,
    setStateShowPopup,
    isNotSaved,
    spotClusterId,
  ) => {
    return (
      <Popup
        title={label}
        showPopup={stateShowPopup}
        setShowPopup={setStateShowPopup}
        trigger={Trigger}
      >
        <Form>
          <Fields
            id={id}
            onRender={(form) => {
              //force showing save buttons by setting 'isChanged' to true
              isNotSaved && form.save(true)
            }}
            initData={{
              ...initSpot,
              spot_cluster_id: spotClusterId,
              spot_group_id: spotGroup.id,
            }}
            form={spotForm}
            apiService={spotService}
            onReset={() => {
              setStateShowPopup(false)
            }}
            updateSuccess={(newSpot) => {
              setStateShowPopup(false)
              if (id) {
                spotMapForm.form.formData.model.spots.map((spot, i) => {
                  if (spot.id === newSpot.id) {
                    spotMapForm.form.formData.model.spots[i] = newSpot
                  }
                  return spot
                })
                spotMapForm.saveSpotGroup(spotMapForm.form.formData.model)
              } else {
                spotMapForm.form.formData.model.spots.push(newSpot)
                spotMapForm.saveSpotGroup(spotMapForm.form.formData.model)
              }
            }}
            preService={(updateSpot) => {
              spotMapForm.form.formData.model.spots.map((spot, i) => {
                if (spot.id === updateSpot.id) {
                  updateSpot.label = spotMapForm.form.formData.model.spots[i].label
                  updateSpot.width = spotMapForm.form.formData.model.spots[i].width
                  updateSpot.length = spotMapForm.form.formData.model.spots[i].length
                  updateSpot.top = spotMapForm.form.formData.model.spots[i].top
                  updateSpot.left = spotMapForm.form.formData.model.spots[i].left
                }
                return spot
              })
              return updateSpot
            }}
            showDelete={(
              spotMapForm.form.formData.model.spots &&
              spotMapForm.form.formData.model.spots.length > 1 &&
              id
            )}
            deleteLabel="Hiermee verwijder je deze Standplaats:"
            deleteSuccess={
              () => {






                // spotMap.setActiveSpotMapIndex(0)







                spotMapForm.setSelectedMappable(null)
                setStateShowPopup(false)
                spotMapForm.form.formData.model.spots.map((spot, i) => {
                  if (spot.id === id) {
                    spotMapForm.form.formData.model.spots.splice(i, 1);
                  }
                  return spot
                })
                // form.save(false)
                spotMapForm.saveSpotGroup(spotMapForm.form.formData.model)
              }
            }
          >
            {!id &&
              <Input
                label="Label"
                name="label"
              />
            }
            {!id &&
              <Input
                label="Lengte (verticaal)"
                name="length" //note: 'length' is used in the database and backend. 'height' is used in the front-end.
                type="meter"
                step="1"
              />
            }
            {!id &&
              <Input
                label="Breedte (horizontaal)"
                name="width"
                type="meter"
                step="1"
              />
            }
            <Textarea
              label="Beschrijving"
              name="description"
            />
            <hr/>
            <h3>Branche & Producten</h3>
            <SelectBlock
              label="Alle producten/branches die je zoekt voor DEZE Standplaats/Spot/Plek"
              name="industries_ids"
              data={industries}
            />
            <hr/>
            <h3>Prijs</h3>
            <Price
              // label="Prijs voor specifiek deze Standplaats/Spot/Plek"
              name="price"
            />
            <hr/>
            <h3>Beschikbaarheid</h3>
            <Three
              label="Is deze Standplaats elke dag beschikbaar?"
              name="available"
              labelYes="Elke dag beschikbaar"
              labelNo="(nog) niet beschikbaar (niet boekbaar en niet in zoekresultaten)"
              labelOther="Specifieke dag(en) ingeven"
            />
            <Availabilities
              label="Beschikbaarheid specifiek voor deze Standplaats/Spot/Plek"
              name="availabilities"
              enabledBy={[{key: 'available', value: 2}]}
            />
            <hr/>
            <h3>Afbeeldingen</h3>
            <Media
              label="Afbeelding van deze Standplaats/Spot/Plek"
              name="overview_media"
              width={900}
              height={450}
              size="normal"
            />
          </Fields>
        </Form>
      </Popup>
    )
  }

  const SpotClusterPopup = (
    spotCluster,
    label,
    Trigger,
    showPopupEditSpotCluster,
    setShowPopupEditSpotCluster,
    isNotSaved,
  ) => {

    return (
      <>
        <Popup
          title={label}
          showPopup={showPopupEditSpotCluster}
          setShowPopup={setShowPopupEditSpotCluster}
          trigger={Trigger}
        >
          <Form>
            <Fields
              id={(spotCluster) ? spotCluster.id : null}
              onRender={(form) => {
                //force showing save buttons by setting 'isChanged' to true
                isNotSaved && form.save(true)
              }}
              initData={{
                ...initSpotCluster,
                spot_group_id: spotGroup.id,
              }}
              onReset={() => {
                setShowPopupEditSpotCluster(false)
              }}
              form={spotClusterForm}
              apiService={spotClusterService}
              updateSuccess={(newSpotCluster) => {
                setShowPopupEditSpotCluster(false)
                if (spotCluster && spotCluster.id) {
                  spotMapForm.form.formData.model.spot_clusters.map((spotCluster, i) => {
                    if (spotCluster.id === newSpotCluster.id) {
                      spotMapForm.form.formData.model.spot_clusters[i] = newSpotCluster
                    }
                    return spotCluster
                  })
                  spotMapForm.saveSpotGroup(spotMapForm.form.formData.model)
                } else {
                  spotMapForm.form.formData.model.spot_clusters.push(newSpotCluster)
                  spotMapForm.saveSpotGroup(spotMapForm.form.formData.model)
                }
              }}
              onChange={(updateSpotCluster) => {


                console.log('updateSpotCluster', updateSpotCluster)


                return updateSpotCluster
              }}
              // preService={(updateSpotCluster) => {
              //   form.formData.model.spot_clusters.map((spotCluster, i) => {
              //     if (spotCluster.id === updateSpotCluster.id) {
              //       updateSpotCluster.label = form.formData.model.spot_clusters[i].label
              //       updateSpotCluster.width = form.formData.model.spot_clusters[i].width
              //       updateSpotCluster.length = form.formData.model.spot_clusters[i].length
              //       updateSpotCluster.top = form.formData.model.spot_clusters[i].top
              //       updateSpotCluster.left = form.formData.model.spot_clusters[i].left
              //     }
              //     return spotCluster
              //   })
              //   return updateSpotCluster
              // }}
              showDelete={(spotCluster)}
              deleteLabel="Hiermee verwijder je deze Spot Cluster:"
              deleteSuccess={
                () => {
                  spotMapForm.setSelectedMappable(null)
                  setShowPopupEditSpotCluster(false)
                  spotMapForm.form.formData.model.spot_clusters.map((item, i) => {
                    if (item.id === spotCluster.id) {
                      spotMapForm.form.formData.model.spot_clusters.splice(i, 1);
                    }
                    return spotCluster
                  })
                  // form.save(false)
                  spotMapForm.saveSpotGroup(spotMapForm.form.formData.model)
                }
              }
            >
              {!spotCluster &&
                <Input
                  label="Label"
                  name="label"
                />
              }
              {!spotCluster &&
                <Input
                  label="Lengte (verticaal)"
                  name="length" //note: 'length' is used in the database and backend. 'height' is used in the front-end.
                  type="meter"
                  step="1"
                />
              }
              {!spotCluster &&
                <Input
                  label="Breedte (horizontaal)"
                  name="width"
                  type="meter"
                  step="1"
                />
              }
              {spotCluster &&
                <SpotMapPublicCluster
                  spotCluster={spotCluster}
                  spots={spotGroup.spots.filter(item => item.spot_cluster_id === spotCluster.id)}
                  spotMapForm={spotMapForm}
                  showPopupAddSpot={showPopupAddSpot}
                  setShowPopupAddSpot={setShowPopupAddSpot}
                  SpotPopup={SpotPopup}
                />
              }
              {spotCluster && spotMapForm.selectedMappable && isSpot(spotMapForm.selectedMappable) &&
                <h4>Standplaats opties:</h4>
              }
              {spotCluster &&
                SpotMapSpotMenu(false)
              }
            </Fields>
          </Form>
        </Popup>
      </>
    )
  }


  const SpotMapSpotMenu = (hideIfPopupEditSpotClusterIsOpen = true) => {
    if (hideIfPopupEditSpotClusterIsOpen && showPopupEditSpotCluster) {
      return
    }

    return (
      <div
        className={(showPopupEditSpotCluster) ? "spot-menu spot-cluster" : "spot-menu"}
        style={(menuOtherSide) ? {right: 0} : {left: 0}}
      >
        {!showPopupEditSpotCluster &&
          <Button
            label={(menuOtherSide) ? "<<" : ">>"}
            size="s"
            color="grey-ghost"
            onClick={() => setMenuOtherSide(!menuOtherSide)}
          />
        }
        <div className="spot-options">
          {
            spotMapForm.selectedMappable &&
            spotGroup &&
            <>
              <Input
                label="Label"
                name="label"
                value={spotMapForm.selectedMappable.label}
                onChange={(e) => spotMapForm.onchangeInput(e, map)}
              />
              <Input
                label="Lengte (verticaal)"
                name="length" //note: 'length' is used in the database and backend. 'height' is used in the front-end.
                type="meter"
                step="1"
                value={spotMapForm.selectedMappable.length}
                onChange={(e) => spotMapForm.onchangeInput(e, map)}
              />
              <Input
                label="Breedte (horizontaal)"
                name="width"
                type="meter"
                step="1"
                value={spotMapForm.selectedMappable.width}
                onChange={(e) => spotMapForm.onchangeInput(e, map)}
              />
              <Input
                label="Draaien"
                name="rotate"
                type="graden"
                step="1"
                value={spotMapForm.selectedMappable.rotate}
                onChange={(e) => spotMapForm.onchangeInput(e, map)}
              />
              <Input
                label="Coördinaat: verticaal (vanaf Boven)"
                name="top"
                type="meter"
                step="1"
                value={spotMapForm.selectedMappable.top}
                onChange={(e) => spotMapForm.onchangeInput(e, map)}
              />
              <Input
                label="Coördinaat: horizontaal (vanaf Links)"
                name="left"
                type="meter"
                step="1"
                value={spotMapForm.selectedMappable.left}
                onChange={(e) => spotMapForm.onchangeInput(e, map)}
              />

              <RelationCheckboxes
                label="Weergeven op kaarten:"
                setSpotGroup={spotMapForm.setSpotGroup}
                spotGroup={spotGroup}
                setIsChanged={spotMapForm.setIsChanged}
                mappable={spotMapForm.selectedMappable}
              />
              <br/>

              {isSpot(spotMapForm.selectedMappable) && SpotPopup(
                spotMapForm.selectedMappable.id,
                "Standplaats opties",
                <Button
                  label="Meer opties: Prijs, Branche, etc"
                  color="grey-ghost"
                  size="s"
                />,
                showPopupEditSpot,
                setShowPopupEditSpot,
              )}

              {isSpotCluster(spotMapForm.selectedMappable) &&
                <Button
                  label="Standplaatsen beheren"
                  color="grey-ghost"
                  size="s"
                  onClick={() => {
                    setShowPopupEditSpotCluster(spotMapForm.selectedMappable)
                    spotMapForm.setSelectedMappable(null)
                  }}
                />
              }
            </>
          }
        </div>
      </div>
    )
  }

  return (
    <div className="">
      <div className="">
        <SpotMap
          map={map}
          onMouseDownMap={(e) => spotMapForm.removeSelected()}
          ControlButtons={() => {
            return (
              <>
                {SpotPopup(
                  null,
                  "Standplaats Toevoegen",
                  <Button
                    label="Standplaats"
                    icon={AddIconWhite}
                    size="s"
                  />,
                  showPopupAddSpot,
                  setShowPopupAddSpot,
                  true,
                )}
                {SpotClusterPopup(
                  null,
                  'Groep',
                  <Button
                    label="Groep"
                    icon={AddIconWhite}
                    size="s"
                  />,
                  showPopupAddSpotCluster,
                  setShowPopupAddSpotCluster,
                  true,
                )}
                {SpotMapPopup(
                  map.getActiveSpotMap().id,
                  'Plattegrond instellingen',
                  <Button
                    label="Plattegr. inst."
                    size="s"
                    color="grey"
                  />,
                  showPopupEditSpotMap,
                  setShowPopupEditSpotMap,
                )}
                <Button
                  label="RSP"
                  size="s"
                  color="grey-ghost"
                  onClick={() => spotMapForm.setMappablesInsideArea(map)}
                />
                {!spotMapForm.wizard &&
                  <div className="save-cancel">
                    <Button
                      label="Opslaan"
                      color="green"
                      onClick={spotMapForm.handleSave}
                      disabled={!spotMapForm.isChanged || spotMapForm.isLoading}
                      size="s"
                    />
                    <Button
                      label="Annuleren"
                      color="red"
                      onClick={spotMapForm.handleCancel}
                      disabled={!spotMapForm.isChanged}
                      size="s"
                    />
                  </div>
                }
              </>
            )
          }}
        >
          <>
            {spotGroup.spots && spotGroup.spots.length &&
              <SpotMapPublicSpots
                map={map}
                spots={spotGroup.spots.filter(spot => !spot.spot_cluster_id)} // Filter spots without cluster ID
                spotMapForm={spotMapForm}
              />
            }

            {spotGroup.spot_clusters && spotGroup.spot_clusters.length &&
              <SpotMapPublicSpotClusters
                map={map}
                spotClusters={spotGroup.spot_clusters}
                spotGroup={spotGroup}
                spotMapForm={spotMapForm}
              />
            }
          </>
        </SpotMap>

        {showPopupEditSpotCluster &&
          SpotClusterPopup(
            showPopupEditSpotCluster,
            "Groep opties",
            <></>,
            showPopupEditSpotCluster,
            setShowPopupEditSpotCluster,
            false,
          )
        }
        {spotMapForm.selectedMappable &&
          SpotMapSpotMenu()
        }
        {/*{!spotMapForm.wizard &&*/}
        {/*  <FormButtons*/}
        {/*    isChanged={spotMapForm.isChanged}*/}
        {/*    handleCancel={spotMapForm.handleCancel}*/}
        {/*    handleConfirm={spotMapForm.handleSave}*/}
        {/*    disabledConfirm={!spotMapForm.isChanged || spotMapForm.isLoading}*/}
        {/*    disabledCancel={!spotMapForm.isChanged}*/}
        {/*  />*/}
        {/*}*/}
      </div>
    </div>
  )
}

/**
 *
 * @param spotCluster
 * @param spots
 * @param booking
 * @returns {JSX.Element}
 * @constructor
 */
const SpotMapPublicCluster = ({spotCluster, spots, spotMapForm, showPopupAddSpot, setShowPopupAddSpot, SpotPopup}) => {

  const map = useSpotMap(spotCluster)
  return (

    <div className="sub-component-spot-map-form-spot-map-public-cluster">
      <SpotMap
        map={map}
        ControlButtons={() => {
          return (
            <>
              {SpotPopup(
                null,
                "Standplaats Toevoegen",
                <Button
                  label="Standplaats"
                  icon={AddIconWhite}
                  size="s"
                />,
                showPopupAddSpot,
                setShowPopupAddSpot,
                true,
                spotCluster.id
              )}
              <Button
                label="Binnenhalen"
                size="s"
                color="grey-ghost"
                onClick={() => spotMapForm.setMappablesInsideArea(map)}
              />
            </>
          )
        }}
      >
        <SpotMapPublicSpots
          map={map}
          spots={spots}
          spotMapForm={spotMapForm}
        />
      </SpotMap>
    </div>
  )
}

/**
 *
 * @param map
 * @param spots
 * @param spotMapForm
 * @returns {JSX.Element}
 * @constructor
 */
const SpotMapPublicSpots = ({map, spots, spotMapForm}) => {
  return (
    <>
      {spots.map((spot, i) => {
        const style = map.getMappableStyle(spot)
        return (
          <div
            key={i}
            className={(spotMapForm.isSelected(spot)) ? ' mappable spot selected ' : ' mappable spot '}
            style={{
              ...map.getMappableStyle(spot), ...{
                outlineWidth: `${2 / map.zoomLevel}px`,
              }
            }}
            onMouseDown={() => spotMapForm.addSelected(spot)}
          >
            <div
              className="label"
              style={{
                fontSize: `${16 / map.zoomLevel}px`,
                transform: 'rotate('+(360-spot.rotate)+'deg)'
              }}
            >{spot.label}</div>
            <ResizableRect
              key={i}
              left={style.left}
              top={style.top}
              width={style.width}
              height={style.height} //ResizableRect.height === spot.length
              rotateAngle={style.rotate}
              minWidth={0.5 * map.scaleFactor}
              minHeight={0.5 * map.scaleFactor}
              zoomable='n, w, s, e, nw, ne, se, sw'
              onRotate={(rotateAngle) => spotMapForm.handleRotate(spot, rotateAngle, map)}
              onResize={(style, isShiftKey, type) => spotMapForm.handleResize(spot, style, map)}
              onDrag={(deltaX, deltaY) => spotMapForm.handleDrag(spot, deltaX, deltaY, map)}
            />
          </div>
        )
      })}
    </>
  )
}

/**
 *
 * @param map
 * @param spotClusters
 * @param spotGroup
 * @param spotMapForm
 * @returns {JSX.Element}
 * @constructor
 */
const SpotMapPublicSpotClusters = ({map, spotClusters, spotGroup, spotMapForm}) => {
  return (
    <>
      {spotClusters.map((spotCluster, i) => {
        const style = map.getMappableStyle(spotCluster)
        return (
          <div
            key={i}
            className={(spotMapForm.isSelected(spotCluster)) ? ' mappable spot-cluster selected ' : ' mappable spot-cluster '}
            style={{
              ...map.getMappableStyle(spotCluster), ...{
                outlineWidth: `${2 / map.zoomLevel}px`,
              }
            }}
            onMouseDown={() => spotMapForm.addSelected(spotCluster)}
          >
            <div
              className="label"
              style={{
                fontSize: `${16 / map.zoomLevel}px`,
                transform: 'rotate('+(360-spotCluster.rotate)+'deg)'

              }}
            >{spotCluster.label}</div>
            <ResizableRect
              key={i}
              left={style.left}
              top={style.top}
              width={style.width}
              height={style.height} //ResizableRect.height === spot.length
              rotateAngle={style.rotate}
              minWidth={0.5 * map.scaleFactor}
              minHeight={0.5 * map.scaleFactor}
              zoomable='n, w, s, e, nw, ne, se, sw'
              onRotate={(rotateAngle) => spotMapForm.handleRotate(spotCluster, rotateAngle, map)}
              onResize={(style, isShiftKey, type) => spotMapForm.handleResize(spotCluster, style, map)}
              onDrag={(deltaX, deltaY) => spotMapForm.handleDrag(spotCluster, deltaX, deltaY, map)}
            />
          </div>
        )
      })}
    </>
  )
}

export default SpotMapForm
