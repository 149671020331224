import React, {useContext} from "react";
import AuthContext from "../../context/AuthContext";
import standIconWhite from "../../assets/icons/stand-white.svg";
import standIcon from "../../assets/icons/stand.svg";
import useLocationState from "../../hooks/useLocationState";
import SelectBookable from "./SelectBookable";
import Button from "../Button/Button";
import Tile from "../Tile/Tile";

/**
 * A container component to change the props of SelectBookable
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const BookingSelectStand = (props) => {
  const {getWizardBookableState} = useLocationState()
  const {user} = useContext(AuthContext)

  let selectBookableProps = {...props}
  selectBookableProps.label = "Selecteer één Stand of Truck voor deze aanvraag:"
  selectBookableProps.bookableName = "stand"
  selectBookableProps.name = "stand_id"
  selectBookableProps.bookables = props.stands

  /*
   * SelectStand
   *   stand-requests-spot
   *     receiving
   *       = showing for spotGroup, that is receiving a booking by a stand. so,
   *       show the stand <Tile>
   *     requesting
   *       = showing for stand, that is requesting a booking on a spotGroup. so,
   *       show SelectBookable (with all owned Stands)
   *
   *   spot-group-requests-stand
   *     receiving
   *       = showing for Stand, that is receiving a booking by a spotGroup. so,
   *       show the stand <Tile>
   *     requesting
   *       = showing for spotGroup that is requesting a stand
   *       show the stand <Tile>
   */
  return (
    <>
      {props.model.type === 'stand-requests-spot' &&
        <>
          {(props.model.direction === 'receiving' || props.model.status !== 'concept') &&
            <>
              <h1>Aangevraagd door:</h1>
              <Tile
                bookable={props.model.stand}
                showEditButton={user && user.active_company_id === props.model.stand.company_id}
              />
            </>
          }
          {props.model.direction === 'requesting' && props.model.status === 'concept' &&
            <>{props.stands && !!props.stands.length
              ? <>
                <h1><img
                  src={standIcon}
                  alt="Markten etc"
                  style={{width: 24}}
                /> Alle Stands & Trucks</h1>
                <SelectBookable {...selectBookableProps}/>
                <Button
                  label="Stand of Truck Aanmaken"
                  link="/wizard/stand/new"
                  icon={standIconWhite}
                  // linkState={getWizardBookableState(props.wizard)}
                  linkState={getWizardBookableState()}
                />
              </>
              :
              <>
                <p>Je hebt nog geen Stands of Trucks aangemaakt/gekoppeld aan je account.</p>
                <Button
                  label="Toevoegen"
                  link="/wizard/stand/new"
                  icon={standIconWhite}
                  // linkState={getWizardBookableState(props.wizard)}
                  linkState={getWizardBookableState()}
                />
              </>
            }</>
          }
        </>
      }
      {props.model.type === 'spot-group-requests-stand' &&
        <>
          {props.model.direction === 'receiving' &&
            <>
              <h1>Geselecteerde Stand of Truck:</h1>
              <Tile
                bookable={props.model.stand}
                showEditButton={user && user.active_company_id === props.model.stand.company_id}
              />
            </>
          }
          {props.model.direction === 'requesting' &&
            <>
              <h1>Geselecteerde Stand of Truck:</h1>
              <Tile
                bookable={props.model.stand}
                showEditButton={user && user.active_company_id === props.model.stand.company_id}
              />
            </>
          }
        </>
      }
    </>
  )
}

export default BookingSelectStand
