import React, {useEffect, useState} from 'react'
import {gsap} from "gsap";
import Button from "../Button/Button";
import PropTypes from "prop-types";
import {ScrollToPlugin} from "gsap/ScrollToPlugin"
import arrowBackIcon from '../../assets/icons/arrow-back-white.svg'
import './style.scss'


const BlockList = (props) => {
  const {
    data,
    state,
    setStateCallback,
    selectParents,
    selectChildren,
    handleClickSelectAll,
    handleClickDeselectAll,
    scrolling,
  } = props

  let {dept} = props
  // let firstTimeRender = true
  dept = dept || 0
  dept++

  /**
   * FAKE state to force re-render
   */
  const [empty, setEmpty] = useState(true)
  const [parentTitle, setParentTitle] = useState('')
  // const containerRef = useRef(null);
  gsap.registerPlugin(ScrollToPlugin);

  useEffect(() => {
    //todo can be better, now only shows first list.
    //todo this element has a design flaw: its a recursive component without a container.. only god knows why its like it is..

    hideChildren()
    showChildren(0)
  }, [])


  useEffect(() => {
    data.map((item) => {
      item.showChildren = false;
      item.isActive = false;
      return item
    })

  }, [data])

  /**
   * Handle click event and set showChildren to true
   *
   * @param i
   */
  const handleClickShow = (i) => {
    // firstTimeRender = false
    if ('children' in data[i]) {
      hideChildren()
      showChildren(i)
      setEmpty(!empty) //FAKE state to force re-render
    }
  }

  /**
   *
   */
  const hideChildren = () => {
    data.map((item) => {
      item.showChildren = false;
      item.isActive = false;
      return item
    })
  }

  /**
   *
   * @param i
   */
  const showChildren = (i) => {

    data[i].showChildren = true
    if ('children' in data[i]) {
      scrolling && scrollToChildren(i)
      data[i].isActive = true
    }
  }

  const scrollToChildren = (i) => {
    //170px is the width of the block
    const blockWidth = 170
    let scrollDept = 1
    setParentTitle(data[i].label)
    gsap.to('.component-block-list .container.dept-'+scrollDept, {duration: 0.3, scrollTo: {x: (scrollDept*blockWidth)}});
  }

  const scrollToParent = () => {
    setParentTitle('')
    let scrollDept = 1
    gsap.to('.component-block-list .container.dept-'+scrollDept, {duration: 0.3, scrollTo: {x: (0)}});
  }

  /**
   * Handle click event on a select box
   * adds/removes if of element to state []
   *
   * @param item
   */
  const handleClickSelect = (item) => {
    const selectedState = state.includes(item.id)

    // recursivelyCheckIfParentIsSelected(item, !selectedState)

    if (selectedState) {
      state.splice(state.indexOf(item.id), 1)
    } else {
      selectWithParents(data, item.id)
    }
    setStateCallback(state)
  }


  /**
   * Recursively check if parent is selected
   *
   * //todo finish this method...?
   */
  // const recursivelyCheckIfParentIsSelected = (data, bool) => {
  //   if (selectChildren && 'children' in data) {
  //
  //     data.children.map((child) => {
  //       child.parentSelected = !state.includes(data.id)
  //
  //       recursivelyCheckIfParentIsSelected(child, bool)
  //       return child
  //     })
  //   }
  // }



  /**
   * Ads ID of item + parent data to state array,
   *
   * //todo BUG nto adding all the parents (only adds 1e parent)
   * //but it's fixed by the backend, so low prio for now.
   *
   * @param data
   * @param id
   */
  const selectWithParents = (data, id) => {
    data.map((item) => {
      if (item.id === id) {
        if (!state.includes(id)) {
          state.push(id)
        }
        if (selectParents && 'parent_id' in item) {
          if (!state.includes(item.parent_id)) {
            state.push(item.parent_id)
          }
          selectWithParents(data, item.parent_id)
        }
      }
      if ('children' in item) {
        selectWithParents(item.children, id)
      }
    })
  }

  /**
   *
   *
   * @param item
   * @return {string}
   */
  const blockClassName = (item) => {

    // console.log('item', item)
    let className = 'block block-list-item-id-'+item.id+' '

    // if (item.parentSelected){
    //   className += ' parentSelected '
    // }

    if (state.includes(item.id)) {
      className += ' selected '
    }
    if ('children' in item) {
      className += ' has-children '
      if (selectParents && checkIfChildrenAreSelected(item)) {
        className += ' has-selected-children '
      }
    }
    if (item.isActive) {
      className += ' active '
    }
    return className
  }

  /**
   * Check if the children of a block are selected
   *
   * @param item
   * @param hasSelectedChildren
   * @return {boolean}
   */
  const checkIfChildrenAreSelected = (item, hasSelectedChildren = false) => {
    item.children.map((child) => {
      if (state.includes(child.id)) {
        hasSelectedChildren = true
      }
      if ('children' in child) {
        hasSelectedChildren = checkIfChildrenAreSelected(child, hasSelectedChildren)
      }
    })
    return hasSelectedChildren;
  }

  return (
    <div className={(scrolling)?"component-block-list scrolling-enabled":"component-block-list"}>
      {dept === 1 && scrolling &&
        <div className="top-buttons">
          <Button
            label="Terug"
            size="s"
            icon={arrowBackIcon}
            color="grey"
            onClick={scrollToParent}
            // disabled={(parentTitle === '')}
          />
          <div className="parent-title">{parentTitle}</div>
        </div>
      }

      <div
        className={"container dept-" + dept}
        // ref={(dept===1)?containerRef:null}
        // ref={containerRef}
      >
        <div className={'list dept-' + dept}>
          {data &&
            data.map((item, i) => {
              // if (i === 0 && firstTimeRender){
              //   showChildren(i)
              // }
              return (
                <div
                  key={i}
                  className={blockClassName(item)}
                  onClick={(e) => handleClickShow(i, dept)}
                >
                  <div className="block-content">
                    <div
                      className="checkbox"
                      onClick={(e) => handleClickSelect(item)}
                    >
                    </div>
                    <div className="label">{item.label}</div>
                  </div>
                </div>
              )
            })
          }
        </div>
        {data &&
          data.map((item, i) => {
            return ('children' in item && item.showChildren &&
              <div key={i} className="children">
                <BlockList
                  data={item.children}
                  selectChildren={selectChildren}
                  dept={dept}
                  state={state}
                  setStateCallback={setStateCallback}
                  selectParents={selectParents}
                  scrolling={scrolling}
                />
              </div>)
          })
        }
      </div>

      {dept === 1 && handleClickSelectAll && handleClickDeselectAll &&
        <div className="buttons">
          <Button
            label="Alles selecteren"
            size="xs"
            color="grey-ghost"
            onClick={handleClickSelectAll}
          />
          <Button
            label="Alles deselecteren"
            size="xs"
            color="grey-ghost"
            onClick={handleClickDeselectAll}
          />
        </div>
      }
    </div>
  )
}


BlockList.defaultProps = {
  selectParents: true,
  selectChildren: false,
}

BlockList.propTypes = {
  selectParents: PropTypes.bool,
  selectChildren: PropTypes.bool,
}

export default BlockList
