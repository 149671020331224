import React, {useEffect} from "react";
import PropTypes from "prop-types";
import useBookable from "../../hooks/useBookable";
import './Price-style.scss'

const Price = (props) => {

  const {
    // label,
    // name,
    // text,
    value,
    className,
    hasError,
    error,
    isLoading,
    formData,
    setFormData,
    save,
    fixedType,
  } = props

  const realType = 'number'
  const step = "0.01"
  const lang = "nl-NL"
  const maxStep = "500000" // 500.000 max mollie payment https://help.mollie.com/hc/en-us/articles/115000667365-What-is-the-minimum-and-maximum-amount-per-payment-method-

  const {isStand, isSpotGroup, isSpot} = useBookable()

  useEffect(() => {
    if (fixedType) {
      value.type = fixedType
      save(false)
    }
  }, [fixedType])

  /**
   * Get the label for the price field
   *
   * @returns {string}
   */
  const getPriceLabel = () => {
    switch (value.type) {
      case 'positive':
        return 'Welk bedrag wil je ontvangen per geboekte dag?'
      case 'negative':
        return 'Welk bedrag ben je bereid te betalen per geboekte dag?'
      case 'negotiate':
        return ''
      case 'relative' && isStand(formData.model):
        return 'Welk percentage van de omzet ben je bereid af te dragen?'
      case 'relative' && isSpotGroup(formData.model):
        return 'Welk percentage van de omzet vraag je van de Stand/Truck?'
      case 'relative' && isSpot(formData.model):
        return 'Welk percentage van de omzet vraag je van de Stand/Truck?'
      default:
        break
    }
  }

  /**
   * Get the afterLabel for the price field
   * @returns {string}
   */
  const getAfterLabel = () => {
    switch (value.type) {
      case 'positive':
        return 'Krijg € Euro, ex. BTW, p/dag'
      case 'negative':
        return 'Betaal € Euro, ex. BTW, p/dag'
      case 'negotiate':
        return ''
      case 'relative':
        return '% van de omzet, ex. BTW'
      default:
        break
    }
  }

  /**
   *
   * @returns {string}
   */
  const getTypePositiveLabel = () => {
    return 'Verdien: U krijgt betaald voor de boeking'
  }

  /**
   *
   * @returns {string}
   */
  const getTypeNegativeLabel = () => {
    return 'Betaal: U betaalt voor de boeking'
  }

  /**
   *
   * @returns {string}
   */
  const getTypeNegotiateLabel = () => {
    return 'Overleg: Prijs is in overleg (n.o.t.k.)'
  }

  /**
   *
   * @returns {string}
   */
  const getTypeRelativeLabel = () => {
    return 'Omzet: Prijs hangt af van de omzet (percentage)'
  }

  /**
   * Check if the value has a weekday value
   *
   * @returns {boolean}
   */
  const hasWeekdayValue = () => {
    return !!(
      value['mo'] !== null ||
      value['tu'] !== null ||
      value['we'] !== null ||
      value['th'] !== null ||
      value['fr'] !== null ||
      value['sa'] !== null ||
      value['su'] !== null
    )
  }

  /**
   *
   */
  const onChangeShowOverridePrice = () => {
    value['mo'] = (hasWeekdayValue()) ? null : value.default
    value['tu'] = null
    value['we'] = null
    value['th'] = null
    value['fr'] = null
    value['sa'] = null
    value['su'] = null
    save()
  }

  /**
   * Change the type of the price
   *
   * @param e
   */
  const onChangeType = (e) => {
    value['type'] = e.target.value
    save()
  }

  /**
   *
   * @param e
   */
  const onChange = (e) => {
    e.preventDefault()
    value[e.target.name] = (e.target.value) ? e.target.value : 0
    save()
  }

  /**
   *
   * @param key
   * @returns {*|number}
   */
  const getValue = (key) => {
    return (!isLoading && value && key in value && value[key]) ? value[key] : 0
  }

  /**
   *
   * @param key
   * @returns {boolean}
   */
  const getValueCheckbox = (key) => {
    return (!(value[key] === null || value[key] === 'null'))
  }

  /**
   *
   * @param key
   */
  const changeValue = (key) => {
    value[key] = (value[key] === null || value[key] === 'null') ? '0' : 'null'
    save()
  }

  /**
   *
   * @param key
   * @returns {JSX.Element}
   */
  const dayField = (key) => {
    return (
      <div className="form-row">
        <input
          type="checkbox"
          onChange={() => changeValue(key)}
          checked={getValueCheckbox(key)}
        />
        {getValueCheckbox(key) &&
          <>
            <input
              disabled={isLoading}
              type={realType}
              name={key}
              value={getValue(key)}
              onChange={onChange}
              step={step}
              lang={lang}
              min="0"
              max={(value.type === 'relative') ? "100" : maxStep}
            />
            <span className="after-label">{getAfterLabel()}</span>
          </>
        }
      </div>
    )
  }

  /**
   *
   */
  const changeShowPrice = () => {
    value.default = (value.default === null) ? '0' : null
    save()
  }

  return (
    <>
      <div className={className + 'price '}>
        {hasError && <span className="error"> - {error}</span>}
        <div>
          {isSpot(formData.model) &&
            <label>Overschrijf de standaard prijs van de Locatie voor deze Standplaats
              <input
                type="checkbox"
                onChange={() => changeShowPrice()}
                checked={value.default !== null}
              />
            </label>
          }
          {
            (
              (isSpot(formData.model) && value.default !== null) ||
              !isSpot(formData.model)
            ) &&
            <>

              {!fixedType &&
                <div className="price-type">
                  <label>Prijs soort: (lees meer over <a href="/prijzen-betaalmethodes/" target="_blank">alle soorten prijzen hier</a>)
                    <div className="select">
                      <select
                        name="type"
                        id=""
                        disabled={isLoading}
                        value={value.type}
                        onChange={onChangeType}
                      >
                        <option value="positive">{getTypePositiveLabel()}</option>
                        <option value="negative">{getTypeNegativeLabel()}</option>
                        <option value="negotiate">{getTypeNegotiateLabel()}</option>
                        <option value="relative">{getTypeRelativeLabel()}</option>
                      </select>
                    </div>
                  </label>
                </div>
              }

              {(value.type === 'positive' || value.type === 'negative' || value.type === 'relative') &&
                <>
                  <label>
                    {getPriceLabel()}
                    <div className="form-row">
                      <input
                        disabled={isLoading}
                        type={realType}
                        name='default'
                        value={getValue('default')}
                        onChange={onChange}
                        step={step}
                        lang={lang}
                        min="0"
                        max={(value.type === 'relative') ? "100" : maxStep}
                      />
                      <span className="after-label">{getAfterLabel()}</span>
                    </div>
                  </label>

                  <div className="override-price-row">
                    <div className="checkbox">
                      <input
                        type="checkbox"
                        onChange={onChangeShowOverridePrice}
                        checked={hasWeekdayValue()}
                      />
                    </div>
                    <div className="label">
                      <p>Een {(value.type === 'relative') ? 'aparte dagprijs-percentage' : 'aparte dagprijs'} voor elke
                        weekdag instellen</p>
                    </div>
                  </div>

                  {hasWeekdayValue() &&
                    <>
                      <p>Overschrijf {(value.type === 'relative') ? 'het dagprijs-percentage' : 'de dagprijs'} op: </p>
                      <div>Maandag
                        {dayField('mo')}
                      </div>
                      <div>Dinsdag
                        {dayField('tu')}
                      </div>
                      <div>Woensdag
                        {dayField('we')}
                      </div>
                      <div>Donderdag
                        {dayField('th')}
                      </div>
                      <div>Vrijdag
                        {dayField('fr')}
                      </div>
                      <div>Zaterdag
                        {dayField('sa')}
                      </div>
                      <div>Zondag
                        {dayField('su')}
                      </div>
                    </>
                  }
                </>
              }
            </>
          }
        </div>
      </div>
    </>
  )
}

Price.defaultProps = {
  label: '',
  name: '',
  text: '',
  value: {},
  type: 'text',
  className: '',
  hasError: false,
  onChange: () => {
  },
  error: '',
  form: null,
  fixedType: null,
}

Price.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.object,
  className: PropTypes.string,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.string,
  form: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  fixedType: PropTypes.string,
}

export default Price
