import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import DatePicker from "react-multi-date-picker";
import {DateObject} from "react-multi-date-picker"
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import Button from "../Button/Button";
import './BusinessHour-style.scss'

const BusinessHour = (props) => {

  const {
    name,
    label,
    value,
    className,
    hasError,
    formData,
    error,
    save,
  } = props

  const afterLabel = 'Uur'

  const [times, setTimes] = useState({
    mo_start: null,
    mo_end: null,
    tu_start: null,
    tu_end: null,
    we_start: null,
    we_end: null,
    th_start: null,
    th_end: null,
    fr_start: null,
    fr_end: null,
    sa_start: null,
    sa_end: null,
    su_start: null,
    su_end: null,
  })

  useEffect(() => {
    setAllTimes()
  }, [value])

  /**
   * add time from value obj. to times obj.
   */
  const setAllTimes = () => {
    if (!value) {
      return
    }
    const keys = Object.keys(value)
    keys.map((key) => {
      if (key === 'id') {
        return null
      }
      if (value[key]) {
        const time = value[key].split(':')
        times[key] = new DateObject()
        times[key].set({
          hour: time[0],
          minute: time[1],
        })
      } else {
        times[key] = null
      }
      return key
    })
    setTimes(times)
  }

  /**
   * Copy values from times obj to value obj
   */
  const onChange = (key, newValue) => {
    value[key] = (newValue) ? newValue.hour + ':' + newValue.minute : null
    save()
    setTimes({...times, [key]: newValue})
  }

  /**
   *
   * @returns {boolean}
   */
  const use = () => {
    let use = false
    if (!value) {
      return use
    }
    const keys = Object.keys(value)
    keys.map((key) => {
      if (key !== 'id') {
        if (value[key]) {
          use = true
        }
      }
      return key
    })
    return use
  }

  /**
   *
   */
  const onChangeUse = () => {
    if (!value) {
      formData.model[name] = {}
      save()
    }
    const start = (use()) ? null : '08:00'
    const end = (use()) ? null : '17:00'
    value.mo_start = start
    value.mo_end = end
    value.tu_start = start
    value.tu_end = end
    value.we_start = start
    value.we_end = end
    value.th_start = start
    value.th_end = end
    value.fr_start = start
    value.fr_end = end
    value.sa_start = start
    value.sa_end = end
    value.su_start = start
    value.su_end = end
    save()
    setAllTimes()
  }

  /**
   *
   * @param keyStart
   * @param keyEnd
   */
  const open = (keyStart, keyEnd) => {
    const start = new DateObject()
    const end = new DateObject()
    start.set({
      hour: 8,
      minute: 0
    })
    end.set({
      hour: 17,
      minute: 0
    })
    onChange(keyStart, start)
    onChange(keyEnd, end)
  }

  /**
   *
   * @param keyStart
   * @param keyEnd
   */
  const close = (keyStart, keyEnd) => {
    onChange(keyStart, null)
    onChange(keyEnd, null)
  }

  /**
   *
   * @param key
   * @returns {JSX.Element}
   */
  const dayField = (key) => {

    const keyStart = key + '_start'
    const keyEnd = key + '_end'

    return (
      <div className="form-row">
        <div className="table">
          {value[keyStart] || value[keyEnd] ?
            <>
              <div className="row">
                <div className="col"><span className="small-label">Vanaf</span></div>
                <div className="col"><span className="small-label">Tot</span></div>
                <div className="col"></div>
                <div className="col"></div>
              </div>
              <div className="row">
                <div className="col">
                  <DatePicker
                    value={times[keyStart]}
                    onChange={newValue => onChange(keyStart, newValue)}
                    disableDayPicker
                    format="HH:mm"
                    plugins={[
                      <TimePicker hideSeconds/>
                    ]}
                  />
                </div>
                <div className="col">
                  <DatePicker
                    value={times[keyEnd]}
                    onChange={newValue => onChange(keyEnd, newValue)}
                    disableDayPicker
                    format="HH:mm"
                    plugins={[
                      <TimePicker hideSeconds/>
                    ]}
                  />
                </div>
                <div className="col">
                  <span className="after-label">{afterLabel}</span>
                </div>
                <div className="col">
                  <Button
                    label="Sluiten"
                    size="xs"
                    color="grey"
                    onClick={() => close(keyStart, keyEnd)}
                  />
                </div>
              </div>
            </>
            :
            <div className="row">
              <div className="col">
                <span>Gesloten</span>
              </div>
              <div className="col">
                <Button
                  label="Openen"
                  size="xs"
                  color="grey"
                  onClick={() => open(keyStart, keyEnd)}
                />
              </div>
            </div>
          }
        </div>
      </div>
    )
  }

  return (
    <>
      <div className={className + 'business-hour '}>
        <p className="text-small"></p>
        {hasError && <span className="error"> - {error}</span>}
        <>

          <label>{label}
            <input
              type="checkbox"
              onChange={() => onChangeUse()}
              checked={use()}
            />
          </label>
          {use() &&
            <>
              <div>Maandag
                {dayField('mo')}
              </div>
              <div>Dinsdag
                {dayField('tu')}
              </div>
              <div>Woensdag
                {dayField('we')}
              </div>
              <div>Donderdag
                {dayField('th')}
              </div>
              <div>Vrijdag
                {dayField('fr')}
              </div>
              <div>Zaterdag
                {dayField('sa')}
              </div>
              <div>Zondag
                {dayField('su')}
              </div>
            </>
          }
        </>
      </div>
    </>
  )
}

BusinessHour.defaultProps = {
  label: '',
  name: '',
  text: '',
  value: {},
  type: 'text',
  className: '',
  hasError: false,
  onChange: () => {
  },
  error: '',
  form: null,
}

BusinessHour.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.object,
  className: PropTypes.string,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.string,
  form: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
}

export default BusinessHour
