import React, {useEffect} from "react";
import {BrowserRouter} from "react-router-dom";
import {AppContextProvider} from "./context/AppContextProvider";
import {mainPageTitle} from "./env";
import AuthContext from "./context/AuthContext";
import {ErrorBoundary} from 'react-error-boundary'
import Background from './assets/images/patern.png'
import MainRouter from "./routers/MainRouter";
import ChatWindow from "./components/ChatWindow/ChatWindow";
import useApi from "./api/useApi";
import Portal from "./components/Portal/Portal";
import Auth from "./context/Auth";
import 'react-tooltip/dist/react-tooltip.css'


function App() {

  const {sendErrorReport} = useApi()

  useEffect(() => {
    document.title = mainPageTitle
  }, []);

  const handleError = (error) => {

    sendErrorReport('Oeps!', 'Sorry.. React Error.', error.error.message, {
      errorResponseData: [error.error.stack],
      errorResponse: [error.error.message],
      error: [error],
      url: window.location.href,
    })

    document.querySelector('.swal-overlay').style.backgroundColor = 'unset'

    return (
      <div role="alert" style={{
        backgroundImage: `url(${Background})`,
        backgroundRepeat: 'repeat',
        width: '100vw',
        height: '100vh',
      }}>
        <pre style={{margin: 0}}>{error.error.message}</pre>
      </div>
    )
  }

  return (
    <ErrorBoundary FallbackComponent={handleError}>
      <Portal/>
      <AuthContext.Provider value={Auth()}>
        <AppContextProvider>
          <BrowserRouter>
            <ChatWindow/>
            <MainRouter/>
          </BrowserRouter>
        </AppContextProvider>
      </AuthContext.Provider>
    </ErrorBoundary>
  );
}

export default App
