import React from "react";
import PropTypes from "prop-types";

const SelectArray = (props) => {

  const {
    label,
    name,
    value,
    className,
    data,
    hasError,
    onChange,
    error,
    isLoading,
  } = props

  return (
    <div className={className + 'select-array '}>
      <label>{label}
        {hasError && <span className="error"> - {error}</span>}
        <div className="form-row">
          <select
            disabled={isLoading}
            name={name}
            value={value}
            onChange={onChange}
          >
            {data.map((item, index) => {
                return (
                  <option key={index} value={item.value}>{item.label}</option>
                )
              }
            )}
          </select>
        </div>
      </label>
    </div>
  )
}

SelectArray.defaultProps = {
  label: '',
  name: '',
  value: '',
  className: '',
  data: [],
  hasError: false,
  onChange: () => {
  },
  error: '',
}

SelectArray.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  data: PropTypes.array,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.string,
}

export default SelectArray
