import useApi from "../../api/useApi";
import useFunction from "../../hooks/useFunction";

const useSpotService = () => {

  const {api} = useApi()
  const {c} = useFunction()

  const userShow = (callbackSuccess, callbackValidationErrors, spotId) => {
    return api(
      'get',
      'user/spot/' + spotId,
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in Service', error),
    )
  }

  const userUpdate = (callbackSuccess, callbackValidationErrors, spotId, data) => {
    return api(
      'post',
      'user/spot/' + spotId + '/update',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in Service', error),
      data,
    )
  }

  const userStore = (callbackSuccess, callbackValidationErrors, spotId, data) => {
    return api(
      'post',
      'user/spot/store',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in Service', error),
      data,
    )
  }

  const userDelete = (callbackSuccess, callbackValidationErrors, id) => {
    return api(
      'post',
      'user/spot/' + id + '/delete',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in Service', error),
    )
  }

  return {
    objectType:'spot',
    userShow,
    userUpdate,
    userStore,
    userDelete,
  }
}
export default useSpotService
