import React from "react";
import {DateObject} from "react-multi-date-picker";
import {Tooltip} from "react-tooltip";
import spotGroupIcon from "../../assets/icons/spot-group.svg"
import standIcon from "../../assets/icons/stand.svg"
import star from "../../assets/icons/star.svg"
import {localhost} from "../../env";
import {Link} from "react-router-dom";
import useBookable from "../../hooks/useBookable";
import useData from "../../hooks/useData";
import Media from "../Media/Media";
import Price from "../Price/Price";
import "./style.scss"

const SquareBookable = ({bookable, responsive}) => {

  const {isStand, isSpotGroup} = useBookable()
  const {getIndustryById} = useData()

  /**
   *
   * @returns {string}
   */
  const getUrl = () => {
    if (isStand(bookable)) {
      return '/stand/' + bookable.slug
    } else {
      return '/locatie/' + bookable.slug
    }
  }

  /**
   *
   * @returns {string}
   */
  const getIndustries = () => {
    const industries = []
    if (isStand(bookable)) {
      bookable.industries_ids && Array.isArray(bookable.industries_ids) && bookable.industries_ids.map((industryId) => {
        const industry = getIndustryById(industryId)
        industries.push(industry.label)
        return industry
      })
    } else if (isSpotGroup(bookable)) {
      bookable.spots && Array.isArray(bookable.spots) && bookable.spots.map((spot) => {
        spot.industries_ids && Array.isArray(spot.industries_ids) && spot.industries_ids.map((industryId) => {
          const industry = getIndustryById(industryId)
          industries.push(industry.label)
          return industry
        })
      })
    }
    return industries.join(', ')
  }

  /**
   *
   * @param bookable
   * @returns {string|string}
   */
  const getTooltipId = (bookable) => {
    return (isStand(bookable)) ? 'stand_' : 'spot_group_' + bookable.id
  }

  /**
   *
   * @param bookable
   * @returns {false|*|boolean}
   */
  const checkAvailabilityIndices = (bookable) => {
    return (
      'spot_group_indices' in bookable &&
      bookable.spot_group_indices &&
      bookable.spot_group_indices.length &&
      bookable.spot_group_indices[0] !== null &&
      'spots_available' in bookable.spot_group_indices[0]
    )
  }

  /**
   *
   * @param bookable
   * @returns {*}
   */
  const getSpotsAvailable = (bookable) => {
    if (checkAvailabilityIndices(bookable)) {
      return bookable.spot_group_indices[0].spots_available
    }
  }

  /**
   *
   * @param bookable
   * @returns {string}
   */
  const getSpotsAvailableDate = (bookable) => {
    if (checkAvailabilityIndices(bookable)) {

      // console.log('bookable.spot_group_indices[0]', bookable.spot_group_indices[0])

      return new DateObject(bookable.spot_group_indices[0].date).format("D MMMM YYYY")
    }
  }

  return (
    <Link className="component-square-bookable" to={getUrl()} target={(localhost) ? "" : "_blank"}>


      <div className={responsive ? "image-container responsive" : "image-container"}>

        {bookable.action &&
          <div className="action-banner"><p>{bookable.action}</p></div>
        }

        <div className="object-type">
          <img
            src={(isStand(bookable)) ? standIcon : spotGroupIcon}
            alt={(isStand(bookable)) ? 'Stand / food-truck / kraam' : 'locatie / event / standplaats'}
            className="icon"
          />
          {isStand(bookable) && 'Stand/Truck'}
          {isSpotGroup(bookable) &&
            <>
              {'spots' in bookable && bookable.spots
                ?
                <>
                  {bookable.multi_spot ?
                    <>
                      {bookable.spots.length === 1 ? '1 Standplaats' : bookable.spots.length + ' Standplaatsen'}
                    </>
                    :
                    <>
                      1 Standplaats
                    </>
                  }
                </>
                :
                <>
                  {checkAvailabilityIndices(bookable) &&
                    <Tooltip
                      id={getTooltipId(bookable)}
                      clickable={true}
                      place="bottom"
                    >
                      {
                        <>
                          <b>Nog {(getSpotsAvailable(bookable) === 1) ? 'maar ' + getSpotsAvailable(bookable) + ' Standplaats' : getSpotsAvailable(bookable) + ' Standplaatsen'} </b>van
                          de <b>{bookable.spot_count}</b><br/>
                          beschikbaar op {getSpotsAvailableDate(bookable)}.
                        </>
                      }
                    </Tooltip>
                  }
                  <div
                    className="component-info"
                    data-tooltip-id={getTooltipId(bookable)}
                    data-tooltip-delay-hide={0}
                  >
                    {checkAvailabilityIndices(bookable) ?
                      <>Nog {(getSpotsAvailable(bookable) === 1) ? 'maar ' + getSpotsAvailable(bookable) + ' Standplaats' : getSpotsAvailable(bookable) + ' Standplaatsen'}</>
                      :
                      <><b>&nbsp;{bookable.spot_count}&nbsp;</b>Standplaatsen</>
                    }
                  </div>
                </>
              }
            </>
          }
        </div>

        <div className="image-container-inner">
          <Media
            media={bookable.overview_media}
            size={'thumb'}
            alt="Stand, truck, kraam, event, locatie"
          />
        </div>
      </div>
      <div className="meta">
        <div className="row titles">
          <div className="col title">
            <h4 className="title">{bookable.label}</h4>
          </div>

          {bookable.rating &&
            <div className="col rating">
              <div className="row"><img src={star} alt="reviews"/>
                {bookable.rating / 10}
              </div>
            </div>
          }
        </div>
        <div className="row industries">
          {/*<div className="col industries">*/}
          <div className="industry">{getIndustries()}</div>
          {/*</div>*/}
          {/*<div className="col price">*/}
          {/*</div>*/}
        </div>
        <div className="row price">

          <Price bookable={bookable} showTextBelowPrice={false}/>
          {/*<div className="price">{formatPrice((isStand(bookable))?priceLowestStand(bookable):priceLowestSpotGroup(bookable))} p/d</div>*/}

        </div>
        {/*{bookableStatus.key === 'bookable' &&*/}
        {/*//for now a bookable is always available, and the available props are not passed by backend*/}
        <div className="availability">
          <div className="dot green"></div>
          <span>Beschikbaar</span>
        </div>
        {/*}*/}
        {/*{bookableStatus.key === 'closed' &&*/}
        {/*  <div className="availability">*/}
        {/*    <span className="dot orange"></span>*/}
        {/*    Tijdelijk gesloten*/}
        {/*  </div>*/}
        {/*}*/}
      </div>
    </Link>
  );
}

export default SquareBookable
