import React, {useEffect, useState} from "react";
import {ScrollToPlugin} from "gsap/ScrollToPlugin"
import {gsap} from "gsap";
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from "prop-types";
import useBookable from "../../hooks/useBookable";
import useFunction from "../../hooks/useFunction";
import FormButtons from "../FormButtons/FormButtons";
import Loading from "../Loading/Loading";
import Button from "../Button/Button";
import swal from "sweetalert";
import ReactGA from "react-ga4";
import {GACode} from "../../env"


const Fields = (props) => {
  gsap.registerPlugin(ScrollToPlugin);

  const {
    id,
    initData, //when no id is passed, initData is used, instead of showService.
    apiService,
    parentShowApiService,
    parentApiServiceIdPath,
    updateSuccess, // updateErrors,
    buttonConfirmLabel,
    buttonCancelLabel,
    children,
    onChange,
    onReset,
    onShow,
    onRender,
    form,
    showDelete,
    deleteLabel,
    deleteSuccess,
    preService,
    wizard,
    fixedFormButtons,
    GAEventLabel,
  } = props
  const errorField = 'error_'
  const {c, objIsEmpty, getWithPath} = useFunction()
  const {
    formData,
    setFormData,
    getFormDataObj,
    originalFormDataModel,
    setOriginalFormDataModel,
    save,
  } = form //useForm()

  const [childId, setChildId] = useState(null)
  const {userShow: showService, userStore: storeService, userUpdate: updateService} = apiService
  const {isStand, isSpotGroup, isSpot} = useBookable()

  /*
   * Store or update call depends on if the model already exists (and has an DB id)
   */
  const saveService = (id) ? updateService : storeService
  const [formHeight, setFormHeight] = useState(0)

  /**
   * if id is set, the page is meant to show a specific row only
   */
  useEffect(() => {
    if (!formData.isLoading) {
      setFormHeight(document.getElementById('component-form-fields').clientHeight) //todo use useRef: const inputRef = useRef(null);  ref={inputRef};  access via     console.log('inputRef', inputRef.current);
    }
  }, [formData.isLoading])

  /**
   *
   */
  useEffect(() => {
    getModelIfNeeded()
  }, [id, formData.model])

  /**
   *
   */
  useEffect(() => {
    if (onRender) {
      onRender(form)
    }
    if (initData) {
      setFormData(prevFormData => {
        return {
          ...prevFormData,
          model: initData,
          isLoading: false,
        }
      })

      setOriginalFormDataModel(cloneDeep(initData))
    }
  }, [])


  /**
   * Callback when formData changes
   */
  useEffect(() => {
    if (onChange && formData.isChanged) {
      onChange(formData.model)
      if (wizard && wizard.setButtonNext && wizard.buttonNext) {
        wizard.setButtonNext({
          ...wizard.buttonNext, [wizard.step]: {
            onClick: wizard.getButtonValue(wizard.buttonNext, 'saveOnClick', (e) => handleService(
              e,
              formData,
              setFormData,
              saveService,
              null,
              null
            )),
            label: wizard.getButtonValue(wizard.buttonNext, 'saveLabel', 'Opslaan'),
            disabled: wizard.getButtonValue(wizard.buttonNext, 'saveDisabled', false),
            visible: wizard.getButtonValue(wizard.buttonNext, 'saveVisible', true),
            className: wizard.getButtonValue(wizard.buttonNext, 'saveClassName', ''),
          }
        })
      }

      if (wizard && wizard.buttonPrev && wizard.setButtonPrev) {
        wizard.setButtonPrev({
          ...wizard.buttonPrev, [wizard.step]: {
            onClick: wizard.getButtonValue(wizard.buttonPrev, 'resetOnClick', (e) => handleCancel()),
            label: wizard.getButtonValue(wizard.buttonPrev, 'resetLabel', 'Reset'),
            disabled: wizard.getButtonValue(wizard.buttonPrev, 'resetDisabled', false),
            visible: wizard.getButtonValue(wizard.buttonPrev, 'resetVisible', true),
            className: wizard.getButtonValue(wizard.buttonPrev, 'resetClassName', ''),
          }
        })
      }
    }
  }, [formData, formData.isChanged])

  /**
   * Get (api show) formData.model when needed
   */
  const getModelIfNeeded = () => {

    /*
     * check if has id -> continue
     *   check if formData has formData.model -> if not, get.
     *   check if formData.model.object_type === apiService.objectType -> if not, get
     *   check if formData.model.id === id -> if not, get
     */
    if (id &&
      (
        (objIsEmpty(formData.model)) ||
        (formData.model.object_type !== apiService.objectType) || //note could cause errors, but maybe that's a good thing.
        ((parseInt(formData.model.id) !== parseInt(id)) && !parentShowApiService) //this line only applies wh
      )
    ) {
      /*
       * Check if model needs a parent. If so, get parent first,
       * then get child.
       */
      const setFormDataFunc = (model) => {
        setFormData(prevFormData => {
          return {
            ...prevFormData,
            model: model,
            isLoading: false,
          }
        })
        setOriginalFormDataModel(cloneDeep(model))
        if (onShow) {
          onShow(model)
        }
      }

      const setFormDataFuncError = (errors) => {
        console.log('errors', errors)
        setFormData(prevFormData => {
          return {
            ...prevFormData,
            isLoading: false,
          }
        })
      }

      if (parentShowApiService) {
        if (objIsEmpty(formData.model)) {
          /*
           * has need for parent, and current formData.model is empty
           */
          parentShowApiService((model) => {
            const childId = getWithPath(model, parentApiServiceIdPath)
            setChildId(childId)
            setIsLoading(true)
            showService(setFormDataFunc, setFormDataFuncError, childId)
          }, null, id)

        } else {
          /*
           * has need for parent, and is formData.model is already loaded
           * (btw, the check if this is the right model is already done
           */
          const childId = getWithPath(formData.model, parentApiServiceIdPath)
          setChildId(childId)
          setIsLoading(true)
          showService(setFormDataFunc, setFormDataFuncError, childId)
        }
      } else {
        if (objIsEmpty(formData.model) || !formData.model.created_at) {
          setIsLoading(true)
          showService(setFormDataFunc, setFormDataFuncError, id)
        }
      }
    }
  }

  /**
   *
   * @param isLoading
   */
  const setIsLoading = (isLoading = true) => {
    setFormData(prevFormData => {
      return {
        ...prevFormData,
        isLoading: isLoading,
      }
    })
  }

  /**
   * Do the API Update/Store call via a Service
   * and set states
   *
   * @param e
   * @param formData
   * @param setFormData
   * @param saveService
   * @param twinSetFormData
   * @param callbackDone
   */
  const handleService = (   //todo change to handleSave(Service)
    e,                      //todo do we need all the other events?
    formData,               //todo do we need this prop? can be higher scope?
    setFormData,            //todo do we need this prop? can be higher scope?
    saveService,            //todo do we need this prop? can be higher scope?
    twinSetFormData,        //todo whut is this again??? //todo do we need this prop? can be higher scope?
    callbackDone            //todo do we need this prop? We have other: updateSuccess . what is callbackDone ?
  ) => {
    c('handleService. formData: ', formData)
    e.preventDefault()

    if (preService) {
      formData.model = preService(formData.model)
    }
    const model = cloneDeep(formData.model)

    setFormData(prevFormData => {
      return {
        ...prevFormData,
        isChanged: false,
        isLoading: true,
      }
    })

    saveService((model) => {
      setFormData(prevFormData => {
        return {
          ...prevFormData, model: model,
          isStored: true, isLoading: false,
        }
      })
      setOriginalFormDataModel(cloneDeep(model))

      swal({
        title: 'Opgeslagen!', icon: 'success', timer: 1000, buttons: false,
      })
      if (callbackDone) {
        c('callbackDone')
        callbackDone()
      }
      if (updateSuccess) {
        updateSuccess(model, form)
      }
    }, (errors) => {
      addErrors(errors)
    }, (childId && parentShowApiService) ? childId : id, getFormDataObj(model))


    if (GAEventLabel){
      GACode && ReactGA.event({
        category: "form",
        action: "submit",
        label: GAEventLabel,
      });
    }
  }

  /**
   * Add field errors to formData.model props, like this:
   * formData.model.error_{field-key}
   *
   * @param errors
   * @return {null}
   */
  const addErrors = (errors) => {
    const keys = Object.keys(errors)
    keys.map((key) => {
      // If it exists, do stuff...
      gsap.to(window, {
        duration: 1.2,
        ease: "power4.inOut",
        scrollTo: {
          // y: '[name=' + element + ']',
          y: '.has-error',
          offsetY: 200
        }
      });
      formData.model[errorField + key] = errors[key]
      return key
    });

    setFormData(prevState => {
      return {
        ...prevState, model:
        formData.model,
        isLoading: false,
      }
    })
  }

  /**
   * Remove error fields from model
   *
   * @param model
   * @param name
   */
  const removeErrors = (model, name) => {
    if (errorField + name in model) {
      delete model[errorField + name]   //todo delete is bad (leaves null in array). use state.splice(state.indexOf(item.id), 1)
    }
    return model
  }

  /**
   * Event listener onChange for the Update/edit Form fields
   */
  const handleChange = (formData, setFormData, e) => {
    if (e.target.type === 'file') {
      formData.model[e.target.name] = {
        value: e.target.value, file: e.target.files[0]
      }
    } else if (e.target.type === 'checkbox') {
      formData.model[e.target.name] = (e.target.checked) ? 1 : 0
    } else {
      formData.model[e.target.name] = e.target.value
      formData.model = removeErrors(formData.model, e.target.name)
    }

    setFormData(prevFormData => {
      return {
        ...prevFormData, model: formData.model, isChanged: true, isStored: false,
      }
    })
  }

  /**
   * Undo all non-saved changes in the form
   */
  const handleCancel = () => {
    (onReset) && onReset()
    setFormData(prevFormData => {
      return {
        ...prevFormData,
        model: cloneDeep(originalFormDataModel),
        isChanged: false,
        isStored: true,
      }
    })
  }

  /**
   *
   * @param model
   * @returns {string}
   */
  const getDeleteLabel = (model) => {
    if (!model && !('label' in model)) {
      return 'Verwijderen'
    }
    if (isStand(model)) {
      return 'Stand of Truck: ' + model.label + ' verwijderen?'
    }
    if (isSpotGroup(model)) {
      return 'Locatie: ' + model.label + ' verwijderen?'
    }
    if (isSpot(model)) {
      return 'Standplaats: ' + model.label + ' verwijderen?'
    }
  }

  /**
   *
   */
  const handleDelete = (model) => {

    //todo add 'really shure, second popup' via swal after X seconds..( but should be optional, since delting a Spot should be more easy)


    swal({
      title: getDeleteLabel(model),
      text: 'Weet u het zeker? Deze actie kan niet ongedaan gemaakt worden!',
      icon: 'error',
      dangerMode: true,
      buttons: {
        cancel: {
          text: "Annuleren",
          value: null,
          visible: true,
          className: 'btn-color-grey btn-size-m',
          closeModal: true,
        },
        confirm: {
          text: "Verwijderen",
          value: true,
          visible: true,
          className: 'btn-color-red btn-size-m',
          closeModal: true,
        }
      }
    }).then((result) => {
      if (result) {

        setFormData(prevFormData => {
          return {
            ...prevFormData,
            isLoading: true,
          }
        })
        apiService.userDelete(
          () => {
            swal({
              title: 'Success',
              icon: 'success',
              timer: 650,
              buttons: false,
            })
            if (deleteSuccess) {

              deleteSuccess(model, form)
              setFormData(prevFormData => {
                return {
                  ...prevFormData,
                  isLoading: false,
                }
              })
            }
          },
          () => {
          },
          model.id
        )
      }
    })
  }

  /**
   * Enrich the props of children to they can access more data (model, i, *)
   */
  const wrappedChildren = (formData, setFormData) => {
    return React.Children.map(children, function (child) {
      if (!child) {
        return
      }
      if (typeof child.type === 'string' || child.type instanceof String) {
        return React.cloneElement(child)
      }
      if ((
          (child.props.disabledByKeys || child.props.enabledBy) &&
          (form.showField(child.props.disabledByKeys, child.props.enabledBy))
        ) ||
        (!child.props.disabledByKeys && !child.props.enabledBy)
      ) {
        let valueDefaultProps = ''
        if ('defaultProps' in child.type && child.type.defaultProps && 'value' in child.type.defaultProps && child.type.defaultProps.value) {
          valueDefaultProps = child.type.defaultProps.value
        }
        const model = formData.model
        const hasModel = (model instanceof Object)
        const name = child.props.name
        const value = (hasModel && [name] in model) ? model[name] : valueDefaultProps
        let hasError = null
        let error = null
        let errorChild = null

        hasModel && Object.keys(model).map((key) => {
          if (
            key.startsWith(errorField)
          ) {
            if (name === key.replace(errorField, '')) {
              hasError = true
              error = model[errorField + name]
            } else if (
              name === key.replace(errorField, '').split('.')[0]
            ) {
              hasError = true
              errorChild = key.replace(errorField, '').split('.')
              error = model[key]
            }
          }
          return key
        })
        const className = (hasError) ? 'has-error field form-component-' : 'field form-component-'
        return React.cloneElement(child, {
          model: model,
          value: value,
          className: className,
          hasError: hasError,
          error: error,
          errorChild: errorChild, //todo implement this
          save: save,
          handleChange: handleChange,
          removeErrors: removeErrors,
          onChange: e => handleChange(formData, setFormData, e),
          formData: formData,
          setFormData: setFormData,
          isLoading: formData.isLoading,
          originalFormDataModel: originalFormDataModel,
          setOriginalFormDataModel: setOriginalFormDataModel,
          form: form, //todo get all props (like formData) from this object
        });
      }
    });
  }

  return (
    <>
      {formData.isLoading ?
        <Loading
          height={formHeight}
        /> : <form id="component-form-fields">
          <div className="model">
            <div className="fields">{wrappedChildren(formData, setFormData)}</div>
            {showDelete &&
              <>
                <br/>
                {deleteLabel &&
                  <p>{deleteLabel}</p>
                }
                <Button
                  label="Verwijderen"
                  onClick={() => handleDelete(formData.model)}
                  className=" btn-red "
                />
              </>
            }
            {!wizard &&
              <FormButtons
                isChanged={formData.isChanged}
                buttonConfirmLabel={buttonConfirmLabel}
                buttonCancelLabel={buttonCancelLabel}
                handleCancel={(e) => handleCancel(e, formData, setFormData, saveService)}
                handleConfirm={(e) => handleService(e, formData, setFormData, saveService)}
                disabledConfirm={!formData.isChanged || formData.isLocked}
                disabledCancel={!formData.isChanged}
                isFixed={fixedFormButtons}
              />
            }
          </div>
        </form>
      }
    </>
  )
}

//todo update
Fields.defaultProps = {
  useEdit: false,
  editLink: '',
  labelModelKey: '',
  parentId: null,
  collapseEditForm: true,
  editPageBaseUrl: '',
  createPageButtonLink: '',
  publicPageBaseUrl: '',
  headerFields: [],
  HeaderComponent: null,
  headerComponentProps: {},
  HeaderButtons: null,
  headerButtonProps: {},
  form: null,
  showDelete: false,
  deleteSuccess: null,
  fixedFormButtons: true,
  GAEventLabel:null,
}

Fields.propTypes = {
  showService: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  updateSuccess: PropTypes.func,
  useEdit: PropTypes.bool,
  editLink: PropTypes.string,
  labelModelKey: PropTypes.string,
  parentId: PropTypes.number,
  collapseEditForm: PropTypes.bool,
  editPageBaseUrl: PropTypes.string,
  createPageButtonLink: PropTypes.string,
  publicPageBaseUrl: PropTypes.string,
  headerFields: PropTypes.array,
  HeaderComponent: PropTypes.object,
  headerComponentProps: PropTypes.object,
  HeaderButtons: PropTypes.object,
  headerButtonProps: PropTypes.object,
  form: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
  showDelete: PropTypes.bool,
  deleteSuccess: PropTypes.func,
  fixedFormButtons: PropTypes.bool,
  GAEventLabel: PropTypes.string,
}

export default Fields
