import React from "react";
import useFunction from "../../hooks/useFunction";
import yesIcon from "../../assets/icons/yes.svg"
import noIcon from "../../assets/icons/no.svg"
import './Verified-style.scss'

const Verified = (props) => {

  const {
    label,
    // name,
    text,
    value,
    className,
    hasError,
    error,
    validateButtonOnClick,
    validateButtonLabel,
    formData,
    name,
    save,
    removeOtherFields,
  } = props

  const {dateTime} = useFunction()

  const removeValidation = () => {
    formData.model[name] = null
    if (removeOtherFields){
      removeOtherFields.map((fieldKey) => {
        formData.model[fieldKey] = ''
        return fieldKey
      })
    }
    save()
  }

  return (
    <div className={className+ 'verified '}>
      <label>{label} validatiestatus:<img className="status-icon" src={(value)?yesIcon:noIcon} alt="status"/>
        {text && text !== '' &&
          <p className="text-small">{text}</p>
        }
        {hasError && <span className="error"> - {error}</span>}
        <br/>
        {value ?
          <div>
            <div className="small-label">Gevalideerd op: {dateTime(value)}</div>
            <br/>
            <div
              onClick={() => removeValidation()}
              className="btn-size-xs btn-color-red"
            >
              Validatie verwijderen
            </div>
            <br/>
            <br/>
          </div>
          :
          <div>
            <div className="small-label">Niet gevalideerd</div>
            <br/>
            <p>Je kan het IBAN nummer valideren door 1 cent via iDeal over te maken met het rekeningnummer waar je op uitbetaald wil worden.</p>
            <div
              onClick={() => (validateButtonOnClick)?validateButtonOnClick():null}
              className="btn-size-s btn-color-green"
            >
              {validateButtonLabel}
            </div>
          </div>
        }

      </label>
    </div>
  )


}


export default Verified
