/**
 * Get update via data array in {backend-server}/api/industries/tree
 *
 */
const industries = [
  {
    id: 1,
    label: "Food",
    parent_id: null,
    children: [
      {
        id: 2,
        label: "Dranken",
        parent_id: 1,
        children: [
          {
            id: 3,
            label: "Smoothies",
            parent_id: 2
          },
          {
            id: 4,
            label: "Koffie / thee",
            parent_id: 2
          },
          {
            id: 5,
            label: "Barista",
            parent_id: 2
          },
          {
            id: 6,
            label: "Wijn",
            parent_id: 2
          },
          {
            id: 7,
            label: "Bier",
            parent_id: 2
          },
          {
            id: 8,
            label: "Specialiteiten",
            parent_id: 2
          },
          {
            id: 9,
            label: "Overig",
            parent_id: 2
          }
        ]
      },
      {
        id: 10,
        label: "Friet & Snacks",
        parent_id: 1,
        children: [
          {
            id: 11,
            label: "Loempia",
            parent_id: 10
          },
          {
            id: 12,
            label: "Friet",
            parent_id: 10
          },
          {
            id: 13,
            label: "Kebab",
            parent_id: 10
          },
          {
            id: 14,
            label: "Pizza",
            parent_id: 10
          },
          {
            id: 15,
            label: "Hotdog",
            parent_id: 10
          },
          {
            id: 16,
            label: "Shoarma",
            parent_id: 10
          },
          {
            id: 17,
            label: "Surinaams",
            parent_id: 10
          },
          {
            id: 18,
            label: "Hamburger",
            parent_id: 10
          },
          {
            id: 19,
            label: "Biologische producten",
            parent_id: 10
          },
          {
            id: 20,
            label: "Overig",
            parent_id: 10
          }
        ]
      },
      {
        id: 21,
        label: "Vis en visproducten",
        parent_id: 1,
        children: [
          {
            id: 22,
            label: "Verse vis",
            parent_id: 21
          },
          {
            id: 23,
            label: "Gebakken vis",
            parent_id: 21
          },
          {
            id: 24,
            label: "Overig",
            parent_id: 21
          }
        ]
      },
      {
        id: 25,
        label: "Aardappelen, Groenten, Fruit & Bio",
        parent_id: 1,
        children: [
          {
            id: 26,
            label: "Aardappels",
            parent_id: 25
          },
          {
            id: 27,
            label: "Aardbeien",
            parent_id: 25
          },
          {
            id: 28,
            label: "Groenten",
            parent_id: 25
          },
          {
            id: 29,
            label: "Fruit",
            parent_id: 25
          },
          {
            id: 30,
            label: "Honing",
            parent_id: 25
          },
          {
            id: 31,
            label: "Biologische producten",
            parent_id: 25
          }
        ]
      },
      {
        id: 32,
        label: "Vlees & vleeswaren",
        parent_id: 1,
        children: [
          {
            id: 33,
            label: "Poelier",
            parent_id: 32
          },
          {
            id: 34,
            label: "Slager",
            parent_id: 32
          },
          {
            id: 35,
            label: "Biologische producten",
            parent_id: 32
          },
          {
            id: 36,
            label: "Overig",
            parent_id: 32
          }
        ]
      },
      {
        id: 37,
        label: "Zuivel, kaas, noten & zuidvruchten",
        parent_id: 1,
        children: [
          {
            id: 38,
            label: "Hummus",
            parent_id: 37
          },
          {
            id: 39,
            label: "Kaas",
            parent_id: 37
          },
          {
            id: 40,
            label: "Noten",
            parent_id: 37
          },
          {
            id: 41,
            label: "Zuivel",
            parent_id: 37
          },
          {
            id: 42,
            label: "Honing",
            parent_id: 37
          }
        ]
      },
      {
        id: 43,
        label: "Brood, banket & Gebak",
        parent_id: 1,
        children: [
          {
            id: 44,
            label: "Bakkerij",
            parent_id: 43
          },
          {
            id: 45,
            label: "Gebak",
            parent_id: 43
          },
          {
            id: 46,
            label: "Oliebollen",
            parent_id: 43
          },
          {
            id: 47,
            label: "Snoepgoed",
            parent_id: 43
          },
          {
            id: 48,
            label: "Churros",
            parent_id: 43
          },
          {
            id: 49,
            label: "Stroopwafels",
            parent_id: 43
          },
          {
            id: 50,
            label: "Poffertjes",
            parent_id: 43
          },
          {
            id: 51,
            label: "Pannenkoeken",
            parent_id: 43
          },
          {
            id: 52,
            label: "wafels",
            parent_id: 43
          }
        ]
      },
      {
        id: 53,
        label: "Ijs",
        parent_id: 1,
        children: [
          {
            id: 54,
            label: "Schepijs",
            parent_id: 53
          },
          {
            id: 55,
            label: "Verpakt ijs",
            parent_id: 53
          }
        ]
      }
    ]
  },
  {
    id: 56,
    label: "Non-food",
    parent_id: null,
    children: [
      {
        id: 57,
        label: "Bloemen en planten",
        parent_id: 56,
        children: [
          {
            id: 58,
            label: "Bloemen",
            parent_id: 57
          },
          {
            id: 59,
            label: "Planten",
            parent_id: 57
          },
          {
            id: 60,
            label: "Kerstbomen",
            parent_id: 57
          }
        ]
      },
      {
        id: 61,
        label: "Textiel",
        parent_id: 56
      },
      {
        id: 62,
        label: "Kleding",
        parent_id: 56,
        children: [
          {
            id: 63,
            label: "Damesconfectie",
            parent_id: 62
          },
          {
            id: 64,
            label: "Herenconfectie",
            parent_id: 62
          },
          {
            id: 65,
            label: "Kinderkleding",
            parent_id: 62
          }
        ]
      },
      {
        id: 66,
        label: "Schoenen",
        parent_id: 56
      },
      {
        id: 67,
        label: "Lederwaren",
        parent_id: 56
      },
      {
        id: 68,
        label: "Kleinvak, wol & garens",
        parent_id: 56
      },
      {
        id: 69,
        label: "Stoffen",
        parent_id: 56
      },
      {
        id: 70,
        label: "Wooninrichting",
        parent_id: 56
      },
      {
        id: 71,
        label: "Lectuur",
        parent_id: 56
      },
      {
        id: 72,
        label: "Huishoudelijke artikelen",
        parent_id: 56
      },
      {
        id: 73,
        label: "Drogisterij",
        parent_id: 56,
        children: [
          {
            id: 74,
            label: "Parfum",
            parent_id: 73
          }
        ]
      },
      {
        id: 75,
        label: "Souvenirs",
        parent_id: 56
      },
      {
        id: 76,
        label: "Gereedschap",
        parent_id: 56
      },
      {
        id: 77,
        label: "Dier artikelen & diervoeding",
        parent_id: 56
      },
      {
        id: 78,
        label: "Haarmode",
        parent_id: 56
      },
      {
        id: 79,
        label: "Hobby & creativiteit",
        parent_id: 56
      },
      {
        id: 80,
        label: "Speelgoed",
        parent_id: 56
      },
      {
        id: 81,
        label: "Antiek",
        parent_id: 56
      },
      {
        id: 82,
        label: "Sieraden",
        parent_id: 56
      },
      {
        id: 83,
        label: "Elektronica",
        parent_id: 56
      },
      {
        id: 84,
        label: "Telefoon Accessoires",
        parent_id: 56,
        children: [
          {
            id: 85,
            label: "Telefoonhoesjes",
            parent_id: 84
          }
        ]
      },
      {
        id: 86,
        label: "Muziek, platen & CD’s",
        parent_id: 56
      },
      {
        id: 87,
        label: "Attracties",
        parent_id: 56,
        children: [
          {
            id: 88,
            label: "Achtbaan",
            parent_id: 87
          },
          {
            id: 89,
            label: "Animatieshow",
            parent_id: 87
          },
          {
            id: 90,
            label: "Botsauto’s",
            parent_id: 87
          },
          {
            id: 91,
            label: "Draaimolen",
            parent_id: 87
          },
          {
            id: 92,
            label: "Glijbaan",
            parent_id: 87
          },
          {
            id: 93,
            label: "Kop van Jut",
            parent_id: 87
          },
          {
            id: 94,
            label: "Polyp",
            parent_id: 87
          },
          {
            id: 95,
            label: "Reuzenrad",
            parent_id: 87
          },
          {
            id: 96,
            label: "Rupsbaan",
            parent_id: 87
          },
          {
            id: 97,
            label: "Schietkraam",
            parent_id: 87
          },
          {
            id: 98,
            label: "Simulator",
            parent_id: 87
          },
          {
            id: 99,
            label: "spin ’n puke",
            parent_id: 87
          },
          {
            id: 100,
            label: "Spookhuis",
            parent_id: 87
          },
          {
            id: 101,
            label: "Zweefmolen",
            parent_id: 87
          },
          {
            id: 102,
            label: "Tarotkaarten",
            parent_id: 87
          },
          {
            id: 103,
            label: "Waarzegger",
            parent_id: 87
          },
          {
            id: 104,
            label: "Overig",
            parent_id: 87
          }
        ]
      }
    ]
  }
]

export default industries




