import {useState} from "react";
import useUserService from "../api/services/useUserService";
import useFunction from "../hooks/useFunction";
import swal from "sweetalert";

const Auth = () => {

  const [user, setUser] = useState({});
  const {c, getToken, objIsEmpty} = useFunction()
  const {userShow} = useUserService()

  if (getToken()) {
    if (objIsEmpty(user)) {
      userShow(
        (data) => {
        //success
        c('User loaded: ', data)

        if (data){
          setUser(prevAuth => {
            return {
              ...prevAuth,
              ...data
            }
          })
        } else {
          swal('Error', 'Get userShow Error, empty response', 'warning')
        }
      })
    }
  }
  return {
    user,
    setUser
  };
}

export default Auth
