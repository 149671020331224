import {createContext} from 'react';

// set the defaults
const AuthContext = createContext({
  user: {},
  setUser: () => {}
});

// AuthContext.Provider AuthContext.Consumer
export default AuthContext
