import React from "react";
import {Form, Fields, Input} from "../../components/Form/Form";
import Button from "../../components/Button/Button";
import DoubleInput from "../../components/Form/DoubleInput";
import {useNavigate} from "react-router-dom";
import useFunction from "../../hooks/useFunction";
import useUserService from "../../api/services/useUserService";
import useForm from "../../hooks/useForm";
import accountIcon from "../../assets/icons/account-white.svg"

const UserAccountPasswordChange = () => {

  const navigate = useNavigate()
  const {userPasswordChange} = useUserService()
  const form = useForm()
  const {removeToken} = useFunction()

  const handleSuccess = () => {
    removeToken()
    navigate('/inloggen')
  }

  return (
    <div>
      <Button
        label="Terug naar Account"
        link={'/user/account'}
        icon={accountIcon}
      />
      <h1>Account: Wachtwoord wijzigen</h1>
      <Form>
        <Fields
          form={form}
          initData={{current_password:'', password:''}}
          apiService={{userStore:userPasswordChange}}
          updateSuccess={handleSuccess}
          GAEventLabel="User Change Email password"
        >
          <Input
            label="Huidig Wachtwoord"
            name="current_password"
            type="password"
            autoFocus="autofocus"
          />
          <DoubleInput
            label="Nieuw Wachtwoord"
            name="password"
            type="password"
          />
        </Fields>
      </Form>
    </div>
  )
}

export default UserAccountPasswordChange
