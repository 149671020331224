import React from "react";
import Button from "../../components/Button/Button";
import useCompanyService from "../../api/services/useCompanyService";
import TileList from "../../components/TileList/TileList";
import companyIcon from "../../assets/icons/company-white.svg";


const UserCompanies = () => {

  //todo er is een error als je geen companies hebt, 2 toevoegd, en dan 2 verwijderd. (mss afbeelding; default afbeelding of demo data afbeelding error. Check even)

  const {userShow} = useCompanyService()

  return (
    <div className="page-user-company">
      <h1>Organisaties</h1>
      <p>Beheer hier de organisaties waarvoor je werkt, bezit of vertegenwoordigd. Acties, zoals boeken, berichten
        versturen, doe je altijd namens een organisatie.
        Daarom moet je altijd een <strong>'actieve organisatie'</strong> hebben. Naast de organisatie naam is de naam van de
        persoon die de actie uitgevoerd heeft ook vaak zichtbaar.</p>
      <Button
        label="Actieve organisatie selecteren"
        link="/user/account/actieve_organisatie"
        icon={companyIcon}
      />
      <br/>
      <br/>
      <Button
        label="Nieuw organisatie aanmaken"
        linkState={{buttonPrev: {new: {onClick: {navigate: '/user/organisaties'}}}}}
        link="/wizard/welkom/company_new"
        icon={companyIcon}
      />
      <br/>
      <br/>
      <br/>
      <TileList
        apiServiceShow={userShow}
        modelLabelKey="legal_name"
        modelImageKey="logo_media"
        showBookableStatus={false}
        buttonOneDynamicUrl={'/user/organisatie/%id/edit'}
        buttonOneLabel={'Instellingen'}
        smallImage={true}
        imageSize={'logo'}
      />
    </div>
  )
}

export default UserCompanies
