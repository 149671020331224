import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import Loading from "../Loading/Loading";
import useAuthService from "../../api/services/useAuthService";


/**
 *
 * https://backend.standplaats.nl/api/login_provider/linkedin-openid
 * https://backend.standplaats.nl/api/login_provider/facebook
 * https://backend.standplaats.nl/api/login_provider/google
 *
 * @returns {Element}
 * @constructor
 */
const SocialLogin = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const {exchangeTokens} = useAuthService()
  const useQuery = () => {
    return new URLSearchParams(location.search);
  }
  const query = useQuery();
  const [hasError, setHasError] = useState('')

  useEffect(() => {
    const token = query.get('token');
    const error = query.get('error');
    window.history.replaceState(null, '', window.location.pathname);

    if (token) {
      exchangeTokens(
        (data) => {
          navigate('/user/mijn-standplaats')
        },
        token
      )
    }
    if (error) {
      setHasError(decodeURI(error))
    }
  }, []);


  // Your component rendering
  return (
    <div className="component-social-login">
      {(hasError === '')
        ? <Loading/>
        : <div className="error">Er is een error. Neem gerust contact op! : {hasError}</div>
      }
    </div>
  );
}

export default SocialLogin;
