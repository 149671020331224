import useBookable from "./useBookable";
import useAvailable from "./useAvailable";
import bookingStatus from "../data/bookingStatus";
import bookingActions from "../data/bookingActions";
import {DateObject} from "react-multi-date-picker";
import useFunction from "./useFunction";


const useBooking = (booking = null, setBooking = null) => {

  const {isSpot, isStand, isSpotGroup} = useBookable()
  const {isAvailable} = useAvailable()
  const {getIDsArray} = useFunction()

  /**
   *
   * @param dates
   */
  const handleSetDates = (dates) => {
    const datesText = []
    dates.map((date) => {
      datesText.push(date.toString())
      return date
    })

    setBooking && setBooking(prevBooking => {
      return {
        ...prevBooking,
        dates: dates,
        dates_text: datesText,
      }
    })
  }

  /**
   * Check if a model is booked on a specific date
   *
   * cloned in BookingService.php
   *
   * @param model model|bookable|stand|spot
   * @param date DateObject|string (start_date, etc.)
   * @returns {string|false|undefined}
   */
  const getBookingStatus = (model, date) => {
    let bookingStatus = false
    if (!isSpot(model) && !isStand(model)) {
      return undefined
    }

    /*
     * if date is string, convert it to DateObject
     */
    if (typeof date === 'string' || date instanceof String) {
      date = new DateObject(date)
    }

    /*
     * Loop through all bookings and check if the date is in the date range
     * if so, add the booking status to the bookingStatus variable
     */
    model.bookings && model.bookings.map((booking) => {
      booking.date_ranges.map((dateRange) => {
        const startDate = new DateObject(dateRange.start_date)
        const endDate = new DateObject(dateRange.end_date)
        if (
          (date.toDays() >= startDate.toDays() && date.toDays() <= endDate.toDays()) ||
          (startDate.toDays() === date.toDays())
        ) {
          bookingStatus = booking.status
        }
        return dateRange
      })
      return booking
    })

    return bookingStatus
  }

  /**
   * check if booking status is booked or available
   *
   * cloned in BookingService.php
   *
   * @param bookingStatus
   * @returns {boolean}
   */
  const isBookedStatus = (bookingStatus) => {
    if (
      bookingStatus === 'requested' ||
      bookingStatus === 'concept'
    ) {
      return false
    } else if (
      bookingStatus === 'rejected' ||
      bookingStatus === 'canceled'
    ) {
      return false
    } else if (
      bookingStatus === 'accepted' ||
      bookingStatus === 'paid' ||
      bookingStatus === 'completed'
    ) {
      return true
    }
    return undefined
  }

  /**
   * check if bookable is available - via booking - on date
   *  if it is a Stand, check in general.
   *  if it is a SpotGroup, first check spotGroup and
   *  then check if all SELECTED spots are available
   *
   *  cloned in BookingService.php
   *
   * @param model
   * @param booking
   * @param date
   * @returns {boolean}
   */
  const isAvailableBooking = (model, booking, date) => {
    let isAvailableBool = true

    if (isStand(model)) {
      if (!isAvailable(model, date)) {
        isAvailableBool = false
      }
    } else if (isSpotGroup(model)) {
      if (isAvailable(model, date)) {
        booking.spots.map((spot) => {
          if (!isAvailable(spot, date)) {
            isAvailableBool = false
          }
          return spot
        })
      } else {
        isAvailableBool = false
      }
    }
    return isAvailableBool
  }

  /**
   * check if bookable is booked - via booking - on date
   *
   * cloned in BookingService.php
   *
   * @param bookable
   * @param booking
   * @param date
   * @returns {boolean}
   */
  const isBookedBooking = (bookable, booking, date) => {
    let isBookedBool = false

    if (isStand(bookable)) {
      if (isBookedStatus(getBookingStatus(bookable, date))) {
        isBookedBool = true
      }

    } else if (isSpotGroup(bookable)) {
      ('spots' in booking) && booking.spots && booking.spots.map((spot) => {
        if (isBookedStatus(getBookingStatus(spot, date))) {
          isBookedBool = true
        }
        return spot
      })
    }
    return isBookedBool
  }

  const isDateInBooking = (booking, date) => {
    let isInBooking = false
    booking.dates && booking.dates.map((bookingDate) => {
      if (bookingDate.toDays() === date.toDays()) {
        isInBooking = true
      }
      return bookingDate
    })
    return isInBooking
  }

  /**
   * Get booking dates array from booking object
   *  //todo at some point also use date_end. for now its not supported
   *
   * @param booking
   * @returns {*[]|*}
   */
  const getBookingDates = (booking) => {
    if ('dates' in booking) {
      return booking.dates
    } else {
      let dates = []
      booking.date_ranges.map(dateRange => {
        dates.push(new DateObject(dateRange.start_date))
        return dateRange
      })
      return dates
    }
  }

  /**
   * Convert booking.dates to array with strings
   */
  const datesToStrings = (booking) => {
    const dates = []
    booking.dates && booking.dates.map((date) => {
      dates.push(date.format('YYYY-MM-DD'))
    })
    return dates
  }

  /**
   *
   * @param value
   * @returns {*}
   */
  const getBookingStatusLabel = (value) => {
    return bookingStatus.find(status => status.value === value).label
  }

  /**
   *
   * @param value
   * @returns {*}
   */
  const getBookingActionLabel = (value) => {
    return bookingActions.find(action => action.value === value).label
  }

  /**
   * move bookings from spotGroup.bookings to spots.bookings
   *
   * @param spotGroup
   * @returns {*}
   */
  const parseSpotGroupBookings = (spotGroup) => {
    spotGroup.spots.map((spot) => {
      spot.bookings = []
      spotGroup.bookings.map((booking) => {
        booking.spots.map((bookingSpot) => {
          if (spot.id === bookingSpot.spot_id) {
            spot.bookings.push(booking)
          }
        })
      })
      return spot
    })
    return spotGroup
  }

  /**
   * Get the classnames for spots
   *
   * Helper function for SpotMapPublic component
   *
   * @param spot
   * @param booking
   * @returns {string}
   */
  const getSpotStateClass = (spot, booking = null) => {
    let classNames = new Set(['mappable-state']); // Use a Set to avoid duplicates

    if (spot.available === 0) {
      classNames.add('not_available');
    } else if (spot.available === 1) {
      classNames.add('available');
    }

    if (booking){
      const alreadySelected = booking.spots.find(item => item.id === spot.id);
      const bookingDates = ('dates' in booking) ? booking.dates : booking.date_ranges;

      if (alreadySelected) {
        classNames.add('selected');
      }

      bookingDates.forEach((bookingDate) => {
        if (!('dates' in booking)) {
          bookingDate = new DateObject(bookingDate.start_date);
        }

        const bookingStatus = getBookingStatus(spot, bookingDate); // Ensure this function returns only 'occupied' or '' (empty string) if that's the intention
        classNames.add(bookingStatus)

        if (isBookedStatus(bookingStatus)) { // this checks if the spot is occupied
          classNames.add('occupied');
        }

        if (!classNames.has('not_available') && !classNames.has('occupied')) {
          classNames.add(isAvailable(spot, bookingDate) ? 'available' : 'not_available');
        }
      });
    }

    return Array.from(classNames).join(' ');
  }

  /**
   * Add selected spot to booking
   * Helper function for SpotMapPublic component
   *
   * @param booking
   * @param setBooking
   * @param spot
   */
  const toggleSpotInBooking = (booking, setBooking, spot) => {
    //return when spot has non available classes
    const classes = getSpotStateClass(spot, booking)
    if (classes.includes('not_available') || classes.includes('occupied')) {
      return
    }

    let newSpots = [...booking.spots]; // Create a new array
    const index = newSpots.findIndex(item => item.id === spot.id);

    if (index === -1) {
      newSpots.push(spot); // Add new spot
    } else {
      newSpots.splice(index, 1); // Remove the spot
    }

    // Create a new booking object for immutability
    const newBooking = {
      ...booking,
      spots: newSpots,
      spots_ids: getIDsArray(newSpots)
    };

    setBooking(newBooking); // Update the state with the new booking object
  };

  return {
    handleSetDates,
    getBookingStatusLabel,
    getBookingActionLabel,
    getBookingStatus,
    isBookedStatus,
    isAvailableBooking,
    isBookedBooking,
    getBookingDates,
    parseSpotGroupBookings,
    datesToStrings,
    isDateInBooking,
    getSpotStateClass,
    toggleSpotInBooking,
  }
}


export default useBooking
