import useBookable from "./useBookable";
import {DateObject} from "react-multi-date-picker";

const useAvailable = () => {

  const {isStand, isSpotGroup, isSpot} = useBookable()



  /**
   * //todo duplicate from usePrice.js
   *
   * @type {{"0": string, "1": string, "2": string, "3": string, "4": string, "5": string, "6": string}}
   */
  const dayKeys = {
    0: 'su',
    1: 'mo',
    2: 'tu',
    3: 'we',
    4: 'th',
    5: 'fr',
    6: 'sa',
  }
  /**
   * //todo duplicate from usePrice.js
   *
   * @param weekDayIndex
   * @return {string}
   */
  const getDayKey = (weekDayIndex) => {
    if (weekDayIndex in dayKeys) {
      return dayKeys[weekDayIndex]
    }
  }

  /**
   * Check if a model is available on a specific date
   *
   * The same method exists in the backend, see AvailabilityService->isAvailable
   *
   * there is an order of priority for the availability:
   * 1. model.available is for all dates and most important
   * 2. specific dates overrides model.available_dates
   * 3. specific dates overrides model.availabilities
   *
   * @param model model\bookable|stand\spotGroup\spot
   * @param date DateObject|string (start_date, etc.)
   * @returns {boolean}
   */
  const isAvailable = (model, date) => {
    if (!isSpot(model) && !isStand(model) && !isSpotGroup(model)) {
      return undefined
    }

    if (model.available === 0 || model.available === '0') {
      return false
    } else if (model.available === 1 || model.available === '1') {
      return true
    } else if (model.available === 2 || model.available === '2') {

      if (typeof date === 'string' || date instanceof String) {
        date = new DateObject(date)
      }

      /*
       * if availability_on_going is true, 1 or '1', then default isAvailableBool = true
       */
      // let isAvailableBool = model.availability_on_going
      let isAvailableBool = false
      let hasAvailableDate = false

      model.available_dates && model.available_dates.map((availableDate) => {
        const availableDateDate = new DateObject(availableDate.date)
        if (date.toDays() === availableDateDate.toDays()) {
          hasAvailableDate = true
          isAvailableBool = availableDate.open
        }
        return availableDate
      })

      if (!hasAvailableDate && model.availability_on_going) {
        model.availabilities.map((availability) => {
          if ('is_trash' in availability && availability.is_trash) {

          } else {
            const startDate = new DateObject(availability.start_date)
            const endDate = new DateObject(availability.end_date)
            if (
              (
                availability.start_date && availability.end_date &&
                date.toDays() >= startDate.toDays() && date.toDays() <= endDate.toDays()
              )
              ||
              (availability.start_date && !availability.end_date &&
                date.toDays() >= startDate.toDays()
              )
              ||
              (!availability.start_date && availability.end_date &&
                date.toDays() <= endDate.toDays()
              )
              ||
              (!availability.start_date && !availability.end_date)
            ) {
              const dayKey = getDayKey(date.weekDay.index)
              isAvailableBool = availability[dayKey]
            }
          }
          return availability
        })
      }

      return isAvailableBool
    }
  }

  /**
   * Check if a model has custom available override on a specific date
   *
   * @param model
   * @param date
   * @returns {boolean}
   */
  const isAvailableCustomDate = (model, date) => {
    let isCustomDate = false
    model.available_dates.map((availableDate) => {
      const availableDateDate = new DateObject(availableDate.date)
      if (date.toDays() === availableDateDate.toDays()) {
        isCustomDate = true
      }
      return availableDate
    })
    return isCustomDate
  }

  /**
   * Toggle the availability of a model on a specific date
   *
   * @param form
   * @param date
   */
  const toggleAvailableDate = (form, date) => {
    const model = form.formData.model
    const dateString = date.format('YYYY-MM-DD')
    const originalValue = isAvailable(model, date)
    const newAvailableDate = {
      open: !originalValue,
      date: dateString,
      stand_id: isStand(model) ? model.id : null,
      spot_group_id: isSpotGroup(model) ? model.id : null,
      spot_id: isSpot(model) ? model.id : null,
    }
    let availableData = model.available_dates.find(availableData => availableData.date === dateString)
    if (availableData) {
      const index = model.available_dates.indexOf(availableData)
      model.available_dates.splice(index, 1)
    } else {
      model.available_dates.push(newAvailableDate)
    }

    model.available_dates_is_null = (model.available_dates.length === 0);
    form.save()
  }

  return {
    isAvailable,
    toggleAvailableDate,
    isAvailableCustomDate,
  }
}

export default useAvailable
