import React from "react";
import AnchorTitle from "../AnchorMenu/AnchorTitle";
import PropTypes from "prop-types";
import "./style.scss"


const LocationForm = (props) => {

  const {
    state,
    setStateCity,
    setStatePostalCode,
    setStateRadius,
    showRadius,
  } = props


  return (
    <div className="component-location-form">
      <label>Plaats
        <input
          type="text"
          name="city"
          onChange={e => setStateCity(e.target.value)}
          value={state.city}
          // autoFocus={true}
        />
      </label>

      <label>Postcode (4 cijfers)
        <input
          className="postal_code"
          type="number"
          max="9999"
          min="1000"
          name="postal_code"
          onChange={e => setStatePostalCode(e.target.value)}
          value={state.postal_code}
        />
      </label>

      {showRadius &&
        <div className="radius-form">
        <label>Maximale reisafstand
          <div className="form-row">
            <input
              type="number"
              min="0"
              step="10"
              name="radius"
              onChange={e => setStateRadius(e.target.value)}
              value={state.radius}
            />
            <span className="after-label">KM</span>
          </div>
        </label>
        </div>
      }
    </div>
  )
}

AnchorTitle.defaultProps = {
  showRadius: true,
}

AnchorTitle.propTypes = {
  state: PropTypes.object.isRequired,
  setStateCity: PropTypes.func.isRequired,
  setStatePostalCode: PropTypes.func.isRequired,
  setStateRadius: PropTypes.func.isRequired,
  showRadius: PropTypes.bool,
}

export default LocationForm
