import useApi from "../useApi";
import useFunction from "../../hooks/useFunction";


const useBookableService = () => {

  const {api} = useApi()
  const {c} = useFunction()

  const show = (callbackSuccess, callbackValidationErrors, callbackSwal, filter) => {
    return api(
      'post',
      'bookable/filtered/',
      response => callbackSuccess(response.data),
      callbackValidationErrors,
      error =>  c('Error in Service', error),
      filter,
      true,
      callbackSwal,
    )
  }

  const showBookableMonthBookings = (callbackSuccess, callbackValidationErrors, objectType, bookableIds, month, selectedDates) => {
    return api(
      'post',
      'bookable/month-bookings/',
      response => callbackSuccess(response.data),
      callbackValidationErrors,
      error =>  c('Error in Service', error),
      {
        object_type: objectType, //stand, spot_group, bookables
        bookable_ids: bookableIds,
        month: month,
        selected_dates: selectedDates
      },
      true,
    )
  }

  const showBookableRangeBookings = (callbackSuccess, callbackValidationErrors, objectType, bookableId, startDate, endDate) => {
    return api(
      'post',
      'user/bookable/range-bookings/',
      response => callbackSuccess(response.data),
      callbackValidationErrors,
      error =>  c('Error in Service', error),
      {
        object_type: objectType, //stand, spot_group, bookables
        bookable_id: bookableId,
        start_date: startDate,
        end_date: endDate,
      },
      true,
    )
  }

  return {
    show,
    showBookableMonthBookings,
    showBookableRangeBookings,
  }
}


export default useBookableService
