import {useState} from "react";

/**
 * Convert formData to new FormData() for multipart/form-data
 * support for API reasons (mainly for sending files via the API)
 *
 * //todo, at some point (above 132kb) this function is not working correct:
 *    It will stop adding fields to formDataObj without notice/warning..
 *    or it wil give a network error without any further info
 *
 *
 */

const useForm = () => {


  /**
   * Get objects from FormContext
   */
  const [formData, setFormData] = useState({
    model: {},
    isChanged: false,
    isStored: false,
    isLoading: true,
    isLocked: false,
  })

  const [originalFormDataModel, setOriginalFormDataModel] = useState(null)

  /**
   *
   * @param isChanged
   */
  const save = (isChanged = true) => {
    setFormData(prevFormData => {
      return {
        ...prevFormData, model: formData.model,
        isChanged: isChanged,
        isStored: false,
      }
    })
  }

  /**
   *
   * @param isLoading
   */
  const setLoading = (isLoading = true) => {
    setFormData(prevFormData => {
      return {
        ...prevFormData, isLoading: isLoading,
      }
    })
  }

  /**
   * //todo this method could be more DRY
   *
   * @param disabledByKeys
   * @param enabledBy
   * @returns {boolean}
   */
  const showField = (disabledByKeys, enabledBy) => {
    let enabled = true
    disabledByKeys && disabledByKeys.map((disabledByKey) => {
      enabled = !(formData &&
        !formData.isLoading &&
        'model' in formData &&
        formData.model &&
        formData.model[disabledByKey.key] !== null &&
        formData.model[disabledByKey.key] === disabledByKey.value
      );
      return disabledByKey
    })

    enabledBy && enabledBy.map((enabledByKey) => {
      enabled = !!(formData &&
        !formData.isLoading &&
        'model' in formData &&
        formData.model &&
        formData.model[enabledByKey.key] !== null &&
        formData.model[enabledByKey.key] === enabledByKey.value
      );
      return enabledByKey
    })

    return enabled
  }

  /**
   * Convert formData object to new FormData() JS object, so
   * the data can be sent via api
   *
   * @param model
   * @param form
   * @param namespace
   * @returns {FormData}
   */
  const getFormDataObj = (model, form = null, namespace = '') => {
    let formDataObj = form || new FormData();
    for (let propertyName in model) {
      const value = model[propertyName]
      if (!model.hasOwnProperty(propertyName)) {
        continue;
      }
      const formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
      // if value has bool or zero. otherwise, cancel
      if (!value && (value !== false && value !== 0 && value !== '')) {
        continue;
      }
      if (value instanceof Date)
        formDataObj.append(formKey, value.toISOString());
      else if (typeof value == 'number')
        formDataObj.append(formKey, value);
      else if (value instanceof Array) {
        value.forEach((element, index) => {
          const tempFormKey = `${formKey}[${index}]`;
          if (typeof element === 'number' || typeof element === 'string') { //todo need other types???
            formDataObj.append(tempFormKey, element.toString());
          } else {
            getFormDataObj(element, formDataObj, tempFormKey);
          }
        });
      } else if (value && typeof value === 'object' && 'file' in value) {
        formDataObj.append(formKey, value['file']);
      } else if (typeof value === 'object' && !(value instanceof File)) {
        getFormDataObj(value, formDataObj, formKey);
      } else {
        formDataObj.append(formKey, value.toString());
      }
    }
    return formDataObj;
  }

  /**
   *
   * @param value
   * @param defaultValue
   * @returns {*|string}
   */
  const getValue = (value, defaultValue = '') => {
    return (value && value !== '') ? value : defaultValue
  }

  return  {
    formData,
    setFormData,
    originalFormDataModel,
    setOriginalFormDataModel,
    getFormDataObj,
    getValue,
    save,
    setLoading,
    showField,
  }
}

export default useForm
