import React, {useEffect, useState} from "react";
import {months, weekDays} from "../../data/calendar";
import initAvailability from "../../data/initAvailability";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useAvailable from "../../hooks/useAvailable";
import DatePicker, {Calendar, DateObject} from "react-multi-date-picker";
import Button from "../Button/Button";
import Info from "../Info/Info";
import swal from "sweetalert";
import PropTypes from "prop-types";
import addIcon from "../../assets/icons/add-white.svg"
import './Availabilities-style.scss'

const Availabilities = (props) => {

  const {
    label,
    text,
    value,
    className,
    hasError,
    error,
    isLoading,
    formData,
    setFormData,
    form,
    save,
  } = props

  const {
    isAvailable,
    toggleAvailableDate,
    isAvailableCustomDate,
  } = useAvailable()

  const {width} = useWindowDimensions();
  const [nCalendars, setNCalendars] = useState(3)
  const [legendDateState, setLegendDateState] = useState(true)

  useEffect(() => {
    if (value.length === 0) {
      handleAddPeriod(false)
    }
  }, [])

  useEffect(() => {
    if (width < 1080 && width >= 820) {
      setNCalendars(2)
    } else if (width < 820) {
      setNCalendars(1)
    } else {
      // setNCalendars(3)
      setNCalendars(2)
    }

  }, [width])

  /**
   *
   * @param e
   * @param i
   */
  const onChangeDay = (e, i) => {
    value[i][e.target.name] = (e.target.checked) ? 1 : 0
    setFormData(prevFormData => {
        return {
          ...prevFormData,
          collection: formData.collection, //todo change to .model
          isChanged: true,
        }
      }
    )
  }

  /**
   *
   * @param key
   * @param i
   * @returns {*|boolean}
   */
  const getValue = (key, i) => {
    return (value[i] && key in value[i]) ? value[i][key] : false
  }

  /**
   *
   * @param key
   * @param i
   * @param day
   * @returns {JSX.Element}
   */
  const dayField = (key, i, day) => {
    return (
      <div className="day">{day}
        <div className="form-row">
          <input
            disabled={isLoading}
            type={'checkbox'}
            name={key}
            checked={getValue(key, i)}
            onChange={(e) => onChangeDay(e, i)}
          />
        </div>
      </div>
    )
  }

  /**
   *
   * @param i
   */
  const handleDelete = (i) => {
    swal({
      title: 'Deze periode Verwijderen?',
      icon: 'warning',
      dangerMode: true,
      text: '',
      buttons: {
        cancel: {
          text: 'Annuleren',
          value: null,
          visible: true,
          className: 'btn-color-grey btn-size-m',
          closeModal: true,
        },
        confirm: {
          text: 'Verwijderen',
          value: true,
          visible: true,
          className: 'btn-color-red btn-size-m',
          closeModel: true,
        }
      }
    }).then((result) => {
      if (result) {
        deleteRow(i)
      }
    })
  }

  /**
   *
   * @param i
   */
  const deleteRow = (i) => {
    value[i].is_trash = true
    setFormData(prevFormData => {
        return {
          ...prevFormData,
          collection: formData.collection, //todo change to .model
          isChanged: true,
        }
      }
    )
  }

  // /**
  //  *
  //  * @param dates
  //  * @param i
  //  */
  // const handeChangeCalendar = (dates, i) => {
  //   value[i].start_date = null
  //   value[i].end_date = null
  //
  //   if (dates.length === 1) {
  //     value[i].start_date = dates[0].toString()
  //   } else if (dates.length === 2) {
  //     value[i].start_date = dates[0].toString()
  //     value[i].end_date = dates[1].toString()
  //   }
  //   setFormData(prevFormData => {
  //       return {
  //         ...prevFormData,
  //         collection: formData.collection,
  //         isChanged: true,
  //       }
  //     }
  //   )
  // }

  /**
   *
   * @param i
   * @returns array
   */
  const getCalendarValueStart = (i) => {
    return (value[i].start_date) ? new DateObject(value[i].start_date) : null
  }

  /**
   *
   */
  const getCalendarValueEnd = (i) => {
    return (value[i].end_date) ? new DateObject(value[i].end_date) : null
  }

  /**
   *
   * @param date
   * @param i
   */
  const handeChangeCalendarStart = (date, i) => {
    value[i].start_date = (date) ? date.toString() : null
    save()
  }

  /**
   *
   * @param date
   * @param i
   */
  const handeChangeCalendarEnd = (date, i) => {
    value[i].end_date = (date) ? date.toString() : null
    save()
  }

  // /**
  //  *
  //  * @param i
  //  * @returns array
  //  */
  // const getCalendarStartValue = (i) => {
  //   return [
  //     (value[i].start_date) ? new DateObject(value[i].start_date) : null,
  //     (value[i].end_date) ? new DateObject(value[i].end_date) : null,
  //   ]
  // }


  /**
   *
   */
  const handleAddPeriod = (isChanged = true) => {
    value.push(initAvailability)
    setFormData(prevFormData => {
        return {
          ...prevFormData,
          collection: formData.collection,
          isChanged: isChanged,
        }
      }
    )
  }

  // /**
  //  *
  //  *
  //  * @param date
  //  * @returns {JSX.Element}
  //  */
  // const calendarDay = (date) => {
  //   const newDate = new DateObject(date)
  //   return (
  //     <div className="calendar-day">
  //       <div className="date">{newDate.format("D")}</div>
  //     </div>
  //   )
  // }


  /**
   *
   * @param e
   */
  const onChangeRadio = (e) => {
    formData.model.availability_on_going = (!availabilityOnGoing())
      ? 1
      : 0
    save()

    // swal({
    //   title: 'Switchen?',
    //   icon: 'warning',
    //   text: 'Alle eerder handmatig ingevoerde data wordt verwijderd. (dit is echter pas definitief na het opslaan van het formulier)',
    //   buttons: {
    //     cancel: {
    //       text: 'Annuleren',
    //       value: null,
    //       visible: true,
    //       className: 'btn btn-dark',
    //       closeModal: true,
    //     },
    //     confirm: {
    //       text: 'Oke',
    //       value: true,
    //       visible: true,
    //       // className: 'btn btn-red', //todo werkt nu niet??
    //       closeModel: true,
    //     }
    //   }
    // }).then((result) => {
    //   if (result) {
    //     // formData.model.available_dates = []
    //     formData.model.availability_on_going = (!availabilityOnGoing())
    //       ? 1
    //       : 0
    //     save()
    //   }
    // })
  }

  /**
   *
   *
   * @returns {number}
   */
  const availabilityOnGoing = () => {
    return (parseInt(formData.model.availability_on_going))
  }


  const changeRoundColor = () => {

  }

  return (
    <div className={className + 'availabilities '}>
      <p>{label}</p>
      {text && <p style={{margin: 0}} className="text-small">{text}</p>}
      {hasError && <span className="error"> - {error}</span>}


      <div className="radio form-row">
        <label>Doorlopend beschikbaar (zoals weekmarkten)
          <input
            disabled={isLoading}
            checked={!!availabilityOnGoing()}
            onChange={onChangeRadio}
            type='radio'
          />
        </label>
        <label>Tijdelijk of specifieke dagen (zoals evementen)
          <input
            disabled={isLoading}
            checked={!availabilityOnGoing()}
            onChange={onChangeRadio}
            type='radio'
          />
        </label>
      </div>

      <>
        {!!availabilityOnGoing() &&
          <>
            {/*<Info>*/}
            {/*  <p>Voer één of meerdere periodes in.</p>*/}
            {/*</Info>*/}

            {value.map((availability, i) => {
              return (
                <div key={i}>
                  {!('is_trash' in availability) && !availability.is_trash &&
                    <div className="availability">
                      <div className="date-pickers">

                        <div className="row row-labels">
                          <div className="col label">
                            Vanaf:
                          </div>

                          <div className="col label">
                            Eindigt op:
                          </div>
                        </div>

                        <div className="row ">
                          <div className="col elements">
                            {value[i].start_date ?
                              <DatePicker
                                value={getCalendarValueStart(i)}
                                onChange={(dates) => handeChangeCalendarStart(dates, i)}
                                numberOfMonths={1}
                                // format="DD-MM-YYYY"
                              />
                              :
                              <>Sinds altijd</>
                            }
                            <Button
                              label={(value[i].start_date) ? 'X' : 'Startdatum instellen'}
                              size="xs"
                              color={(value[i].start_date) ? 'red' : 'grey'}
                              onClick={() => {
                                value[i].start_date = (value[i].start_date) ? null : new DateObject().add("2", "day").toString();
                                save()
                              }}
                            />
                          </div>


                          <div className="col elements">

                            {value[i].end_date ?
                              <DatePicker
                                value={getCalendarValueEnd(i)}
                                onChange={(dates) => handeChangeCalendarEnd(dates, i)}
                                numberOfMonths={1}
                                // format="DD-MM-YYYY"
                              />
                              :
                              <>Oneindig</>
                            }
                            <Button
                              label={(value[i].end_date) ? 'X' : 'Einddatum instellen'}
                              size="xs"
                              color={(value[i].end_date) ? 'red' : 'grey'}
                              onClick={() => {
                                value[i].end_date = (value[i].end_date) ? null : new DateObject().add("2", "month").toString();
                                save()
                              }}
                            />
                          </div>
                        </div>

                      </div>

                      <div className="day-form">
                        <p>In deze periode open op:</p>
                        <div className="days">
                          {dayField('mo', i, 'Maandagen')}
                          {dayField('tu', i, 'Dinsdagen')}
                          {dayField('we', i, 'Woensdagen')}
                          {dayField('th', i, 'Donderdagen')}
                          {dayField('fr', i, 'Vrijdagen')}
                          {dayField('sa', i, 'Zaterdagen')}
                          {dayField('su', i, 'Zondagen')}
                        </div>
                      </div>

                      <div className="buttons">
                        {i !== 0 &&
                          <Button
                            label="Verwijderen"
                            size="s"
                            color="red"
                            onClick={() => handleDelete(i)}
                          />
                        }
                      </div>
                    </div>
                  }
                </div>
              )
            })}
            <Button
              label="Nog een Periode toevoegen"
              icon={addIcon}
              onClick={() => handleAddPeriod()}
            />
          </>
        }

        <p>Klik hieronder in de kalender op een specifieke dag om te openen of te sluiten</p>
        Legenda:
        <Info
          label="Legenda"
          variant="light"
        >
          <div className="legend">
            <div>
              <div className="label">Beschikbaar:</div>
              <div className="round green"></div>
            </div>
            <div>
              <div className="label">Niet beschikbaar:</div>
              <div className="round red"></div>
            </div>
            <div>
              <div className="label">Handmatig aangepast:</div>
              <div className="sub-label">(klik in de kalener op bolletjes om ze aan te passen)</div>
              <div className="row">
                <div
                  onClick={() => setLegendDateState(!legendDateState)}
                  className={"round border " + (legendDateState ? 'green' : 'red')}
                ></div>
                <div
                  className={"round border " + (legendDateState ? 'red' : 'green')}
                  onClick={() => setLegendDateState(!legendDateState)}
                ></div>
              </div>
            </div>
            <div>
              <div className="label">Vandaag:</div>
              <div className="row">
                <div
                  className={"round border-orange " + (legendDateState ? 'red' : 'green')}
                  onClick={() => setLegendDateState(!legendDateState)}
                ></div>
                <div
                  className={"round border-orange " + (!legendDateState ? 'red' : 'green')}
                  onClick={() => setLegendDateState(!legendDateState)}
                ></div>
              </div>
            </div>
          </div>
        </Info>
        <Calendar
          numberOfMonths={nCalendars}
          readOnly
          weekStartDayIndex={1}
          shadow={false}
          weekDays={weekDays}
          months={months}
          mapDays={({date, today, selectedDate, currentMonth, isSameDate}) => {
            const result = date.toDays() - today.toDays()
            const isCustomDate = isAvailableCustomDate(formData.model, date);
            if (result < 0) return {
              disabled: true,
              style: {color: "#ccc"},
            }

            if (isAvailable(formData.model, date)) {
              if (isCustomDate) {
                return {
                  disabled: false,
                  style: {backgroundColor: '#58AD50', color: "white", border: "3px solid #2D3040"},
                  onClick: () => toggleAvailableDate(form, date)
                }
              } else {
                return {
                  disabled: false,
                  style: {backgroundColor: '#58AD50', color: "white"},
                  onClick: () => toggleAvailableDate(form, date)
                }
              }
            } else {
              if (isCustomDate) {
                return {
                  disabled: false,
                  style: {backgroundColor: '#FF6969', color: "white", border: "3px solid #2D3040"},
                  onClick: () => toggleAvailableDate(form, date)
                }
              } else {
                return {
                  disabled: false,
                  style: {backgroundColor: '#FF6969', color: "white"},
                  onClick: () => toggleAvailableDate(form, date)
                }
              }
            }
          }}
        />
      </>
    </div>
  )
}

Availabilities.defaultProps = {
  label: '',
  name: '',
  text: '',
  value: [],
  type: 'text',
  className: '',
  hasError: false,
  onChange: () => {
  },
  error: '',
  disabledByKey: '',
}

Availabilities.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.array,
  className: PropTypes.string,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.string,
  disabledByKey: PropTypes.string,
}

export default Availabilities
