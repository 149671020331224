import React, {useContext, useEffect, useState} from "react"
import {backendUrl} from "../../env";
import AuthContext from "../../context/AuthContext";
import {Calendar} from "react-multi-date-picker";
import Button from "../../components/Button/Button";
import useApi from "../../api/useApi";
import Loading from "../../components/Loading/Loading";
import Tabs from "../../components/Tabs/Tabs";
import Tab from "../../components/Tabs/Tab";
import './style.scss'
import AdminNav from "../../components/AdminNav/AdminNav";
import swal from "sweetalert";

const UserAdminTools = () => {

  const [cacheIndex, setCacheIndex] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [isRunning, setIsRunning] = useState(false)
  const [error, setError] = useState(false)
  const {user} = useContext(AuthContext)
  const [date, setDate] = useState(null)
  const [model, setModel] = useState('spot_group')
  const [done, setDone] = useState('')
  const [isLoading2, setIsLoading2] = React.useState(false)
  const {api} = useApi()

  const handleCacheReset = () => {
    setIsRunning(!isRunning)
  }

  const cacheCallback = () => {

    const timeout = setTimeout(() => {
      setIsLoading(true)
      return api(
        'post',
        'admin/re-generate-bookables/',
        response => {
          if (!isNaN(response.data.index)) {
            setCacheIndex(response.data.index)
          } else if (response.data[0] === 'done') {
            setIsRunning(false)
            setCacheIndex(1)
            console.log('Done', response.data)
            const doneString = ' Done: '+model+' '+ date.format('YYYY-MM-DD').toString()
            setDone(done+' '+doneString)
            date.add(1, 'day')
            setIsRunning(true)
          } else {
            setError(true)
            console.log('ERROR', response.data)
          }
          setIsLoading(false)
        },
        errors => console.log('TEST 1', errors),
        error => console.log('TEST 2', error),
        {
          index: cacheIndex,
          date_string: (date)?date.format('YYYY-MM-DD'):null,
          model: model,
        },
        true,
        ()=>{},
        300000
      )

    }, 1000)
  }

  useEffect(() => {
    isRunning && cacheCallback()
  }, [isRunning])

  useEffect(() => {
    isRunning && cacheCallback()
  }, [cacheIndex])


  const handleClick = () => {

    setIsLoading2(true)
    api(
      'post',
      'admin/send-alerts/',
      () => {
        setIsLoading2(false)
      },
      () => {
        setIsLoading2(false)
      },
      () => {
        setIsLoading2(false)
      }
    )
  }


  const handleAnonymize = () => {
    swal({
      title: 'Anonymize?',
      icon: '', //question //todo not working??
      dangerMode:true,
      buttons: {
        cancel: {
          text: "Annuleren",
          value: null,
          visible: true,
          className: 'btn-color-grey btn-size-m',
          closeModal: true,
        },
        confirm: {
          text: "Anonymize!",
          value: true,
          visible: true,
          className: 'btn-color-red btn-size-m',
          closeModal: true,
        }
      }
    }).then((result) => {
      if (result) {
        api(
          'get',
          'admin/anonymize-for-local',
          (response) => {
            swal({
              title: response.message,
              icon: 'success',
              timer: 1000,
              buttons: false,
            }).then(() => {
            })
          },
          () => {},
          (errors) => {
            //error
          }
        )
      }
    })
  }

  return (
    <div className="page-user-admin">
      {user && user.is_admin &&
        <>
          <AdminNav/>
          <h4>Beheer links:</h4>
          <div className="form-row">
            <Button
              label="See email template"
              linkOutside={backendUrl + "/email-template-tester-main-example"}
              target={"_blank"}
            />
            <Button
              label="Trello board"
              linkOutside="https://trello.com/b/ZrqSM2zc/website"
              target={"_blank"}
            />
            <Button
              label="Website beheer (Wordpress)"
              linkOutside="https://standplaats.nl/wp-admin"
              target={"_blank"}
            />
          </div>


          <hr/>
          <h4>Check and send Alerts</h4>


          <Button
            label="Send alerts"
            onClick={handleClick}
          />
          {isLoading2 && <Loading/>}

          <hr/>
          <h4>Anonymize database</h4>

          <Button
            label="Anonimize! (dev and local only!)"
            onClick={handleAnonymize}
            color="red"
          />
          {isLoading2 && <Loading/>}

          <hr/>



          <h4>Regenerate caches:</h4>

          <div className="form-row">
            <Button
              label={(isRunning) ? "RUNNING! Stop" : "Re-generate search caches"}
              color="blue"
              // disabled={date === null}
              onClick={handleCacheReset}
            />


            <Button
              label="Re-Generate media sizes"
              color="red"
              target={"_blank"}
              linkOutside={backendUrl + "/api/admin/re-create-media"}
            />


          </div>


          <div className="form-row">
            <div>
              <h4>Results:</h4>
              {/*{cacheIndex &&*/}
                <div>

                  <div className="form-row">

                    <div>Start Model ID:
                      <input
                        type="number"
                        value={cacheIndex}
                        onChange={(e) => setCacheIndex(e.target.value)}
                      />


                      <Tabs
                        activeTab={model}
                        setActiveTab={id => setModel(id)}
                        design="buttons"
                        // noPadding={true}
                      >
                        <Tab
                          label="SpotGroups"
                          id="spot_group"
                        />
                        <Tab
                          label="Stands"
                          id="stand"
                        />
                      </Tabs>
                    </div>



                    {!isRunning &&
                      <div><Button label="Reset count" color="red" size="xs" onClick={() => setCacheIndex(1)}/></div>
                    }
                  </div>

                  <div className="form-row">

                    <Calendar
                      value={date}
                      onChange={setDate}
                    />

                    <Button
                      color="red"
                      size="s"
                      label="1 jaar"
                      onClick={() => setDate(null)}
                    />
                  </div>

                  <div className="form-row">
                    {done && done}
                  </div>

                </div>
              {/*}*/}
              {isLoading &&
                <Loading/>
              }
              {error &&
                <p>Has error</p>
              }
            </div>
          </div>
        </>
      }
    </div>


  )
}

export default UserAdminTools
