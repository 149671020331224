import React, {useRef, useCallback, useState, useEffect} from "react";
import {googleMapsApiKey} from "../../env";
import PropTypes from "prop-types";
import {Circle, GoogleMap, LoadScript, Marker, StandaloneSearchBox} from "@react-google-maps/api";
import mapStand from "../../assets/icons/map-stand.svg";
import mapSpotGroup from "../../assets/icons/map-spot-group.svg";
import useBookable from "../../hooks/useBookable";
import swal from "sweetalert";
import './Location-style.scss'

const libraries = ["drawing", "places"];

const Location = (props) => {

  const {label, model, className, hasError, error, save} = props
  const {isStand} = useBookable()
  const [zoom, setZoom] = useState(8)
  const [center, setCenter] = useState(
    model.lat && model.lng ?
      {lat: model.lat, lng: model.lng}
      :
      {lat: 52.497118623051904, lng: 5.5198451726829845}
  )
  const mapRef = useRef(null);
  const circleRef = useRef(null);

  /**
   * Handle click on map
   *
   * @param event
   */
  const handleClick = (event) => {
    popup(() => {
      handleChangePositionEvent(event)

    })
  }

  /**
   * Handle change position event
   *
   * @param event
   */
  const handleChangePositionEvent = (event) => {
    model.lat = event.latLng.lat()
    model.lng = event.latLng.lng()
    save()
  }

  /**
   *
   * @type {{lng: number, lat: number}}
   */
  const coordinates = {
    lat: parseFloat(model.lat),
    lng: parseFloat(model.lng),
  }

  const popup = (callback) => {
    swal({
      title: 'Deze locatie gebruiken?',
      icon: 'warning', //todo question
      text: 'Je kan ook door op de map te kliken de locatie aanpassen en presiezer plaatsen.',
      buttons: {
        cancel: {
          text: 'nee',
          value: null,
          visible: true,
          className: 'btn-color-grey btn-size-m',
          closeModal: true,
        },
        confirm: {
          text: 'Deze locatie kiezen',
          value: true,
          visible: true,
          className: 'btn-color-green btn-size-m',
          closeModel: true,
        }
      }
    }).then((result) => {
      if (result) {
        callback()
      }
    })
  }

  /**
   *
   * @param args
   */
  const onPlacesChanged = (args) => {
    setNewLocation();
    popup(() => {
      model.lat = mapRef.current.getPlaces()[0].geometry.location.lat()
      model.lng = mapRef.current.getPlaces()[0].geometry.location.lng()
      save()
    })
  }

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onLoad = useCallback(
    map => {
      mapRef.current = map;
    },
    [onPlacesChanged]
  );

  /**
   *
   */
  const setNewLocation = () => {
    setZoom(11)
    setCenter({
      lat: mapRef.current.getPlaces()[0].geometry.location.lat(),
      lng: mapRef.current.getPlaces()[0].geometry.location.lng(),
    })
  }

  /**
   *
   */
  const onRadiusChanged = () => {
    if (circleRef && circleRef.current) {
      model.radius = Math.round(circleRef.current.state.circle.radius / 1000)
      save()
    }
  }

  return (
    <div className={className + 'location '}>
      <label>{label}
        {hasError && <span className="error"> - {error}</span>}
      </label>
      <LoadScript
        googleMapsApiKey={googleMapsApiKey}
        libraries={libraries}
      >
        <GoogleMap
          mapContainerClassName={'map-container-inner'}
          zoom={zoom}
          center={center}
          clickableIcons={false}
          streetViewControl={false}
          onClick={handleClick}
        >
          <StandaloneSearchBox
            onLoad={onLoad}
            onPlacesChanged={onPlacesChanged}
          >
            <input
              type="text"
              placeholder="Zoek naar adres of plaatsnamen"
              onKeyPress={(e)=>{e.target.keyCode === 13 && e.preventDefault();}}
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `260px`,
                height: `49px`,
                padding: `12px 12px`,
                borderRadius: `30px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: "absolute",
                left: "50%",
                top: "10px",
                marginLeft: "-130px"
              }}
            />
          </StandaloneSearchBox>
          {model.lat && model.lng &&
            <>
              <Marker
                position={{
                  lat: parseFloat(model.lat),
                  lng: parseFloat(model.lng),
                }}
                draggable={true}
                onDragEnd={handleChangePositionEvent}
                icon={(isStand(model)) ? mapStand : mapSpotGroup}
              />
              <>
                {model.radius &&
                  <Circle
                    ref={circleRef}
                    center={coordinates}
                    radius={model.radius * 1000}
                    editable={true}
                    onRadiusChanged={() => onRadiusChanged()}
                    onClick={handleClick}
                    options={{
                      strokeColor: "#58AD50",
                    }}
                  />
                }
              </>
            </>
          }
        </GoogleMap>
      </LoadScript>
    </div>
  )
}
Location.defaultProps = {
  label: '',
  name: '',
  type: 'text',
  className: '',
  hasError: false,
  onChange: () => {
  },
  error: '',
}

Location.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.string,
}

export default Location
