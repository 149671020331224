import React, {useEffect} from "react";
import PropTypes from "prop-types";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {localhost, dev, mainPageTitle} from "../../env";
import './style.scss'

const Page = (props) => {

  const {
    hideFooter,
    transparentHeader,
  } = props

  useEffect(() => {
    document.title = mainPageTitle
    window.scroll(0, 0)
  })

  return (
    <div className={transparentHeader?'layouts-page':'layouts-page not-transparent'}>
      {localhost && dev && <div className="break-point-debugger">
        <div className={"min"}>min 0px,</div>
        <div className={"phone-small"}>phone-small 350px,</div>
        <div className={"phone"}>phone 576px,</div>
        <div className={"tablet"}>tablet 768px,</div>
        <div className={"desktop"}>desktop 992px,</div>
        <div className={"desktop-12"}>desktop-12 1200px,</div>
        <div className={"desktop-14"}>desktop-14 1400px,</div>
        <div className={"desktop-16"}>desktop-16 1600px,</div>
        <div className={"max "}>max 9999px</div>
      </div>}
      <Header/>
      <div className="main-content">
        {props.children}
      </div>
      {!hideFooter &&
        <Footer/>
      }
    </div>
  )
}

Page.defaultProps = {
  hideFooter: false,
}

Page.propTypes = {
  hideFooter: PropTypes.bool
}

export default Page
