import React, {useEffect} from "react";
import PropTypes from "prop-types";
import './style.scss'

const Tabs = (props) => {

  const {
    activeTab,
    setActiveTab,
    children,
    noPadding,
    design,
    isMobile,
    initialCloseOnMobile,
  } = props



  useEffect(() => {
    if (initialCloseOnMobile && isMobile) {
      setActiveTab(null)
    } else {
      // setActiveTab(activeTab)
    }
  }, [isMobile]);

  /**
   *
   * @param id
   */
  const changeTab = (id) => {
    if (isMobile){
      if (id === activeTab) {
        setActiveTab(null)
      } else {
        setActiveTab(id)
      }
    } else {
      setActiveTab(id)
    }
  }

  /**
   *
   * @returns {boolean}
   */
  const isActiveFirstTab = () => {
    return (children[0].props.id === activeTab)
  }

  /**
   *
   * @returns {boolean}
   */
  const isActiveLastTab = () => {
    return (children[children.length-1].props.id === activeTab)
  }

  /**
   *
   * @returns {string}
   */
  const getClassNames = () => {
    let className = "tab-content"
    if (isActiveFirstTab()) {
      className += " first-tab-active"
    }
    if (isActiveLastTab()) {
      className += " last-tab-active"
    }
    if (noPadding)  {
      className += " no-padding"
    }
    return className
  }

  return (
    <div className={"component-tabs design-"+design +' active-tab-'+activeTab }>
      <div className="tab-buttons">
        {children.map((child, i) => {
          return (
            <div
              key={i}
              className={(child.props.id === activeTab)?"tab-button active":"tab-button"}
              onClick={() => changeTab(child.props.id)}
            >
              {child.props.label}
            </div>
          )
        })}
      </div>
      <div className={getClassNames()}>
        {children.map((child, i) => {
          // child.props.isMobile = isMobile
          return (
            <div key={i}>
              {child.props.id === activeTab && child}
            </div>
          )
        })}
      </div>
    </div>
  )
}

Tabs.defaultProps = {
  design: 'tabs',
  noPadding: false,
  isMobile: false,
  initialCloseOnMobile: false,
}

Tabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  design: PropTypes.string,
  noPadding: PropTypes.bool,
  isMobile: PropTypes.bool,
  initialCloseOnMobile: PropTypes.bool,
}
export default Tabs
