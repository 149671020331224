import useApi from "../../api/useApi";
import useFunction from "../../hooks/useFunction";

const usePaymentService = () => {

  const {api} =  useApi()
  const {c} = useFunction()

  const userShow = (callbackSuccess, callbackValidationErrors) => {
    return api(
      'get',
      'user/payments',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in Service', error),
    )
  }

  const check = (callbackSuccess, callbackValidationErrors, id) => {
    return api(
      'get',
      'payment/check/'+id,
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in Service', error),
    )
  }

  return {
    userShow,
    check
  }
}
export default usePaymentService
