const initAvailability = {
  mo:1,
  tu:1,
  we:1,
  th:1,
  fr:1,
  sa:1,
  su:1,
  start_date:null,
  end_date:null,
}

export default initAvailability
