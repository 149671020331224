import React, {useEffect, useState} from "react";
import Button from "../../components/Button/Button";
import Tile from "../../components/Tile/Tile";
import Loading from "../../components/Loading/Loading";
import useSpotGroupService from "../../api/services/useSpotGroupService";
import spotGroupIcon from '../../assets/icons/spot-group-white.svg'

const WizardSpotGroupStart = (props) => {

  const [spotGroups, setSpotGroups] = useState(null)
  const {wizard} = props
  const {userShowCollection} = useSpotGroupService()

  useEffect(() => {
    userShowCollection(
      (spotGroups) => {
        setSpotGroups(spotGroups)
      }
    )
  }, [])

  const handleAdd = () => {
    wizard.setFinished(false)
    wizard.nextStep('')
  }

  return (
    <div className="wizard-add-spot-group-index">
      <div className="width-medium">
        {spotGroups ?
          spotGroups.length ?
            spotGroups.map((spotGroup, i) => {
              return (
                <Tile
                  key={i}
                  bookable={spotGroup}
                  showEditButton={true}
                  editLinkWizard={true}
                  editButtonHook={() => wizard.setFinished(true)}
                />
              )
            })
            :
            <div>Je hebt op dit moment nog geen <strong>locaties</strong> op het platform aangemaakt.</div>
          :
          <Loading/>
        }
        <br/>
        <br/>
        <p>Om een nieuwe locatie aan te maken:</p>
        <div className="center">
          <Button
            label="Toevoegen"
            onClick={handleAdd}
            icon={spotGroupIcon}
          />
        </div>
      </div>
    </div>
  )
}

export default WizardSpotGroupStart
