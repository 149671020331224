import React from "react";
import types from "../../data/types";
import {
  Media,
  SelectBlock, Price, Fields,
  Slug, Options, Textarea,
  Input, Form,
} from "../../components/Form/Form";
import {useNavigate, useParams} from "react-router-dom";
import useSpotGroupService from "../../api/services/useSpotGroupService";
import useOptionsService from "../../api/services/useOptionsService";
import ProfileStatusForm from "../../components/Form/ProfileStatusForm";
import Availabilities from "../../components/Form/Availabilities";
import BusinessHour from "../../components/Form/BusinessHour";
import AnchorTitle from "../../components/AnchorMenu/AnchorTitle";
import AnchorIndex from "../../components/AnchorMenu/AnchorIndex";
import EditButton from "../../components/Form/EditButton";
import Checkbox from "../../components/Form/Checkbox";
import Location from "../../components/Form/Location";
import Address from "../../components/Form/Address";
import useForm from "../../hooks/useForm";
import Three from "../../components/Form/Three";
import Bool from "../../components/Form/Bool";
import markedImage from "../../assets/images/example-images/markt.jpg";


const UserSpotGroupEdit = () => {
  const optionServiceSpotGroup = useOptionsService('spot_group_option')
  const infoServiceSpotGroup = useOptionsService('spot_group_info')
  const optionServiceStand = useOptionsService('stand_option')
  const infoServiceStand = useOptionsService('stand_info')
  const spotGroupService = useSpotGroupService()
  const navigate = useNavigate()
  const form = useForm();
  const {id} = useParams();

  return (
    <div>
      <h1>Locatie Instellingen</h1>
      <Form>
        <Fields
          id={id}
          form={form}
          apiService={spotGroupService}
          showDelete={true}
          deleteLabel="Hiermee verwijder je deze locatie:"
          deleteSuccess={() => navigate('/user/locaties')}
          GAEventLabel="User SpotGroup Edit"
        >
          <AnchorIndex
            titles={[
              {
                label: 'Algemeen',
                id: 'general'
              },
              {
                label: 'Profiel',
                id: 'profile'
              },
              {
                label: 'Locatie',
                id: 'location'
              },
              {
                label: 'Afbeeldingen',
                id: 'images'
              },
              {
                label: 'Beschikbaarheid',
                id: 'availabilities'
              },
              {
                label: 'Prijs',
                id: 'price'
              },
              {
                label: 'Standplaatsen',
                id: 'spots'
              },
              {
                label: 'Opties & Info',
                id: 'options'
              },
              {
                label: 'Boekingen',
                id: 'bookings'
              },
            ]}
          />

          <ProfileStatusForm/>

          <AnchorTitle
            label="Algemeen"
            id="general"
          />
          <Input
            label="Naam"
            name="label"
          />
          <Input
            label="Actie banner"
            name="action"
            placeholder="Laat text zien voor speciale acties"
          />
          <Textarea
            label="Beschrijving"
            name="description"
          />


          <AnchorTitle
            label="Profiel"
            id="profile"
          />
          <Bool
            label="Openbaar Profiel: Wil je dat Stands & Trucks jou profiel kunnen bekijken (via onderstaande link) & boeken?"
            name="public"
          />
          <Slug
            label="Profiel URL / slug"
            text="Je mag de url voor je profiel maar een beperkt aantal keren aanpassen!"
            urlPostFix="locatie"
            name="slug"
            basedOnField="label"
          />


          <AnchorTitle
            label="Locatie"
            id="location"
          />
          <SelectBlock
            label="Deze locatie is:"
            name="types_ids"
            data={types}
          />
          <Location
            label="Klik op de kaart om de locatie aan te geven"
          />
          <br/>
          <br/>
          <Address
            label="Locatie Adres"
            text="Zichtbaar na een afgeronde boeking, zodat de Stand/Truck/Act naar deze locatie kan navigeren."
            name="address"
          />

          <AnchorTitle
            label="Afbeeldingen"
            id="images"
          />
          <Media
            label="Algemene/Vooraanzicht afbeelding"
            name="overview_media"
            width={1600}
            height={700}
            exampleImage={markedImage}
            size="banner"
          />

          <Media
            label="Andere afbeelding van locatie"
            name="other_media"
            width={900}
            size="normal_full_height"
          />

          <AnchorTitle
            label="Beschikbaarheid"
            id="availabilities"
          />
          <BusinessHour
            label="Openingstijden gebruiken"
            name="business_hour"
          />

          <Three
            label="Wat is de status van deze Locatie?"
            name="available"
            labelYes="Elke dag beschikbaar"
            labelNo="(nog) niet beschikbaar (niet boekbaar en niet in zoekresultaten)"
            labelOther="Specifieke dag(en) ingeven"
          />
          <Availabilities
            label="Beschikbaarheid specifiek voor deze Standplaats/Spot/Plek"
            name="availabilities"
            enabledBy={[{key: 'available', value: 2}]}
          />

          <AnchorTitle
            label="Prijs"
            id="price"
          />
          <Price
            name="price"
          />

          <AnchorTitle
            label="Standplaatsen / Spots / Plaatsen / Plekken"
            id="spots"
          />
          <Bool
            label="Hoeveel Standplaatsen heeft deze locatie?"
            name="multi_spot"
            labelNo="1"
            labelYes="Meerdere"
          />
          <Checkbox
            label="Elke spot heeft een 'voorkant' op de spot-kaart, aangegeven door een richting-pijl"
            name="spot_map_show_spot_front"
            enabledBy={[{key: 'multi_spot', value: 1}]}
          />
          <EditButton
            label={'Standplaats instellingen'}
            link={'/user/locatie/%id/spots'}
            enabledBy={[{key: 'multi_spot', value: 1}]}
            disabledOnChangeByKey={'multi_spot'}
          />
          <EditButton
            label={'Standplaats instellingen'}
            link={'/user/locatie/%id/spots'}
            enabledBy={[{key: 'multi_spot', value: 0}]}
            disabledOnChangeByKey={'multi_spot'}
          />
          <Input
            label="Marge tussen Standplaatsen aanhouden voor verschillende Stands binnen een Standplaats groep"
            text="Dwz, als standplaatsen direct tegen elkaar liggen wordt er een marge aangehouden tussen verschillende Stands/Trucks. BV: als je hier '2 meter' instelt, en er standplaatsen op de kaart dichter dan 2 meter tegen elkaar aan liggen, ze automatisch de zelfde boekings-status krijgen als de standplaats ernaast. Deze standplaatsen binnen deze marge zijn overigens wel beschikbaar voor de stand/truck die hier al staat."
            name="booking_spot_margin"
            type="meter"
            enabledBy={[{key: 'multi_spot', value: 1}]}
          />

          <AnchorTitle
            label="Opties & Info"
            id="options"
          />
          <Options
            label="Aangeboden Opties"
            name="bookable_options"
            type="spot_group_option"
            text="Geef hier aan welke opties deze locatie/standplaatsen allemaal hebben. (Denk aan '16a stroom p/dag' of 'kraam p/meter')"
            apiService={optionServiceSpotGroup}
            showPrice={true}
          />

          <Options
            label="Gevraagde Opties"
            name="bookable_options_requested"
            type="stand_option"
            text="Geef hier aan welke opties je verwacht van een Stand/Truck/Act (Denk aan '16a stroom/verlengsnoer 10 meter')"
            apiService={optionServiceStand}
            showPrice={false}
          />

          <Options
            label="Belangrijke Informatie Regels & Voorzieningen"
            name="bookable_infos"
            type="spot_group_info"
            text="Geef hier aan welke belangrijke informatie / voorzieningen deze locatie allemaal heeft. Deze worden weergegeven op het profiel van deze locatie. (Denk aan bijgelegen parkeergelegenheid of milieu-zones)"
            apiService={infoServiceSpotGroup}
            modelLabel={'informatie regel'}
            modelLabels={'informatie regels'}
            showPrice={false}
          />

          <Options
            label="Gevraagde Informatie Regels & Voorzieningen"
            name="bookable_infos_requested"
            type="stand_info"
            text="Geef hier aan welke eisen je aan de Stand of Trucks stelt, of welke zaken ze zelf zelf moeten regelen (Denk aan: 'electrische motor' of bepaalde vergunningen)"
            apiService={infoServiceStand}
            modelLabel={'informatie regel'}
            modelLabels={'informatie regels'}
            showPrice={false}
          />

          <AnchorTitle
            label="Boekingen"
            id="bookings"
          />
          <Checkbox
            label="Stands/Trucks toestaan om boekingsverzoeken te sturen ook als ze niet aan de (spot)-branche instellingen voldoen"
            name="booking_flexible_industry"
          />
          <Checkbox
            label="Stands/Trucks toestaan om te boeken zonder gekozen standplaats/spot"
            name="booking_without_spot"
          />
          <Textarea
            label="Boekingsvoorwaarden - zichtbaar bij het accepteren van de boeking"
            placeholder="Algemene (boekings)voorwaarden die gelden naast de standaardboekingsvoorwaarden van Standplaats.nl BV. In geval van overlap hebben de voorwaarden van Standplaats.nl BV voorrang."
            name="booking_conditions"
            rows={10}
          />
          <Textarea
            label="Boekingsinformatie - zichtbaar voor Stands/Trucks/Acts nadat ze een boeking afgerond hebben op deze Locatie"
            placeholder="Aanvangtijd/opbouwtijd, contactinformatie, rij-routes"
            name="booking_information"
            rows={8}
          />
        </Fields>
      </Form>
    </div>
  )
}

export default UserSpotGroupEdit
