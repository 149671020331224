import React, {useEffect, useRef} from "react"
import useBooking from "../../hooks/useBooking";
import Calendar from "../Calendar/Calendar"
import './style.scss'

const CalendarBooking = (props) => {

  const {
    dates,
    setDates,
    booking,
    isLoadingBookings,
    loadMonthBookings,
  } = props

  const conflictingDatesRef = useRef([]);
  const maybeConflictingDatesRef = useRef([]);
  const {isBookedBooking, isAvailableBooking, isDateInBooking, getBookingDates} = useBooking()
  const unavailableButSelectedClassname = ' unavailable-but-selected '
  const availableAndSelectedClassname = ' available-and-selected '
  const maybeUnavailableAndSelectedClassname = ' maybe-unavailable-and-selected '

  /**
   * Remove event listeners and clear the custom property
   * this is a hack since the DatePanel clicks do not call the
   * montChange callback
   */
  useEffect(() => {
    return () => {
      // Remove event listeners and clear the custom property
      const panelBody = document.querySelector('.rmdp-panel-body');
      if (panelBody) {
        const dateElements = panelBody.querySelectorAll('li');
        dateElements.forEach(li => {
          if (li.dataset.listenerAdded) {
            li.removeEventListener('click', loadMonthBookings);
            delete li.dataset.listenerAdded;
          }
        });
      }
    };
  }, []);

  /**
   *
   */
  useEffect(() => {
    const executeWithDelay = async () => {
      await delay(20); //todo this is a hack: delay to make 'sure' the elements are loaded first...
      resetDatePanelAndConflicts(booking);
    };

    executeWithDelay().then();
  }, [booking, booking.date_ranges, booking.dates]);

  /**
   *
   * @param ms
   * @returns {Promise<unknown>}
   */
  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  /**
   * Add the booking new
   */
  const resetDatePanelAndConflicts = (booking) => {
    booking.dates = getBookingDates(booking)
    booking.dates.map((date) => {
      setDatePanelAndConflicts(getStyleAndClassNames({style: {}, className: '', date: date}))
      return date
    })
  }

  /**
   *
   * @param date
   * @param className
   * @returns {{disabled: boolean, className: string, style: {}}}
   */
  const mapDays = ({date, today, selectedDate, currentMonth, isSameDate}) => {
    const daysFromNow = date.toDays() - today.toDays();
    const isPast = daysFromNow < 0;

    let className = '';
    let style = {};

    if (isPast) {
      style = {color: "#ccc"};
    } else {
      let conclicts = setDatePanelAndConflicts(getStyleAndClassNames({style, className, date}))
      className = conclicts.className
      style = conclicts.style
    }
    return {disabled: isPast, className, style};
  };

  /**
   *
   * @param date
   * @param className
   * @param style
   * @returns {{date, className: (string), style: ({color: string}|{color: string})}}
   */
  const getStyleAndClassNames = ({date, className, style}) => {

    const inBooking = isDateInBooking(booking, date);

    let datesAreAvailableStand = true
    let datesAreAvailableSpotGroup = true
    let datesOwnerIsAvailable = true


    // Check for Stand availability
    if (booking.stand) {
      if (booking.user_owns_object_type === 'stand') {
        // For Stand owners, only check the booking status
        if (isBookedBooking(booking.stand, booking, date)) {
          datesAreAvailableStand = false;
          // break;
        }

        if (!isAvailableBooking(booking.stand, booking, date)) {
          datesOwnerIsAvailable = false
        }

      } else {
        // For non-owners, check both availability and booking status
        if (
          !isAvailableBooking(booking.stand, booking, date) ||
          isBookedBooking(booking.stand, booking, date)
        ) {
          datesAreAvailableStand = false;
          // break;
        }
      }
    }


    // Check for SpotGroup availability
    if (booking.spot_group) {
      if (booking.user_owns_object_type === 'spot_group') {
        // For SpotGroup owners, only check the booking status:
        if (isBookedBooking(booking.spot_group, booking, date)) {
          datesAreAvailableSpotGroup = false;
          // break;
        }

        if (!isAvailableBooking(booking.spot_group, booking, date)) {
          datesOwnerIsAvailable = false
        }

      } else {
        // For non-owners, check both availability and booking status
        if (
          !isAvailableBooking(booking.spot_group, booking, date) ||
          isBookedBooking(booking.spot_group, booking, date)
        ) {
          datesAreAvailableSpotGroup = false;
          // break;
        }
      }
    }

    if (!datesOwnerIsAvailable) {
      className = inBooking ? maybeUnavailableAndSelectedClassname : '';
      style = {color: "orange"};
    } else if (datesAreAvailableSpotGroup && datesAreAvailableStand) {
      className = inBooking ? availableAndSelectedClassname : '';
      style = {color: "green"};
    } else {
      className = (inBooking) ? unavailableButSelectedClassname : ''
      style = {color: "#ccc"};
    }

    return {
      date: date,
      className: className,
      style: style
    }
  }

  /**
   *
   * @param date
   * @param className
   * @param style
   * @returns {{date, className, style}}
   */
  const setDatePanelAndConflicts = ({date, className, style}) => {

    const formattedDate = date.format("YYYY/MM/DD");

    /*
    * Count the problems
    */
    if (className === maybeUnavailableAndSelectedClassname) {
      if (!maybeConflictingDatesRef.current.includes((formattedDate))) {
        maybeConflictingDatesRef.current.push(formattedDate)
      }
    } else if (className === unavailableButSelectedClassname) {
      if (!conflictingDatesRef.current.includes(formattedDate)) {
        conflictingDatesRef.current.push(formattedDate);
      }
    } else {
      const index = conflictingDatesRef.current.indexOf(formattedDate);
      if (index !== -1) {
        conflictingDatesRef.current.splice(index, 1);
      }
      const index2 = maybeConflictingDatesRef.current.indexOf(formattedDate);
      if (index2 !== -1) {
        maybeConflictingDatesRef.current.splice(index2, 1);
      }
    }

    /*
     * Direct DOM manipulation to update DatePanel
     * This is not mandatory, but there is no prop to do this.
     * maybe it's better to create a complete new DatePanel
     *
     * Also, there is a click event listener on the span because when
     * a click happens on here the Calendar is changed date view but the
     * calendar's onw montChange is never called.
     */
    const panelBody = document.querySelector('.rmdp-panel-body');
    if (panelBody) {
      const dateElements = panelBody.querySelectorAll('li');
      dateElements.forEach(li => {
        const span = li.querySelector('span');
        if (span && span.textContent.includes(formattedDate)) {
          // Split className into individual classes and add them one by one
          className.trim().split(/\s+/).forEach(cls => {
            if (cls) {
              li.setAttribute("class", "")
              li.classList.add(cls);
            }
          });

          if (!span.dataset.listenerAdded) {
            span.addEventListener('click', (e) => {
              // e.preventDefault()
              loadMonthBookings(date)
            });

            span.dataset.listenerAdded = "true"; // Mark as event listener attached
          }
          Object.assign(li.style, style);
        }
      });
    }

    return {
      date: date,
      className: className,
      style: style,
    }
  }

  return (
    <div className="component-calendar-booking">
      <Calendar
        dates={dates}
        setDates={setDates}
        isLoadingBookings={isLoadingBookings}
        loadMonthBookings={loadMonthBookings}
        mapDays={mapDays}
      />
      {conflictingDatesRef.current.length > 0 && (
        <div className="conflicting-dates">
          {conflictingDatesRef.current.length} conflicterende data gevonden.
        </div>
      )}
      {maybeConflictingDatesRef.current.length > 0 && (
        <div className="maybe-conflicting-dates">
          {maybeConflictingDatesRef.current.length} datum waarom {(booking.user_owns_object_type === 'stand') ? ' jouw Stand ' : ' jouw Locatie '} niet
           beschikbaar is voor anderen.
        </div>
      )}
      <div className="legend">
        <div className="row">
          <div className="dot green"></div>
          <div className="label">Groen: beschikbaar</div>
        </div>
        <div className="row">
          <div className="dot orange"></div>
          <div className="label">{(booking.user_owns_object_type === 'stand') ? 'Jouw Stand ' : 'Jouw Locatie '} heeft nog geen boeking op deze dag, maar staat niet niet-beschikbaar ingesteld voor anderen. De {(booking.user_owns_object_type === 'stand') ? 'De Locatie ' : 'De Stand '} is beschikbaar deze dag.</div>
        </div>
        <div className="row">
          <div className="dot red"></div>
          <div className="label">De stand en/of de locatie hebben al een boeking op deze dag</div>
        </div>
        <div className="row">
          <div className="dot grey"></div>
          <div className="label">{(booking.user_owns_object_type === 'stand') ? 'De Locatie ' : 'De Stand '} is deze dag niet beschikbaar</div>
        </div>
      </div>
    </div>)
}


CalendarBooking.defaultProps = {}

CalendarBooking.propTypes = {
  // enableDisabledDays: PropTypes.bool,
  // showHeader: PropTypes.bool,
  // dates: PropTypes.object.isRequired,
  // setDates: PropTypes.object.isRequired,
  // bookable: PropTypes.object.isRequired,
  // booking: PropTypes.object.isRequired,
}

export default CalendarBooking
