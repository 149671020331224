import React, {useEffect, useState} from "react";
import {months, weekDays} from "../../data/calendar";
import {useParams} from "react-router-dom";
import {Calendar, DateObject} from "react-multi-date-picker";
import useBookableService from "../../api/services/useBookableService";
import useStandService from "../../api/services/useStandService";
import ProfileStatus from "../../components/ProfileStatus/ProfileStatus";
import BookingSmall from "../../components/BookingSmall/BookingSmall";
import Loading from "../../components/Loading/Loading";
import StandOccupancy from "../../components/StandOccupancy/StandOccupancy";

const UserStandManager = () => {

  const [isLoading, setIsLoading] = useState(true)
  const [stand, setStand] = useState(null)
  const [bookings, setBookings] = useState(null)
  const [isLoadingBookings, setIsLoadingBookings] = useState(false)
  const [dates, setDates] = useState([])
  const [nCalendars, setNCalendars] = useState(2)
  const {showBookableRangeBookings} = useBookableService()
  const standService = useStandService()
  const {standId} = useParams()

  useEffect(() => {
    standService.userShowManager(
      (stand) => {
        setStand(stand)
        setIsLoading(false)
      },
      (error) => {
        console.log('validation errors', error)
        //todo do something
        setIsLoading(false)
      },
      standId
    )
  }, [])

  /**
   *
   * @param dates
   */
  const handleCalendarChange = (dates) => {
    setDates(dates)
    if (dates.length === 2) {
      setIsLoadingBookings(true)
      showBookableRangeBookings(
        (bookings) => {
          setStand({...stand, bookings: bookings})
          setBookings(bookings)
          setIsLoadingBookings(false)
        },
        (error) => {
          setIsLoadingBookings(false)
        },
        'stand',
        standId,
        dates[0].toString(),
        dates[1].toString(),
      )
    }
  }

  /**
   * Create an array with a range of date items; one for every day.
   *
   * @param dates
   * @returns {*[]}
   */
  const createDateRange = (dates) => {
    // Validate input
    if (dates.length !== 2 || !(dates[0] instanceof DateObject) || !(dates[1] instanceof DateObject)) {
      return [];
    }

    const startDate = dates[0];
    const endDate = dates[1];
    const dateRange = [];

    // Ensure the start date is before the end date
    if (startDate > endDate) {
      console.error("Start date must be before end date.");
      return [];
    }

    let currentDate = new DateObject(startDate);

    // Generate range
    while (currentDate <= endDate) {
      dateRange.push(new DateObject(currentDate));
      currentDate = currentDate.add(1, "days");
    }

    return dateRange;
  }

  return (
    <div className="component-stand-manager">
      <h1>Stand/Truck/Act beheer - <span style={{color: 'red'}}>nog onder constructie</span></h1>

      {isLoading ?
        <Loading/>
        :
        <>
          {stand &&
            <>
              Stand/Truck/Act: {stand.label}
              <ProfileStatus
                model={stand}
              />
              <h3>Beschikbaarheid komende jaar</h3>
              <StandOccupancy
                stand={stand}
                createDateRange={createDateRange}
              />
              <h3>Beschikbaarheid in datum-range</h3>
              <p>Klik 2x(!) op de calendar tot de datum rond wordt. Maak op deze manier een 'range' of 1 datum</p>
              <div className="calendar">
                <Calendar
                  range
                  disabled={isLoadingBookings}
                  value={dates}
                  weekStartDayIndex={1}
                  shadow={false}
                  weekDays={weekDays}
                  months={months}
                  numberOfMonths={nCalendars}
                  onChange={handleCalendarChange}
                />
              </div>
              <h3>Boekingen in geselecteerde periode</h3>
              <div className="bookings">
                {isLoadingBookings
                  ? <Loading/>
                  : bookings && !!bookings.length
                    ? bookings.map((booking, i) => {
                      return (
                        <BookingSmall
                          booking={booking}
                          showAction={false}
                          key={i}
                          target="_blank"
                        />
                      )
                    })
                    : <>Geen boekingen in geselecteerde periode</>
                }
              </div>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
            </>
          }
        </>
      }

    </div>
  )
}

export default UserStandManager
