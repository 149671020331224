const initPrice = {
  default: null,
  type:'positive',
  mo: null,
  tu: null,
  we: null,
  th: null,
  fr: null,
  sa: null,
  su: null,
}

export default initPrice
