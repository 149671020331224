import React from "react";
import LiUserMenuButton from "../../components/LiUserMenuButton/LiUserMenuButton";
import useAuthService from "../../api/services/useAuthService";
import {useAppContext} from "../../context/AppContextProvider";
import messageIcon from "../../assets/icons/message.svg"
import alertIcon from "../../assets/icons/alarm.svg"
import favoriteIcon from "../../assets/icons/hearth-black.svg"
import bookingIcon from "../../assets/icons/booking.svg"
import standIcon from "../../assets/icons/stand.svg"
import spotGroupIcon from "../../assets/icons/spot-group.svg"
import companyIcon from "../../assets/icons/company.svg"
import accountIcon from "../../assets/icons/account.svg"
import logoutIcon from "../../assets/icons/logout.svg"
import myStandplaatsIcon from "../../assets/icons/favicon.svg"
import './style.scss'


const User = (props) => {
  const {logout} = useAuthService()
  const {notifications} = useAppContext()

  const logoutSubmit = (e) => {
    e.preventDefault()
    logout(() => {}, () => {})
  }

  return (
    <div className="layouts-user">
      <nav className="sidebar">
        <ul>
          <LiUserMenuButton
            to="/user/mijn-standplaats"
            title="Overzichtspagina van uw account"
            label="Mijn Standplaats"
            icon={myStandplaatsIcon}
          />
          <LiUserMenuButton
            to="/user/berichten"
            title="Notificaties, Berichten, Contact"
            label="Berichten"
            icon={messageIcon}
            count={notifications}
          />
          <LiUserMenuButton
            to="/user/zoek-alerts"
            title=""
            label="Alert Meldingen"
            icon={alertIcon}
          />
          <LiUserMenuButton
            to="/user/favorieten"
            title="Favoriete Stands, Locaties enz."
            label="Favorieten"
            icon={favoriteIcon}
          />
          <LiUserMenuButton
            to="/user/boekingen"
            title="Boekingen van of naar organisaties waarvoor je werkt, bezit of vertegenwoordigd"
            label="Boekingen"
            icon={bookingIcon}
          />
          <LiUserMenuButton
            to="/user/stands"
            title="Jouw FoodTrucks, Marktkraam, Mobiele onderneming of andere Stands"
            label="Stands & Trucks"
            icon={standIcon}
          />
          <LiUserMenuButton
            to="/user/locaties"
            title="Jouw Events, Markten, Braderien, Supermarkten of andere Locaties"
            label="Locaties"
            icon={spotGroupIcon}
          />
          <LiUserMenuButton
            to="/user/organisaties"
            title="Organisaties waarvoor je werkt, bezit of vertegenwoordigd"
            label="Organisatie"
            icon={companyIcon}
          />
          {/*<LiUserMenuButton*/}
          {/*  to="/user/notificaties"*/}
          {/*  title="Al jouw persoonlijke/account/login gegevens"*/}
          {/*  label="Emails & Meldingen"*/}
          {/*  icon={emailIcon}*/}
          {/*/>*/}
          <LiUserMenuButton
            to="/user/account"
            title="Al jouw persoonlijke/account/login gegevens"
            label="Account"
            icon={accountIcon}
          />
          <li className="logout"><div
            onClick={logoutSubmit}
          ><img src={logoutIcon} alt="Uitloggen"/> Uitloggen</div></li>
        </ul>
      </nav>
      <div className="main-content-layout">
        {props.children}
      </div>
    </div>
  )
}

export default User
