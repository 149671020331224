import React, {useContext} from "react"
import AuthContext from "../../context/AuthContext";
import AdminNav from "../../components/AdminNav/AdminNav";
import './style.scss'

const UserAdmin = () => {

  const {user} = useContext(AuthContext)

  return (
    <div className="page-user-admin">
      {user && user.is_admin &&
        <>
          <AdminNav/>
        </>
      }
    </div>
  )
}

export default UserAdmin
