import React, {Fragment} from 'react';
import {googleMapsApiKey} from "../../env";
import {GoogleMap, LoadScript, Marker, Circle} from '@react-google-maps/api';
import mapSpotGroup from '../../assets/icons/map-spot-group.svg'
import mapStand from '../../assets/icons/map-stand.svg'
import useBookable from "../../hooks/useBookable";
import "./style.scss";

const MapBookable = (props) => {
  const {bookable} = props
  const {isSpotGroup, isStand} = useBookable()

  const coordinates = {
    lat: parseFloat(bookable.lat),
    lng: parseFloat(bookable.lng),
  }

  return (
    <>
      {
        ((isSpotGroup(bookable) && bookable.lat && bookable.lng) ||
          (isStand(bookable) && bookable.lat && bookable.lng && bookable.radius)) &&
        <div className="component-map-bookable">
          <LoadScript
            googleMapsApiKey={googleMapsApiKey}>
            <GoogleMap
              mapContainerClassName={'map-container-inner'}
              zoom={8}
              center={coordinates}
              clickableIcons={false}
              streetViewControl={false}
            >
              <Fragment key={bookable.id}>
                <Marker
                  position={coordinates}
                  icon={(isSpotGroup(bookable) ? mapSpotGroup : mapStand)}
                />
                {bookable.radius &&
                  <Circle
                    center={coordinates}
                    radius={bookable.radius * 1000}
                    options={{
                      strokeColor: "#58AD50",
                    }}
                  />
                }
              </Fragment>
            </GoogleMap>
          </LoadScript>
        </div>
      }
    </>
  )
}

const isEqual = (prevProps, nextProps) => {
  return true
}

export default React.memo(MapBookable, isEqual)
