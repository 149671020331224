import React, {useContext, useState} from "react";
import AuthContext from "../../context/AuthContext";
import chatIcon from '../../assets/icons/chat-white.svg'
import {useLocation} from "react-router-dom";
import useFunction from "../../hooks/useFunction";
import Register from "../Register/Register";
import Messages from "../Messages/Messages";
import Button from "../Button/Button";
import Login from "../Login/Login";
import './style.scss'

const ChatWindow = () => {

  const {objIsEmpty} = useFunction()
  const [showChat, setShowChat] = useState(false)
  const {user} = useContext(AuthContext)

  const handleClick = () => {
    setShowLogin(false)
    setShowRegister(false)
    setShowChat(!showChat)
  }
  const location = useLocation();

  const [showLogin, setShowLogin] = useState(false)
  const [showRegister, setShowRegister] = useState(false)

  if (location.pathname.startsWith('/user/berichten')){
    return (<></>)
  }

  return (
    <div className={(showChat) ? 'component-chat-window open' : 'component-chat-window'}>
      {showChat ?
        <div className="chat-window">
          <div
            className="close"
            onClick={handleClick}
          >&#10005;</div>
          {objIsEmpty(user)
            ?
            <div className="login-register">
              {!showLogin && !showRegister &&
                <>
                  <p><strong>We willen je graag te woord staan!</strong></p>
                  <p>Echter kan dit (voorlopig) technisch alleen als je ingelogd bent.</p>
                  <p>Maak in enkele stappen, geheel <strong>gratis & vrijblijvend</strong> een account aan.</p>
                  <div className="buttons">
                    <Button
                      label="Inloggen"
                      onClick={() => setShowLogin(true)}
                      color="green-ghost"
                      size="s"
                    />
                    <Button
                      label="Account aanmaken"
                      onClick={() => setShowRegister(true)}
                      color="green-ghost"
                      size="s"
                    />
                  </div>
                </>
              }
              {showLogin &&
                <>
                  <Login
                    showRegisterButton={false}
                  />
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                </>
              }
              {showRegister &&
                <>
                  <Register
                    showLoginButton={false}
                  />
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                </>
              }

            </div>
            :
            <Messages
              fixedConversation={1}
            />
          }
        </div>
        :
        <div
          className="show-chat-button"
          onClick={handleClick}
        >
          <img src={chatIcon}/>
        </div>
      }
    </div>
  )
}

export default ChatWindow
