import {useContext, useState} from "react";
import AuthContext from "../context/AuthContext";
import useFunction from "./useFunction";
import useFavoriteService from "../api/services/useFavoriteService";
import swal from "sweetalert";
import useBookable from "./useBookable";

const useFavorite = () => {

  const {user, setUser} = useContext(AuthContext)
  const {userRemoveSpotGroup, userAddSpotGroup, userRemoveStand, userAddStand} = useFavoriteService()
  const {isStand} = useBookable()
  const {objIsEmpty} = useFunction()
  const [favoriteIsLoading, setFavoriteIsLoading] = useState(false)

  /**
   * Check if model is a user favorite
   *
   * @param bookable
   * @returns {boolean}
   */
  const isFavorite = (bookable) => {
    const key = isStand(bookable)?'favorite_stands':'favorite_spot_groups'

    if (objIsEmpty(user) ){
      return false
    }
    let isFavorite = false
    user[key] && user[key].map((sp) => {
      if (sp.id === bookable.id){
        isFavorite = true
      }
      return sp
    })
    return isFavorite
  }

  /**
   * Adds or removes bookable to users favorites
   *
   * @param bookable
   * @returns {null}
   */
  const handleClickFavorite = (bookable) => {
    if (objIsEmpty(user)){
      swal({
        title: 'Inloggen?',
        text: 'Je bent niet ingelogd. Inloggen?',
        buttons: {
          cancel: {
            text: "Annuleren",
            value: false,
            visible: true,
            closeModal: true,
            className: 'btn-color-grey btn-size-m',
          },
          confirm: {
            text: "Inloggen",
            value: true,
            visible: true,
            className: 'btn-color-green btn-size-m',
            closeModal: true
          },
        }
      }).then((result) => {
        if (result) {
          window.location.href = "/inloggen"
        }
      })
      return null
    }

    const favorites = (isStand(bookable))?user.favorite_stands:user.favorite_spot_groups
    setFavoriteIsLoading(true)
    if (isFavorite(bookable)) {
      const userRemoveBookable = (isStand(bookable))?userRemoveStand:userRemoveSpotGroup
      userRemoveBookable(
        () => {
          setFavoriteIsLoading(false)
          favorites.splice(favorites.indexOf({id:bookable.id}), 1)
          setUser(prevState => {
            return {
              ...prevState,
              [(isStand(bookable))?'favorite_stands':'favorite_spot_groups']: favorites,
            }
          })
        },
        () => {
        },
        bookable.id
      )
    } else {
      const userAddBookable = (isStand(bookable))?userAddStand:userAddSpotGroup
      userAddBookable(
        () => {
          setFavoriteIsLoading(false)
          favorites.push({id:bookable.id})
          setUser(prevState => {
            return {
              ...prevState,
              [(isStand(bookable))?'favorite_stands':'favorite_spot_groups']: favorites,
            }
          })
        },
        () => {
        },
        bookable.id
      )
    }
  }

  return {
    isFavorite,
    handleClickFavorite,
    favoriteIsLoading,
    setFavoriteIsLoading,
  }
}

export default useFavorite
