import React, {useContext, useEffect, useState} from "react"
import './style.scss'
import AuthContext from "../../context/AuthContext";
import AdminNav from "../../components/AdminNav/AdminNav";
import Loading from "../../components/Loading/Loading";
import useAdminService from "../../api/services/useAdminService";
import Button from "../../components/Button/Button";
import spotGroupIcon from "../../assets/icons/spot-group.svg";
import standIcon from "../../assets/icons/stand.svg";
import useFunction from "../../hooks/useFunction";
import useData from "../../hooks/useData";
import {Link} from "react-router-dom";

const UserAdminCompanies = () => {

  const {user} = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(true)
  const [companies, setCompanies] = useState([])
  const [users, setUsers] = useState([])
  const {adminShowCompaniesCollection} = useAdminService()
  const {date, truncate} = useFunction()
  const {getIndustryById, getTypeById} = useData()


  useEffect(() => {

    adminShowCompaniesCollection(
      (data) => {

        // console.log('companies', data.companies)
        // console.log('users', data.users)

        setUsers(data.users)
        setCompanies(data.companies)
        setIsLoading(false)
      },
      (errors) => {
        setIsLoading(false)
      },
    )

  }, []);


  return (
    <div className="page-user-admin">
      {user && user.is_admin &&
        <>
          <AdminNav/>
          {isLoading
            ? <Loading/>
            : <>

              <>
                {users.length &&
                  <>
                    <div className="row">
                      <h2>Gebruikers zonder organisatie:</h2>
                    </div>
                    {users.map((user, index) => {
                      return (<p
                        key={index}>{user.first_name} {user.last_name}, {user.email} - Email
                        check: {date(user.email_verified_at)} -
                        last activity: {date(user.last_activity_at)}
                      </p>)
                    })}
                    <hr/>
                  </>
                }
              </>
              <h2>Organisaties en gebruikers:</h2>
              <hr/>
              {companies.map((company, i) => {
                return (
                  <div key={i}>
                    <div className="row">
                      <div className="col">
                        <p>Organisatie: <strong>{company.legal_name}</strong></p>
                        <p>KvK: {company.coc_number}</p>
                        <p>Tel: {company.phone}</p>
                        <p>Public Errors: {company.public_errors}</p>
                      </div>
                      <div className="col">
                        <p>Gebruikers:</p>
                        {company.users.map((user, index) => {
                          return (<p
                            key={index}>{user.first_name} {user.last_name}, {user.email} - Email
                            check: {date(user.email_verified_at)}</p>)
                        })}
                        {company.user_invites && !!company.user_invites.length &&
                          <>
                            <p>Uitgenodigde gebruikers:</p>
                            {company.user_invites.map((user, index) => {
                              return (<p key={index}>{user.email}</p>)
                            })}
                          </>
                        }
                      </div>

                      <div className="col">
                        {company.stands && !!company.stands.length &&
                          <>
                            <p>Stands:</p>
                            {company.stands.map((stand, index) => {
                              return (
                                <Button
                                  key={index}
                                  color="grey-ghost"
                                  size="s"
                                  link={'/stand/' + stand.slug}
                                  label={truncate(stand.label, 20)}
                                  target="_blank"
                                  icon={standIcon}
                                />
                              )
                            })}
                          </>
                        }
                        {company.spot_groups && !!company.spot_groups.length &&
                          <>
                            <p>Locaties:</p>
                            {company.spot_groups.map((spotGroup, index) => {
                              return (
                                <Button
                                  key={index}
                                  color="grey-ghost"
                                  size="s"
                                  link={'/locatie/' + spotGroup.slug}
                                  label={truncate(spotGroup.label, 20)}
                                  target="_blank"
                                  icon={spotGroupIcon}
                                />
                              )
                            })}
                          </>
                        }
                      </div>
                    </div>
                    {company.alerts && !!company.alerts.length &&
                      <>
                        <h4>Alerts: {company.alerts.length}x</h4>
                        <div className="row">
                          {company.alerts.map((alert, index) => {
                            return (
                              <div className="" key={index} style={{maxWidth: '200px'}}>
                                <p>Created at: {date(alert.created_at)}</p>
                                <p>zoekrichting: {alert.filter.direction}</p>
                                <p>Zoekt: {alert.filter.search_model}</p>
                                {alert.filter.map &&
                                  <>
                                    {alert.filter.map.lat &&
                                      <span> lat: {alert.filter.map.lat}</span>
                                    }
                                    {alert.filter.map.lng &&
                                      <span> lng: {alert.filter.map.lng}</span>
                                    }
                                    {alert.filter.map.radius &&
                                      <span> Radius: {alert.filter.map.radius}</span>
                                    }
                                  </>
                                }
                                {alert.filter.types && !!alert.filter.types.length &&
                                  <><strong>Types:</strong>
                                    {alert.filter.types.map((typeId, key) => {
                                      return (<span key={key}>{getTypeById(typeId).label},</span>
                                      )
                                    })}
                                    <br/>
                                  </>
                                }
                                {alert.filter.industries && !!alert.filter.industries.length &&
                                  <><strong>Branches:</strong>
                                    {alert.filter.industries.map((industryId, key) => {
                                      return (<span key={key}>{getIndustryById(industryId).label}</span>)
                                    })}
                                    <br/>
                                  </>
                                }
                              </div>
                            )
                          })}
                        </div>
                      </>
                    }
                    {company.received_bookings && !!company.received_bookings.length &&
                      <>
                        <h4>Ontvangen boekingen: {company.received_bookings.length}x</h4>
                        <div className="row">
                          {company.received_bookings.map((booking) => {
                            return (<>
                              <Link target={"_blank"} to={'/user/boeking/' + booking.id}>{booking.status}</Link>&nbsp;
                            </>)
                          })}
                        </div>
                      </>
                    }

                    {company.requested_bookings && !!company.requested_bookings.length &&
                      <>
                        <h4>Verzonden boekingen: {company.requested_bookings.length}x</h4>
                        <div className="row">
                          {company.requested_bookings.map((booking) => {
                            return (<>
                              <Link target={"_blank"} to={'/user/boeking/' + booking.id}>{booking.status}</Link>&nbsp;
                            </>)
                          })}
                        </div>
                      </>
                    }
                    {(company.sent_messages_count || company.received_messages_count) &&
                      <>
                        <h4>Berichten:</h4>
                        {company.received_messages_count &&
                          <p>Ontvangen berichten: {company.received_messages_count - 1}x</p>
                        }
                        {company.sent_messages_count &&
                          <p>Verzonden berichten: {company.sent_messages_count}x</p>
                        }
                      </>
                    }
                    <hr/>
                  </div>
                )
              })}
            </>
          }
        </>
      }
    </div>
  )
}

export default UserAdminCompanies
