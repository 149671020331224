import React, {useContext, useEffect, useState} from "react"
import {ScrollToPlugin} from "gsap/ScrollToPlugin"
import {gsap} from "gsap";
import MapBookable from "../MapBookable/MapBookable";
import AuthContext from "../../context/AuthContext";
import {useNavigate} from "react-router-dom";
import swal from "sweetalert";
import Media from "../Media/Media";
import Popup from "../Popup/Popup";
import Totals from "../Totals/Totals";
import Button from "../Button/Button";
import useData from "../../hooks/useData";
import SpotPopup from "../SpotPopup/SpotPopup";
import useBooking from "../../hooks/useBooking";
import useFavorite from "../../hooks/useFavorite";
import useBookable from "../../hooks/useBookable";
import useFunction from "../../hooks/useFunction";
import BusinessHours from "../BusinessHours/BusinessHours";
import SpotMapPublic from "../SpotMap/SpotMapPublic";
import CalendarProfile from "../CalendarProfile/CalendarProfile";
import useLocationState from "../../hooks/useLocationState";
import useBookingService from "../../api/services/useBookingService";
import SocialShareProfile from "../SocialShareProfile/SocialShareProfile";
import useBookableService from "../../api/services/useBookableService";
import hearthSolidIcon from "../../assets/icons/hearth-solid.svg";
import spotGroupIcon from "../../assets/icons/spot-group.svg"
import hearthIcon from "../../assets/icons/hearth-grey.svg";
import shareIcon from "../../assets/icons/share.svg";
import standIcon from "../../assets/icons/stand.svg"
import PropTypes from "prop-types";
import "./style.scss"


const Profile = (props) => {
  gsap.registerPlugin(ScrollToPlugin);

  const {bookable, setBookable, booking, setBooking, reloadBookable} = props
  const navigate = useNavigate()
  const {
    isStand,
    isSpotGroup,
    getEditLink,
    isOwnedByCurrentActiveCompany,
  } = useBookable()
  const {handleClickFavorite, favoriteIsLoading, isFavorite} = useFavorite()
  const {
    handleSetDates,
    parseSpotGroupBookings,
    getBookingActionLabel,
    getBookingStatusLabel,
    datesToStrings,
    toggleSpotInBooking,
  } = useBooking(
    booking,
    setBooking,
  )
  // const spotMap = useSpotMap()
  const [showPopupShare, setShowPopupShare] = useState();
  const [isLoadingBookings, setIsLoadingBookings] = useState(false)
  const [showPopupSpot, setShowPopupSpot] = useState([])
  const {getIndustryById, getTypeById} = useData()
  const {getWizardBookingState} = useLocationState()
  const {userAction} = useBookingService()
  const {showBookableMonthBookings} = useBookableService()
  const {user} = useContext(AuthContext)
  const {objIsEmpty} = useFunction()

  useEffect(() => {
    document.title = bookable.label + ' - Standplaats.nl';
  }, [bookable]);

  /**
   *
   */
  const handleClickMessage = () => {
    if (objIsEmpty(user)){
      return navigate('/wizard/welkom/choice_login_register/')
    } else {
      return navigate('/user/berichten/' + bookable.company.id, {state: {company: bookable.company}})
    }
  }

  /**
   *
   * @returns {string}
   */
  const getBookingErrorPopupTitle = () => {
    let string = 'Selecteer '
    string += (booking.spots.length < 1) ? 'Standplaats' : ''
    string += (booking.spots.length < 1 && booking.dates.length < 1) ? ' + ' : ' '
    string += (booking.dates.length < 1) ? 'datum' : ''
    return string
  }

  /**
   *
   * @returns {string}
   */
  const getBookingErrorPopupText = () => {
    let string = 'Selecteer minimaal '
    string += (booking.spots.length < 1) ? 'één Standplaats op de kaart' : ''
    string += (booking.spots.length < 1 && booking.dates.length < 1) ? ', en ' : ' '
    string += (booking.dates.length < 1) ? 'één datum op de kalender' : ''
    return string + '.'
  }

  /**
   *
   */
  const handleClickReserve = () => {
    if (objIsEmpty(user)){
      return navigate('/wizard/welkom/choice_login_register/')
    }

    if (isStand(bookable)) {
      if (booking.dates.length < 1) {
        swal({
          title: 'Selecteer Datum',
          text: 'Selecteer minimaal één datum op de kalender',
          buttons: {
            confirm: {
              text: 'oke',
              value: true,
              visible: true,
              className: 'btn-color-green btn-size-m',
              closeModal: true
            }
          }
        })
      } else {
        doNavigateToWizard()
      }
    } else {
      if (booking.spots.length < 1 || booking.dates.length < 1) {
        swal({
          title: getBookingErrorPopupTitle(),
          text: getBookingErrorPopupText(),
          buttons: {
            confirm: {
              text: 'oke',
              value: true,
              visible: true,
              className: 'btn-color-green btn-size-m',
              closeModal: true
            }
          }
        })
        if (booking.spots.length < 1) {
          gsap.to(window, {
            duration: 1.2,
            ease: "power4.inOut",
            scrollTo: {
              y: '.component-spot-map-public',
              offsetY: 87
            }
          });
        } else {
        }

      } else {
        doNavigateToWizard()
      }
    }
  }

  const doNavigateToWizard = () => {
    navigate(
      '/wizard/boek/start', {
        state: {...getWizardBookingState(booking), hideRequestedBookableSteps: true}
      }
    )
  }
  //
  // /**
  //  * Add spot to booking
  //  *
  //  * @param spotId
  //  */
  // const handleAddSelectedSpot = (spotId) => {
  //   spotMap.addSelectedSpot(booking, spotId, bookable)
  //   setBooking(prevBooking => {
  //     return {
  //       ...prevBooking,
  //       spots: booking.spots
  //     }
  //   })
  // }
  //
  // /**
  //  * Remove spot from booking
  //  *
  //  * @param spotId
  //  */
  // const handleRemoveSelectedSpot = (spotId) => {
  //   spotMap.removeSelectedSpot(booking, spotId, bookable)
  //   setBooking(prevBooking => {
  //     return {
  //       ...prevBooking,
  //       spots: booking.spots
  //     }
  //   })
  // }

  const loadMonthBookings = (date) => {
    setIsLoadingBookings(true)
    showBookableMonthBookings((newBookings) => {


        if (isSpotGroup(bookable)) {
          bookable.bookings = newBookings
          setBookable(parseSpotGroupBookings(bookable))
        }


        setIsLoadingBookings(false)
      }, () => {

        setIsLoadingBookings(false)
      },
      bookable.object_type,
      bookable.id,
      date.format('YYYY-MM-DD'),
      datesToStrings(booking)
    )
  }


  return (
    <div className="component-profile">
      {(isStand(bookable) || isSpotGroup(bookable)) ?
        <>
          <div className="page-banner">
            <div className="container-outer">
              <div className="container-inner">
                {bookable.overview_media &&
                  <div className="img-container">
                    <Media
                      media={bookable.overview_media}
                      size="banner"
                      alt={bookable.label}
                      // alt={(isStand(bookable) ? 'Stand, Truck, Mobiele ondernemer, foodtruck, ambulante handel' : 'locatie, evenement, markt, braderie')}
                    />
                  </div>
                }
                <div className="buttons-container">
                  <div className="buttons">
                    <Button
                      label={(isFavorite(bookable)) ? "Opgeslagen!" : "Bewaren"}
                      color="white"
                      size="s"
                      icon={(isFavorite(bookable)) ? hearthSolidIcon : hearthIcon}
                      onClick={() => handleClickFavorite(bookable)}
                      disabled={favoriteIsLoading}
                    />
                    <Popup
                      showPopup={showPopupShare}
                      setShowPopup={setShowPopupShare}
                      title={"Deel deze profielpagina"}
                      trigger={
                        <Button
                          label="Delen"
                          color="white"
                          size="s"
                          icon={shareIcon}
                        />}
                    >
                      <SocialShareProfile
                        model={bookable}
                      />
                    </Popup>
                  </div>
                  {bookable.action &&
                    <div className="action-banner"><p>{bookable.action}</p></div>
                  }
                </div>
              </div>
            </div>
          </div>

          {/*{'public' in bookable && !bookable.public &&*/}
          {/*  <section id="" className="notifications">*/}
          {/*    <div className="section-container">*/}
          {/*      <div className="notification"><h4>Profiel niet openbaar, wel zichtbaar voor uitgenodigde organisaties</h4>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </section>*/}
          {/*}*/}

          {'is_public' in bookable && !bookable.is_public &&
            <section id="" className="notifications">
              <div className="section-container">
                <div className="notification"><h4>Profiel niet openbaar, wel zichtbaar voor benaderde organisaties.</h4>
                  {(user.active_company_id === bookable.company_id) &&
                    <Button
                      label="Bewerk profiel"
                      color="grey"
                      size="xs"
                      link={getEditLink(bookable)}
                    />
                  }
                </div>
              </div>
            </section>
          }

          {'bookable_bookings' in bookable && bookable.bookable_bookings && !!bookable.bookable_bookings.length &&
            bookable.bookable_bookings.map((bookableBooking, i) => {
              if (i < 2) {
                return (
                  <section key={i} id="" className="notifications grey">
                    <div className="section-container">
                      <div className="notification">
                        <h4>{bookableBooking.status === 'concept' ? 'Concept: ' : 'Openstaande aanvraag: '} {
                          (typeof bookableBooking.needs_action === 'string')
                            ? 'Actie nodig: ' + getBookingActionLabel(bookableBooking.needs_action) + '. '
                            : 'Wachten op tegenpartij'
                        }
                          {/*Status: {getBookingStatusLabel(bookableBooking.status)}*/}
                        </h4>
                        <div className="buttons">
                          {bookableBooking.status === 'concept' &&
                            <Button
                              label="Verwijder"
                              color="red-ghost"
                              size="xs"
                              onClick={() => {
                                swal({
                                  title: "Weet je het zeker?",
                                  text: "Deze concept boeking wordt verwijderd",
                                  icon: "warning",
                                  buttons: ["Nee", "Ja"],
                                  dangerMode: true,
                                })
                                  .then((willDelete) => {
                                    if (willDelete) {
                                      userAction(
                                        () => {
                                          reloadBookable && reloadBookable()
                                        },
                                        () => {
                                        },
                                        bookableBooking.id,
                                        {action: 'remove'}
                                      )
                                    }
                                  })
                              }}
                            />
                          }

                          {(typeof bookableBooking.needs_action === 'string')
                            ?
                            <Button
                              label="Ga verder"
                              color="green"
                              size="xs"
                              link={'/user/boeking/' + bookableBooking.id}
                              linkState={getWizardBookingState(booking)}
                            />
                            :
                            <Button
                              label="Bekijk boeking"
                              color="special"
                              size="xs"
                              link={'/user/boeking/' + bookableBooking.id}
                              linkState={getWizardBookingState(booking)}
                            />
                          }
                        </div>
                      </div>
                    </div>
                  </section>
                )
              } else {
                return (
                  <section key={i} id="" className="notifications grey">
                    <div className="section-container">
                      <div className="notification"><h4>Er zijn meerdere boekingen die acties nodig hebben.</h4>
                        <Button
                          label="Bekijk Alle boeking"
                          color="special"
                          size="xs"
                          link={'/user/boekingen'}
                        />
                      </div>
                    </div>
                  </section>
                )
              }
            })
          }
          {'bookable_booked_bookings' in bookable && bookable.bookable_booked_bookings && !!bookable.bookable_booked_bookings.length &&
            bookable.bookable_booked_bookings.map((bookableBooking, i) => {
              if (i < 2) {
                return (
                  <section key={i} id="" className="notifications grey">
                    <div className="section-container">
                      <div className="notification"><h4>Nog niet volledig afgeronde boeking:
                        Status: {getBookingStatusLabel(bookableBooking.status)}
                        {bookableBooking.needs_action ? ', ' + getBookingActionLabel(bookableBooking.needs_action) : ', Wachten op tegenpartij'}
                      </h4>
                        {(typeof bookableBooking.needs_action === 'string')
                          ?
                          <Button
                            label="Ga verder"
                            color="green"
                            size="xs"
                            link={'/user/boeking/' + bookableBooking.id}
                            linkState={getWizardBookingState(booking)}
                          />
                          :
                          <Button
                            label="Bekijk boeking"
                            color="special"
                            size="xs"
                            link={'/user/boeking/' + bookableBooking.id}
                            linkState={getWizardBookingState(booking)}
                          />
                        }
                      </div>
                    </div>
                  </section>
                )
              } else {
                return (
                  <section key={i} id="" className="notifications grey">
                    <div className="section-container">
                      <div className="notification"><h4>Meer boekingen...</h4>
                        <Button
                          label="Bekijk Alle boeking"
                          color="special"
                          size="xs"
                          link={'/user/boekingen'}
                        />
                      </div>
                    </div>
                  </section>
                )
              }
            })
          }

          <section id="section-content" className="section-container">
            <div className="content">
              <div className="content-header">
                <h1 className="title"><img
                  alt=""
                  width={35}
                  style={{marginLeft: -4}}
                  src={(isStand(bookable)) ? standIcon : spotGroupIcon}/>&nbsp;
                  {isOwnedByCurrentActiveCompany(bookable) &&
                    <span className="color-blue">Jouw {isStand(bookable) ? "Stand" : "Locatie"}: </span>
                  }
                  {bookable.label}
                  {isOwnedByCurrentActiveCompany(bookable) &&
                    <Button
                      style={{display: "inline-text", float: "right", marginTop: "15px"}}
                      label="Profiel Instellingen"
                      link={getEditLink(bookable)}
                      color="blue-ghost"
                      size="xs"
                    />
                  }
                </h1>
                <p>
                  {isSpotGroup(bookable) && bookable.spots &&
                    <span className="count-spots">
                      {bookable.multi_spot ?
                        <>
                          {bookable.spots.length === 1 ? '1 Standplaats · ' : bookable.spots.length + ' Standplaatsen · '}
                        </>
                        :
                        <>
                          1 Standplaats ·&nbsp;
                        </>
                      }
                    </span>
                  }
                  Organisatie: <strong className="organisation-link" onClick={handleClickMessage}>{bookable.company.legal_name}</strong>
                </p>

                {isStand(bookable) && bookable.slogan &&
                  <h3 className="slogan">"{bookable.slogan}"</h3>
                }
              </div>
              <div className="description">
                <h4>Over deze {isStand(bookable) ? 'Stand of Truck' : 'Locatie'}: {!bookable.available &&
                  <span className="color-red"> · Gesloten voor boekingen</span>}</h4>
                <p>{bookable.description}</p>

              </div>

              {isStand(bookable) && bookable.industries_ids && bookable.industries_ids.length > 0 &&
                <div className="industries">
                  <h3>Branche:</h3>
                  <ul>
                    {bookable.industries_ids.map((industryId, i) => {
                      return (
                        <li key={i}>
                          {getIndustryById(industryId).label}
                        </li>
                      )
                    })
                    }
                  </ul>
                </div>
              }

              {bookable.types_ids.length > 0 &&
                <div className="types">
                  <h3>{isStand(bookable) ? 'Gewenste type locatie:' : 'Type locatie:'}</h3>
                  <ul>
                    {bookable.types_ids.map((typeId, i) => {
                      return (
                        <li
                          key={i}
                        >
                          {getTypeById(typeId).label}
                        </li>
                      )
                    })
                    }
                  </ul>
                </div>
              }

              {bookable.bookable_options.length > 0 &&
                <div className="options">
                  <h3>Faciliteiten:</h3>
                  <ul>
                    {bookable.bookable_options.map((bookableOption, i) => {
                      return (
                        <li
                          key={i}
                        >
                          {bookableOption.option.label} - {bookableOption.label}
                        </li>
                      )
                    })
                    }
                  </ul>
                </div>
              }
              {bookable.bookable_infos.length > 0 &&
                <div className="bookable_infos">
                  <h3>Extra informatie:</h3>
                  {bookable.bookable_infos.map((bookableInfo, i) => {
                    return (
                      <p
                        key={i}
                      >
                        {bookableInfo.option.label} - {bookableInfo.label}
                      </p>
                    )
                  })
                  }
                </div>
              }

              {isSpotGroup(bookable) && bookable.other_media &&
                <div className="other-media">
                  <Media
                    media={bookable.other_media}
                    size="normal_full_height"
                    alt="Afbeelding Locatie of evenement"
                  />
                </div>
              }

              {isStand(bookable) && bookable.action_media &&
                <div className="action-media">
                  <Media
                    media={bookable.action_media}
                    size="normal"
                    alt="Actiefoto stand of truck"
                  />
                </div>
              }

              {isStand(bookable) && bookable.product_media &&
                <div className="product-media">
                  <Media
                    media={bookable.product_media}
                    size="normal"
                    alt="Product van stand of truck"
                  />
                </div>
              }

              {isSpotGroup(bookable) &&
                <>
                  {bookable.multi_spot ?
                    <>
                      <h3>Plattegrond:</h3>
                      {isOwnedByCurrentActiveCompany(bookable) &&
                        <Button
                          label="Standplaats Instellingen"
                          size="s"
                          color="grey-ghost"
                          link={"/user/locatie/" + bookable.id + "/spots"}
                        />
                      }
                      <SpotMapPublic
                        spotGroup={bookable}
                        booking={booking}
                        setBooking={setBooking}
                        onSpotClick={(booking, setBooking, spot) => {
                          toggleSpotInBooking(booking, setBooking, spot)
                        }}
                      />
                    </>
                    :
                    <>
                      <Button
                        label="Meer info over deze standplaats"
                        onClick={() => setShowPopupSpot({...showPopupSpot, [0]: true})}
                        color="grey"
                      />
                      <SpotPopup
                        spot={bookable.spots[0]}
                        iteration={0}
                        setShowPopupSpot={setShowPopupSpot}
                        showPopupSpot={showPopupSpot}
                      />
                    </>
                  }
                </>
              }

              {bookable &&
                <MapBookable
                  bookable={bookable}
                />
              }
            </div>
            <div
              className={bookable.available ? 'sidebar' : 'sidebar disabled'}>
              {'price' in bookable && bookable.price ?
                <>
                  <CalendarProfile
                    dates={booking.dates}
                    setDates={handleSetDates}
                    bookable={bookable}
                    setBookable={setBookable}
                    booking={booking}
                    setIsLoadingBookings={setIsLoadingBookings}
                    isLoadingBookings={isLoadingBookings}
                    loadMonthBookings={loadMonthBookings}
                  />
                  <Totals
                    booking={booking}
                    bookable={bookable}
                    handleClickMessage={handleClickMessage}
                    handleClickReserve={handleClickReserve}
                  />
                  <BusinessHours
                    bookable={bookable}
                  />
                </>
                :
                <>Deze {isStand(bookable) ? 'Stand of Truck' : 'locatie'} heeft geen prijs en/of beschikbaarheid
                  ingesteld</>
              }
            </div>
          </section>
        </>
        :
        <div className="error-center">
          <h1>Profiel niet openbaar</h1>
          {objIsEmpty(user) &&
            <>
              <p>Misschien dat je dit profiel wel kunt zien als je ingelogd bent.</p>
              <div className="buttons">
                <Button
                  label="Inloggen"
                  link="/inloggen"
                  color="green-ghost"
                />
                <Button
                  label="Account aanmaken"
                  link="/registreren"
                  color="green-ghost"
                />
              </div>
            </>
          }

        </div>
      }
    </div>
  )
}

// BusinessHours.defaultProps = {
//   bookable: {},
// }

Profile.propTypes = {
  bookable: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
  setBooking: PropTypes.func,
}

export default Profile
