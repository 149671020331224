import swal from "sweetalert";
import useFunction from "../../hooks/useFunction";
import useApi from "../../api/useApi";
import {useContext} from "react";
import AuthContext from "../../context/AuthContext";
import {useNavigate} from "react-router-dom";

//tODO change the structure here so its the same as in other service files

const useAuthService = () => {

  const {c, removeToken} = useFunction()
  const {api} = useApi()
  const {setLoginData} = useFunction()
  const {setUser} = useContext(AuthContext)
  const navigate = useNavigate()

  const register = (callbackSuccess, callbackValidationErrors, modelId, data) => {
    return api(
      'post',
      'register',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in userRegister service', error),
      data,
    )
  }

  /**
   * Login
   *
   * @param callbackSuccess
   * @param callbackValidationErrors
   * @param modelId
   * @param data
   */
  const login = (callbackSuccess, callbackValidationErrors, modelId, data) => {
    return api(
      'post',
      'login',
      response => setLoginData(response.data, callbackSuccess, setUser),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in userLogin service', error),
      data,
    )
  }

  const exchangeTokens = (callbackSuccess, token) => {
    return api(
      'post',
      'token/exchange',
      response => setLoginData(response.data, callbackSuccess, setUser),
      errors => () => {},
      error =>  c('Error in userLogin service', error),
      {shortLiveToken:token},
    )
  }

  /**
   * Logout
   *
   * @param callbackSuccess
   * @param callbackFailed
   * @param data
   */
  const logout = (callbackSuccess = () => {}, callbackFailed = () => {}, data) => {
    swal({
      title: 'Uitloggen?',
      icon: '', //question //todo not working??
      dangerMode:true,
      buttons: {
        cancel: {
          text: "Annuleren",
          value: null,
          visible: true,
          className: 'btn-color-grey btn-size-m',
          closeModal: true,
        },
        confirm: {
          text: "Uitloggen",
          value: true,
          visible: true,
          className: 'btn-color-red btn-size-m',
          closeModal: true,
        }
      }
    }).then((result) => {
      if (result) {
        api(
          'post',
          'logout',
          (response) => {
            clearUser()
            swal({
              title: response.message,
              icon: 'success',
              timer: 1000,
              buttons: false,
            }).then(() => {
            })

            return callbackSuccess(response.data)
          },
          () => {},
          (errors) => {
            //error
            c('Logout Service error', errors);
            clearUser()
            return callbackFailed(errors)
          },
          data
        )
      }
    })
  }

  /**
   *
   */
  const clearUser = () => {
    removeToken()
    setUser(false)
    navigate("/")
  }

  /**
   * Password Forgot
   *
   * @param callbackSuccess
   * @param callbackValidationErrors
   * @param modelId
   * @param data
   */
  const passwordForgot = (callbackSuccess, callbackValidationErrors, modelId, data) => {
    return api(
      'post',
      'password/forgot',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in passwordForgot service', error),
      data,
    )
  }

  return {
    register,
    login,
    exchangeTokens,
    logout,
    passwordForgot
  }
}

export default useAuthService
