import React, {useContext, useState} from "react";
import {DateObject} from "react-multi-date-picker";
import AuthContext from "../../context/AuthContext";
import {Link} from "react-router-dom";
import useBookingService from "../../api/services/useBookingService";
import useBookable from "../../hooks/useBookable";
import useFunction from "../../hooks/useFunction";
import usePrice from "../../hooks/usePrice";
import Loading from "../Loading/Loading";
import Button from "../Button/Button";
import swal from "sweetalert";
import "./BookingOverview-style.scss"

const BookingOverview = (props) => {

  const {
    className,
    save,
    formData,
    wizard,
  } = props

  const {
    formatPrice,
    countBookingPriceTypePositive,
    countBookingPriceTypeNegative,
    countBookingPriceTypeNegotiate,
    countBookingPriceTypeRelative,
    getBookingTotalPricePositive,
    getBookingTotalPriceNegative,
    getBookingTotalAmountNegotiate,
    getBookingTotalFormattedPercentagesRelative,
    getLatestNegotiationTotalPrice,
    // getBookingPriceTotalWithoutFee,
    getBookable,
    addBookingFee,
    // isPositivePrice:isPositivePriceFunc,
    transactionFee,
  } = usePrice()
  const booking = formData.model;
  const bookable = getBookable(booking)
  const {isSpotGroup, isStand} = useBookable()
  const {userAction} = useBookingService()
  const {user} = useContext(AuthContext)
  const {dateTime} = useFunction()
  const [isLoading, setIsLoading] = useState(false)
  const countTypePositive = countBookingPriceTypePositive(booking, bookable)
  const countTypeNegative = countBookingPriceTypeNegative(booking, bookable)
  const countTypeNegotiate = countBookingPriceTypeNegotiate(booking, bookable)
  const countTypeRelative = countBookingPriceTypeRelative(booking, bookable)
  // const isPositivePrice = isPositivePriceFunc(booking, bookable)
  let hasOpenNegotiation = false
  const onChangeRequesterAcceptTerms = () => {
    if (!booking.requested_terms_accepted_at) {
      swal({
        title: 'Accepteer je de algemene en boekingsvoorwaarden?',
        text: '',
        icon: '', //question
        buttons: {
          cancel: {
            text: 'Nee',
            value: null,
            visible: true,
            className: 'btn-color-grey btn-size-m',
            closeModal: true,
          },
          confirm: {
            text: 'Accepteer',
            value: true,
            visible: true,
            className: 'btn-color-green btn-size-m',
            closeModal: true,
          }
        }
      }).then((result) => {
        if (result) {
          //scroll to top
          window.scroll(0, 0)
          setIsLoading(true)
          userAction(
            () => {
              booking.requested_terms_accepted_at = 'just-now'
              save(false)
              setIsLoading(false)
              wizard.nextStep()
            },
            (errors) => {
              //todo error
              // formData.isLoading = false
              // save()
            },
            booking.id,
            {action: 'accept'},
          )
        }
      })
    }
  }

  const onChangeReceiverAcceptTerms = () => {
    if (!booking.received_terms_accepted_at) {
      swal({
        title: 'Accepteer boekingsaanvraag?',
        text: '',
        icon: '', //question
        buttons: {
          cancel: {
            text: 'Nee',
            value: null,
            visible: true,
            className: 'btn-color-grey btn-size-m',
            closeModal: true,
          },
          confirm: {
            text: 'Accepteer',
            value: true,
            visible: true,
            className: 'btn-color-green btn-size-m',
            closeModal: true,
          }
        }
      }).then((result) => {
        if (result) {
          window.scroll(0, 0)
          setIsLoading(true)
          userAction(
            () => {
              booking.received_terms_accepted_at = 'just-now'
              save(false)
              setIsLoading(false)
              wizard.nextStep()
            },
            (errors) => {
              //todo error
              // formData.isLoading = false
              // save()
            },
            booking.id,
            {action: 'accept'},
          )
        }
      })
    }
  }

  return (
    <>
      {isLoading ?
        <Loading/>
        :
        <div className={className + 'booking-overview '}>
          <div className="overview">

            {booking.direction === 'requesting' &&
              <>
                <h1>Reservering aanvragen, id: {booking.booking_number}</h1>
                <p>Je staat op het punt om een reserverings-<u>aanvraag</u> te doen. <strong>De aanvraag is
                  altijd <u>geheel</u> gratis. </strong>Wanneer jouw aanvraag goedkeurd wordt, ontvang je een
                  betaalverzoek. Als de aanvraag wordt afgewezen, worden er geen kosten in rekening gebracht.</p>
              </>
            }

            {booking.direction === 'receiving' &&
              <>
                <h1>Reservering accepteren, id: {booking.booking_number}</h1>
                <p>Je staat op het punt om een reserverings-<u>aanvraag</u> te accepteren.</p>
              </>
            }

            <h3>Controlleer de onderstaande gegevens:</h3>
            {!isSpotGroup(bookable) &&
              <h4><strong>Aanvrager </strong>{(booking.spot_group_id) ? 'Locatie' : 'Organisatie (zonder locatie profiel)'}
              </h4>
            }
            {!isStand(bookable) &&
              <h4><strong>Aanvrager </strong>Stand, Truck of Act</h4>
            }
            <div className="table">

              {isSpotGroup(bookable) &&
                <div className="row">
                  <div className="col">Stand of Truck:</div>
                  <div className="col">
                    {booking.stand
                      ? <strong>{booking.stand.label}</strong>
                      : <strong className="color-red">GEEN STAND: <Link
                        to={wizard.pathname + '/select_stand'}>SELECTEER STAND</Link></strong>
                    }
                  </div>
                </div>
              }

              {isStand(bookable) && booking.spot_group &&
                <div className="row">
                  <div className="col">Locatie:</div>
                  <div className="col">
                    {booking.spot_group
                      ? <strong>{booking.spot_group.label}</strong>
                      : <strong>Deze boeking is op een onbekende Locatie</strong>
                    }
                  </div>
                </div>
              }
              <div className="row">
                <div className="col">Organisatie:</div>
                <div className="col">{booking.requested_by_company.legal_name}</div>
              </div>
              <div className="row">
                <div className="col">Gebruiker:</div>
                <div className="col">{booking.requested_by_user.first_name} {booking.requested_by_user.last_name}</div>
              </div>

            </div>
            <h4><strong>Ontvanger aanvraag </strong>{(isSpotGroup(bookable)) ? 'Locatie' : 'Stand of Truck'}:</h4>
            <div className="table">

              {isSpotGroup(bookable) &&
                <>
                  <div className="row">
                    <div className="col">Locatie naam:</div>
                    <div
                      className="col">{booking.spot_group.label}</div>
                  </div>
                  <div className="row">
                    <div className="col">Organisatiesnaam:</div>
                    <div
                      className="col">{booking.spot_group.company.legal_name}</div>
                  </div>
                </>
              }
              {isStand(bookable) &&
                <>
                  <div className="row">
                    <div className="col">Stand/Truck/Act:</div>
                    <div
                      className="col">{booking.stand.label}</div>
                  </div>
                  <div className="row">
                    <div className="col">Organisatie naam:</div>
                    <div
                      className="col">{booking.stand.company.legal_name}</div>
                  </div>
                </>
              }

            </div>
            <h4>Details:</h4>
            <div className="table">
              {isSpotGroup(bookable) &&
                <div className="row">
                  <div className="col">Aantal spots:</div>
                  <div className="col">{booking.spots.length}</div>
                </div>
              }
              <div className="row">
                <div className="col">Aantal dagen:</div>
                <div className="col">{booking.date_ranges.length}</div>
              </div>

              <div className="row">
                <div className="col">Standplaats Namen/id's:</div>
                <div className="col">
                  {booking.spots && booking.spots.map((spot, i) => {
                    if (spot.spot_cluster){
                      return (<div key={i}>{spot.spot_cluster.label}: {spot.label}</div>)
                    } else {
                      return (<div key={i}>{spot.label}</div>)
                    }
                  })}
                </div>
              </div>
              <div className="row">
                <div className="col">Data:</div>
                <div className="col">
                  {booking.date_ranges.map((date, i) => {
                    // return (<span key={i}>{Moment(new Date(date.toString())).format('D MMMM YYYY')}, </span>
                    return (<span key={i}>{new DateObject(date.start_date).format("D MMMM YYYY")}, </span>
                    )
                  })}
                </div>
              </div>
            </div>

            <h4>Prijzen:</h4>
            <div className="table">
              {isSpotGroup(bookable) &&
                <div className="row">
                  <div
                    className="col">{(booking.spots.length === 1) ? booking.spots.length + ' standplaats' : booking.spots.length + ' standplaatsen'}:
                  </div>
                </div>
              }
              {!!countTypePositive &&
                <div className="row">
                  <div className="col">
                    {countTypePositive}, Totaal te betalen
                  </div>
                  <div className="col">
                    {formatPrice(addBookingFee(getBookingTotalPricePositive(booking, bookable)))}
                  </div>
                </div>
              }
              {!!countTypeNegative &&
                <div className="row">
                  <div className="col">
                    {countTypeNegative}, Totaal te ontvangen
                  </div>
                  <div className="col">
                    {formatPrice(getBookingTotalPriceNegative(booking, bookable))}
                  </div>
                </div>
              }
              {isSpotGroup(bookable) && !!countTypeNegotiate &&
                <div className="row">
                  <div className="col">
                    {countTypeNegotiate}, Standplaatsen n.o.t.k.
                  </div>
                  <div className="col">
                    {getBookingTotalAmountNegotiate(bookable, booking)} Totaal: {formatPrice(getLatestNegotiationTotalPrice(booking) * booking.date_ranges.length)}
                    {booking.prices.spots.map((spot, i) => {
                      if (spot.price_type === 'negotiate') {
                        if (spot.offers && Array.isArray(spot.offers) && spot.offers.length > 0) {
                          const offer = spot.offers[0]
                          return (
                            <p key={i}>Laatste
                              bieding: {booking.direction === 'receiving' && offer.type === 'positive' && 'Verdien'}
                              {booking.direction === 'requesting' && offer.type === 'positive' && 'Betaal'}
                              {booking.direction === 'receiving' && offer.type === 'negative' && 'Betaal'}
                              {booking.direction === 'requesting' && offer.type === 'negative' && 'Verdien'}
                              {offer.type === 'relative' && 'Omzet'}
                              {offer.type === 'negotiate' && 'Onderhandelen'} {formatPrice(offer.value * booking.date_ranges.length)} ({formatPrice(offer.value)}p/d)</p>)
                        } else {
                          hasOpenNegotiation = true
                        }
                      }

                    })}
                  </div>
                </div>
              }
              {isStand(bookable) && !!countTypeNegotiate &&
                <div className="row">
                  <div className="col">
                    {countTypeNegotiate}, Stand/Truck/Act n.o.t.k.
                  </div>
                  <div className="col">
                    {getBookingTotalAmountNegotiate(bookable, booking)} Totaal: {formatPrice(getLatestNegotiationTotalPrice(booking) * booking.date_ranges.length)}

                    {[1].map((item, i) => {
                      const stand = booking.prices.stand
                      if (stand.offers && Array.isArray(stand.offers) && stand.offers.length > 0) {
                        const offer = stand.offers[0]
                        return (
                          <p key={i}>Laatste
                            bieding: {booking.direction === 'receiving' && offer.type === 'positive' && 'Verdien'}
                            {booking.direction === 'requesting' && offer.type === 'positive' && 'Betaal'}
                            {booking.direction === 'receiving' && offer.type === 'negative' && 'Betaal'}
                            {booking.direction === 'requesting' && offer.type === 'negative' && 'Verdien'}
                            {offer.type === 'relative' && 'Omzet'}
                            {offer.type === 'negotiate' && 'Onderhandelen'} {formatPrice(offer.value * booking.date_ranges.length)} ({formatPrice(offer.value)}p/d)</p>)
                      } else {
                        hasOpenNegotiation = true
                      }
                    })
                    }
                  </div>
                </div>
              }

              {!!countTypeRelative &&
                <div className="row">
                  <div className="col">
                    {countTypeRelative}, Totaal op basis van omzet
                  </div>
                  <div className="col">
                    {getBookingTotalFormattedPercentagesRelative(booking, bookable)} ***
                  </div>
                </div>
              }
              <hr/>
              <>
                {booking.direction === 'receiving' &&
                  <div className="field price">
                    <h4>{booking.prices.totals.receiving.total.get ? 'Inkomsten:' : 'Uitgaven:'}</h4>



                    {booking.prices.totals.receiving.get.rent
                      ?
                      <div className="row">
                        <div className="col">Verhuur inkomsten:</div>
                        <div className="col">{formatPrice(booking.prices.totals.receiving.get.rent)}</div>
                      </div>
                      :
                      <div className="row">
                        <div className="col">Huur kosten:</div>
                        <div className="col">{formatPrice(booking.prices.totals.receiving.pay.rent)}</div>
                      </div>
                    }



                    <div className="row">
                      <div className="col">Opties inkomsten:</div>
                      <div className="col">{formatPrice(booking.prices.totals.receiving.get.options)}</div>
                    </div>

                    {booking.prices.totals.receiving.total.get
                      ?
                      <div className="row">
                        <div className="col"><strong>Totale inkomsten na akkoord:</strong></div>
                        <div className="col"><strong>{formatPrice(booking.prices.totals.receiving.total.get)}</strong></div>
                      </div>
                      :
                      <div className="row">
                        <div className="col"><strong>Totale kosten na akkoord:</strong></div>
                        <div className="col"><strong>{formatPrice(booking.prices.totals.receiving.total.pay)}</strong></div>
                      </div>
                    }
                  </div>
                }
                {booking.direction === 'requesting' &&
                  <div className="field price">
                    <h4>{booking.prices.totals.requesting.total.get ? 'Inkomsten:' : 'Uitgaven:'}</h4>

                    {booking.prices.totals.requesting.get.rent
                      ?
                      <div className="row">
                        <div className="col">Verhuur inkomsten:</div>
                        <div className="col">{formatPrice(booking.prices.totals.requesting.get.rent)}</div>
                      </div>
                      :
                      <div className="row">
                        <div className="col">Huur kosten:</div>
                        <div className="col">{formatPrice(booking.prices.totals.requesting.pay.rent)}</div>
                      </div>
                    }

                    <div className="row">
                      <div className="col">Opties kosten:</div>
                      <div className="col">{formatPrice(booking.prices.totals.requesting.pay.options)}</div>
                    </div>

                    <div className="row">
                      <div
                        className="col">{(booking.prices.totals.requesting.pay.fees >= transactionFee) ? 'Boekingskosten:' : 'Fee & Boekingskosten:'}</div>
                      <div className="col">{formatPrice(booking.prices.totals.requesting.pay.fees)}</div>
                    </div>

                    {booking.prices.totals.requesting.total.get
                      ?
                      <div className="row">
                        <div className="col"><strong>Totale inkomsten na akkoord:</strong></div>
                        <div className="col"><strong>{formatPrice(booking.prices.totals.requesting.total.get)}</strong></div>
                      </div>
                      :
                      <div className="row">
                        <div className="col"><strong>Totale kosten na akkoord:</strong></div>
                        <div className="col"><strong>{formatPrice(booking.prices.totals.requesting.total.pay)}</strong></div>
                      </div>
                    }
                  </div>
                }


              </>
            </div>
            <p>* Alle prijzen zijn EXCL. BTW</p>
            {
              !!countTypeNegotiate && hasOpenNegotiation &&
              <p>** N.O.T.K. moeten nog overeengekomen worden en zijn hier nu niet weergegeven.</p>
            }
            {
              !!countTypeRelative &&
              <p>*** Prijzen op basis van omzet worden achteraf verrekend, en zijn hier nu niet weergegeven.</p>
            }
          </div>
          <div className="terms">

            {booking.direction === 'requesting' &&
              <div className="row">
                {isSpotGroup(bookable) ?
                  <>
                    {booking.spot_group && booking.spot_group.booking_conditions &&
                      <div className="owner-terms">
                        <h3>Aanvullende Voorwaarden van de Locatie
                          eigenaar: {booking.spot_group.company.legal_name}</h3>
                        {booking.spot_group.booking_conditions}
                      </div>
                    }
                  </>
                  :
                  <>
                    {booking.stand && booking.stand.booking_conditions &&
                      <div className="owner-terms">

                        <h3>Aanvullende Voorwaarden van de Stand of Truck
                          eigenaar: {booking.stand.company.legal_name}</h3>
                        {booking.stand.booking_conditions}
                      </div>
                    }
                  </>
                }
              </div>
            }

            <label>
              <div className="row">
                {booking.direction === 'requesting' &&
                  <>
                    {typeof booking.requested_terms_accepted_at === 'string'
                      ? <>Aangevraagd &
                        ondertekend: {booking.requested_terms_accepted_at === 'just-now' ? 'zojuist' : dateTime(booking.requested_terms_accepted_at)}</>
                      :
                      <>
                        <div className="col">
                          <input
                            onChange={onChangeRequesterAcceptTerms}
                            checked={!!booking.requested_terms_accepted_at}
                            type="checkbox"
                          />
                        </div>
                        <div className="col text">
                          <div>Ik, {user.first_name} {user.last_name}, ga akkoord met de <a
                            href="/algemene-voorwaarden/"
                            target="_blank">algemene voorwaarden</a> en de voorwaarden van
                            de {(isSpotGroup(bookable)) ? 'locatie' : 'stand of truck'} eigenaar, zichtbaar hierboven op
                            deze pagina. Tevens verklaar ik bevoegd te zijn om
                            namens aanvragende organisatie '{user.active_company.legal_name}' deze boekingsaanvraag te mogen
                            doen. Mocht organisatie
                            '{(isSpotGroup(bookable)) ? booking.spot_group.label : booking.stand.label}', tevens
                            ontvanger van deze boekingsaanvraag, deze boekingsaanvraag accepteren, zal ik zorg dragen
                            dat aanvragende organisatie '{user.active_company.legal_name}' direct het bedrag
                            {/*van {formatPrice(priceGrandTotal(bookable, booking))} voldoen aan Standplaats.nl BV*/}
                            van {formatPrice(booking.price)}* voldoet aan Standplaats.nl BV via dit platform.
                          </div>
                        </div>
                      </>
                    }
                  </>
                }
                {booking.direction === 'receiving' &&
                  <>
                    {typeof booking.received_terms_accepted_at === 'string'
                      ? <>Aangevraagd &
                        ondertekend: {booking.received_terms_accepted_at === 'just-now' ? 'zojuist' : dateTime(booking.received_terms_accepted_at)}</>
                      : <>
                        <Button
                          label="Accepteer"
                          onClick={onChangeReceiverAcceptTerms}
                        />
                      </>
                    }
                  </>
                }

              </div>
            </label>
          </div>
        </div>
      }
    </>
  )
}

export default BookingOverview
