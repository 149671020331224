import React, {useContext, useEffect} from "react";
import {debug, dev, localhost, GACode, logRocketCode, hotJarCode, hotJarVersion} from "../env";
import ReactGA from "react-ga4";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import UserAccountPasswordChange from "../pages/UserAccountPasswordChange/UserAccountPasswordChange";
import UserAccountActiveCompany from "../pages/UserAccountActiveCompany/UserAccountActiveCompany";
import UserAccountEmailChange from "../pages/UserAccountEmailChange/UserAccountEmailChange";
import WaitingForVerification from "../pages/WaitingFormVerification/WaitingForVerification";
import UserSpotGroupManager from "../pages/UserSpotGroupManager/UserSpotGroupManager";
import WizardAddSpotGroup from "../pages/WizardAddSpotGroup/WizardAddSpotGroup";
import UserSpotGroupEdit from "../pages/UserSpotGroupEdit/UserSpotGroupEdit";
import UserNotifications from "../pages/UserNotifications/UserNotifications";
import UserStandManager from "../pages/UserStandManager/UserStandManager";
import UserCompanyEdit from "../pages/UserCompanyEdit/UserCompanyEdit";
import UserSpotGroups from "../pages/UserSpotGroups/UserSpotGroups";
import WizardAddStand from "../pages/WizardAddStand/WizardAddStand";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import ProcessPayment from "../pages/ProcessPayment/ProcessPayment";
import UserDashboard from "../pages/UserDashboard/UserDashboard";
import UserCompanies from "../pages/UserCompanies/UserCompanies";
import WizardWelcome from "../pages/WizardWelcome/WizardWelcome";
import UserFavorites from "../pages/UserFavorites/UserFavorites";
import UserStandEdit from "../pages/UserStandEdit/UserStandEdit";
import WizardBooking from "../pages/WizardBooking/WizardBooking";
import UserBookings from "../pages/UserBookings/UserBookings";
import UserMessages from "../pages/UserMessages/UserMessages";
import UserPayments from "../pages/UserPayments/UserPayments";
import useFunction from "../hooks/useFunction";
import UserAccount from "../pages/UserAccount/UserAccount";
import ErrorReport from "../pages/ErrorReport/ErrorReport";
import HowItWorks from "../pages/HowItWorks/HowItWorks"
import UserAlerts from "../pages/UserAlerts/UserAlerts";
import UserStands from "../pages/UserStands/UserStands";
import UserSpots from "../pages/UserSpots/UserSpots";
import SpotGroup from "../pages/SpotGroup/SpotGroup";
import UserAdmin from "../pages/UserAdmin/UserAdmin";
import Error404 from "../pages/Error404/Error404";
import UserSpot from "../pages/UserSpot/UserSpot";
import TestPage from "../pages/TestPage/TestPage";
import Register from "../pages/Register/Register";
import Login from "../pages/Login/Login";
import Stand from "../pages/Stand/Stand";
import Home from "../pages/Home/Home";
import Page from "../layouts/Page/Page";
import User from "../layouts/User/User";
import SocialLogin from "../components/SocialLogin/SocialLogin";
import UserAdminTools from "../pages/UserAdmin/UserAdminTools";
import UserAdminCompanies from "../pages/UserAdmin/UserAdminCompanies";
import Hotjar from '@hotjar/browser';
import LogRocket from 'logrocket';
import AuthContext from "../context/AuthContext";

const MainRouter = () => {


//   wizard: /wizard            (logo, next/prev. cancel)
//   - booking                  /wizard/booking/
//   - Stand                    /wizard/add-stand
//   - SpotGroup, Option, Info  /wizard/add-spot-group
//   - User, Company            /wizard/add-account
//
//   users /user                (sidebar menu)
//   - dashboard                /user/dashboard
//   - profile                  /user/...
//   - company                  /user/...
//   - etc                      /user/...
//
// default /         (normal header, empty page)
//   - login                    /inloggen
//   - register                 /registreren
//   - forgot-password          /wachtwoord/vergeten
//   - waiting-for-verification /email/verification
//   - error                    /error
//   - Filter Stand             /stand
//   - Filter SpotGroup         /locatie
//   - Stand                    /stand/broodje-aap
//   - SpotGroup                /group/market-x-and-y
//   - How it works             /hoe-werkt-het
//   - Payment                  /payment/process/*

  //TODO betere Auth instellen voor de routes?
  //todo type in URL verwerken voor SEO (ipv /locatie/bla-bla -> /avond-markt/bla-bla)

  const {getToken, objIsEmpty} = useFunction()
  const location = useLocation()
  const {user} = useContext(AuthContext)

  useEffect(() => {
    if (!dev && !localhost) {

      /*
       * LogRocket
       */
      LogRocket.init(logRocketCode);
      if (!objIsEmpty(user)) {
        LogRocket.identify(user.id, {
          first_name: user.first_name,
          email: user.email,
          company: ('active_company' in user && user.active_company)?user.active_company.legal_name:null,
        });
      }

      /*
       * Hotjar
       */
      Hotjar.init(hotJarCode, hotJarVersion, {
        debug: debug
      });
      if (!objIsEmpty(user)) {
        Hotjar.identify(user.id, {
          first_name: user.first_name,
          email: user.email,
          company: ('active_company' in user && user.active_company)?user.active_company.legal_name:null,
        });
      }
    }
  }, [user]);

  useEffect(() => {
    if (!dev && !localhost) {
      if (GACode) {
        ReactGA.initialize(GACode);  //todo does it need to be initialized everytime?
        ReactGA.send({
          hitType: "pageview",
          page: location.pathname
        });
      }
    }
  }, [location.pathname, location.search, user])

  return (<Routes>
    {localhost && dev && <Route path="/testpage" element={<TestPage/>}/>}
    <Route exact path="/wp-content/themes/appic-child/reactjs/build"
           element={<Navigate replace to="/"/>}/>
    <Route path="/user/mijn-standplaats"
           element={getToken() ? <Page><User><UserDashboard/></User></Page> : <Page><Login/></Page>}/>
    <Route path="/user/berichten/:conversationId"
           element={getToken() ? <Page><User><UserMessages/></User></Page> : <Page><Login/></Page>}/>
    <Route path="/user/berichten"
           element={getToken() ? <Page><User><UserMessages/></User></Page> : <Page><Login/></Page>}/>
    <Route path="/user/zoek-alerts"
           element={getToken() ? <Page><User><UserAlerts/></User></Page> : <Page><Login/></Page>}/>
    <Route path="/user/favorieten"
           element={getToken() ? <Page><User><UserFavorites/></User></Page> : <Page><Login/></Page>}/>
    <Route path="/user/boeking/:bookingId"
           element={getToken() ? <Page><User><UserBookings/></User></Page> : <Page><Login/></Page>}/>
    <Route path="/user/boekingen"
           element={getToken() ? <Page><User><UserBookings/></User></Page> : <Page><Login/></Page>}/>
    {/*<Route path="/user/boeking/:id/edit"*/}
    {/*       element={getToken() ? <Page><User><UserBookingEdit/></User></Page> : <Page><Login/></Page>}/>*/}
    <Route path="/user/betalingen"
           element={getToken() ? <Page><User><UserPayments/></User></Page> : <Page><Login/></Page>}/>
    <Route path="/user/organisaties"
           element={getToken() ? <Page><User><UserCompanies/></User></Page> : <Page><Login/></Page>}/>
    <Route path="/user/organisatie/:id/edit"
           element={getToken() ? <Page><User><UserCompanyEdit/></User></Page> : <Page><Login/></Page>}/>
    <Route path="/user/organisatie/:id/edit/*"
           element={getToken() ? <Page><User><UserCompanyEdit/></User></Page> : <Page><Login/></Page>}/>
    <Route path="/user/locaties"
           element={getToken() ? <Page><User><UserSpotGroups/></User></Page> : <Page><Login/></Page>}/>
    <Route path="/user/locatie/:spotGroupId/spots"
           element={getToken() ? <Page><User><UserSpots/></User></Page> : <Page><Login/></Page>}/>
    <Route path="/user/locatie/:spotGroupId/manager"
           element={getToken() ? <Page><User><UserSpotGroupManager/></User></Page> : <Page><Login/></Page>}/>
    <Route path="/user/spot/:id"
           element={getToken() ? <Page><User><UserSpot/></User></Page> : <Page><Login/></Page>}/>
    <Route path="/user/locatie/:id/edit"
           element={getToken() ? <Page><User><UserSpotGroupEdit/></User></Page> : <Page><Login/></Page>}/>
    <Route path="/user/stand/:standId/manager"
           element={getToken() ? <Page><User><UserStandManager/></User></Page> : <Page><Login/></Page>}/>
    <Route path="/user/stands"
           element={getToken() ? <Page><User><UserStands/></User></Page> : <Page><Login/></Page>}/>
    <Route path="/user/stand/:id/edit"
           element={getToken() ? <Page><User><UserStandEdit/></User></Page> : <Page><Login/></Page>}/>
    <Route path="/user/notificaties"
           element={getToken() ? <Page><User><UserNotifications/></User></Page> : <Page><Login/></Page>}/>
    <Route path="/user/account"
           element={getToken() ? <Page><User><UserAccount/></User></Page> : <Page><Login/></Page>}/>
    <Route path="/user/account/email/wijzigen"
           element={getToken() ? <Page><User><UserAccountEmailChange/></User></Page> : <Page><Login/></Page>}/>
    <Route path="/user/account/actieve_organisatie"
           element={getToken() ? <Page><User><UserAccountActiveCompany/></User></Page> : <Page><Login/></Page>}/>
    <Route path="/user/account/wachtwoord/wijzigen"
           element={getToken() ? <Page><User><UserAccountPasswordChange/></User></Page> : <Page><Login/></Page>}/>
    <Route path="/user/admin"
           element={getToken() ? <Page><User><UserAdmin/></User></Page> : <Page><Login/></Page>}/>
    <Route path="/user/admin/tools"
           element={getToken() ? <Page><User><UserAdminTools/></User></Page> : <Page><Login/></Page>}/>
    <Route path="/user/admin/users"
           element={getToken() ? <Page><User><UserAdminCompanies/></User></Page> : <Page><Login/></Page>}/>
    <Route path="/user/" element={getToken() ? <Page><User><UserDashboard/></User></Page> : <Page><Login/></Page>}/>
    <Route path="/inloggen" element={<Page><Login/></Page>}/>
    <Route path="/registreren" element={<Page><Register/></Page>}/>
    <Route path="/wachtwoord/vergeten" element={<Page><ForgotPassword/></Page>}/>
    <Route path="/email/verification" element={<Page><WaitingForVerification/></Page>}/>
    <Route path="/error" element={<Page><ErrorReport/></Page>}/>
    <Route path="/stand/:standSlug" element={<Page transparentHeader={true}><Stand/></Page>}/>
    <Route path="/locatie/:spotGroupSlug" element={<Page transparentHeader={true}><SpotGroup/></Page>}/>
    <Route path="/wizard/welkom/*" element={<WizardWelcome/>}/>
    <Route path="/wizard/locatie/*" element={<WizardAddSpotGroup/>}/>
    <Route path="/wizard/stand/*" element={<WizardAddStand/>}/>
    <Route path="/wizard/boek/*" element={<WizardBooking/>}/>
    <Route path="/payment/process/:id" element={<Page><ProcessPayment/></Page>}/>
    <Route path="/" element={<Page transparentHeader={false}><Home/></Page>}/>
    <Route path="/hoe-werkt-het" element={<Page transparentHeader={true}><HowItWorks/></Page>}/>
    <Route path="/social_login/*" element={<Page><SocialLogin/></Page>}/>
    <Route path="*" status={404} element={<Page><Error404/></Page>}/>
  </Routes>)
}
export default MainRouter
