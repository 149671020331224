
const initSpotMap = {
  label: '',
  area_length: 50,
  area_width: 50,
  spot_group_id: null,
  spot_map_media: null,
  spot_map_media_id: null,
}
export default initSpotMap
