import React, {useContext} from "react";
import AuthContext from "../../context/AuthContext";
import spotGroupIconWhite from "../../assets/icons/spot-group-white.svg";
import spotGroupIcon from "../../assets/icons/spot-group.svg";
import useLocationState from "../../hooks/useLocationState";
import SelectBookable from "./SelectBookable";
import Button from "../Button/Button";
import Tile from "../Tile/Tile";
import './BookingSelectSpotGroup-style.scss'

/**
 * A container component to change the props of SelectBookable
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const BookingSelectSpotGroup = (props) => {
  const {getWizardBookableState} = useLocationState()
  const {user} = useContext(AuthContext)

  let selectBookableProps = {...props}
  selectBookableProps.label = "Welke Locatie wil je inzetten voor deze boeking?"
  selectBookableProps.bookableName = "spot_group"
  selectBookableProps.name = "spot_group_id"
  selectBookableProps.bookables = props.spotGroups


  const spotGroupTile = () => {
    return (
      <>
        <h1>Geselecteerde Locatie:</h1>
        {props.model.spot_group_id
          ? <Tile
            bookable={props.model.spot_group}
            showEditButton={user && user.active_company_id === props.model.spot_group.company_id}
          />
          : <>
            Geen Locatie profiel geselecteerd voor deze boeking.<br/>
            <strong>Deze boeking is op een locatie naar keuze door organisatie:
            {(props.model.type === 'spot-group-requests-stand')
              ? <>{props.model.requested_by_company.legal_name}</>
              : <>{props.model.received_by_company.legal_name}</>
            }
            </strong>
          </>
        }
      </>
    )
  }

  /*
   * SelectSpotGroup
   *   stand-requests-spot
   *     receiving
   *       = showing for spotGroup, that is receiving a booking by a stand. so,
   *       show the spotGroup <Tile>
   *     requesting
   *       = showing for stand, that is requesting a booking on a spotGroup. so,
   *       show the spotGroup <Tile>
   *
   *   spot-group-requests-stand
   *     receiving
   *       = showing for Stand, that is receiving a booking by a spotGroup. so,
   *       show the spotGroup <Tile>
   *     requesting
   *       = showing for spotGroup that is requesting a stand
   *       show SelectBookable (with all owned SpotGroups)
   */
  return (
    <div className="form-component-booking-select-spot-group">
      {props.model.type === 'stand-requests-spot' &&
        <>
          {props.model.direction === 'receiving' && spotGroupTile()}
          {props.model.direction === 'requesting' && spotGroupTile()}
        </>
      }
      {props.model.type === 'spot-group-requests-stand' &&
        <>
          {(props.model.direction === 'receiving' || props.model.status !== 'concept') &&
            spotGroupTile()
          }

          {props.model.direction === 'requesting' && props.model.status === 'concept' &&
            <>{props.spotGroups && !!props.spotGroups.length
              ? <>
                <h1><img
                  src={spotGroupIcon}
                  alt="Markten etc"
                  style={{width: 24}}
                /> Alle Locaties</h1>
                <SelectBookable {...selectBookableProps}/>


                {!!props.model.stand.booking_without_spot_group &&
                  <div className="select-no-spot-group">
                    <label>Geen Locatie, via organisatie '{user.active_company.legal_name}'
                      <input
                        onChange={() => {
                          props.model.spot_group_id = null;
                          props.form.save()
                        }}
                        checked={!props.model.spot_group_id}
                        type="radio"
                        name="spot_group_id"
                      />
                    </label>
                  </div>
                }

                {!props.model.spot_group_id &&
                  <p><strong>Stands, Trucks, Acts willen graag zien waar ze staan zodat ze een goede afweging kunnen
                    maken of de locatie geschikt is voor hun. Het wordt daarom aangeraden om een Locatie te selecteren
                    of aan te maken!</strong></p>
                }

                <Button
                  label="Locatie Aanmaken"
                  link="/wizard/locatie/new"
                  icon={spotGroupIconWhite}
                  linkState={getWizardBookableState()}
                  // linkState={getWizardBookableState(props.wizard)}
                />
              </>
              :
              <>
                <p>Je hebt nog geen Locaties aangemaakt/gekoppeld aan je account.</p>
                <Button
                  label="Toevoegen"
                  link="/wizard/locatie/new"
                  icon={spotGroupIconWhite}
                  linkState={getWizardBookableState()}
                  // linkState={getWizardBookableState(props.wizard)}
                />
              </>
            }</>
          }
        </>
      }
    </div>
  )
}

export default BookingSelectSpotGroup
