import React from "react"
import types from "../../data/types";
import industries from "../../data/industries";
import initStand from "../../data/initStand";
import {
  Media,
  SelectBlock,
  Textarea,
  Fields,
  Input,
  Price,
  Form,
  Slug,
} from "../../components/Form/Form";
import {Route, useNavigate, useParams} from "react-router-dom";
import WizardAddStandStart from "./WizardAddStandStart";
import SocialShareProfile from "../../components/SocialShareProfile/SocialShareProfile";
import ProfileStatusForm from "../../components/Form/ProfileStatusForm";
import useStandService from "../../api/services/useStandService";
import Availabilities from "../../components/Form/Availabilities";
import useWizard from "../../hooks/useWizard";
import Location from "../../components/Form/Location";
import useForm from "../../hooks/useForm";
import Wizard from "../../layouts/Wizard/Wizard";
import Three from "../../components/Form/Three";
import Bool from "../../components/Form/Bool";
import salesManImage from "../../assets/images/example-images/verkoper.jpg"
import productImage from "../../assets/images/example-images/product.jpg"
import truckImage from "../../assets/images/example-images/truck.jpg"
import standIcon from "../../assets/icons/stand.svg";

const WizardAddStand = () => {

  const params = useParams()
  const form = useForm()
  const pathname = "/wizard/stand"
  const steps = [
    {slug: 'start', label: 'Alle Stand & Trucks'},
    {slug: 'new', label: 'Algemeen'},
    {slug: 'industry', label: 'Branche & Producten'},
    {slug: 'type', label: 'Verkoop locaties'},
    {slug: 'size', label: 'Afmetingen Stand of Truck'},
    {slug: 'map', label: 'Vertreklocatie'},
    {slug: 'images', label: 'Fotos'},
    {
      slug: 'profile', label: 'Profiel',
      next: {
        key: 'public',
        value: 0,
        goTo: 'finish'
      }
    },
    {slug: 'availabilities', label: 'Beschikbaarheid',},
    {slug: 'price', label: 'Prijs'},

    // {slug: 'options', label: 'Opties'},
    // {slug: 'options_requested'},
    // {slug: 'info', label: 'Belangrijke informatie'},
    // {slug: 'info_requested'},

    {
      slug: 'finish', label: 'Finish',
      prev: {
        key: 'public',
        value: 0,
        goTo: 'profile',
      }
    },
  ]

  const initialButtonPrev = {
    start: {
      onClick: () => navigate('/wizard/welkom/choice_stand_truck_location/') //goes to last page of welcome wizard
    },
  }

  const paramsSplit = params['*'].split('/')

  //todo disabled new button is not reloaded.. therefore when editing spotgroup the new next button is still disabled... :(

  const initialButtonNext = {
    start: {
      visible: false,
    },
    new: {
      disabled: !Number.isInteger(parseInt(paramsSplit[1])),
    },
    finish: {
      label: 'Zoek Standplaatsen',
      onClick: () => navigate('/?direction=search&search_model=spot_group'),
    },
  }

  const updateSteps = (steps) => {
    return steps
  }

  const wizard = useWizard(
    steps,
    updateSteps,
    pathname,
    initialButtonPrev,
    initialButtonNext,
    form,
  )
  const standService = useStandService()
  // const optionService = useOptionsService('stand_option')
  // const optionRequestService = useOptionsService('spot_group_option')
  // const infoService = useOptionsService('stand_info')
  // const infoRequestService = useOptionsService('spot_group_info')
  const navigate = useNavigate()

  return (
    <div className="wizard-add-stand">
      <Wizard
        form={form}
        wizard={wizard}
      >
        <Route path="/start/*" element={
          <div className="center">
            <h1><img
              src={standIcon}
              alt="Markten etc"
              style={{width: 28}}
            /> Stands & Trucks</h1>
            <WizardAddStandStart
              wizard={wizard}
            />
          </div>
        }/>

        <Route path="/new/*" element={
          <div className="center">
            {wizard.id ?
              <h2>Stand of Truck bewerken</h2>
              :
              <h2>Aanmelden: Stand of Truck</h2>
            }
            <div className="width-medium">
              <Form>
                <Fields
                  id={wizard.id}
                  form={form}
                  wizard={wizard}
                  initData={initStand}
                  apiService={standService}
                  updateSuccess={(stand) => wizard.nextStep(stand.id)}
                  onChange={() => wizard.nextButtonDisabled()}
                  onReset={() => wizard.setInitialButtons()}
                >
                  <Input
                    label="Naam"
                    name="label"
                    autoFocus="autofocus"
                  />
                  <Input
                    label="Slogan"
                    name="slogan"
                    placeholder="Korte pakkende zin"
                  />
                  <Textarea
                    label="Beschrijving"
                    name="description"
                    rows={8}
                    placeholder="Vertel iets meer over jouw organisatie:
Wat maakt je uniek?
Waarom zouden organisaties jou boeken?"
                  />
                </Fields>
              </Form>
            </div>
          </div>
        }/>
        {wizard.id &&
          <>
            <Route path="/industry/*" element={
              <div className="center">
                <h2>Branche & Producten</h2>
                <div className="width-medium">
                  <Form>
                    <Fields
                      id={wizard.id}
                      form={form}
                      wizard={wizard}
                      apiService={standService}
                      updateSuccess={(stand) => wizard.nextStep(undefined, stand)}
                      onChange={() => wizard.navButtonsDisabled()}
                      onReset={() => wizard.setInitialButtons()}
                      onShow={() => wizard.setButtonNext({...wizard.buttonNext, new: {disabled: false}})}
                    >
                      <SelectBlock
                        label="Vink aan in welke branche jij valt met jouw Stand of Truck: (je kunt meerdere vakjes aankruisen)"
                        name="industries_ids"
                        data={industries}
                      />
                    </Fields>
                  </Form>
                </div>
              </div>
            }/>

            <Route path="/type/*" element={
              <div className="center">
                <h2>Verkoop locaties</h2>
                <div className="width-medium">
                  <p>Vink hier aan naar wat voor locaties jij opzoekt bent: (Je kan meerdere vakjes aankruisen)</p>
                  <Form>
                    <Fields
                      id={wizard.id}
                      form={form}
                      wizard={wizard}
                      apiService={standService}
                      updateSuccess={(stand) => wizard.nextStep(undefined, stand)}
                      onChange={() => wizard.navButtonsDisabled()}
                      onReset={() => wizard.setInitialButtons()}
                    >
                      <SelectBlock
                        label="Met deze informatie kunnen wij jou de juiste locaties aanbieden en weten we waar jij wel en in niet in geïnteresseerd bent"
                        name="types_ids"
                        data={types}
                      />
                    </Fields>
                  </Form>
                </div>
              </div>
            }/>

            <Route path="/size/*" element={
              <div className="center">
                <h2>Afmetingen Stand of Truck</h2>
                <div className="width-small">
                  <p>Geef hier aan wat de maten zijn van jouw stand of truck:</p>
                  <Form>
                    <Fields
                      id={wizard.id}
                      form={form}
                      wizard={wizard}
                      apiService={standService}
                      updateSuccess={(stand) => wizard.nextStep(undefined, stand)}
                      onChange={() => wizard.navButtonsDisabled()}
                      onReset={() => wizard.setInitialButtons()}
                    >
                      <Input
                        label="Lengte"
                        name="length"
                        type="meter"
                        autoFocus="autofocus"
                      />
                      <Input
                        label="Diepte/Breedte"
                        name="width"
                        type="meter"
                      />
                      <Input
                        label="Hoogte"
                        name="height"
                        type="meter"
                      />
                    </Fields>
                  </Form>
                </div>
              </div>
            }/>

            <Route path="/map/*" element={
              <div className="center">
                <h2>Vertreklocatie</h2>
                <p></p>
                <div>
                  <Form>
                    <Fields
                      id={wizard.id}
                      form={form}
                      wizard={wizard}
                      apiService={standService}
                      updateSuccess={(stand) => wizard.nextStep(undefined, stand)}
                      onChange={() => wizard.navButtonsDisabled()}
                      onReset={() => wizard.setInitialButtons()}
                    >
                      <Location
                        label="Klik op de map om aan te geven waar de locatie is van waar jij vertrekt. Dit wordt openbaar zichtbaar, je kunt ervoor kiezen om niet jouw exacte locatie op te geven: zet hem gerust ergens anders binnen (twee)honderd meter."
                      />
                      <Input
                        label="Geef vervolgens aan wat de maximale afstand is die jij zou willen afleggen voor een standplaats. (enkele reis)"
                        name="radius"
                        type="km"
                      />
                    </Fields>
                  </Form>
                </div>
              </div>
            }/>

            <Route path="/images/*" element={
              <div className="center">
                <h2>Foto's toevoegen van je stand of truck</h2>
                <div className="width-600">
                  <p>Je hebt drie foto's nodig om te beginnen. Je kunt er later meer toevoegen of dingen veranderen.</p>

                  <Form>
                    <Fields
                      id={wizard.id}
                      form={form}
                      wizard={wizard}
                      apiService={standService}
                      updateSuccess={(stand) => wizard.nextStep(undefined, stand)}
                      onChange={() => wizard.navButtonsDisabled()}
                      onReset={() => wizard.setInitialButtons()}
                    >
                      <Media
                        label="1. Vooraanzicht van je stand of truck"
                        name="overview_media"
                        width={1600}
                        height={700}
                        exampleImage={truckImage}
                        size="banner"
                      />
                      <Media
                        label="2. Actie waarin de verkoper te zien is"
                        name="action_media"
                        width={900}
                        height={450}
                        exampleImage={salesManImage}
                        size="normal"
                      />
                      <Media
                        label="3. Foto van het product dat wordt verkocht (close-up)"
                        name="product_media"
                        width={900}
                        height={450}
                        exampleImage={productImage}
                        size="normal"
                      />
                    </Fields>
                  </Form>
                </div>
              </div>
            }/>

            <Route path="/profile/*" element={
              <div className="center">
                <h2>Openbaar profiel aanmaken?</h2>
                <p></p>
                <div>
                  <Form>
                    <Fields
                      id={wizard.id}
                      form={form}
                      wizard={wizard}
                      apiService={standService}
                      updateSuccess={(stand) => wizard.nextStep(undefined, stand)}
                      onChange={() => wizard.navButtonsDisabled()}
                      onReset={() => wizard.setInitialButtons()}
                    >
                      <Bool
                        label="Wil je dat Locaties jou kunnen vinden & boeken?"
                        name="public"
                      />
                      <Slug
                        label="Link naar profiel"
                        text="Voorbeeld: broodje-ham-van-tam"
                        urlPostFix="stand"
                        name="slug"
                        basedOnField="label"
                        enabledBy={[{key:'public', value:1}]}
                      />
                    </Fields>
                  </Form>
                </div>
              </div>
            }/>

            <Route path="/availabilities/*" element={
              <div className="center">
                <h2>Beschikbaarheid</h2>
                <div className="width-full">
                  <Form>
                    <Fields
                      id={wizard.id}
                      form={form}
                      wizard={wizard}
                      apiService={standService}
                      updateSuccess={(stand) => wizard.nextStep(undefined, stand)}
                      onChange={() => wizard.navButtonsDisabled()}
                      onReset={() => wizard.setInitialButtons()}
                    >
                      <Three
                        label="Wat is de status van deze Stand of Truck?"
                        name="available"
                        labelYes="Elke dag beschikbaar"
                        labelNo="(nog) niet beschikbaar (niet boekbaar en niet in zoekresultaten)"
                        labelOther="Specifieke dag(en) ingeven"
                      />
                      <Availabilities
                        label="Geef hier aan welke dagen je niet beschikbaar bent."
                        name="availabilities"
                        enabledBy={[{key:'available', value:2}]}
                      />
                    </Fields>
                  </Form>
                </div>
              </div>
            }/>

            <Route path="/price/*" element={
              <div className="center">
                <h2>Prijs</h2>
                <div className="width-medium">
                  <Form>
                    <Fields
                      id={wizard.id}
                      form={form}
                      wizard={wizard}
                      apiService={standService}
                      updateSuccess={(stand) => wizard.nextStep(undefined, stand)}
                      onChange={() => wizard.navButtonsDisabled()}
                      onReset={() => wizard.setInitialButtons()}
                    >
                      <Price
                        // label="Prijs"
                        name="price"
                      />
                    </Fields>
                  </Form>
                </div>
              </div>
            }/>

            {/*<Route path="/options/*" element={*/}
            {/*  <div className="center">*/}
            {/*    <h2>Opties</h2>*/}
            {/*    <div className="width-medium">*/}
            {/*      <Form>*/}
            {/*        <Fields*/}
            {/*          id={wizard.id}*/}
            {/*          form={form}*/}
            {/*          wizard={wizard}*/}
            {/*          apiService={standService}*/}
            {/*          updateSuccess={(stand) => wizard.nextStep(undefined, stand)}*/}
            {/*          onChange={() => wizard.navButtonsDisabled()}*/}
            {/*          onReset={() => wizard.setInitialButtons()}*/}
            {/*        >*/}


            {/*        </Fields>*/}
            {/*      </Form>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*}/>*/}

            {/*<Route path="/options_requested/*" element={*/}
            {/*  <div className="center">*/}
            {/*    <h2>Opties</h2>*/}
            {/*    <div className="width-medium">*/}
            {/*      <Form>*/}
            {/*        <Fields*/}
            {/*          id={wizard.id}*/}
            {/*          form={form}*/}
            {/*          wizard={wizard}*/}
            {/*          apiService={standService}*/}
            {/*          updateSuccess={(stand) => wizard.nextStep(undefined, stand)}*/}
            {/*          onChange={() => wizard.navButtonsDisabled()}*/}
            {/*          onReset={() => wizard.setInitialButtons()}*/}
            {/*        >*/}


            {/*        </Fields>*/}
            {/*      </Form>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*}/>*/}

            {/*<Route path="/info/*" element={*/}
            {/*  <div className="center">*/}
            {/*    <h2>Belangrijke Informatie</h2>*/}
            {/*    <div className="width-medium">*/}
            {/*      <Form>*/}
            {/*        <Fields*/}
            {/*          id={wizard.id}*/}
            {/*          form={form}*/}
            {/*          wizard={wizard}*/}
            {/*          apiService={standService}*/}
            {/*          updateSuccess={(stand) => wizard.nextStep(undefined, stand)}*/}
            {/*          onChange={() => wizard.navButtonsDisabled()}*/}
            {/*          onReset={() => wizard.setInitialButtons()}*/}
            {/*        >*/}


            {/*        </Fields>*/}
            {/*      </Form>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*}/>*/}

            {/*<Route path="/info_requested/*" element={*/}
            {/*  <div className="center">*/}
            {/*    <h2>Belangrijke Informatie</h2>*/}
            {/*    <div className="width-medium">*/}
            {/*      <Form>*/}
            {/*        <Fields*/}
            {/*          wizard={wizard}*/}
            {/*          id={wizard.id}*/}
            {/*          form={form}*/}
            {/*          apiService={standService}*/}
            {/*          updateSuccess={(stand) => wizard.nextStep(undefined, stand)}*/}
            {/*          onChange={() => wizard.navButtonsDisabled()}*/}
            {/*          onReset={() => wizard.setInitialButtons()}*/}
            {/*        >*/}


            {/*        </Fields>*/}
            {/*      </Form>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*}/>*/}

            <Route path="/finish/*" element={
              <div className="center">
                <h2>Gefeliciteerd!</h2>
                <div className="width-medium">
                  <h4>Jouw Stand of Truck is aangemaakt!</h4>
                  <Form>
                    <Fields
                      id={wizard.id}
                      form={form}
                      wizard={wizard}
                      apiService={standService}
                    >
                      <ProfileStatusForm/>
                      {/*<ProfileExample/>*/}
                      <SocialShareProfile/>
                    </Fields>
                  </Form>
                </div>
              </div>
            }/>
          </>
        }
      </Wizard>
    </div>
  )
}

export default WizardAddStand
