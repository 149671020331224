import React, {useEffect, useState} from "react";
import {DateObject} from "react-multi-date-picker";
import {months, weekDays} from "../../data/calendar";
import {Fields, Form, SelectBlock} from "../../components/Form/Form";
import {useParams} from "react-router-dom";
import {Calendar} from "react-multi-date-picker";
import SpotGroupOccupancy from "../../components/SpotGroupOccupancy/SpotGroupOccupancy";
import industries from "../../data/industries";
import useSpotGroupService from "../../api/services/useSpotGroupService";
import useBookableService from "../../api/services/useBookableService";
import Availabilities from "../../components/Form/Availabilities";
import useSpotService from "../../api/services/useSpotService";
import ProfileStatus from "../../components/ProfileStatus/ProfileStatus";
import SpotMapPublic from "../../components/SpotMap/SpotMapPublic";
import BookingSmall from "../../components/BookingSmall/BookingSmall";
import useBooking from "../../hooks/useBooking";
import SpotPopup from "../../components/SpotPopup/SpotPopup";
import useForm from "../../hooks/useForm";
import Loading from "../../components/Loading/Loading";
import Button from "../../components/Button/Button";
import Popup from "../../components/Popup/Popup";
import swal from "sweetalert";
import "./style.scss"
import Three from "../../components/Form/Three";

const UserSpotGroupManager = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [spotGroup, setSpotGroup] = useState(null)
  const [bookings, setBookings] = useState(null)
  const spotGroupService = useSpotGroupService()
  const {spotGroupId} = useParams()
  const [isLoadingBookings, setIsLoadingBookings] = useState(false)
  const [showPopupSpot, setShowPopupSpot] = useState([])
  const [dates, setDates] = useState([])
  const [nCalendars, setNCalendars] = useState(2)
  const {showBookableRangeBookings} = useBookableService()
  const {parseSpotGroupBookings} = useBooking()
  const [showPopupIndustry, setShowPopupIndustry] = useState([])
  const [showPopupAvailability, setShowPopupAvailability] = useState([])

  // const spotForm = useForm()

  useEffect(() => {
    spotGroupService.userShowManager(
      (spotGroup) => {
        setSpotGroup({...spotGroup, bookings: []})
        setIsLoading(false)
      },
      (error) => {
        console.error('validation errors', error)
        //todo do something
        setIsLoading(false)
      },
      spotGroupId
    )
  }, [])

  /**
   *
   * @param dates
   */
  const handleCalendarChange = (dates) => {
    setDates(dates)
    if (dates.length === 2) {
      setIsLoadingBookings(true)
      showBookableRangeBookings(
        (bookings) => {
          const parsed = parseSpotGroupBookings({...spotGroup, bookings: bookings})
          setSpotGroup(parsed)
          setBookings(bookings)
          setIsLoadingBookings(false)
        },
        (error) => {
          setIsLoadingBookings(false)
        },
        'spot_group',
        spotGroupId,
        dates[0].toString(),
        dates[1].toString(),
      )
    }
  }

  /**
   * Create an array with a range of date items; one for every day.
   *
   * @param dates
   * @returns {*[]}
   */
  const createDateRange = (dates) => {
    // Validate input
    if (dates.length !== 2 || !(dates[0] instanceof DateObject) || !(dates[1] instanceof DateObject)) {
      return [];
    }

    const startDate = dates[0];
    const endDate = dates[1];
    const dateRange = [];

    // Ensure the start date is before the end date
    if (startDate > endDate) {
      console.error("Start date must be before end date.");
      return [];
    }

    let currentDate = new DateObject(startDate);

    // Generate range
    while (currentDate <= endDate) {
      dateRange.push(new DateObject(currentDate));
      currentDate = currentDate.add(1, "days");
    }

    return dateRange;
  }

  return (
    <div className="component-spot-group-manager">
      <h1>Locatiebeheer - <span style={{color: 'red'}}>nog onder constructie</span></h1>

      {isLoading ?
        <Loading/>
        :
        <>
          {spotGroup &&
            <>
              Locatie: {spotGroup.label}
              <ProfileStatus
                model={spotGroup}
              />
              <h3>Beschikbaarheid komende jaar</h3>
              <SpotGroupOccupancy
                spotGroup={spotGroup}
                createDateRange={createDateRange}
              />
              <h3>Beschikbaarheid in datum-range</h3>
              <p>Klik 2x(!) op de calendar tot de datum rond wordt. Maak op deze manier een 'range' of 1 datum</p>
              <div className="calendar">
                <Calendar
                  range
                  disabled={isLoadingBookings}
                  value={dates}
                  weekStartDayIndex={1}
                  shadow={false}
                  weekDays={weekDays}
                  months={months}
                  numberOfMonths={nCalendars}
                  onChange={handleCalendarChange}
                />
              </div>
              <div className="spot-map">
                {spotGroup.multi_spot ?
                  <>
                    <SpotMapPublic
                      spotGroup={spotGroup}
                      onSpotClick={(booking, setBooking, spot) => {
                        swal({
                          title: 'Instellingen',
                          text: null,
                          icon: null,
                          buttons: {
                            confirmA: {
                              text: 'Beschikbaarheid',
                              value: 'availability',
                              className: 'btn-color-grey btn-size-m',
                            },
                            confirmB: {
                              text: 'Branches',
                              value: 'industry',
                              className: 'btn-color-grey btn-size-m',
                            }
                          }
                        }).then((result) => {
                          if (result) {
                            if (result === 'availability') {
                              setShowPopupAvailability({...showPopupAvailability, [spot.id]: true})
                            }
                            if (result === 'industry') {
                              setShowPopupIndustry({...showPopupIndustry, [spot.id]: true})
                            }
                          }
                        })
                      }}
                      booking={{
                        dates: createDateRange(dates),
                        spots: []
                      }}
                    />

                    {spotGroup.spots.map((spot, i) => {
                      return (

                        <div key={i}>
                          <SpotPopups
                            spot={spot}
                            spotGroup={spotGroup}
                            setSpotGroup={setSpotGroup}
                            setShowPopupAvailability={
                              (bool) => {
                                setShowPopupAvailability({...showPopupAvailability, [spot.id]: bool})
                              }}
                            setShowPopupIndustry={
                              (bool) => setShowPopupIndustry({...showPopupIndustry, [spot.id]: bool})
                            }
                            showPopupAvailability={showPopupAvailability[spot.id]}
                            showPopupIndustry={showPopupIndustry[spot.id]}
                          />
                        </div>
                      )
                    })}
                  </>
                  :
                  <>
                    <Button
                      label="Meer info over deze standplaats"
                      onClick={() => setShowPopupSpot({...showPopupSpot, [0]: true})}
                      color="grey"
                    />
                    <SpotPopup
                      spot={spotGroup.spots[0]}
                      iteration={0}
                      setShowPopupSpot={setShowPopupSpot}
                      showPopupSpot={showPopupSpot}
                    />
                  </>
                }
              </div>
              <h3>Boekingen in geselecteerde periode</h3>
              <div className="bookings">
                {isLoadingBookings
                  ? <Loading/>
                  : bookings && !!bookings.length
                    ? bookings.map((booking, i) => {
                      return (
                        <BookingSmall
                          booking={booking}
                          showAction={false}
                          key={i}
                          target="_blank"
                        />
                      )
                    })
                    : <>Geen boekingen in geselecteerde periode</>
                }
              </div>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
            </>
          }
        </>
      }

    </div>
  )
}

const SpotPopups = ({
                      spot,
                      spotGroup,
                      setSpotGroup,
                      showPopupAvailability,
                      setShowPopupAvailability,
                      showPopupIndustry,
                      setShowPopupIndustry,
                    }) => {

  const form = useForm()
  const spotService = useSpotService()
  return (
    <>
      <Popup
        showPopup={showPopupAvailability}
        setShowPopup={setShowPopupAvailability}
        title="Beschikbaarheid"
      >
        <Form>
          <Fields
            id={spot.id}
            onRender={(form) => {
              //force showing save buttons by setting 'isChanged' to true
              form.save(true)
            }}
            form={form}
            apiService={spotService}
            onReset={() => {
              setShowPopupAvailability(false)
            }}
            updateSuccess={(newSpot) => {
              setShowPopupAvailability(false)
              spotGroup.spots.map((spot, i) => {
                if (spot.id === newSpot.id) {
                  spotGroup.spots[i] = newSpot
                }
                return spot
              })
              setSpotGroup(spotGroup)
            }}
          >
            <Three
              label="Wat is de status van deze Standplaats?"
              name="available"
              labelYes="Elke dag beschikbaar"
              labelNo="(nog) niet beschikbaar (niet boekbaar en niet in zoekresultaten)"
              labelOther="Specifieke dag(en) ingeven"
            />
            <Availabilities
              label="Beschikbaarheid specifiek voor deze Standplaats/Spot/Plek"
              name="availabilities"
              enabledBy={[{key: 'available', value: 2}]}
            />
          </Fields>
        </Form>
      </Popup>
      <Popup
        showPopup={showPopupIndustry}
        setShowPopup={setShowPopupIndustry}
        title="Branches"
      >
        <Form>
          <Fields
            id={spot.id}
            onRender={(form) => {
              //force showing save buttons by setting 'isChanged' to true
              form.save(true)
            }}
            form={form}
            apiService={spotService}
            onReset={() => {
              setShowPopupIndustry(false)
            }}
            updateSuccess={(newSpot) => {
              setShowPopupIndustry(false)
              spotGroup.spots.map((spot, i) => {
                if (spot.id === newSpot.id) {
                  spotGroup.spots[i] = newSpot
                }
                return spot
              })
              setSpotGroup(spotGroup)
            }}
          >
            <p>{spot.id}</p>
            <SelectBlock
              label="Vink aan in welke branche jij valt met jouw Stand of Truck: (je kunt meerdere vakjes aankruisen)"
              name="industries_ids"
              data={industries}
            />
          </Fields>
        </Form>

      </Popup>
    </>
  )

}


export default UserSpotGroupManager
