import React from "react";
import {DateObject} from "react-multi-date-picker";
import PropTypes from "prop-types";
import "./style.scss"

const StandOccupancy = (props) => {
  const {
    stand,
    createDateRange,
  } = props

  return (
    <div className="component-stand-occupancy">
      <div className="occupancies">
        {createDateRange([new DateObject(), new DateObject().add("1", "year")]).map((date, index) => {
          const standIndex = stand.stand_indices.find(item => new DateObject(item.date).toDays() === date.toDays())
          return (
            <div className="occupancy" key={index}>
              {date.day === 1 && <div className="month">{date.month.name}</div>}
              <div className="day">{date.day}<br/><span style={{fontSize:'12px'}}>{date.weekDay.shortName}</span></div>
              <div className="bar">
                <div
                  className="filled"
                  style={{height: `${(standIndex)?100:0}%`}}
                ></div>
                <div className="text">
                  {/*<span>{percent}%</span>*/}
                  <span>{(standIndex)?1:0}</span>
                  {/*<span>{stand.spot_count}</span>*/}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

StandOccupancy.defaultProps = {}

StandOccupancy.propTypes = {
  stand: PropTypes.object.isRequired,
}

const isEqual = (prevProps, nextProps) => {
  return true
}

export default React.memo(StandOccupancy, isEqual)
