import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import useFunction from "./useFunction";
import useLocationState from "./useLocationState";
import cloneDeep from 'lodash/cloneDeep';

const useWizard = (
  steps,
  updateSteps,
  pathname,
  initialButtonPrev,
  initialButtonNext,
  form,
) => {

  const navigate = useNavigate()
  const params = useParams()
  const [step, setStep] = useState()
  const [finished, setFinished] = useState(false)
  const [id, setId] = useState()
  const {objIsEmpty} = useFunction()
  const [buttonPrev, setButtonPrev] = useState(initialButtonPrev)
  const [buttonNext, setButtonNext] = useState(initialButtonNext)
  const [buttonReset, setButtonReset] = useState(initialButtonPrev)
  const [locationStateButtonPrev, setLocationStateButtonPrev] = useState({})
  const [locationStateButtonNext, setLocationStateButtonNext] = useState({})
  const {hasLocationState, location} = useLocationState()


  /**
   * Preserve buttons from location state into state in the wizard
   */
  useEffect(() => {
    if (hasLocationState('buttonPrev')) {
      setLocationStateButtonPrev(location.state.buttonPrev)
    }
    if (hasLocationState('buttonNext')) {
      setLocationStateButtonNext(location.state.buttonNext)
    }
  }, []);

  /**
   * If locationStateButtonPrev or locationStateButtonNext changes,
   * set buttons to initial value + locationStateButtonPrev or locationStateButtonNext
   */
  useEffect(() => {
    setInitialButtons()
  }, [locationStateButtonPrev, locationStateButtonNext]);

  /**
   * Set step and id from params
   */
  useEffect(() => {
    if (params['*']) {
      const [newStep, newId] = params['*'].split('/')
      setStep(newStep)
      setId(newId)
    }
  }, [params])

  /**
   * If pathname changes, check if pathname is equal to location.pathname
   * if so, navigate to first step
   */
  useEffect(() => {
    if (pathname === location.pathname || pathname + '/' === location.pathname) {
      navigate(pathname + '/' + getFirstStep().slug)
    }
  }, [location.pathname])

  /**
   * Set buttons back to init value when step changes
   */
  useEffect(() => {
    if (
      (step && getStep(step).slug === 'finish')
    ) {
      setFinished(true)
    }
    window.scroll(0, 0)
  }, [step])

  /**
   * Loops through buttonNext of buttonPrev object and changes pre-defined key into functions
   * this solves the issue that location.state is serialized and cannot container functions
   *
   *
   * @param button
   * @returns {*}
   */
  const parseButtonObj = (button) => {
    Object.keys(button).map((key) => {
      if (button[key] && 'onClick' in button[key] && button[key].onClick) {

        /* Change 'navigate' into navigate function*/
        if ('navigate' in button[key].onClick && button[key].onClick) {
          let link = cloneDeep(button[key].onClick.navigate)
          const state = ('state' in button[key].onClick && button[key].onClick.state)
            ? cloneDeep(button[key].onClick.state)
            : {}
          button[key].onClick = () => navigate(link, state)
        }

        /* add more parsing for onClick here*/
      }

      /* add more other parsings here*/

      return key
    })
    return button
  }

  /**
   *
   */
  const setInitialButtons = () => {
    setButtonPrev(parseButtonObj({...initialButtonPrev, ...locationStateButtonPrev}))
    setButtonNext(parseButtonObj({...initialButtonNext, ...locationStateButtonNext}))
  }

  /**
   *
   * @param n
   * @param model
   * @returns {*}
   */
  const getStepDelta = (n, model = form.formData.model) => {
    const stepsUpdate = updateSteps(steps, model)
    const i = stepsUpdate.findIndex((stepItem) => stepItem.slug === step) + n
    return (stepsUpdate[i]) ? stepsUpdate[i] : getFirstStep()
  }

  /**
   *
   * @param slug
   * @returns {*}
   */
  const getStep = (slug) => {
    return updateSteps(steps, form.formData.model).find((stepItem) => stepItem.slug === slug)
  }

  /**
   *
   * @returns {*}
   */
  const getNextStep = () => {

    //loop until next step is not hidden

    //todo do check if the first or last steps are caught in loop

    let nextStep
    let i = 0;
    do {
      i++;
      nextStep = getStepDelta(i)
    }
    while ('hidden' in nextStep && nextStep.hidden);
    return nextStep
  }

  /**
   *
   * @returns {*}
   */
  const getPrevStep = () => {

    //loop backwards until next step is not hidden

    //todo do check if the first or last steps are caught in loop

    let prevStep
    let i = 0;
    do {
      i--;
      prevStep = getStepDelta(i)
    }
    while ('hidden' in prevStep && prevStep.hidden);
    return prevStep
  }

  /**
   *
   * @param newPostFix
   * @returns {string|string}
   */
  const getPostFix = (newPostFix = undefined) => {
    if (typeof newPostFix !== 'undefined') {
      return '/' + newPostFix
    }
    return (id) ? '/' + id : '/'
  }

  /**
   *
   * @param newPostFix
   * @param model
   */
  const nextStep = (newPostFix = undefined, model = form.formData.model) => {
    const nextStep = getNextStep()
    const stepItem = getStep(step)
    const nextSlug = (shouldSkipNext(stepItem, model))
      ? stepItem.next.goTo
      : nextStep.slug
    navigate(pathname + '/' + nextSlug + getPostFix(newPostFix))
  }

  /**
   *
   * @param newPostFix
   * @param model
   */
  const prevStep = (newPostFix = undefined, model = form.formData.model) => {
    const prevStep = getPrevStep()
    const stepItem = getStep(step)
    const prevSlug = (shouldSkipPrev(stepItem, model))
      ? stepItem.prev.goTo
      : prevStep.slug
    navigate(pathname + '/' + prevSlug + getPostFix(newPostFix))
  }

  /**
   * Go to finish step
   */
  const goToFinish = () => {
    goTo(getFinishStep().slug)
  }

  /**
   * Get last step from steps array
   *
   * @returns {*}
   */
  const getFinishStep = () => {
    const stepsUpdate = updateSteps(steps, form.formData.model)
    return stepsUpdate[stepsUpdate.length - 1]
  }

  /**
   * Get first step from steps array
   *
   * @returns {*}
   */
  const getFirstStep = () => {
    return updateSteps(steps, form.formData.model)[0]
  }

  /**
   * Loop through 'steps' array,
   * check per step if it has a 'next' prop,
   * if so, skipp all non steps that should not be shown.
   *
   * @param model
   * @returns {*[]}
   */
  const getBreadcrumbs = (model) => {
    let breadcrumbs = []
    let skipTo = false

    /* If no model is passed, always all breadcrumbs should be returned*/
    if (objIsEmpty(model)) {
      return steps
    }

    updateSteps(steps, model).map((stepItem) => {
      if ('hidden' in stepItem && stepItem.hidden) {

      } else {
        if (!skipTo && shouldSkipNext(stepItem, model)) {
          skipTo = stepItem.next.goTo
          breadcrumbs.push(stepItem)
        }
        if (!skipTo) {
          breadcrumbs.push(stepItem)
        }
        if (stepItem.slug === skipTo) {
          breadcrumbs.push(stepItem)
          skipTo = false
        }
      }

      return stepItem
    })
    return breadcrumbs
  }

  /**
   * check if stepItemB has skip function
   *
   * @param stepItem
   * @param model
   * @returns {boolean}
   */
  const shouldSkipNext = (stepItem, model) => {
    return (
      stepItem &&
      'next' in stepItem &&
      model[stepItem.next.key].toString() === stepItem.next.value.toString()
    )
  }

  /**
   *
   * @param stepItem
   * @param model
   * @returns {boolean}
   */
  const shouldSkipPrev = (stepItem, model) => {
    return (stepItem &&
      'prev' in stepItem &&
      model[stepItem.prev.key].toString() === stepItem.prev.value.toString()
    )
  }

  /**
   * Calculate progress based on total steps and step (passed via param)
   * returns int percentage
   *
   * @param breadcrumbs
   * @param step
   * @returns {number}
   */
  const getProgress = (breadcrumbs, step) => {
    // const breadcrumbs = getBreadcrumbs(model)
    const index = breadcrumbs.findIndex(breadCrumb => breadCrumb.slug === step)
    return (index + 1) / breadcrumbs.length * 100
  }

  /**
   *
   * @param step
   * @param newPostFix
   */
  const goTo = (step, newPostFix = undefined) => {
    const route = pathname + '/' + cloneDeep(step) + getPostFix(newPostFix)
    navigate(route)
  }

  /**f
   * Disable next/prev buttons
   *
   * @param bool
   */
  const navButtonsDisabled = (bool = true) => {
    prevButtonDisabled(bool)
    nextButtonDisabled(bool)
  }

  /**
   *
   * @param bool
   */
  const prevButtonDisabled = (bool = true) => {
    setButtonPrev({...buttonPrev, [step]: {disabled: bool}})
  }

  /**
   *
   * @param bool
   */
  const nextButtonDisabled = (bool = true) => {
    setButtonNext({...buttonNext, [step]: {disabled: bool}})
  }

  /**
   *
   * @param buttonObj
   * @param key
   * @param defaultValue
   * @returns {*}
   */
  const getButtonValue = (buttonObj, key, defaultValue) => {
    if (
      step &&
      step in buttonObj &&
      key in buttonObj[step]
    ) {
      const value = buttonObj[step][key]
      if (Array.isArray(value)) {
        if (value[0] === 'if') {
          return form.formData.model[value[1]]
        } else if (value[0] === 'ifNot') {
          return !form.formData.model[value[1]]
        }
      } else {
        return value
      }
    } else {
      return defaultValue
    }
  }

  /**
   *
   * @param stepItem
   */
  const breadcrumbClick = (stepItem) => {
    if (!form.formData.isChanged) {
      goTo(stepItem.slug)
    }
  }

  /**
   *
   */
  const handleClickFinish = () => {
    if (!form.formData.isChanged) {
      goToFinish()
    }
  }

  return {
    nextStep,
    prevStep,
    getNextStep,
    getPrevStep,
    getStepDelta,
    goTo,
    goToFinish,
    getFinishStep,
    // hideStep,
    // showStep,
    navButtonsDisabled,
    prevButtonDisabled,
    nextButtonDisabled,
    setInitialButtons,
    // hasBreadcrumb,
    getStep,
    getBreadcrumbs,
    getProgress,
    buttonPrev,
    setButtonPrev,
    buttonReset,
    setButtonReset,
    buttonNext,
    setButtonNext,
    step,
    pathname,
    id,
    finished,
    setFinished,
    getButtonValue,
    breadcrumbClick,
    handleClickFinish,
    locationStateButtonPrev,
    locationStateButtonNext,
  }
}

export default useWizard
