import React from "react";
import ListBookables from "../../components/ListBookables/ListBookables";
import InfoBar from "../../components/InfoBar/InfoBar";
import "./style.scss"

const Home = () => {

  return (
    <div className="page-home">
      {/*<InfoBar*/}
      {/*  title={<>We zijn bijna klaar om live te gaan...</>}*/}
      {/*  text={<><p>Nog een klein beetje geduld! In de tussentijd ben je welkom om alvast rond te kijken!</p><p>Wil je op de hoogte blijven? Schrijf je in voor onze <a href="/service/nieuwsbrief/">nieuwsbrief</a></p></>}*/}
      {/*  id="almost-live-0"*/}
      {/*/>*/}
      <InfoBar
        title={<>Beta versie live!</>}
        text={<><p>Het kan zijn dat je nog bugs tegenkomt, of feedback hebt. <a href="/service/contact/" target="_blank">We horen dit graag van je!</a></p></>}
        id="beta-0"
      />
      <section className="list-bookables">
        <div className="section-container">
          <ListBookables/>
        </div>
      </section>
    </div>
  )
}

export default Home
