import useApi from "../useApi";
import useFunction from "../../hooks/useFunction";

const useInfoService = () => {

  const {api} = useApi()
  const {c} = useFunction()

  const userShowInfo = (callbackSuccess, callbackValidationErrors, id) => {
    return api(
      'get',
      'user/media/info/'+id,
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in Service', error),
    )
  }

  return {
    userShowInfo
  }
}

export default useInfoService
