import React from "react";
import {
  Form,
  Input,
  SelectArray,
  Media,
  Static,
  MultiBlock,
  AddMultiBlock,
  Fields
} from "../../components/Form/Form";
import countryCodes from "../../data/countryCodes";
import useCompanyService from "../../api/services/useCompanyService";
import {useParams} from "react-router-dom";
import useForm from "../../hooks/useForm";
import IbanMedia from "../../components/Form/IbanMedia";
import CompanyUsers from "../../components/Form/CompanyUsers";
import Address from "../../components/Form/Address";

const UserCompanyEdit = () => {

  //todo er is een error als je geen companies hebt, 2 toevoegd, en dan 2 verwijderd. (mss afbeelding; default afbeelding of demo data afbeelding error. Check even)
  const {id} = useParams();

  const companyService = useCompanyService()
  const form = useForm()

  return (
    <div className="page-user-company">
      <h1>Organisatie Instellingen</h1>
      <Form>
        <Fields
          id={id}
          form={form}
          apiService={companyService}
          GAEventLabel="User Company Edit"
        >
          <Media
            label="Logo"
            name="logo_media"
            width={120}
            height={120}
            size="logo"
          />
          <Static
            label="Standplaats Credits"
            name="credits"
          />
          <h3>Organisatie / bedrijfsgegevens & KVK</h3>
          {/*<Verified*/}
          {/*  label="Bedrijfsnaam"*/}
          {/*  name="legal_name_verified_at"*/}
          {/*  validateButtonOnClick={onClickValidate}*/}
          {/*  validateButtonLabel={"nu valideren via iDeal"}*/}
          {/*/>*/}
          <Input
            label="Organisatie naam of officiële bedrijfs naam"
            name="legal_name"
            placeholder="Voorbeeld: Standplaats.nl BV"
          />

          <Input
            label="KVK Nummer (Alleen invullen als deze organisatie ingeschreven staat bij de KVK)"
            name="coc_number"
          />
          <SelectArray
            label="Land van inschrijving (KVK)"
            name="country_code"
            data={countryCodes}
          />
          {/*<Verified*/}
          {/*  label="KVK & Bedrijfsnaam"*/}
          {/*  name="coc_number_verified_at"*/}
          {/*  validateButtonOnClick={() => {*/}
          {/*  }}*/}
          {/*  validateButtonLabel={"nu valideren"}*/}
          {/*/>*/}

          <hr/>
          <h3>Adres</h3>
          <Address
            text="Het adres waar je organisatie berijkbaar is."
            name="address"
          />
          <hr/>

          <h3>BTW</h3>
          {/*<Verified*/}
          {/*  label="BTW nummer"*/}
          {/*  name="vat_number_verified_at"*/}
          {/*  validateButtonOnClick={}*/}
          {/*  validateButtonLabel={"nu valideren"}*/}
          {/*/>*/}
          <Input
            label="BTW Nummer (alleen invullen als deze organisatie BTW plichtig is)"
            name="vat_number"
          />
          <hr/>


          <h3>Contactgegevens </h3>
          {/*<Verified*/}
          {/*  label="Telefoon"*/}
          {/*  name="phone_verified_at"*/}
          {/*  validateButtonOnClick={}*/}
          {/*  validateButtonLabel={"nu valideren"}*/}
          {/*/>*/}
          <Input
            label="Telefoonnummer"
            name="phone"
          />

          {/*Adres toevoegen*/}

          <hr/>

          <h3>Bankgegevens voor te ontvangen inkomsten</h3>
          <IbanMedia
            name="iban_media"
            width={900}
            size="normal_full_height"
            id={id}
          />
          <hr/>

          <CompanyUsers/>
        </Fields>
      </Form>
    </div>
  )
}

export default UserCompanyEdit
