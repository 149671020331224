import React from "react";
import useBookable from "../../hooks/useBookable";
import "./style.scss"

const BookableStatus = ({bookable}) => {

  const {getBookableStatus} = useBookable()

  return (
    <div className={'component-bookable-status ' + getBookableStatus(bookable).key}>
      <div className="status"></div>
      <span>{getBookableStatus(bookable).label}</span>
    </div>
  )
}

export default BookableStatus
