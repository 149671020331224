import React from "react";
import Messages from "../../components/Messages/Messages";
import './style.scss'

const UserMessages = () => {

  return(
    <div className="page-user-messages">
      {/*<h1>Berichten & Notificaties</h1>*/}
      {/*<p>Zie en reageer hier op alle berichten/chats met andere organisaties, notificaties, herinneringen, hulp-tickets en alerts.</p>*/}
      <Messages/>
    </div>
  )
}


export default UserMessages
