const countryCodes = [
  {
    label:'Nederland',
    value: 'nl',
  },
  {
    label:'Belgie',
    value: 'be',
  },
  {
    label:'Duitsland',
    value: 'de',
  },
];

export default countryCodes
