import React from "react";
import {default as RegisterComp} from "../../components/Register/Register"
import './style.scss';

const Register = () => {

  return (
    <div className="page-register">
      <h1>Registreren</h1>
      <h4>Laten we beginnen!</h4>
      <RegisterComp
        showLoginButton={true}
      />
    </div>
  )
}

export default Register
