import React, {useContext, useEffect} from "react";
import countryCodes from "../../data/countryCodes";
import AuthContext from "../../context/AuthContext";
import initCompany from "../../data/initCompany";
import {Form, Input, Media, Select, SelectArray, Static} from "../../components/Form/Form";
import {Route, useNavigate} from "react-router-dom";
import WaitingForVerification from "../WaitingFormVerification/WaitingForVerification";
import useCompanyService from "../../api/services/useCompanyService";
import useUserService from "../../api/services/useUserService";
import useFunction from "../../hooks/useFunction";
import useWizard from "../../hooks/useWizard";
import Register from "../../components/Register/Register";
import useForm from "../../hooks/useForm";
import Address from "../../components/Form/Address";
import Fields from "../../components/Form/Fields";
import Wizard from "../../layouts/Wizard/Wizard";
import Button from "../../components/Button/Button";
import Login from "../../components/Login/Login";
import spotGroupIcon from "../../assets/icons/spot-group.svg";
import accountIcon from "../../assets/icons/account.svg"
import truckIcon from "../../assets/images/truck-icon.png"
import standIcon from "../../assets/icons/stand.svg";
import './style.scss'
import SocialLoginButtons from "../../components/SocialLoginButtons/SocialLoginButtons";
// import IbanMedia from "../../components/Form/IbanMedia";
// import Verified from "../../components/Form/Verified";
// import useValidationIban from "../../hooks/useValidationIban";


function WizardWelcome() {

  const objIsEmpty = useFunction
  const pathname = '/wizard/welkom'
  const steps = [
    {slug: 'start', label: ''},
    {slug: 'choice_login_register'},
    {slug: 'register'},
    {slug: 'register_confirm_email'},
    {slug: 'login'},
    {slug: 'company_select'},
    {slug: 'company_new'},
    {slug: 'company_new_extra'},
    // {slug:'company_new_bank'},
    {slug: 'company_new_overview'},
    {slug: 'choice_stand_truck_location'},
  ]

  const initialButtonPrev = {
    start: {
      label: 'Annuleren',
      onClick: () => navigate('/'),
      className: 'btn-red',
    },
    register: {},
    register_confirm_email: {},
    login: {
      onClick: () => wizard.goTo('choice_login_register')
    },
    company_select: {
      label: 'Annuleren',
      onClick: () => navigate('/'),
      className: 'btn-red',
    },
    company_new: {
      onClick: () => navigate(-1)
    },
    company_new_overview: {
      label: "Mijn Standplaats",
      onClick: () => navigate('/user/mijn-standplaats'),
      className: 'btn-green'
    },
    choice_stand_truck_location: {
      label: "Annuleren",
      className: 'btn-red',
      onClick: () => navigate('/'),
    }
  }
  const initialButtonNext = {
    choice_login_register: {
      visible: false,
    },
    login: {
      visible: false,
    },
    register: {
      visible: false,
    },
    company_select: {
      disabled: true
    },
    company_new: {
      visible: false,
    },
    company_new_overview: {
      label: "Doorgaan"
    },
    choice_stand_truck_location: {
      visible: false,
    }
  }

  const updateSteps = (steps) => {
    return steps
  }
  const form = useForm()
  const wizard = useWizard(
    steps,
    updateSteps,
    pathname,
    initialButtonPrev,
    initialButtonNext,
    form,
  )


  const {setUser} = useContext(AuthContext)
  const {getToken} = useFunction()
  const navigate = useNavigate()
  const userService = useUserService()
  const companyService = useCompanyService()

  // const {onClickValidateIban} = useValidationIban(wizard.id, form, '/wizard/welkom/company_new_bank/'+wizard.id+'/')


  useEffect(() => {
    //not sure why, but it fixes that buttons are not showing
    if (wizard.step === 'start') {
      form.formData.isLoading = false
      form.save(false)
    }
    if (wizard.step === 'choice_login_register' && getToken()) {
      wizard.goTo('company_select')
    }
    if (wizard.step === 'login' && getToken()) {
      wizard.goTo('company_select')
    }
    if (wizard.step === 'company_select') {
      userService.userShow((user) => {
        setUser(user) /* todo test. needed here? update global user state */
        if (user.active_company_id) {
          wizard.setButtonNext(prevState => {
            return {
              ...prevState,
              company_select: {
                disabled: false,
                onClick: () => wizard.goTo('choice_stand_truck_location'),
              }
            }
          })
        }
      })
    }
  }, [wizard.step])

  const handleSelectActiveCompany = (user) => {
    if (user.active_company_id) {
      wizard.goTo('choice_stand_truck_location')
    } else {
      wizard.nextButtonDisabled()
    }
  }

  const onResetCompanySelect = () => {
    wizard.setInitialButtons()
  }

  //
  //
  // //todo change/. Same function in WizardWelcome.js and UserCompanyEdit.js
  // const onClickValidate = () => {
  //   swal({
  //     title: 'Let op!',
  //     icon: 'warning', //todo question
  //     text: 'Voorlopig kun je alles doen zonder gevalideerde IBAN. LET OP: Zorg er met absolute zekerheid voor dat het ' +
  //       'IBAN nummer wat hier ingevoerd is klopt! Als Standplaats.nl BV het geld overmaakt naar het verkeerde IBAN account, kunnen' +
  //       'we het geld niet meer terugkrijgen! Het is jouw verandwoordelijkheid om het juiste nummer hier in te voeren.',
  //     buttons: {
  //       confirm: {
  //         text: 'OK',
  //         value: true,
  //         visible: true,
  //         className: 'btn btn-green', //todo werkt nu niet??
  //         closeModel: true,
  //       }
  //     }
  //   })
  // }

  return (
    <div className="wizard-welcome">
      <Wizard
        form={form}
        wizard={wizard}
      >
        <Route path="/start/*" element={
          <div className="center">
            <h1>Welkom bij Standplaats.nl!</h1>
            <div className="width-medium">
              <p><strong>Op deze pagina's doorlopen we de volgende stappen:</strong></p>
              <ul>
                <li>Een <strong>account aanmaken of inloggen</strong> als je al over een account beschikt.</li>
                <li>Een <strong>organisatie creëren of toevoegen</strong> aan je account.</li>
                <li>Een <strong>Locatie, Stand of Truck aanmaken</strong>, afhankelijk van jouw behoefte.</li>
              </ul>
              <p>Je kunt later altijd terugkomen om verder te gaan of wijzigingen aan te brengen, zodat je op elk
                gewenst moment je informatie kunt bijwerken of aanpassen.</p>
            </div>

            <div className="truck-image">
              <img src={truckIcon} alt="Food Truck"/>
            </div>
          </div>
        }/>

        <Route path="/choice_login_register/*" element={
          <div className="center">
            <h2>Join the club!</h2>
            <div className="width-medium">
              <p>Om boekingsaavragen te doen moet je een account hebben</p>
              <p>Log in, of als je nog geen account hebt maken we die nu <span className="color-green"><strong>gratis & vrijblijvend</strong></span> aan!
              </p>
              <br/><br/>
              <h3>Ik wil:</h3>
              <div className="buttons">
                <Button
                  label="Account aanmaken"
                  onClick={() => wizard.goTo('register')}
                />
                <Button
                  label="Inloggen"
                  onClick={() => wizard.goTo('login')}
                />
              </div>
              <p>Of login/registreer met 1 klik:</p>
              <SocialLoginButtons/>
            </div>
          </div>
        }/>

        <Route path="/register/*" element={
          <Register
            showLoginButton={false}
          />
        }/>

        <Route path="/register_confirm_email/*" element={
          <WaitingForVerification/>
        }/>

        <Route path="/login/*" element={
          <Login
            showRegisterButton={false}
            updateSuccess={() => wizard.goTo('company_select')}
          />
        }/>

        <Route path="/company_select/*" element={
          <div className="center">
            <h2>Voor welke organisatie of bedrijf ben je nu actief?</h2>
            <AuthContext.Consumer>
              {
                ({setUser, user}) => {
                  return (
                    <div className="width-medium">
                      {!('companies' in user) || ('companies' in user && user.companies.length) ?
                        <>
                          <div className="width-small">
                            <Form>
                              <Fields
                                id={user.id}
                                form={form}
                                wizard={wizard}
                                apiService={userService}
                                updateSuccess={handleSelectActiveCompany}
                                onChange={() => wizard.navButtonsDisabled()}
                                onReset={() => onResetCompanySelect}
                              >
                                <Select
                                  label="Selecteer organisatie:"
                                  name="active_company_id"
                                  relation="companies"
                                  relationLabel="legal_name"
                                />
                              </Fields>
                            </Form>
                          </div>
                          <h3>Of:</h3>
                        </>
                        :
                        <>
                          <p>Je hebt nog geen organisatie of bedrijf gekoppeld aan dit account. Dit is wel nodig bij
                            Standplaats.nl</p>
                          <p>Is jouw organisatie of bedrijf nog niet eerder aangemaakt op Standplaats.nl? Maak hem dan
                            nu direct
                            aan:</p>
                        </>
                      }
                    </div>
                  )
                }
              }
            </AuthContext.Consumer>

            <Button
              onClick={() => wizard.nextStep()}
              label="Nieuwe organisatie aanmaken"
              color="grey-ghost"
            />

          </div>
        }/>

        <Route path="/company_new/*" element={
          <div className="center">
            <h2>Nieuwe organisatie toevoegen</h2>
            <p>Heeft iemand anders al jouw organisatie toegevoegd aan Standplaats.nl?</p>
            <p><strong>Vraag dan hem/haar om jouw uit te nodigen voor de organisatie.</strong></p>
            <br/>
            <div className="width-small">
              <Form>
                <Fields
                  initData={initCompany}
                  form={form}
                  wizard={wizard}
                  apiService={companyService}
                  updateSuccess={(company) => wizard.nextStep(company.id)}
                  onChange={() => wizard.navButtonsDisabled()}
                  onReset={() => onResetCompanySelect}
                >
                  <Input
                    label="Organisatie naam of officiële bedrijfs naam"
                    name="legal_name"
                    autoFocus="autofocus"
                  />
                  <Input
                    label="BTW Nummer (alleen invullen als deze organisatie BTW plichtig is)"
                    name="vat_number"
                  />
                  <Input
                    label="KVK Nummer (Alleen invullen als deze organisatie ingeschreven staat bij de KVK)"
                    name="coc_number"
                  />
                  <SelectArray
                    label="Land"
                    name="country_code"
                    data={countryCodes}
                  />
                </Fields>
              </Form>
            </div>
          </div>
        }/>

        <Route path="/company_new_extra/*" element={
          <div className="center">
            <h2>Organisatie gegevens</h2>
            <div className="width-small">
              <Form>
                <Fields
                  id={wizard.id}
                  form={form}
                  wizard={wizard}
                  apiService={companyService}
                  updateSuccess={() => wizard.nextStep()}
                  onChange={() => wizard.navButtonsDisabled()}
                  onReset={() => wizard.setInitialButtons()}
                >
                  <Input
                    label="Telefoonnummer"
                    name="phone"
                    autoFocus="autofocus"
                  />
                  <Media
                    label="Logo"
                    name="logo_media"
                    width={120}
                    height={120}
                    size="logo"
                  />
                  <Address
                    label="Organisatie adres"
                    text="Het adres waar je organisatie berijkbaar is."
                    name="address"
                  />

                  {/*<MultiBlock*/}
                  {/*  label="Gebruikers"*/}
                  {/*  name="users"*/}
                  {/*  keyLabel="email"*/}
                  {/*  confirmDelete="Word pas echt verwijderd nadat u op OPSLAAN geklikt heeft, onderaan deze pagina"*/}
                  {/*/>*/}
                  {/*<AddMultiBlock*/}
                  {/*  label="Gebruiker toevoegen aan deze organisatie"*/}
                  {/*  name="users"*/}
                  {/*  keyLabel="email"*/}
                  {/*  type="email"*/}
                  {/*  placeholder="Email van gebruiker"*/}
                  {/*  confirmAdd="U staat op het punt om %s volledige toegang te verleden tot deze organisatie."*/}
                  {/*/>*/}
                </Fields>
              </Form>
            </div>
          </div>
        }/>


        {/*<Route path="/company_new_bank/*" element={*/}
        {/*  <div className="center">*/}
        {/*    <h2>Bankgegevens</h2>*/}
        {/*    <div className="width-medium">*/}
        {/*      <Form>*/}
        {/*        <Fields*/}
        {/*          id={wizard.id}*/}
        {/*          form={form}*/}
        {/*          wizard={wizard}*/}
        {/*          apiService={companyService}*/}
        {/*          updateSuccess={() => wizard.nextStep()}*/}
        {/*          onChange={() => wizard.navButtonsDisabled()}*/}
        {/*          onReset={() => wizard.setInitialButtons()}*/}
        {/*        >*/}
        {/*          <IbanMedia*/}
        {/*            name="iban_media"*/}
        {/*            width={900}*/}
        {/*            size="normal_full_height"*/}
        {/*            id={wizard.id}*/}
        {/*          />*/}
        {/*        </Fields>*/}
        {/*      </Form>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*}/>*/}

        <Route path="/company_new_overview/*" element={
          <div className="center">
            <h2>Gefeliciteerd, de organisatie is succesvol toegevoegd!</h2>
            <div className="width-small">
              <Form>
                <Fields
                  id={wizard.id}
                  form={form}
                  wizard={wizard}
                  apiService={companyService}
                  onChange={() => wizard.navButtonsDisabled()}
                  onReset={() => onResetCompanySelect}
                >
                  <Static
                    label="Organisatie naam:"
                    name="legal_name"
                  />
                  <p>Nu kun je vanuit deze organisatie:</p>
                  <ul>
                    <li>Stands of trucks huren of verhuren</li>
                    <li>Locaties huren of verhuren</li>
                    <li>Berichten versturen</li>
                  </ul>
                </Fields>
              </Form>
            </div>
          </div>
        }/>


        <Route path="/choice_stand_truck_location/*" element={
          <div className="choice-stand-truck-location center">
            <h2>Wat biedt jouw organisatie aan?</h2>
            <div className="">
              <p>Het is voor ons belangrijk om te weten wat je wil doen op ons platform en om jou daar zo goed mogelijk
                bij te helpen, stellen we de nodige vragen:</p>
              <p>- Wil je een locatie aanbieden? -> Kies voor Locatie</p>
              <p>- Wil je een stand of truck op het platform zetten? -> Kies voor Stand of Truck</p>
              <p>- Of weet je het nog niet en wil je eerst op het platform rondkijken en later beslissen? -> Kies voor
                Gast</p>
              <br/>
              <br/>

              <div className="items">

                <div className="item">
                  <h3>Aanbieden:</h3>
                  <img width={70} src={spotGroupIcon} alt="locatie"/>
                  <div className="center">
                    <Button
                      label="Locatie"
                      link="/wizard/locatie/start"
                      linkState={{buttonPrev: {new: {onClick: {navigate: '/wizard/welkom/choice_stand_truck_location/'}}}}}
                    />
                  </div>
                  <br/>
                  <ul className="color-grey">
                    <div>Markten</div>
                    <div>Evenementen</div>
                    <div>Winkels</div>
                    <div>Bedrijventerreinen</div>
                  </ul>
                  <br/>
                  <br/>
                </div>

                <div className="item">
                  <h3>Ik zoek:</h3>
                  <img width={70} src={standIcon}/>
                  <div className="center">
                    <Button
                      label="Zoek"
                      link="/?search_model=stand"
                      color="green"
                    />
                  </div>
                  <br/>
                  <ul className="color-grey">
                    <div>Voor organisaties die op zoek zijn naar Stands, Trucks, Acts, maar die geen locatie profiel
                      willen
                      aanmaken
                    </div>
                  </ul>
                  <br/>
                  <br/>
                </div>

                <div className="item">
                  <h3>Ik bezit:</h3>
                  <img width={70} src={standIcon} alt="stand"/>
                  <div className="center">
                    <Button
                      label="Stand of Truck"
                      link="/wizard/stand/start"
                      linkState={{buttonPrev: {new: {onClick: {navigate: '/wizard/welkom/choice_stand_truck_location/'}}}}}
                    />
                  </div>
                  <br/>
                  <ul className="color-grey">
                    <div>Standhouders</div>
                    <div>Foodtrucks</div>
                    <div>Marktkramen</div>
                    <div>Mobiele ondernemers</div>
                    <div>Ambulante handelaren</div>
                  </ul>
                  <br/>
                  <br/>
                </div>

                <div className="item">
                  <h3>Ik ben:</h3>
                  <img width={70} src={accountIcon}/>
                  <div className="center">
                    <Button
                      label="Gast"
                      link="/"
                      color="grey-ghost"
                    />
                  </div>
                  <br/>
                  <ul className="color-grey">
                    <div>Later beslissen</div>
                    <div>Overig</div>
                  </ul>
                  <br/>
                  <br/>
                </div>

              </div>
              <div className="footer-buttons">
                <h4>Oplossing op maat voor grote organisaties:</h4>
                <div className="footer-items" style={{justifyContent: "space-evenly"}}>
                  <Button
                    label="Gemeentes"
                    linkOutside="/organisaties/gemeenten-marktmeesters/"
                    color="grey-ghost"
                  />
                  <Button
                    label="Holdings & franchises"
                    linkOutside="/service/contact/"
                    color="grey-ghost"
                  />
                  <Button
                    label="Overige"
                    linkOutside="/service/contact/"
                    color="grey-ghost"
                  />
                </div>
              </div>
            </div>
          </div>
        }/>

      </Wizard>
    </div>
  )
}

export default WizardWelcome
