import React, {useContext, useEffect, useState} from "react";
import AuthContext from "../../context/AuthContext";
import {Link, useLocation, useNavigate} from "react-router-dom";
import LiUserMenuButton from "../LiUserMenuButton/LiUserMenuButton";
import messageIcon from "../../assets/icons/message.svg"
import account from '../../assets/icons/account-solid.svg'
import alertIcon from "../../assets/icons/alarm.svg"
import favoriteIcon from "../../assets/icons/hearth-black.svg"
import bookingIcon from "../../assets/icons/booking.svg"
import standIcon from "../../assets/icons/stand.svg"
import spotGroupIcon from "../../assets/icons/spot-group.svg"
import companyIcon from "../../assets/icons/company.svg"
import accountIcon from "../../assets/icons/account.svg"
import logoutIcon from "../../assets/icons/logout.svg"
import myStandplaatsIcon from "../../assets/icons/favicon.svg"
import cogIcon from "../../assets/icons/cog-blue.svg";
import useFunction from "../../hooks/useFunction";
import useAuthService from "../../api/services/useAuthService";
import {useAppContext} from "../../context/AppContextProvider";
import Media from "../Media/Media";
import './style.scss'

const UserButton = () => {

  const {objIsEmpty} = useFunction()
  const {user, setUser} = useContext(AuthContext)
  const {logout} = useAuthService()
  const [isHovered, setIsHovered] = useState(false)
  const navigate = useNavigate();
  const {notifications} = useAppContext()
  const {isTouchScreen, ConditionalWrapper} = useFunction()
  const location = useLocation()


  useEffect(() => {
    setIsHovered(false)
  }, [location.pathname])

  const onMouseEnter = () => {
    setIsHovered(true)
  }

  const onMouseLeave = () => {
    setIsHovered(false)
  }

  const handleClickMessages = () => {
    navigate('/user/berichten')
  }

  const handleClick = () => {
    setIsHovered(true)
  }

  /**
   * @param e
   */
  const logoutSubmit = (e) => {
    e.preventDefault()
    logout((response) => {
      navigate('/')
      setUser(false)
    }, () => {})
  }

  return (
    <div className="component-user-button" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {notifications !== 0 &&
        <div onClick={handleClickMessages} className="notifications">{notifications}</div>
      }
      <div className="menu-user-button">
        <ConditionalWrapper
          condition={isTouchScreen()}
          wrapperA={children => <div onClick={handleClick}>{children}</div>}
          wrapperB={children => <Link
            to={(objIsEmpty(user)) ? '/wizard/welkom/start' : '/user/mijn-standplaats'}
            state={(objIsEmpty(user))
              ?{buttonPrev:{start:{onClick:{navigate:location.pathname}}}}
              :{}
            }>{children}</Link>}
        >
          <div className={(user && 'is_admin' in user && user.is_admin && user.active_company_id && user.active_company_id !== 1)?"square-button alien":"square-button"}>
            <div className="company-name">
              {
                !objIsEmpty(user) &&
                'active_company' in user &&
                user.active_company &&
                'legal_name' in user.active_company &&
                user.active_company.legal_name
                  ? user.active_company.legal_name
                  : 'Direct beginnen'
              }
            </div>
            <div className="user-name">
              {objIsEmpty(user) ? 'Login/registreer' : user.first_name}
            </div>
          </div>
          <div className="round-button">
            {
              !objIsEmpty(user) &&
              'avatar_media' in user &&
              user.avatar_media
                ? <Media className="avatar-user-image" media={user.avatar_media} size={'logo'} alt="Avatar"/>
                : <img className="avatar-placeholder" src={account} alt="Avatar"/>
            }
          </div>
        </ConditionalWrapper>


        {isHovered &&
          <div className="sub-menu-user-button">
            <ul>
              {!objIsEmpty(user)
                ?
                <>
                  <LiUserMenuButton
                    to="/user/mijn-standplaats"
                    title="Overzichtspagina van uw account"
                    label="Mijn Standplaats"
                    icon={myStandplaatsIcon}
                  />
                  <LiUserMenuButton
                    to="/user/berichten"
                    title="Notificaties, Berichten, Contact"
                    label="Berichten"
                    icon={messageIcon}
                  />
                  <LiUserMenuButton
                    to="/user/zoek-alerts"
                    title=""
                    label="Alert Meldingen"
                    icon={alertIcon}
                  />
                  <LiUserMenuButton
                    to="/user/favorieten"
                    title="Favoriete Stands, Locaties enz."
                    label="Favorieten"
                    icon={favoriteIcon}
                  />
                  <LiUserMenuButton
                    to="/user/boekingen"
                    title="Boekingen van of naar organisaties waarvoor je werkt, bezit of vertegenwoordigd"
                    label="Boekingen"
                    icon={bookingIcon}
                  />
                  <LiUserMenuButton
                    to="/user/stands"
                    title="Jouw FoodTrucks, Marktkraam, Mobiele onderneming of andere Stands"
                    label="Stands & Trucks"
                    icon={standIcon}
                  />
                  <LiUserMenuButton
                    to="/user/locaties"
                    title="Jouw Events, Markten, Braderien, Supermarkten of andere Locaties"
                    label="Locaties"
                    icon={spotGroupIcon}
                  />
                  <LiUserMenuButton
                    to="/user/organisaties"
                    title="Organisaties waarvoor je werkt, bezit of vertegenwoordigd"
                    label="Organisatie"
                    icon={companyIcon}
                  />
                  {/*<LiUserMenuButton*/}
                  {/*  to="/user/notificaties"*/}
                  {/*  title="Al jouw persoonlijke/account/login gegevens"*/}
                  {/*  label="Emails & Meldingen"*/}
                  {/*  icon={emailIcon}*/}
                  {/*/>*/}
                  <LiUserMenuButton
                    to="/user/account"
                    title="Al jouw persoonlijke/account/login gegevens"
                    label="Account"
                    icon={accountIcon}
                  />

                  {'is_admin' in user && user.is_admin &&
                    <>
                      <hr/>
                      <LiUserMenuButton
                        to="/user/admin"
                        title="Admin"
                        label="Admin"
                        icon={cogIcon}
                      />
                    </>
                  }

                  <hr/>
                  <li className="logout">
                    <div
                      className="logout-button"
                      onClick={logoutSubmit}
                    ><img src={logoutIcon} alt="Uitloggen"/> Uitloggen
                    </div>
                  </li>
                </>
                :
                <>
                  <li><Link
                    to="/wizard/welkom/start"
                    title="Direct beginnen"
                    state={{buttonPrev:{start:{onClick:{navigate:location.pathname}}}}}
                  >Direct beginnen</Link></li>
                  <li><Link
                    to="/inloggen"
                    title="Inloggen"
                  >Inloggen</Link></li>
                  <li><Link
                    to="/registreren"
                    title="Registreren"
                  >Registreren</Link></li>
                  <hr/>
                  <li><a
                    href="/service/help/"
                    title="Help"
                  >Help</a></li>
                  <li><a
                    href="/stand-truck-huren/locatie-verhuren/"
                    title="Verhuur Locatie"
                  >Verhuur Locatie</a></li>
                  <li><a
                    href="/hoe-werkt-het/"
                    title="Hoe werkt het?"
                  >Hoe werkt het?</a></li>
                  <hr/>
                  <li><a
                    href="/organisaties/evenementen-braderieen/"
                    title="Ik organiseer festivals"
                  >Ik organiseer festivals</a></li>
                  <li><a
                    href="/organisaties/gemeenten-marktmeesters/"
                    title="Ik werk bij een gemeente"
                  >Ik werk bij een gemeente</a></li>
                  <li><a
                    href="/organisaties/winkels-andere-organisaties/"
                    title="Ik heb een winkel, terrein of locatie"
                  >Ik heb een winkel, terrein of locatie</a></li>
                </>
              }
            </ul>
          </div>
        }
      </div>
    </div>
  )
}

export default UserButton
