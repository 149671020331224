import industries from "../data/industries";
import types from "../data/types";


const useData = () => {

  /**
   * Get industry or type or other same structured data by id
   *
   * @param id
   * @param data (industries or types)
   * @returns {*}
   **/
  const getTypeOrIndustry = (id, data) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === id) {
        return data[i]
      } else if ('children' in data[i]) {
        const result = getTypeOrIndustry(id, data[i].children)
        if (result) {
          return result
        }
      }
    }
  }

  /**
   * Get type by id
   *
   * @param id
   * @returns {*}
   */
  const getTypeById = (id) => {
    return getTypeOrIndustry(id, types)
  }

  /**
   * Get industry by id
   *
   * @param id
   * @returns {*}
   */
  const getIndustryById = (id) => {
    return getTypeOrIndustry(id, industries)
  }

  return {
    getTypeOrIndustry,
    getTypeById,
    getIndustryById,
  }
}

export default useData
