import initPrice from "./initPrice";

const initStand = {
  public: 1,
  available: 1,
  label: '',
  slogan: '',
  slug: null,
  lat: null,//'52.2129919',
  lng: null,//'5.2793703',
  radius: '90',
  availabilities: [],
  price: initPrice,
  options: [],
  infos: [],
}

export default initStand
