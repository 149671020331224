import React from "react";
import Button from "../Button/Button";
import cloneDeep from 'lodash/cloneDeep';

const EditButton = (props) => {

  const {
    link,
    model,
    isLoading,
    formData,
    originalFormDataModel,
    disabledOnChangeByKey,
    fullWidth,
    className,
    size,
    color,
  } = props
  const propsUpdate = cloneDeep(props)
  propsUpdate.className = className
  propsUpdate.disabled = isLoading
  propsUpdate.size = (size)?size:'s'
  propsUpdate.color = (color)?color:'grey'
  propsUpdate.fullWidth = fullWidth

  let warning = null

  if (!isLoading) {

    //todo maybe add more replaces

    propsUpdate.link = link.replace('%id', model.id)
  }

  if (disabledOnChangeByKey && formData.isChanged){
    if (originalFormDataModel[disabledOnChangeByKey] !== formData.model[disabledOnChangeByKey]){
      propsUpdate.disabled = true
      warning = 'Klik eerst op OPSLAAN of ANNULEREN om deze knop te gebruiken'
    }
  }

  return (
    <>
      {warning && <div className="color-red">{warning}</div>}
      <Button {...propsUpdate} />
    </>
  )
}

export default EditButton
