import useApi from "../../api/useApi";
import useFunction from "../../hooks/useFunction";

const useStandService = () => {

  const {api} = useApi()
  const {c} = useFunction()

  const userShow = (callbackSuccess, callbackValidationErrors, standId) => {
    return api(
      'get',
      'user/stand/' + standId,
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in Service', error),
    )
  }

  const userShowCollection = (callbackSuccess, callbackValidationErrors) => {
    return api(
      'get',
      'user/stands',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in Service', error),
    )
  }

  const userUpdate = (callbackSuccess, callbackValidationErrors, standId, data) => {
    return api(
      'post',
      'user/stand/' + standId + '/update',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in Service', error),
      data,
    )
  }

  const userShowManager = (callbackSuccess, callbackValidationErrors, id, callbackFailed) => {
    return api(
      'get',
      'user/stand/' + id + '/management/show',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      (error) => callbackFailed(error),
    )
  }

  const userStore = (callbackSuccess, callbackValidationErrors, modelId, data) => {
    return api(
      'post',
      'user/stand/store',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in Service', error),
      data,
    )
  }

  const filteredShow = (callbackSuccess, callbackValidationErrors, data) => {
    return api(
      'post',
      'stands/filtered',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in Service', error),
      data,
    )
  }

  const show = (callbackSuccess, callbackValidationErrors, slug, callbackFailed) => {
    return api(
      'get',
      'stand/show/' + slug,
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      (error) => callbackFailed(error),
    )
  }

  const userDelete = (callbackSuccess, callbackValidationErrors, id) => {
    return api(
      'post',
      'user/stand/' + id + '/delete',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in Service', error),
    )
  }

  return {
    objectType:'stand',
    userShow,
    userShowCollection,
    userShowManager,
    userUpdate,
    userStore,
    filteredShow,
    show,
    userDelete,
  }
}

export default useStandService
